
import * as Kr from "./kr_library";
/**
 * 当たり判定管理クラスです。
 */
export class KRCollision{
    public cList			:Kr.KRCircle[] = [];	                        //circleList:判定円を格納しておくリスト
    public bList			:Kr.KRBox[] = [];		                        //boxList:判定ボックスを格納しておくリスト

    public _tp 			    :Kr.KRPoint = Kr.Element.getKRPoint(0,0);	    //tranceformPoint:チェックに渡された座標を変換した座標を入れておく入れ物
    public _rough_circle_r	:number = 0;						        //roughCircleRadius:大雑把チェック用円の半径入れ（この範囲外はチェックする必要ない）
    public _rough_box		:Kr.KRBox = Kr.Element.getKRBox(0,0,1,1);

    public _selfPos		    :Kr.KRPoint = Kr.Element.getKRPoint(0,0);	    //※変更できるのは位置と回転とスケール。
    public _selfPivot		:Kr.KRPoint = Kr.Element.getKRPoint(0,0);	    //
    public _selfScale       :Kr.KRPoint = Kr.Element.getKRPoint(1,1);
    public _selfSin		    :number = 0;						        //現在の回転角θ(初期値0度)
    public _selfCos		    :number = 1;						        //現在の回転角θ(初期値0度)
    public _selfMSin		:number = 0;						        //更できるのは位置と回転とスケール。現在の回転角-θ
    public _selfMCos		:number = 1;						        //更できるのは位置と回転とスケール。現在の回転角-θ
    public _tempDegree		:number = 0;						        //同じ角度で何度も再計算させないため現在のDegを保存
    public _tempPoint		:Kr.KRPoint = Kr.Element.getKRPoint(0,0);
    public _tempSeg         :Kr.KRSegment = Kr.Element.getKRSegment(0, 0, 1, 1);

    public _latest_hit_type :number = 0;		                        //最も最近行ったCheckのhit type -> Circle or Box
    //constructor
    constructor(){

    }
    //functions
    /**
     * 登録されている、あたり判定用の円と方形を削除します。
     */
    public clear():void{
        'use strict';
        this.cList.splice(0, this.cList.length);
        this.bList.splice(0, this.bList.length);
    }
    /**
     * 自分の位置を設定します。
     */
    public setPosition(x:number, y:number):void{
        'use strict';
        this._selfPos.x = x;
        this._selfPos.y = y;
    }
    /**
     * 拡大縮小を設定します。
     */
    public setScale(x:number, y:number):void{
        'use strict';
        this._selfScale.x = x;
        this._selfScale.y = y;
    }
    /**
     * 回転を設定します。
     */
    public setRotate(degree:number):void{
        'use strict';
        while(degree < 0){
            degree = degree + 360;
        }
        while(degree >= 360){
            degree = degree - 360;
        }
        if (degree !== this._tempDegree) {
            let rad = degree * Math.PI / 180;
            this._selfSin = Math.sin(rad);
            this._selfCos = Math.cos(rad);
            this._selfMSin = -this._selfSin;		//-θ
            this._selfMCos = this._selfCos;			//-θ
            this._tempDegree = degree;
        }
    }
    /**
     * あたり判定（円要素）を追加します。
     */
    public addHitCircle(x:number, y:number, radius:number):void{
        'use strict';
        this.cList.push(Kr.Element.getKRCircle(x, y, radius));
    }
    /**
     * あたり判定（方形要素）を追加します。
     */
    public addHitBox(cx:number, cy:number, width:number, height:number):void{
        'use strict';
        this.bList.push(Kr.Element.getKRBox(cx, cy, width, height));
    }
    /**
     * 自分自身の位置と回転とスケールを(0,0),0°,(1,1)とした時の判定対象の位置(x,y)を(_tp.x, _tp.y)に設定します。
     * checkCAndC(),checkBAndC()を実行する前に、この関数を実行する必要があります。
     */
    public transformPosition(x:number, y:number):void{
        'use strict';
        //原点に移動
        let tempX:number = x - this._selfPos.x,
            tempY:number = y - this._selfPos.y;
        //回転
        this._tp.x = tempX * this._selfMCos - tempY * this._selfMSin;
        this._tp.y = tempX * this._selfMSin + tempY * this._selfMCos;
        //スケール
        this._tp.x = this._tp.x / this._selfScale.x;
        this._tp.y = this._tp.y / this._selfScale.y;
    }
    /**
     * 現在の位置と角度から新しい座標を算出します。
     */
    public transformPoint(p:Kr.KRPoint):void{
        'use strict';
        let tempX:number = p.x,
            tempY:number = p.y;
        //回転させる
        p.x = tempX * this._selfCos - tempY * this._selfSin;
        p.y = tempX * this._selfSin + tempY * this._selfCos;
        //移動
        p.x = p.x + this._selfPos.x;
        p.y = p.y + this._selfPos.y;
    }
    /**
     * 大雑把円の半径を設定します。
     */
    public setRoughCircle(radius:number):void{
        this._rough_circle_r = radius;
    }
    /**
     * 大雑把方形の幅と高さを設定します。
     */
    public setRoughBox(w:number, h:number):void{
        this._rough_box.width = w;
        this._rough_box.height = h;
    }
    /**
     * 大雑把円と円の当たり判定(radius=0にすれば点の判定になります）
     */
    public checkRoughCircleAndCircle(tx:number, ty:number, radius:number):boolean{
        'use strict';
        let xx:number = tx - this._selfPos.x,
            yy:number = ty - this._selfPos.y,
            rr:number = radius + this._rough_circle_r;
        if( xx * xx + yy * yy <= rr * rr ) {
            return true;
        }
        return false;
    }
    /**
     * 大雑把方形と円の当たり判定(radius=0にすれば点の判定になります)
     * 回転等も考慮してます。
     */
    public checkRoughBoxAndCircle(tx:number, ty:number, radius:number):boolean{
        'use strict';
        if(this._selfScale.x * this._selfScale.y === 0) return false;
        this.transformPosition(tx, ty);
        return this.checkBAndC(this._rough_box, radius);
    }

    //円リストと線分の当たり判定//////////////////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * 円リスト(cList)と線分(ax,ay,bx,by)の当たり判定をします。
     * Hitした円のindexを返します。
     */
    public checkCircleListAndSegment(ax:number, ay:number, bx:number, by:number):number{
        'use strict';
        if(this._selfScale.x * this._selfScale.y === 0) return -1;
        this.transformPosition(ax, ay);
        this._tempSeg.ax = this._tp.x;
        this._tempSeg.ay = this._tp.y;
        this.transformPosition(bx, by);
        this._tempSeg.bx = this._tp.x;
        this._tempSeg.by = this._tp.y;
        //全ての円に対して判定
        let len	= this.cList.length,
            i	= 0;
        for (i = 0; i < len; i++) {
            if(Kr.Collision.crossToSegmentAndCircle2(this._tempSeg, this.cList[i])) {
                return i;
            }
        }
        return -1;
    }

    //円リストと円の当たり判定(radius=0にすれば点の判定にもなる）///////////////////////////////////////////////////////////////////////////////
    /**
     * 円リスト(cList)と円(x, y, radius)の当たり判定をします。
     * Hitした円のindexを返します。
     */
    public checkCircleListAndCircle(x:number, y:number, radius:number):number{
        'use strict';
        if(this._selfScale.x * this._selfScale.y === 0) return -1;
        this.transformPosition(x, y);
        //全ての円に対して判定
        let len :number	= this.cList.length,
            i   :number	= 0;
        for (i = 0; i < len; i++) {
            if( this.checkCAndC(this.cList[i], radius) ) {
                return i;
            }
        }
        return -1;
    }
    /**
     * 円リスト(cList)と円(x, y, radius)の当たり判定の若干高速Ver.です。
     * 移動、回転等を考慮しないで判定を行います。
     * 画面UI等の不動なオブジェクトのHit判定に使用できます。
     */
    public checkCircleListAndCircleFast(x:number, y:number, radius:number):number {
        'use strict';
        //自分自身の位置は(0,0),0°固定。
        this._tp.x = x;
        this._tp.y = y;
        //全ての円に対して判定
        let len	= this.cList.length,
            i	= 0;
        for (i = 0; i < len; i++) {
            if( this.checkCAndC(this.cList[i], radius) ) {
                return i;
            }
        }
        return -1;
    }
    /**
     * 円リスト(cList)と円(x, y, radius)の当たり判定をします。
     * この関数は、Hitした全てのCircleのIndexを取得します。
     * Indexはhit_listに格納されます。
     */
    public checkCircleListAndCircleAll(x:number, y:number, radius:number, hit_list:number[]):void {
        'use strict';
        if(this._selfScale.x * this._selfScale.y === 0) return;
        this.transformPosition(x, y);
        //全ての円に対して判定
        let len :number	= this.cList.length,
            i   :number	= 0;
        for (i = 0; i < len; i++) {
            if( this.checkCAndC(this.cList[i], radius) ) {
                hit_list.push(i);
            }
        }
    }

    /**
     * 円リスト(cList)と円(x, y, radius)の当たり判定をします。
     * この関数は、cList内の指定indexのみ判定を行います。
     */
    public checkCircleListAndCircleIndex(x:number, y:number, radius:number, index:number):boolean {
        'use strict';
        if(this._selfScale.x * this._selfScale.y === 0) return false;
        this.transformPosition(x, y);
        return this.checkCAndC(this.cList[index], radius);
    }
    /**
     * ※この関数を直接呼び出さないでください。
     */
    private checkCAndC(circle:Kr.KRCircle, radius:number):boolean {
        'use strict';
        let xx = this._tp.x - circle.x,
            yy = this._tp.y - circle.y,
            rr = radius + circle.radius;
        return xx * xx + yy * yy <= rr * rr;
    }

    //Boxリストと円の当たり判定////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * 方形リスト(bList)と円(x,y,radius)の当たり判定をします。
     * Hitした方形のindexを返します。
     */
    public checkBoxListAndCircle(x:number, y:number, radius:number):number {
        'use strict';
        if(this._selfScale.x * this._selfScale.y === 0) return -1;
        this.transformPosition(x, y);
        //全てのBoxに対して判定
        let len	= this.bList.length,
            i	= 0;
        for (i = 0; i < len; i++) {
            if (this.checkBAndC(this.bList[i], radius)) return i;
        }
        return -1;
    }
    /**
     * 方形リスト(cList)と円(x, y, radius)の当たり判定の若干高速Ver.です。
     * 移動、回転等を考慮しないで判定を行います。
     * 画面UI等の不動なオブジェクトのHit判定に使用できます。
     */
    public checkBoxListAndCircleFast(x:number, y:number, radius:number):number {
        'use strict';
        //自分自身の位置は(0,0),0°固定。
        this._tp.x = x;
        this._tp.y = y;
        //全てのBoxに対して判定
        let len :number	= this.bList.length,
            i   :number	= 0;
        for (i = 0; i < len; i++) {
            if( this.checkBAndC(this.bList[i], radius) ) {
                return i;
            }
        }
        return -1;
    }
    /**
     * 方形リスト(cList)と円(x, y, radius)の当たり判定をします。
     * この関数は、Hitした全てのBoxのIndexを取得します。
     * Indexはhit_listに格納されます。
     */
    public checkBoxListAndCircleAll(x:number, y:number, radius:number, hit_list:number[]):void {
        'use strict';
        if(this._selfScale.x * this._selfScale.y === 0) return;
        this.transformPosition(x, y);
        //全てのBoxに対して判定
        let len	= this.bList.length,
            i	= 0;
        for (i = 0; i < len; i++) {
            if( this.checkBAndC(this.bList[i], radius) ) {
                hit_list.push(i);
            }
        }
    }

    /**
     * 方形リスト(cList)と円(x, y, radius)の当たり判定をします。
     * この関数は、bList内の指定indexのみ判定を行います。
     */
    public checkBoxListAndCircleIndex(x:number, y:number, radius:number, index:number):boolean {
        'use strict';
        if(this._selfScale.x * this._selfScale.y === 0) return false;
        this.transformPosition(x, y);
        return this.checkBAndC(this.bList[index], radius);
    }
    /**
     * ※この関数を直接呼び出さないでください。
     */
    private checkBAndC(box:Kr.KRBox, radius:number):boolean {
        'use strict';
        //topLeftが座標の原点(0, 0)にくるようにBoxを移動してから計算
        let cx  :number = box.cx,
            cy  :number = box.cy,
            w   :number = box.width,
            h   :number = box.height,
            tbx :number = this._tp.x - (cx - w / 2),
            tby :number = this._tp.y - (cy - h / 2);
        if (tbx < 0) {//左側
            if (tby < 0) {//左上
                if (tbx * tbx + tby * tby <= radius * radius) return true;
            }else if (tby > h) {//左下
                if (tbx * tbx + (tby - h) * (tby - h) <= radius * radius) return true;
            }else {//左中
                if (-tbx <= radius) return true;
            }
        }else if (tbx > w) {//右側
            if (tby < 0) {//右上
                if ((tbx - w) * (tbx - w) + tby * tby <= radius * radius) return true;
            }else if (tby > h) {//右下
                if ((tbx - w) * (tbx - w) + (tby - h) * (tby - h) <= radius * radius) return true;
            }else {//右中
                if (tbx - w <= radius) return true;
            }
        }else {//真ん中
            if (tby < 0) {//中上
                if (-tby <= radius) return true;
            }else if (tby > h) {//中下
                if (tby - h < radius) return true;
            }else {//中中
                return true;
            }
        }
        return false;
    }

}


