/**
 * Canvas表示用のスプライト管理クラスです。
 */
export class KRSprite2DManager{
    private STOCK_LENGTH        :number = 100;
    private _models             :{[key: string]: {model: typeof KRSprite2D, stocker:Array<KRSprite2D>, stock_count:number}};//連想配列(keyはnumber or string)
    private _sprites            :{[key: number]: {list:{[key:number]:KRSprite2D} , counter:number}};
    private _canvas             :HTMLCanvasElement;
    private _context            :CanvasRenderingContext2D;

    constructor(aCanvas:HTMLCanvasElement){
        this._models = {};
        this._sprites = {};
        this._canvas = aCanvas;
        this._context = this._canvas.getContext('2d');
        //デフォルトのSprite
        //this.registSpriteClass(KRSprite2D);
        this.registSpriteClass(KRSprite2D, "KRSprite2D");
    }

    /**
     * KRSprite2Dを継承したスプライトクラスを登録します。
     * 登録にはクラス名(name)を使用します。
     * 継承にはKr.inherits()を使用してください。
     * ※難読化するとエラーが出るので引数にclass_name(string)を追加することで対処。
     */
    /*
    public registSpriteClass(spriteModel:typeof KRSprite2D){
        //クラス名を取得
        let class_name  :string = spriteModel.name;
        if(class_name == ""){
            class_name = spriteModel.toString().match(/\w+/g)[1];
        }
        //2重登録チェック
        if(this._models[class_name]){ return; }
        //登録
        this._models[class_name] = {
            model: spriteModel,
            stocker: new Array(this.STOCK_LENGTH),//とりあえず100個
            stock_count: 0
        };
    }*/
    public registSpriteClass(spriteModel:typeof KRSprite2D, class_name:string){
        //2重登録チェック
        if(this._models[class_name]){ return; }
        //登録
        this._models[class_name] = {
            model: spriteModel,
            stocker: new Array(this.STOCK_LENGTH),//とりあえず100個
            stock_count: 0
        };
    }
    /** 指定boxからスプライトを取得もしくは作成します。
     */
    public getSprite(box:{model: typeof KRSprite2D, stocker:Array<KRSprite2D>, stock_count:number}){
        let SpriteModel:typeof KRSprite2D = box.model;
        let stocker:KRSprite2D[] = box.stocker;
        let count:number = box.stock_count;
        let res:KRSprite2D = null;
        if(count > 0){
            count--;
            res = stocker[count];
            stocker[count] = null;
            box.stock_count = count;
        }else{
            res = new SpriteModel();
            //自分が何のクラス（関数）か分かるように登録
            //※newはSpriteModelの完全な複製を生成するわけでは無いらしい。
            (res as any).name = SpriteModel.name;
        }
        return res;
    }
    /**
     * 引数spriteを再利用するためストックしておきます。
     * stocker.length == stock_count（満杯）ならなにもしません。
     */
    public stockSprite(sprite:KRSprite2D){
        let box     :{model: typeof KRSprite2D, stocker:Array<KRSprite2D>, stock_count:number} = this._models[(sprite as any).name];
        let stocker :Array<KRSprite2D> = box.stocker;
        let count   :number = box.stock_count;
        if(stocker.length > count){
            sprite.init();
            stocker[count] = sprite;
            box.stock_count++;
        }
    }
    /**
     * Sprite2Dを作成し、返します。
     * ※難読化するとエラーが出るので引数をObjectからstringに変更
     */
    /*
    public createSprite(spriteModel:typeof KRSprite2D, image:HTMLImageElement, image_pattern:KRImagePattern, ax:number, ay:number, az:number){
        let box = this._models[spriteModel.name];
        if(!box){ return null;}
        if(image && !image_pattern){
            image_pattern = new KRImagePattern(image, 1, 1);
        }
        let res = this.getSprite(box);
        //色々設定
        res._parent = this;
        res.setImage(image, image_pattern);
        if(ax && ay){
            res.setPosition(ax, ay);
        }
        let z = 0;
        if(az){
            z = az;
        }
        //登録
        this.setDrawOrder(res, z);
        return res;
    }
    */
    public createSprite(class_name:string, image:HTMLImageElement, image_pattern:KRImagePattern, ax:number, ay:number, az:number){
        let box = this._models[class_name];
        if(!box){ return null;}
        if(image && !image_pattern){
            image_pattern = new KRImagePattern(image, 1, 1);
        }
        let res = this.getSprite(box);
        //色々設定
        res._parent = this;
        res.setImage(image, image_pattern);
        if(ax && ay){
            res.setPosition(ax, ay);
        }
        let z = 0;
        if(az){
            z = az;
        }
        //登録
        this.setDrawOrder(res, z);
        return res;
    }
    /**
     * Sprite2DをdrawIndexに登録します。
     */
    public setDrawOrder(sprite:KRSprite2D, drawIndex:number){
        let value = sprite._z.value;
        let id = sprite._z.id;
        //value != -1　ならすでに登録済なのでまず、切り離し作業
        if(value !== -1){
            delete this._sprites[value].list[id];
        }
        //登録
        if(!this._sprites[drawIndex]){
            this._sprites[drawIndex] = {
                list:{},
                counter:0//これはlist内の増減にかかわらず、常に増えてゆく
            };
        }
        let prop = this._sprites[drawIndex];
        id = prop.counter;
        prop.list[id] = sprite;
        sprite._z.value = drawIndex;
        sprite._z.id = id;
        prop.counter++;
    }
    /**登録されているスプライトを更新します。
     */
    public move(elapsed:number){
        let sprites = this._sprites;
        let sprite = null;
        for(let value in sprites){
            let list = sprites[value].list;
            for(let id in list){
                list[id].doMove(elapsed);
            }
        }
    }
    /**
     * 描画します。
     */
    public draw(){
        let canvas  :HTMLCanvasElement = this._canvas;
        let context :CanvasRenderingContext2D = this._context;
        context.setTransform(1, 0, 0, 1, 0, 0);//何もしない(defaultの)変換行列
        context.clearRect(0, 0, canvas.width, canvas.height);
        //描画
        let sprites = this._sprites;
        let sprite = null;
        for(let value in sprites){
            let list = sprites[value].list;
            for(let id in list){
                sprite = list[id];
                if(!sprite.dead){
                    sprite.doDraw(context);
                }
            }
        }

    }
    /**
     * sprite.dead==trueを排除します。
     */
    public removeDeadSprite(){
        let sprites = this._sprites;
        let sprite = null;
        for(let value in sprites){
            let list = sprites[value].list;
            for(let id in list){
                sprite = list[id];
                if(sprite.dead){
                    //登録を削除
                    delete sprites[value].list[id];
                    //ストック
                    this.stockSprite(sprite);
                }
            }
        }
    }

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export class KRImagePattern{
    public width:number;
    public height:number;
    public pattern_width:number;
    public pattern_height:number;
    public x_length:number;
    public y_length:number;
    constructor(image:HTMLImageElement, x_length:number, y_length:number){
        let w = image.naturalWidth,
            h = image.naturalHeight;
        if(x_length === 0){
            x_length = 1;
        }
        if(y_length === 0){
            y_length =1;
        }
        this.width = w;
        this.height = h;
        this.pattern_width = Math.floor(w / x_length);
        this.pattern_height= Math.floor(h / y_length);
        this.x_length = x_length;
        this.y_length = y_length;
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export class KRSprite2D{
    public dead             :boolean;
    public _pattern_index   :number;
    public _parent          :KRSprite2DManager;         //マネージャクラス
    public _center          :{
                                x:number,                //中心位置x デフォルトはwidth/2
                                y:number,                 //中心位置y デフォルトはheight/2
                            };
    public _position        :{
                                x:number,                //位置x
                                y:number,                 //位置y
                            };
    public _z               :{
                                value: number,          //z値
                                id: number              //同z値の識別番号(登録先から貰う)
                            };
    public _scale           :{
                                x:number,                //x方向縮尺
                                y:number                 //y方向縮尺
                            };
    public _degree:        number;      //角度
    public _v_vector:      {
                                x: number,               //移動方向x(正規化)
                                y: number                //移動方向y(正規化)
                            };
    public _a_vector        :{
                                x: number,               //加速度方向x(正規化)
                                y: number                //加速度方向y(正規化)
                            };
    public _now_v           :number;        //現在速度
    public _now_a           :number;        //現在の加速度
    public _image           :HTMLImageElement;
    public _image_pattern   :KRImagePattern;
    //constructor
    constructor(){
        this.dead = false;
        this._pattern_index = 0;
        this._parent = null;    //マネージャオブジェクト
        this._center = {
            x:0,                //中心位置x デフォルトはwidth/2
            y:0,                 //中心位置y デフォルトはheight/2
        };
        this._position = {
            x:0,                //位置x
            y:0,                 //位置y
        };
        this._z = {
            value: -1,          //z値
            id: -1,              //同z値の識別番号(登録先から貰う)
        };            //描画順序
        this._scale = {
            x:1,                //x方向縮尺
            y:1,                 //y方向縮尺
        };
        this._degree = 0;       //角度
        //move
        this._v_vector = {
            x: 0,               //移動方向x(正規化)
            y: 0                //移動方向y(正規化)
        };
        this._a_vector = {
            x: 0,               //加速度方向x(正規化)
            y: 0,                //加速度方向y(正規化)
        };
        this._now_v = 0;        //現在速度
        this._now_a = 0;        //現在の加速度

        /**@param {Image} _image*/
        this._image = null;
        this._image_pattern = null;
    }
    /**
     * imageを設定します。image_pattern==nullなら自動で作成されます。
     * image==nullなら、image_patternもnullになります。
     */
    public setImage(image:HTMLImageElement, image_pattern:KRImagePattern){
        if(image && image_pattern == null){
            image_pattern = new KRImagePattern(image, 1, 1);
        }
        this._image = image;
        this._image_pattern = image_pattern;
    }
    /**
     * 描画順を設定します。
     */
    public setDrawOrder(drawIndex:number){
        this._parent.setDrawOrder(this, drawIndex);
    }
    /**
     * 位置を設定します。
     */
    public setPosition(x:number, y:number){
        this._position.x = x;
        this._position.y = y;
    }
    /**
     * 画像の中心を設定します。デフォルト(0, 0)はImage_Patternの中心となります。
     * 例えば(100, 100)と設定した場合、画像は右と下に100移動して表示されます。
     */
    public setCenterPosition(x:number, y:number){
        this._center.x = x;
        this._center.y = y;
    }
    /**
     * 縮尺の設定です。
     */
    public setScale(x:number, y:number){
        this._scale.x = x;
        this._scale.y = y;
    }
    /**
     * Image Patternを設定します。
     */
    public setPatternIndex(index:number){
        this._pattern_index = index;
    }
    /**
     * 回転を設定します。。
     */
    public setRotate(value:number){
        while(value < 0){
            value = value + 360;
        }
        while(value >= 360){
            value = value - 360;
        }
        this._degree = value;
    }
    /**
     * 抽象メソッド
     */
    public doMove(elapsed:number){

    }
    /**
     * 描画メソッドです。
     */
    public doDraw(_context:CanvasRenderingContext2D){
        if(!this._image)return;
        let x_len   :number = this._image_pattern.x_length,
            y_len   :number = this._image_pattern.y_length,
            sw      :number = this._image_pattern.pattern_width,
            sh      :number = this._image_pattern.pattern_height,
            sx      :number = (this._pattern_index % x_len) * sw,
            sy      :number = Math.floor(this._pattern_index / x_len) * sh,
            dw      :number = sw,
            dh      :number = sh,
            trans_cx:number = this._center.x,
            trans_cy:number = this._center.y,
            trans_x :number = this._position.x,
            trans_y :number = this._position.y,
            dx      :number = trans_x - dw / 2 + trans_cx,
            dy      :number = trans_y - dh / 2 + trans_cy;
        //初期化(※座標変換は逆順で行う！)
        _context.setTransform(1, 0, 0, 1, 0, 0);//何もしない(defaultの)変換行列
        //描画位置
        //_context.translate(this._position.x, this._position.y);
        _context.translate(trans_x, trans_y);
        //回転
        if(this._degree !== 0){
            _context.rotate(this._degree * Math.PI / 180);
        }
        //スケール
        _context.scale(this._scale.x, this._scale.y);
        //中心へ移動
        _context.translate(-trans_x, -trans_y);
        //描画
        _context.drawImage(this._image, sx, sy, sw, sh, dx, dy, dw, dh);
    }
    /**
     * 内容を初期化します。
     */
    public init(){
        this.dead = false;
        this._pattern_index = 0;
        this._parent = null; //マネージャオブジェクト
        this._center.x = 0;
        this._center.y = 0;
        this._position.x = 0;
        this._position.y = 0;
        this._z.value = -1;
        this._z.id = -1;
        this._scale.x = 1;
        this._scale.y = 1;
        this._degree = 0;
        this._v_vector.x = 0;
        this._v_vector.y = 0;
        this._a_vector.x = 0;
        this._a_vector.y = 0;
        this._now_v = 0;
        this._now_a = 0;
        this._image = null;
        this._image_pattern = null;
    }

}







