export default function KRImageLoader(){
    "use strict";
/* コーディングサンプル(canvasを固定・中央ぞろえにし、sourcesで指定した画像を読み込み、完了したらcanvasに描画する)
    //canvas作成・追加
    let divv = document.createElement("div");
    divv.style.position = "fixed";
    divv.style.left = "0px";
    divv.style.top = "0px";
    divv.style.width = "100%";
    divv.style.height = "100%";
    divv.style.backgroundColor = "green";
    let canvas = document.createElement("canvas");
    canvas.style.position = "absolute";
    canvas.style.left = "0px";//※中央ぞろえにするにはtop,left,right,bottomも指定しないと適用されない。
    canvas.style.top = "0px";
    canvas.style.right = "0px";
    canvas.style.bottom = "0px";
    canvas.style.margin = "auto";
    canvas.style.backgroundColor = "red";
    //canvas.style.width = "512px";//<-拡大縮小？
    //canvas.style.height = "480px";//拡大縮小？
    canvas.setAttribute("width", "1024px");
    canvas.setAttribute("height", "480px");
    let context = canvas.getContext('2d');
    divv.appendChild(canvas);
    document.body.appendChild(divv);
    //画像のロード
    let img_loader = KRImageLoader();
    let sources = {
        test_00: "../img/trump/0.png",
        test_01: "../img/trump/1.png"
    };
    //描画
    img_loader.loadWithObject(sources, function(images) {
        //初期化(※座標変換は逆順で行う！)
        context.setTransform(1, 0, 0, 1, 0, 0);//何もしない(defaultの)変換行列
        //描画位置
        context.translate(150, 150);
        //45度回転
        context.rotate(45 * Math.PI / 180);
        //縦を1/2
        context.scale(0.5, 0.5);
        //中心へ移動
        let w = images.test_00.naturalWidth;
        let h = images.test_00.naturalHeight;
        context.translate(-w/2, -h/2);

        context.drawImage(images.test_00, 0, 0);
        //context.drawImage(images.test_01, 300, 50);
    });
*/
    let _numImages      :number = 0;         //loadする画像数
    let _loadedImages   :number = 0;      //loadが完了した数
    let _now_loading    :boolean = false;   //現在ロード中...
    let _images_ary     :HTMLImageElement[] = null;
    let _images_obj     :{[key:string]:HTMLImageElement} = null;

    /**
     * sources(Object)のプロパティに記載されたURLから画像をロードします。
     * 全てのロードが完了したらimages(Object)を引数とするcallbackを実行します。
     * @param {Object} sources
     * @param {Function} callback
     */
    function loadWithObject(sources:{[key:string]:string}, callback:(images:{[key:string]:HTMLImageElement})=>void) {
        if(_now_loading) return;
        _now_loading = true;
        //初期化
        _images_obj = {};
        _loadedImages = 0;
        _numImages = 0;
        // get num of sources
        for(let src in sources) {
            _numImages++;
        }
        for(let src in sources) {
            _images_obj[src] = new Image();
            _images_obj[src].onload = function() {
                //全ての読み込みが完了したらcallback()
                if(++_loadedImages >= _numImages) {
                    callback(_images_obj);
                    _now_loading = false;
                }
            };
            _images_obj[src].src = sources[src];//これで読み込み
        }
    }

    /**
     * sources(Array)に記載されたURLから画像をロードします。
     * 全てのロードが完了したらimages(Array)を引数とするcallbackを実行します。
     * @param {Array.<string>} sources
     * @param {Function} callback
     */
    function loadWithArray(sources:string[], callback:(images:HTMLImageElement[])=>void) {
        if(_now_loading) return;
        _now_loading = true;
        //初期化
        _images_ary = [];
        _loadedImages = 0;
        _numImages = 0;
        // get num of sources
        _numImages = sources.length;
        let i = 0;
        for(i = 0; i < _numImages; i++) {
            _images_ary[i] = new Image();
            _images_ary[i].onload = function() {
                //全ての読み込みが完了したらcallback()
                if(++_loadedImages >= _numImages) {
                    callback(_images_ary);
                    _now_loading = false;
                }
            };
            _images_ary[i].src = sources[i];
        }
    }

    return{
        loadWithObject,
        loadWithArray,
        now_loading: _now_loading
    }

}
