import * as CFDT from "../../common/cfunc_daifugou";
import KRImageLoader from "./kr_image_loader";
import { KRCollision } from "./kr_collision";
import { KRSprite2DManager } from "./kr_sprite2d";
import { KRSprite2D } from "./kr_sprite2d";
import { KRImagePattern } from "./kr_sprite2d";

//TYPES----------------------------------------------------------------------------------------
/**
 * @typedef {{index:number,num:number,mark:number,selected:boolean}} CardObject
 * @typedef {{rank:number,exist_joker:boolean,cards:CardObject[]}} PutDownCardsContentObject
 * @typedef {{symbol_lock:number,discard_8:number,must_always_win:number,eleven_back:number,s3_win_joker:number}} OptionRulesObject
 * @typedef {{recruit_time:number,players_count:number,round_count:number,thinking_time:number}} SettingsObject
 * @typedef {{now_round:number,now_turn:number,now_order_name:string,latest_putter_name:string,putdown_history:PutDownCardsContentObject[],rankers:string[],now_kakumei:boolean,now_symbol_lock:number,now_eleven_back:number}} RoundStatusObject
 * @typedef {{name:string,img_avater_index:number,img_avater:Image,ranking:number,status:number,score:number[],cards:CardObject[],card_length:number}} PlayerObject
 * @typedef {{prog_status:number,settings:SettingsObject,option_rules:OptionRulesObject,round_status:RoundStatusObject,players:PlayerObject[]}} PlayingDataObject
 * @typedef {{max:SettingsObject,min:SettingsObject,def:SettingsObject,per:SettingsObject,val:SettingsObject}} SettingsSetObject
 */
export default function KRDaifugouUI(){
    "use strict";
//CONST Public/////////////////////////////////////////////////////////////////////////////
    let cfunc = CFDT;                                       //サーバ側と合わせてみた
    const ST_STANDBY          = cfunc.ST_STANDBY;            //待機中...Game開始できますよ。
    const ST_RECRUIT          = cfunc.ST_RECRUIT;            //募集中
    const ST_PLAYING          = cfunc.ST_PLAYING;            //プレイ中
    const ST_DISPLAY_RESULT   = cfunc.ST_DISPLAY_RESULT;     //結果表示中
    const ST_EXCHANGE_CARDS   = cfunc.ST_EXCHANGE_CARDS;     //カード交換中
    const ST_GAME_OVER        = cfunc.ST_GAME_OVER;          //ゲームオーバー中
    const ST_DONE             = cfunc.ST_DONE;               //あがり
    const ST_DISCONNECT       = cfunc.ST_DISCONNECT;         //落ちた

    const RANKING_DAI_HINMIN  = cfunc.RANKING_DAI_HINMIN;
    const RANKING_HINMIN      = cfunc.RANKING_HINMIN;
    const RANKING_HEIMIN      = cfunc.RANKING_HEIMIN;
    const RANKING_FUGOU       = cfunc.RANKING_FUGOU;
    const RANKING_DAI_FUGOU   = cfunc.RANKING_DAI_FUGOU;

    const EXCHANGE_TOP_LENGTH = cfunc.EXCHANGE_TOP_LENGTH;
    const EXCHANGE_SECOND_LENGTH = cfunc.EXCHANGE_SECOND_LENGTH;
//CONST Private/////////////////////////////////////////////////////////////////////////////
    const TXT_RANKING_DAI_HINMIN  = '大貧民';
    const TXT_RANKING_HINMIN      = '貧民';
    const TXT_RANKING_HEIMIN      = '平民';
    const TXT_RANKING_FUGOU       = '富豪';
    const TXT_RANKING_DAI_FUGOU   = '大富豪';
    const TXT_MARK_KAKUMEI        = '革';
    const TXT_MARK_SYMBOL_LOCK    = '縛';
    const TXT_MARK_ELEVEN_BACK    = '11';
    const TXT_EXCHANGE_CARDS_WAIT = 'カード交換中です...';
    const TXT_EXCHANGE_CARDS_00   = 'カードを';
    const TXT_EXCHANGE_CARDS_01   = '枚選択してください。';
    const CARDSPRITE_CAPACITY = 20;
    const CARD_SCALE = 0.5;
    const CARD_SELECTED_UP = 16;
    const CARD_ON_CURSOR_UP = 4;
    const CARD_SELECTED_COLOR = 'rgb(0, 255, 255)';
    const BTN_COLOR = 'rgb(128, 160, 128)';
    const BTN_RIGHT_COLOR = 'rgb(160, 192, 160)';
    const BTN_DARK_COLOR = 'rgb(96, 128, 96)';
    const BTN_TEXT_COLOR = 'rgb(255, 128, 128)';
    const BTN_POS_Y = 360;
    const BTN_WIDTH = 112;
    const BTN_HEIGHT = 40;
    const BTN_DEPTH = 4;
    const TEXT_BTN_PASS = 'パス';
    const TEXT_BTN_PUTDOWN = '出す';
    const TEXT_BTN_EXCHANGE = '交換';
    const MAX_CANVAS_WIDTH = 512;
    const MAX_CANVAS_HEIGHT = 400;
    const CARD_PADDING = 4;
    const HAND_PADDING = 4;
    const ST_PLAYERS_ROW_LENGTH = 2;
    const ST_PLAYERS_COL_LENGTH = 4;
    const BIG_BAR_HEIGHT = 12;
    const SMALL_BAR_HEIGHT = 4;
//画面の大きさで決まる変数//////////////////////////////////////////////////////////
    let _st_player_width = 128;
    let _st_player_height = 64;
    let _card_pos_top = 172;
///////////////////////////////////////////////////////////////////////////////////
    let _first_setup = false;
    let _activated = false;
    /** @type {Function} */
    let _func_do_my_putdown_action  :(putdown_indexs:number[])=>boolean = null;
    /** @type {KRSprite2DManager} _mgr_sprite */
    let _mgr_sprite                 :KRSprite2DManager = null;
    /** @type {PlayingDataObject} _read_only_playing_data 呼び出し専用。この中でデータをいじっては絶対ダメ*/
    let _read_only_playing_data     :CFDT.PlayingDataObject = null;
    //フレーム更新用Timer
    let _frame_timer = {
        id: 0,
        old_msec: 0
    };
    let _sp_cards                   :{hand:CardSprite2DT[], stock:CardSprite2DT[]} = {hand: [], stock: []};
    /** @type {ButtonSprite2DT} _sp_button */
    let _sp_button                  :ButtonSprite2DT   = null;      //button描画
    /** @type {StPlayerSprite2DT} _sp_st_players */
    let _sp_st_players              :StPlayerSprite2DT = null;

    let _parent_elm                 :HTMLElement = null;
    //_canvasの作成とdocumentへの追加
    let _screen                     :HTMLDivElement = null;//縮尺が絡むとこれが必要になる
    let _canvas                     :HTMLCanvasElement = null;
    let _down_start = {
        now_down: false,
        time: 0,
        x: 0,
        y: 0
    };
    //自身のユーザー情報
    let _my_username                :string = "";
    let _my_player                  :CFDT.PlayerObject = null;
    //制限時間
    let _limit = {
        now_msec: 1,
        max_msec: 1,
        rate: 1
    };
    //操作ロック
    let _ctrl_lock = false;
    /**
     * debug用
     */
    let _txt_status = ['STANDBY','RECRUIT','PLAYING','DISPLAY_RESULT','EXCHANGE_CARDS','GAME_OVER','DONE','DISCONNECT'];
    let _touch_x = 0;
    let _touch_y = 0;
    let _touch_name = "";
    /**
     * スマホかPCかで、イベントを選別
     */
    let _support_touch = 'ontouchend' in document;
    let EVENTNAME_TOUCHSTART = _support_touch ? 'touchstart' : 'mousedown';
    let EVENTNAME_TOUCHMOVE = _support_touch ? 'touchmove' : 'mousemove';
    let EVENTNAME_TOUCHEND = _support_touch ? 'touchend' : 'mouseup';
    /**
     * Touch Eventだとevent.offsetX,offsetYが存在しないので、_canvasのスケールと位置（スケールを考慮しない）を設定時に取得しておく必要がある。
     * この変数が無くても、event内プロパティを漁ればわかりそうだが、設定時に取得しとけば手間もかからないだろう・・・という考え。
     * つまりTouch Event以外では使ってない。
     */
    let _screen_values = {
        now_left: 0,
        now_top: 0,
        now_scale: 1
    }
    /*
    let sssss = function(p:number | string){
        if(typeof p === 'number'){

        }else{

        }
    }
    */

    /**
     * @param {Event} e
     */
    let userEvent = function(e:any) {
        e.preventDefault();//動作を停止
        //console.log(e);
        if(_ctrl_lock) return;//コントロールがロックされていれば何もしない。
        if(_my_player && _my_player.status !== ST_PLAYING && _my_player.status !== ST_EXCHANGE_CARDS) return;//プレイ中でも交換中でも無ければ何もしない
        // マウス位置を取得する(pageX,pageYだと画面左上からの位置になる)
        let now_x = 0,
            now_y = 0,
            cards = _sp_cards.hand,
            i = 0,
            len = cards.length,
            hit_index = -1,
            col = null;
        if(e.changedTouches) {  //TouchイベントにはOffsetが無いのでこうするしかない。
            now_x = e.changedTouches[0].pageX / _screen_values.now_scale - _screen_values.now_left;
            now_y = e.changedTouches[0].pageY / _screen_values.now_scale - _screen_values.now_top;
            now_x = Math.floor(now_x);
            now_y = Math.floor(now_y);
        } else {                //Mouse
            now_x = e.offsetX;
            now_y = e.offsetY;
        }
        //_touch_x = now_x;
        //_touch_y = now_y;
        //初期化
        _sp_button.pushed = false;
        for(i = 0; i < len; i++){
            cards[i].on_cursor = false;
        }
        //e.type
        switch(e.type){
            case EVENTNAME_TOUCHSTART:
                //_touch_name = EVENTNAME_TOUCHSTART;
                doDown();
                break;
            case EVENTNAME_TOUCHMOVE:
                //_touch_name = EVENTNAME_TOUCHMOVE;
                doMove();
                break;
            case EVENTNAME_TOUCHEND:
                //_touch_name = EVENTNAME_TOUCHEND;
                doUp();
                break;
            default:
        }
        function doDown(){
            _down_start.now_down = true;
            _down_start.x = now_x;
            _down_start.y = now_y;
            checkCollision();
        }
        function doMove(){
            checkCollision();
        }
        function doUp(){
            checkCollision();
            _down_start.now_down = false;
            if(_sp_button.pushed){
                //Buttonを押した時することをここに記述
                _sp_button.pushed = false;
                doPushedButton();
                return;
            }
            if(hit_index !== -1){
                let card = cards[hit_index];
                card.selected = !card.selected;
                card.on_cursor = false;
            }
        }
        function checkCollision(){
            if(_down_start.now_down && _sp_button.visibled && _sp_button.col.checkRoughBoxAndCircle(now_x, now_y, 0)){
                _sp_button.pushed = true;
                return;
            }
            //衝突判定
            //hit_index = -1;
            for(i = len - 1; i >= 0; i--){
                col = cards[i].col;
                if(col.checkRoughBoxAndCircle(now_x, now_y, 0)){
                    hit_index = i;
                    cards[i].on_cursor = true;
                    break;
                }
            }
        }
    };
    /**
     * ボタンが押されたときにする処理
     */
    function doPushedButton(){
        //選択されているカードインデックスを抽出
        let putdown_indexs = [],
            i = 0,
            hand = _sp_cards.hand,
            len = hand.length;
        for(i=0; i < len; i++){
            if(hand[i].selected){
                putdown_indexs.push(hand[i].card_index);
            }
        }
        //実行して、行動が成功すれば、ボタンを消す。
        if(_func_do_my_putdown_action(putdown_indexs)){
             _sp_button.visibled = false;
        }
    }
//CardSprite2DT/////////////////////////////////////////////////////////////////////////////////////////
    class CardSprite2DT extends KRSprite2D{
        public col          :KRCollision = new KRCollision();
        public card_index   :number = 0;
        public selected     :boolean = false;
        public on_cursor    :boolean = false;
        public base_x       :number = 0;
        public base_y       :number = 0;
        public visibled     :boolean = false;

        //function
        public reset():void{
            this.selected = false;
            this.on_cursor = false;
            this.visibled = false;
        }
        //override function
        public setImage(image:HTMLImageElement, image_pattern:KRImagePattern):void{
            super.setImage(image, image_pattern);
            if(image){
                this.col.setRoughBox(image.naturalWidth, image.naturalHeight);
            }
        }
        public setPosition(x:number, y:number):void{
            super.setPosition(x, y);
            this.col.setPosition(x, y);
        }
        public setScale(x:number, y:number):void{
            super.setScale(x, y);
            this.col.setScale(x, y);
        }
        public setRotate(value:number):void{
            super.setRotate(value);
            this.col.setRotate(value);
        }
        public doMove(elapsed:number):void{
            let new_x:number = this.base_x,
                new_y:number = this.base_y;
            if(this.selected){
                new_y = new_y - CARD_SELECTED_UP;
            }else
            if(this.on_cursor){
                new_y = new_y - CARD_ON_CURSOR_UP;
            }
            this.setPosition(new_x, new_y);
        }
        public doDraw(context:CanvasRenderingContext2D):void{
            if(this.on_cursor){
                context.setTransform(1, 0, 0, 1, 0, 0);
                let w = this._image.naturalWidth * this._scale.x + CARD_PADDING * 2,
                    h = this._image.naturalHeight * this._scale.y + CARD_PADDING * 2,
                    dx = this._position.x - w / 2,
                    dy = this._position.y - h / 2;
                context.fillStyle = CARD_SELECTED_COLOR;
                context.fillRect(dx, dy, w, h);
            }
            if(this.visibled){
                super.doDraw(context);
            }
        }
    }
//ButtonSprite///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    class ButtonSprite2DT extends KRSprite2D{
        public col      :KRCollision = new KRCollision();
        public width    :number = 10;
        public height   :number = 10;
        public text     :string = TEXT_BTN_PASS;
        public visibled :boolean = false;
        public pushed   :boolean = false;

        public setSize(w:number, h:number):void{
            this.width = w;
            this.height = h;
            this.col.setRoughBox(w, h);
        }
        public setPosition(x:number, y:number):void{
            super.setPosition(x, y);
            this.col.setPosition(x, y);
        }
        public doDraw(context:CanvasRenderingContext2D):void{
            if(!this.visibled)return;
            context.setTransform(1, 0, 0, 1, 0, 0);
            let d_s = 2,
                d_pos = 0,
                w = this.width,
                h = this.height,
                dx = this._position.x - w / 2,
                dy = this._position.y - h / 2;
            let i=0,
                hand = _sp_cards.hand,
                len = hand.length;
            //ボタン文字決定
            if(_read_only_playing_data.prog_status === ST_PLAYING){
                this.text = TEXT_BTN_PASS;
                for(i=0; i<len; i++){
                    if(hand[i].selected){
                        this.text = TEXT_BTN_PUTDOWN;
                        break;
                    }
                }
            }else{
                this.text = TEXT_BTN_EXCHANGE;
            }
            //ボタン描画
            if(this.pushed){
                d_pos = d_s;
            }
            context.font = "italic bold 22px 'ＭＳ Ｐゴシック'";
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.fillStyle = BTN_COLOR;
            context.fillRect(dx, dy, w, h);
            context.fillStyle = BTN_RIGHT_COLOR;
            context.fillRect(dx + d_pos, dy + d_pos, w - d_pos, BTN_DEPTH);
            context.fillRect(dx + d_pos, dy + d_pos, BTN_DEPTH, h - d_pos);
            context.fillStyle = BTN_DARK_COLOR;
            context.fillRect(dx + d_pos, dy + h - BTN_DEPTH + d_pos, w - d_pos, BTN_DEPTH - d_pos);
            context.fillRect(dx + w - BTN_DEPTH + d_pos, dy + d_pos, BTN_DEPTH - d_pos, h - d_pos);
            if(this.pushed){
                context.fillStyle = "black";
                context.fillRect(dx, dy, w, BTN_DEPTH - d_pos);
                context.fillRect(dx, dy, BTN_DEPTH - d_pos, h);
            }
            context.fillStyle = BTN_TEXT_COLOR;
            context.fillText(this.text, this._position.x + d_pos, this._position.y + d_pos, w - BTN_DEPTH * 2);
        }
    }


//STPlayerSprite///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    class StPlayerSprite2DT extends KRSprite2D{
        public doDraw(context:CanvasRenderingContext2D):void{
            //プレイングデータが無ければ何もしない。
            if(!_read_only_playing_data)return;
            context.setTransform(1, 0, 0, 1, 0, 0);
            context.font = "bold 26px 'ＭＳ Ｐゴシック'";
            context.textAlign = "left";
            context.textBaseline = "middle";
            let i           :number = 0,
                players     :CFDT.PlayerObject[] = _read_only_playing_data.players,
                player      :CFDT.PlayerObject = null,
                len         :number = players.length,
                r_s         :CFDT.RoundStatusObject = _read_only_playing_data.round_status,
                order_name  :string = r_s.now_order_name,
                //基本
                base_x      :number = 0,
                base_y      :number = 0,
                base_w      :number = _st_player_width,
                base_h      :number = _st_player_height,
                padding     :number = 2,
                //枠
                frame_x     :number = 1,
                frame_y     :number = 1,
                frame_w     :number = base_w - 1 * 2,
                frame_h     :number = base_h - 1 * 2,
                //Face
                face_w      :number = 48,
                face_x      :number = 4,
                face_y      :number = 4,
                //text
                text_w      :number = base_w - (face_w + face_x * 2),
                text_x      :number = (face_w + face_x * 2),
                text_y      :number = (face_w + face_y) / 2,
                //short bar
                sbar_cx     :number = _st_player_width / 2,
                sbar_cy     :number = 57,
                sbar_w      :number = _st_player_width - 4 * 2,
                sbar_h      :number = SMALL_BAR_HEIGHT,
                //big bar
                bbar_cx = _canvas.width / 2,
                bbar_cy = _card_pos_top - 32,
                bbar_w = _canvas.width - 8,
                bbar_h = BIG_BAR_HEIGHT,
                //exchange
                txt_exchange = TXT_EXCHANGE_CARDS_WAIT,
                txt_exchange_cy = _canvas.height * 4 / 6,  //_sp_button._position.y - 36,
                //Mark
                mk_r = 16,
                mk_x = _sp_button._position.x + BTN_WIDTH / 2 + mk_r * 2 + 4,
                mk_y = _sp_button._position.y,
                //round
                card_count = 0,
                now_turn = _read_only_playing_data.round_status.now_turn,
                now_round = _read_only_playing_data.round_status.now_round,
                //card icon
                card_icon_w = 22,
                card_icon_h = 32,
                card_icon_y = _sp_button._position.y - card_icon_h / 2,
                card_icon_line_thick = 1,
                card_icon_fill_color = 'rgba(0, 160, 255, 1.0)',
                card_icon_stroke_color = 'rgba(255, 255, 255, 1.0)';
            //Bar color
            let rate = _limit.rate,
                r = -4/3 * rate + 4/3,
                g = 4 * rate - 1,
                b = 2 * rate - 1,
                a = 1;
            if(r > 1){r = 1;} else if(r < 0){r = 0;}; r = Math.floor(r * 255);
            if(g > 1){g = 1;} else if(g < 0){g = 0;}; g = Math.floor(g * 255);
            if(b > 1){b = 1;} else if(b < 0){b = 0;}; b = Math.floor(b * 255);
            let txt_bar_color = 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
            //①プレイヤーステータス描画
            for(i=0;i<len;i++){
                player = players[i];
                //描画基本位置（左上）
                base_x = (i % ST_PLAYERS_COL_LENGTH) * base_w;
                base_y = Math.floor(i / ST_PLAYERS_COL_LENGTH) * base_h;
                //枠描画
                context.lineWidth = 2;
                if(_read_only_playing_data.prog_status === ST_EXCHANGE_CARDS){
                    //自プレイヤーで　大富豪 or 富豪　でまだ交換カード選んでない
                    if(player.name === _my_username && player.status === ST_EXCHANGE_CARDS){
                        if(player.ranking === RANKING_DAI_FUGOU){
                            txt_exchange = TXT_EXCHANGE_CARDS_00 + EXCHANGE_TOP_LENGTH + TXT_EXCHANGE_CARDS_01;
                        }else
                        if(player.ranking === RANKING_FUGOU){
                            txt_exchange = TXT_EXCHANGE_CARDS_00 + EXCHANGE_SECOND_LENGTH + TXT_EXCHANGE_CARDS_01;
                        }
                        drawBigBar();
                    }
                    context.strokeStyle = 'rgba(255, 255, 255, 0.5)';
                }else
                if(_read_only_playing_data.prog_status === ST_PLAYING){
                    if(player.name === order_name){
                        context.strokeStyle = 'rgba(180, 255, 255, 1.0)';
                        drawShortBar();
                        if(player.name === _my_username){
                            drawBigBar();
                        }
                    }else{
                        context.strokeStyle = 'rgba(255, 255, 255, 0.5)';
                    }
                }else{
                    context.strokeStyle = 'rgba(255, 255, 255, 0.5)';
                }
                context.strokeRect(base_x + frame_x, base_y + frame_y, frame_w, frame_h);
                context.globalAlpha = 1;
                //顔描画
                context.fillStyle = 'rgb(128, 255, 255)';
                context.fillRect(base_x + face_x, base_y + face_y, face_w, face_w);
                context.drawImage(player.img_avater, base_x + face_x, base_y + face_y, face_w, face_w);
                //名前描画Or残り枚数Or順位描画
                card_count = player.card_length;
                //ST_RECRUITなら名前描画(ゲーム開始前)
                if(_read_only_playing_data.prog_status === ST_RECRUIT){
                    drawPlayerName();//プレイヤー名
                }else{
                    if(player.status === ST_PLAYING){
                        drawCardsIconAndLength();//カード枚数
                    }else{
                        drawPlayerRank();//順位
                    }
                }
                //drawPlayerStatusForDebug();//debug
            }
            //②交換中メッセージ描画
            if(_read_only_playing_data.prog_status === ST_EXCHANGE_CARDS){
                drawExchangeMessage();
            }
            //③開始前"秒読み"描画
            if(_read_only_playing_data.prog_status === ST_RECRUIT){
                let time    :string = Math.floor(_limit.now_msec / 1000).toString(),
                    time_x  :number = _canvas.width / 2,
                    time_y  :number = _canvas.height / 2;
                context.font = "bold 40px 'ＭＳ Ｐゴシック'";
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.fillStyle = 'rgba(0, 255, 255, 1.0)';
                context.fillText(time, time_x, time_y, 32);
            }
            //④debug clientのPlayingDataの状態表示
            //drawPlayingDataProgStatusForDebug();
            //⑤革命、縛り等の状態表示
            drawEffectMark();
            //⑥Table上の状態表示
            drawPutDownCardsIcon();
            //functions...
            function drawPlayingDataProgStatusForDebug(){
                let text_x = 2,
                    text_y = 98,
                    text_w = 160;
                context.font = "bold 24px 'ＭＳ Ｐゴシック'";
                context.textAlign = "left";
                context.textBaseline = "middle";
                context.fillStyle = 'rgba(255, 0, 0, 1.0)';
                //context.fillText('PD_ST = ' + _txt_status[_read_only_playing_data.prog_status], text_x, text_y, text_w);
                //context.fillText(_touch_name +  ' (' + _touch_x + ', ' + _touch_y + ')', text_x, text_y, text_w);
            }
            function drawPlayerStatusForDebug(){
                let text_x = base_x + 56,
                    text_y = base_y + frame_h / 2 + 48,
                    text_w = frame_w - 56;
                context.fillStyle = 'rgba(255, 0, 0, 1.0)';
                context.fillText(_txt_status[player.status], text_x, text_y, text_w);
            }
            function drawExchangeMessage(){
                let text_x = _canvas.width / 2,
                    text_y = txt_exchange_cy,
                    text_w = _canvas.width - 8;
                context.font = "bold 36px 'ＭＳ Ｐゴシック'";
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.fillStyle = 'rgba(0, 128, 255, 1.0)';
                context.fillText(txt_exchange, text_x, text_y, text_w);
            }
            function drawCardsIconAndLength(){
                let i = 0,
                    card_x = base_x + 56,
                    card_y = base_y + 9,
                    text_w = frame_w - face_w - card_icon_w - 10;
                context.lineWidth = card_icon_line_thick;
                context.strokeStyle = card_icon_stroke_color;
                context.fillStyle = card_icon_fill_color;
                for(i=0; i < 3; i++){
                    context.strokeRect(card_x + i * 3, card_y + i * 3, card_icon_w, card_icon_h);
                    context.fillRect(card_x + card_icon_line_thick + i * 3, card_y + card_icon_line_thick + i * 3, card_icon_w - card_icon_line_thick * 2, card_icon_h - card_icon_line_thick * 2);
                }
                let text_x = card_x + card_icon_w,
                    text_y = card_y + card_icon_h / 2 + 12;
                context.fillStyle = 'rgb(255, 255, 255)';
                context.fillText('x' + card_count, text_x, text_y, text_w);
            }
            function drawPutDownCardsIcon(){
                let putdown_history = _read_only_playing_data.round_status.putdown_history;
                if(putdown_history.length === 0) return;
                let i = 0,
                    len = putdown_history[0].cards.length,
                    w = (_canvas.width - BTN_WIDTH) / 2,
                    x = (w - len * card_icon_w) / 2;
                context.lineWidth = card_icon_line_thick;
                context.strokeStyle = card_icon_stroke_color;
                context.fillStyle = card_icon_fill_color;
                for(i=0; i < len; i++){
                    context.fillRect(x, card_icon_y, card_icon_w, card_icon_h);
                    context.strokeRect(x, card_icon_y, card_icon_w, card_icon_h);
                    x = x + card_icon_w + 4;
                }
            }
            function drawShortBar(){
                let now_sbar_w = sbar_w * _limit.rate;
                context.fillStyle = txt_bar_color;
                context.fillRect(base_x + sbar_cx - now_sbar_w / 2, base_y + sbar_cy - sbar_h / 2, now_sbar_w, sbar_h);
            }
            function drawBigBar(){
                let now_bbar_w = bbar_w * _limit.rate;
                context.fillStyle = txt_bar_color;
                context.fillRect(bbar_cx - now_bbar_w / 2, bbar_cy - bbar_h / 2, now_bbar_w, bbar_h);
            }
            function drawPlayerName(){
                let text_x = base_x + 56,
                    text_y = base_y + frame_h / 2,
                    w = frame_w - 58;
                context.fillText(player.name, text_x, text_y, w);
            }
            function drawPlayerRank(){
                let txt_ranking = getRankingName(player.ranking),
                    text_x = base_x + 56,
                    text_y = base_y + frame_h / 2,
                    w = frame_w - 58;
                context.fillText(txt_ranking, text_x, text_y, w);
            }
            function drawEffectMark(){
                let r_s = _read_only_playing_data.round_status,
                    o_r = _read_only_playing_data.option_rules,
                    kakumei  = r_s.now_kakumei,
                    symbol_lock = o_r.symbol_lock !== 0 && r_s.now_symbol_lock >= o_r.symbol_lock,
                    eleven_back = r_s.now_eleven_back > 0;
                context.font = "bold 32px 'ＭＳ Ｐゴシック'";
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.fillStyle = 'rgba(255, 0, 0, 1.0)';
                context.strokeStyle = 'rgba(255, 0, 0, 1.0)';
                context.lineWidth = 2;
                if(kakumei){
                    context.fillText(TXT_MARK_KAKUMEI, mk_x, mk_y);
                    context.beginPath();
                    context.arc(mk_x, mk_y, mk_r, 0, Math.PI * 2, false);
                    context.closePath();
                    context.stroke();
                    mk_x = mk_x + mk_r * 2 + 4;
                }
                if(symbol_lock){
                    context.fillText(TXT_MARK_SYMBOL_LOCK, mk_x, mk_y);
                    context.beginPath();
                    context.arc(mk_x, mk_y, mk_r, 0, Math.PI * 2, false);
                    context.closePath();
                    context.stroke();
                    mk_x = mk_x + mk_r * 2 + 4;
                }
                if(eleven_back){
                    context.fillText(TXT_MARK_ELEVEN_BACK, mk_x, mk_y);
                    context.beginPath();
                    context.arc(mk_x, mk_y, mk_r, 0, Math.PI * 2, false);
                    context.closePath();
                    context.stroke();
                }
            }
        }
    }


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //画像ローダー
    let _img_loader = KRImageLoader();
    let _sources            :{[key:string]:string} = {};          //property扱い
    let _img_pattern_card   :KRImagePattern = null;
    let _images             :{[key:string]:HTMLImageElement} = null;
    //以下にDLしたい画像を記述
    for(let i = 0; i < 53; i++){
        _sources[i] = "../img/trump/" + i + ".png";
    }
    //_sources["char_chip"] = "../img/char_chip.png";
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /**
     * @param {number} id
     * @return {string}
     */
    function getRankingName(id:number):string{
        let res = '不正なid';
        switch(id){
            case RANKING_DAI_HINMIN:
                res = TXT_RANKING_DAI_HINMIN;
                break;
            case RANKING_HINMIN:
                res = TXT_RANKING_HINMIN;
                break;
            case RANKING_HEIMIN:
                res = TXT_RANKING_HEIMIN;
                break;
            case RANKING_FUGOU:
                res = TXT_RANKING_FUGOU;
                break;
            case RANKING_DAI_FUGOU:
                res = TXT_RANKING_DAI_FUGOU;
                break;
        }
        return res;
    }
    //フレーム更新用関数
    function updateFrame(interval:number):void{
        _frame_timer.id = window.setTimeout(function(){
            let now_msec = Date.now();
            let elapsed = now_msec - _frame_timer.old_msec;
            _mgr_sprite.move(elapsed);
            _frame_timer.old_msec = now_msec;
            _mgr_sprite.draw();
            updateFrame(interval);
        }, interval);
    }
    /**
     * 初期設定を行います。必ず一度だけ行われます。
     * @param {Object} parent_element _screenを追加するための要素(Element)
     * @param {Function} func_do_my_putdown_action パスOr札を出すボタンを押した時に実行する関数。
     * @param {Function} func_finished 設定が完了した際に呼び出したい関数。
     */
    function setup(parent_element:HTMLElement, func_do_my_putdown_action:(putdown_indexs:number[])=>boolean, func_finished:()=>void){
        if(_first_setup) return;
        _first_setup = true;
        _func_do_my_putdown_action = func_do_my_putdown_action;
        //親エレメント
        _parent_elm = parent_element;
        //スクリーン
        _screen = document.createElement("div");
        _screen.style.position = "fixed";
        _screen.style.backgroundColor = "black";
        //キャンバス
        _canvas = document.createElement("canvas");
        _canvas.style.position = "absolute";
        _canvas.style.left = "0px";//※中央ぞろえにするにはtop,left,right,bottomも指定しないと適用されない。
        _canvas.style.top = "0px";
        _canvas.style.right = "0px";
        _canvas.style.bottom = "0px";
        _canvas.style.margin = "auto";
        _canvas.style.backgroundColor = "green";
        //_canvas.setAttribute("width", "512px");//キャンバスのサイズ（横）を変える！
        //_canvas.setAttribute("height", "384px");//キャンバスのサイズ（縦）を変える！
        //_canvas.style.width = "512px";   //こっちはキャンバスの縮尺を変える！
        //_canvas.style.height = "240px";  //こっちはキャンバスの縮尺を変える！
        _screen.appendChild(_canvas);

        //スプライトマネージャ
        _mgr_sprite = new KRSprite2DManager(_canvas);
        //スプライトマネージャにカスタムスプライトを登録
        _mgr_sprite.registSpriteClass(CardSprite2DT, "CardSprite2DT");
        _mgr_sprite.registSpriteClass(ButtonSprite2DT, "ButtonSprite2DT");
        _mgr_sprite.registSpriteClass(StPlayerSprite2DT, "StPlayerSprite2DT");
        //画像のロード
        _img_loader.loadWithObject(_sources, function(images){
            //画像の確保および、イメージパターンの作成。
            _images = images;
            _img_pattern_card = new KRImagePattern(_images[0], 1, 1);
            //スプライト(手札)作成
            for(let i = 0; i < CARDSPRITE_CAPACITY; i++){
                let sp  :CardSprite2DT = _mgr_sprite.createSprite("CardSprite2DT", _images[0], _img_pattern_card, 0, 0, 0) as CardSprite2DT;
                sp.setScale(CARD_SCALE, CARD_SCALE);
                _sp_cards.stock.push(sp);
            }
            //ボタン作成
            _sp_button = _mgr_sprite.createSprite("ButtonSprite2DT", null, null, 0, 0, 1) as ButtonSprite2DT;
            _sp_button.setSize(BTN_WIDTH, BTN_HEIGHT);
            //プレイヤーST作成
            _sp_st_players = _mgr_sprite.createSprite("StPlayerSprite2DT", null, null, 0, 0, 2);
            if(func_finished){
                func_finished();
            }
        });
    }

    /**
     * カードイメージを取得するために必要
     * @param {number} index
     */
    function getCardImage(index:number):HTMLImageElement{
        return _images[index];
    }
    /**
     * キャンバスの位置とサイズを設定します。値は
     * 全体（ブラウザ）のレイアウトとサイズに依存します。
     * @param {number} px_left
     * @param {number} px_top
     * @param {number} px_width
     * @param {number} px_height
     * @param {number} scale touchEventで必要です。
     */
    function setPositionAndSize(px_left:number, px_top:number, px_width:number, px_height:number, scale:number):void{
        _screen_values.now_left = px_left;
        _screen_values.now_top = px_top;
        _screen_values.now_scale = scale;
        if(_screen){
            _screen.style.left = px_left + "px";
            _screen.style.top =  px_top + "px";
            _screen.style.width = px_width + "px";      //縮尺が変わる
            _screen.style.height = px_height + "px";    //縮尺が変わる
        }
        if(_canvas){
            _canvas.width = px_width;                   //実際のサイズが変わる
            _canvas.height = px_height;                 //実際のサイズが変わる
        }
        //ボタン配置位置
        if(_sp_button){
            //_sp_button.setPosition(px_width / 2, px_height - BTN_HEIGHT - 32);
            _sp_button.setPosition(px_width / 2, px_height * 5 / 6);
        }
        //st_player設定
        _st_player_width = px_width / ST_PLAYERS_COL_LENGTH;
        //カード位置設定
        alignHand();
    }
    /**
     * 大富豪のUIの表示・非表示を設定します。
     * @param {boolean} value
     * @param {PlayingDataObject} playing_data read only
     */
    function setActive(value:boolean, playing_data:CFDT.PlayingDataObject){
        if(_activated === value) return;
        _activated = value;
        if(_activated){
            _read_only_playing_data = playing_data;
            //表示
            _screen.addEventListener(EVENTNAME_TOUCHSTART, userEvent);
            _screen.addEventListener(EVENTNAME_TOUCHMOVE, userEvent);
            _screen.addEventListener(EVENTNAME_TOUCHEND, userEvent);
            _parent_elm.appendChild(_screen);
            _frame_timer.old_msec = Date.now();
            updateFrame(33);
        }else{
            _read_only_playing_data = null;
            _my_player = null;
            clearHand();
            //非表示
            _screen.removeEventListener(EVENTNAME_TOUCHSTART, userEvent);
            _screen.removeEventListener(EVENTNAME_TOUCHMOVE, userEvent);
            _screen.removeEventListener(EVENTNAME_TOUCHEND, userEvent);
            _parent_elm.removeChild(_screen);   //node.parentNode.removeChild(node);//node = Element
            clearTimeout(_frame_timer.id);
            setButtonVisibled(false);
        }
    }
//CardSprites controll///////////////////////////////////////////////////////////////////////////
    /**
     * card_indexsで指定されたカード番号から手札をセットします。
     * @param {number[]} card_indexs
     */
    function setMyHand(card_indexs:number[]){
        clearHand();
        let i = 0,
            hand = _sp_cards.hand,
            stock = _sp_cards.stock,
            len = card_indexs.length,
            sp = null;
        for(i=0; i < len; i++){
            sp = stock[i];
            sp.card_index = card_indexs[i];
            sp.setImage(_images[sp.card_index], _img_pattern_card);
            sp.visibled = true;
            hand.push(sp);
        }
        alignHand();
    }
    /**
     * カード交換を適用します。※※※まだ未実装！！！！とりあえずsetMyHandで済ませる
     * @param {number[]} send_card_indexs
     * @param {number[]} receive_card_indexs
     */
    function exchnageMyHand(send_card_indexs:number[], receive_card_indexs:number[]){

    }
    /**
     * hand内のカードを整列させます。
     * カード幅の合計がcanvas幅より大きい場合、
     * 重ねて表示します。
     */
    function alignHand(){
        if(!_sp_cards)return;
        let i = 0,
            hand = _sp_cards.hand,
            len = hand.length,
            sp = null;
        if(len === 0) return;
        sp = hand[0];
        let w = sp._image.naturalWidth * sp._scale.x,
            h = sp._image.naturalHeight * sp._scale.y,
            canvas_w = _canvas.width,
            canvas_h = _canvas.height,
            align_w = w * len;
        if(align_w > canvas_w + HAND_PADDING * 2){
            align_w = canvas_w - HAND_PADDING * 2;
        }
        _card_pos_top = (canvas_h - h) / 2;
        let dx = (canvas_w - align_w) / 2 + w / 2,
            dy = canvas_h / 2;
        let step_w = 0;
        if(len > 1){
            step_w = (align_w - w) / (len - 1);
        }
        for(i=0;i < len; i++){
            sp = hand[i];
            sp.base_x = dx + step_w * i;
            sp.base_y = dy;
        }
    }
    /**
     * 手札をクリアします
     */
    function clearHand(){
        let i = 0,
            hand = _sp_cards.hand,
            len = hand.length;
        for(i=0; i < len; i++){
            hand[i].reset();
        }
        hand.splice(0, len);
    }
    /**
     * Client側から指示されたカードを手札内から場に出します。
     * @param {number[]} card_indexs
     */
    function putDownMyHand(card_indexs:number[]):void{
        let i = 0,
            ii = 0,
            hand = _sp_cards.hand,
            len = card_indexs.length,
            lenlen = 0,
            target_index = 0,
            sp = null;
        for(i=0; i < len; i++){
            target_index = card_indexs[i];
            lenlen = hand.length;
            for(ii=0; ii<lenlen; ii++){
                sp = hand[ii];
                if(target_index === sp.card_index){
                    sp.reset();
                    hand.splice(ii, 1);
                    break;
                }
            }
        }
        alignHand();
    }
    /**
     * @param {boolean} value
     */
    function setButtonVisibled(value:boolean):void{
        _sp_button.visibled = value;
    }
    /**
     * 残り時間を設定します。現在の状態により、表示方法等が異なります。
     * @param {number} now_msec 残り時間
     * @param {number} max_msec 最大値
     */
    function setlimitTime(now_msec:number, max_msec:number):void{
        _limit.now_msec = now_msec;
        _limit.max_msec = max_msec;
        _limit.rate = now_msec / max_msec;
    }
    /**
     * PlayingDataObjectにplayerが追加される際、プレイヤーのImageを追加するために呼び出される関数です。
     * @param {Image} image
     */
    function setPlayerImage(image:HTMLImageElement){
        //clientの方で設定するのでいらない
    }
    /**自分のクライアントのユーザー設定
     * @param {string} my_username
     */
    function setMyUserData(my_username:string){
        _my_username = my_username;
    }
    /**
     * 自ユーザーがゲームに参加したら設定します。
     * Activeをfalseにすると、自動でnullに置き換えられます。
     * @param {PlayerObject} player
     */
    function setMyPlayer(player:CFDT.PlayerObject):void{
        _my_player = player;
    }
    /**
     * 現在の表示状態（大貧民実行中）を取得します。
     * 表示中の場合、trueを返します。
     */
    function getActivated():boolean{
        return _activated;
    }
    /**
     * コントロールをロックします。
     * trueでロック。falseで解除します。
     * @param {boolean} value
     */
    function controllLock(value:boolean):void{
        _ctrl_lock = value;
    }
////////////////////////////////////////////////////////////////////////////////////////////////
    return {
        setup,
        setActive,
        activated:getActivated,
        setButtonVisibled,
        setPositionAndSize,
        putDownMyHand,   //自分のカードでもサーバからの許可を貰ってからでないと出せないので、client側からの操作で出す。
        getCardImage,
        setMyHand,
        exchnageMyHand,
        setlimitTime,
        setPlayerImage,//プレイングデータにプレイヤーが追加されたら、プレイヤーのイメージの追加を行わなきゃなのでその関数。
        setMyUserData,
        setMyPlayer,
        controllLock
    }

}
