import { KRImagePattern } from "./kr_sprite2d";
import KRSpriteElement from "./kr_sprite_element";
import { Geometry } from "./kr_library";
/**
 * 画面上に簡単な演出を表示します。
 * @param {HTMLElement} parent_element
 */
export default function KRDisplayEffect(parent_element:HTMLElement){
    "use strict";
    const DEF_WAIT_MSEC = 5000;

    let _timer:{id:number, old_msec:number} = {
                                                id          :0,
                                                old_msec    :0
                                            };
    let _wait_msec = DEF_WAIT_MSEC * (1 + Math.random());     //待機時間
    let _footprint = KRDEFootPrint(parent_element);
    let _flg_stopping = false;                                    //update中にstopTimer()が呼び出された
     /**
     * 更新処理。
     * @param {number} interval
     */
    function updateTimer(interval:number){
        _timer.id = window.setTimeout(function(){
            //処理記述--------------------------------------------------
            let now_msec = Date.now(),
                elapsed = now_msec - _timer.old_msec;
            _timer.old_msec = now_msec;
            if(_wait_msec > 0){
                _wait_msec = _wait_msec - elapsed;
                if(_wait_msec <= 0){
                    _wait_msec = 0;
                    _footprint.init();
                }
            }else{
                if(_footprint.update(elapsed)){
                    if(_flg_stopping){
                        doStop();
                        return;
                    }else{
                        _wait_msec = DEF_WAIT_MSEC * (1 + Math.random());
                    }
                }
            }
            //END-------------------------------------------------------
            updateTimer(interval);
        }, interval);
    }
    /**
     * 処理を開始します。
     * @param {number} interval
     */
    function startTimer(interval = 100){
        _timer.old_msec = Date.now();
        updateTimer(interval);
    }
    /**
     * 処理を停止します。
     */
    function stopTimer(){
        if(_wait_msec <= 0){//updateが完了するまで待機
            _flg_stopping = true;
        }else{
            doStop();
        }
    }
    /**
     * 実際のクリア処理
     */
    function doStop(){
        _flg_stopping = false;
        _footprint.clear();
        _wait_msec = DEF_WAIT_MSEC;
        clearTimeout(_timer.id);
        _timer.id = null;
    }
    /**
     * 現在実行中か？
     */
    function nowActive(){
        return _timer.id != null;
    }

    return{
        nowActive,
        startTimer,
        stopTimer
    }
}



//---------------------------------------------------------------------------------------------------------------------------------------------------------------------
/**
 * Effect - FootPrint
 * 移動方向(1, 0)を0度として処理します。
 * @param {HTMLElement} parent_element
 * @api private
 */
function KRDEFootPrint(parent_element:HTMLElement){
    "use strict";
    const RELATIVE_X            :number = 64;
    const RELATIVE_Y            :number = -24;
    const STEP_LENGTH           :number = 64;                               //一歩の移動距離
    const LIFE_MSEC             :number = 1000;
    const MOVE_SPEED            :number = 0.4;
    const SPRITE_LENGTH         :number = 100;
    const IMG_NAME              :string = "../img/footprint.png";

    let _sprites                :KRSpriteElement[] = [];                   //足跡Sprites
    let _actives                :KRSpriteElement[] = [];                   //更新中のスプライト
    let _s_pos                  :{x:number,y:number} = {                    //始点
                                                            x: 0, y: 0
                                                        }
    let _e_pos                  :{x:number,y:number} = {                    //終点
                                                            x: 0, y:0
                                                        }
    let _n_vec                  :{x:number,y:number} = {                    //正規化された方向ベクトル
                                                            x: 1, y:0
                                                        }
    let _speed                  :number = MOVE_SPEED;                       //速度(msec)
    let _now_length             :number = 0;                                //現在の移動距離
    let _max_length             :number = 0;                                //最大の移動距離
    let _counter                :number = 0;                                //一歩ごとに＋１する
    let _parent_elm             :HTMLElement = parent_element;              //足跡を追加する要素
    let _sin                    :number = 0;
    let _cos                    :number = 0;
    let _rad                    :number = 0;

    setup();
    /**
     * 初期設定
     */
    function setup():void{
        let i=0,
            len = SPRITE_LENGTH,
            sp = null,
            img = new Image();
        img.src = IMG_NAME;
        img.onload = function(){
            let img_ptn = new KRImagePattern(img, 1, 1);
            for(i=0; i<len; i++){
                sp = new KRSpriteElement();
                sp.setImage(IMG_NAME, img_ptn);
                _sprites.push(sp);
            }
        }
    }
    /**
     * 開始前の処理
     */
    function init():void{
        clear();
        //スタートとゴールを設定
        let cx = window.innerWidth / 2;
        let cy = window.innerHeight / 2;
        let r = Math.sqrt(cx * cx + cy * cy);
        let deg = Math.random() * 360;
        let rad = Geometry.degToRad(deg);
        let sin = Math.sin(rad);
        let cos = Math.cos(rad);
        let px = r * cos;
        let py = r * sin;
        _s_pos.x = cx + px;
        _s_pos.y = cy + py;
        _e_pos.x = cx - px;
        _e_pos.y = cy - py;
        _rad = rad + Math.PI;
        _sin = Math.sin(_rad);
        _cos = Math.cos(_rad);
        _max_length = 2 * r;
/*
        _s_pos.x = 0;
        _s_pos.y = 100;
        _e_pos.x = 1000;
        _e_pos.y = 100;
        _rad = 0;
        _sin = Math.sin(_rad);
        _cos = Math.cos(_rad);
        _max_length = 1000;
*/
        //正規化
        let vx = _e_pos.x - _s_pos.x;
        let vy = _e_pos.y - _s_pos.y;
        let l = Math.sqrt(vx * vx + vy * vy);
        _n_vec.x = vx / l;
        _n_vec.y = vy / l;
        //最初の足追加
        addFootprint();
    }
    /**
     * 初期化
     */
    function clear():void{
        _now_length = 0;
        _counter = 0;
        for(let i=0; i<_actives.length; i++){
            _actives[i].removeSelf();
        }
        if(_actives.length > 0){
            _actives.splice(0, _actives.length);
        }
    }
    /**
     * 処理中です。全ての処理が完了したらtrueを返します。
     * @param {number} elapsed
     * @return {boolean}
     */
    function update(elapsed:number):boolean{
        //追加チェック
        if(_now_length < _max_length){
            _now_length = _now_length + _speed * elapsed;
            let cnt = Math.floor(_now_length / STEP_LENGTH);
            while(_counter < cnt){
                _counter++;
                addFootprint();
            }
        }
        //透明度更新
        let i = 0,
            len = _actives.length,
            sp = null;
        for(i = len - 1; i >= 0; i--){
            sp = _actives[i];
            sp.setAlpha(sp.life / LIFE_MSEC);
            sp.life = sp.life - elapsed;
            if(sp.life <= 0){
                sp.removeSelf();
                _actives.splice(i, 1);
            }
        }
        //全ての更新が完了したら終了
        if(_now_length >= _max_length && _actives.length === 0){
            return true;
        }

        return false;
    }
    /**
     * 足跡を追加
     * _counterが更新されたらここに来ます。
     */
    function addFootprint():void{
        //_counterが偶数なら左、奇数なら右
        //基準位置を計算
        let l = _counter * STEP_LENGTH;
        let cx = _s_pos.x + _n_vec.x * l;
        let cy = _s_pos.y + _n_vec.y * l;
        //とりあえず左前足で設定
        let rx = RELATIVE_X;
        let ry = RELATIVE_Y;
        //もしくは右前足
        if(_counter % 2 === 1){
            ry = - ry;
        }
        //回転
        let px = _cos * rx - _sin * ry;
        let py = _sin * rx + _cos * ry;
        //追加
        add(cx + px, cy + py);
        add(cx - px, cy - py);
    }
    function add(x:number, y:number):void{
        //画面外なら描画しない
        let w = _sprites[0]._image_pattern.pattern_width / 2;
        let h = _sprites[0]._image_pattern.pattern_height / 2;
        let l = Math.sqrt(w * w + h * h);
        if(x - l < 0 || x + l > window.innerWidth || y - l < 0 || y + l > window.innerHeight){
            return;
        }
        //sprite取得
        let sp = null;
        let i = 0;
        let len = _sprites.length;
        for(i=0; i < len; i++){
            sp = _sprites[i];
            if(!sp.exsistParent()){
                break;
            }
        }
        if(sp){
            sp.setPosition(x, y);
            let deg = Geometry.radToDeg(_rad);
            sp.setRotate(deg);
            sp.life = LIFE_MSEC;
            sp.applyTransform();
            sp.setAlpha(1);
            sp.appendSelf(_parent_elm);
            _actives.push(sp);
        }
    }

    return {
        init,
        clear,
        update
    }
}
