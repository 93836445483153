import { KRImagePattern } from "./kr_sprite2d";
import * as Kr from "./kr_library";


/** @typedef {{width:Number,height:Number,pattern_width:Number,pattern_height:Number,x_length:Number,y_length:Number}} ImagePattern */

/**
 * canvasでなく、htmlに直接画像を表示する為のクラスです。
 * 要素"Div"を内部で作成し、Css3を用いて回転、拡大縮小等を実現します。
 * ※_(アンダーバー)付き変数は全て参照のみで使用してください。
 * ※変数の値の設定は関数（set...())から行ってください。
 */
export default class KRSpriteElement{
    public _div            :HTMLDivElement;
    public _index          :number = 0;
    public _image_pattern  :KRImagePattern = null;
    public _x              :number = 0;
    public _y              :number = 0;
    public _scale_x        :number = 1;
    public _scale_y        :number = 1;
    public _degree         :number = 0;
    public _alpha          :number = 1;
    public _z_index        :number = 0;

    public life            :number = 0;

    constructor(){
        let div = document.createElement('div');
        div.style.position = 'absolute';
        this._div = div
    }

    /**
     * @param {number} z_index
     */
    public setZIndex(z_index:number):void{
        this._z_index = z_index;
        this._div.style.zIndex = z_index.toString();
    }
    /**
     * @param {number} alpha
     */
    public setAlpha(alpha:number):void{
        this._alpha = alpha;
        this._div.style.opacity = alpha.toString();
    }
    /**
     * @param {number} x
     * @param {number} y
     */
    public setPosition(x:number, y:number):void{
        this._x = x;
        this._y = y;
        let cel_w = 0;
        let cel_h = 0;
        if(this._image_pattern){
            cel_w = this._image_pattern.pattern_width;
            cel_h = this._image_pattern.pattern_height;
        }
        let left = x - cel_w / 2;
        let top = y - cel_h / 2;

        this._div.style.left = left + "px";
        this._div.style.top = top + "px";
    }
    /**
     * @param {string} image_url
     * @param {ImagePattern} image_pattern
     */
    public setImage(image_url:string, image_pattern:KRImagePattern):void{
        let src = 'url("' + image_url + '")';
        this._div.style.backgroundImage = src;
        this._image_pattern = image_pattern;

        this._div.style.width = image_pattern.pattern_width + "px";
        this._div.style.height = image_pattern.pattern_height + "px";
        this.setIndex(0);
    }
    /**
     * 画像を"degree"度、回転させます。
     * ※適用するためにapplyTransform()を実行してください
     * @param {number} degree
     */
    public setRotate(degree:number):void{
        degree = degree % 360;
        this._degree = degree;
    }
    /**
     * 画像を拡大縮小します。
     * ※適用するためにapplyTransform()を実行してください
     * @param {number} scale_x
     * @param {number} scale_y
     */
    public setScale(scale_x:number, scale_y:number):void{
        this._scale_x = scale_x;
        this._scale_y = scale_y;
    }
    /**
     * CssのTransformを適用します。
     */
    public applyTransform():void{
        let sdeg = "rotate(" + this._degree + "deg)";
        let sscale = "scale(" + this._scale_x + "," + this._scale_y + ")";
        let svalue = sdeg + " " + sscale;
        Kr.Css.styleDeclarationSetTransform(this._div.style, svalue);
    }
    /**
     * @param {number} index
     */
    public setIndex(index:number):void{
        let img_ptn = this._image_pattern;
        let ptn_x = index % img_ptn.x_length;
        let ptn_y = Math.floor(index / img_ptn.x_length);
        let pos_w = - ptn_x * img_ptn.pattern_width;
        let pos_h = - ptn_y * img_ptn.pattern_height;
        let pos_wh = String(pos_w) + "px " + String(pos_h) + "px";

        this._index = index;
        this._div.style.backgroundPosition = pos_wh;
    }
    /**
     * parent要素にdivを追加します。
     * @param {HTMLElement} parent
     */
    public appendSelf(parent:HTMLElement):void{
        this.removeSelf();
        parent.appendChild(this._div);
    }
    /**
     * parent要素があれば、自分自身を開放します。
     */
    public removeSelf():void{
        let div = this._div;
        let old_parent = div.parentNode;
        if(old_parent){
            old_parent.removeChild(div);
        }
    }
    /**
     * 現在親を持っているかを返します。
     * 親が存在する場合trueを返します。
     * @return {boolean}
     */
    public exsistParent(){
        return this._div.parentNode != null;
    }

}


