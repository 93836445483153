"use strict";
//game status
export const ST_RECRUIT            :number = 0;        //募集中
export const ST_PLAYING            :number = 1;        //プレイ中
export const ST_GAME_OVER          :number = 2;        //終了中
//request_id
export const REQ_ID_RECRUIT        :number = 0;        //参加者募集
export const REQ_ID_JOIN_GAME      :number = 1;        //参加者する -> 開始する
export const REQ_ID_PUT_PIECE      :number = 2;        //駒を置く -> 終了（するかもしれない）
export const REQ_ID_PASS_TURN      :number = 3;        //パスする
export const REQ_ID_CANCEL_GAME    :number = 4;        //何かしらの理由によりゲーム続行不可
export const REQ_ID_JOIN_IN_MIDWAY :number = 5;        //途中入室時

export const BOARD_LEN_X            :number = 8;
export const BOARD_LEN_Y            :number = 8;
//駒　探索方向
export const SEARCH_VECTORS :number[][] = [[1,0], [1, 1], [0, 1], [-1, 1], [-1, 0], [-1, -1], [0, -1], [1, -1]];
export const SEARCH_VEC_R   :number = 0;        //Right
export const SEARCH_VEC_RB  :number = 1;        //Right Bottom
export const SEARCH_VEC_B   :number = 2;        //Bottom
export const SEARCH_VEC_LB  :number = 3;        //Left Bottom
export const SEARCH_VEC_L   :number = 4;        //Left
export const SEARCH_VEC_LT  :number = 5;        //Left Top
export const SEARCH_VEC_T   :number = 6;        //Top
export const SEARCH_VEC_RT  :number = 7;        //Rigth Top
/**
 * ※定数PIECEの値は　論理演算等でも使用しています。
 * よって値を変更するとおかしなことになるので、変更不可です。
 */
export const PIECE_NONE         :number = 0;    //※変更不可　無し or 何も置けない
export const PIECE_BLACK        :number = 1;    //※変更不可　黒配置 or 黒が置ける
export const PIECE_WHITE        :number = 2;    //※変更不可　白配置 or 白が置ける
export const PIECE_BLACK_WHITE  :number = 3;    //※変更不可　ー or 白と黒が置ける
//settings sec
//募集時間
export const MAX_RECRUIT_TIME      :number = 60;
export const MIN_RECRUIT_TIME      :number = 15;
export const DEF_RECRUIT_TIME      :number = 30;//debug
export const PER_RECRUIT_TIME      :number = 15;
//思考時間
export const MAX_THINKING_TIME     :number = 180;
export const MIN_THINKING_TIME     :number = 10;
export const DEF_THINKING_TIME     :number = 60;//debug
export const PER_THINKING_TIME     :number = 10;

//Settings 2
export const GAME_OVER_INTERVAL    :number = 10000;     //msec
export const ANIM_REVERS_PIECE     :number = 200;       //msec ひっくり返しアニメ時間
export const PASS_INTERVAL         :number = 3000;      //msec

//Event
export const EV_NONE               :number = 0;             //何でもない、何もしない
export const EV_PLAYER_DONE        :number = 1;             //今のプレイヤーが上がった
export const EV_NEXT_TURN          :number = 2;             //次のプレイヤーの番
export const EV_END_GAME           :number = 3;             //ゲーム終了

/**@description 基本設定をまとめたオブジェクトです。
 */
export interface SettingsObject{
    recruit_time    :number,
    thinking_time   :number
}
/**@description プレイ中の情報をまとめたオブジェクトです。
*/
//プレイ中の情報まとめ・clientと共用
export interface RoundStatusObject{
    now_turn             :number       //現在のターン数
}
/**@description プレイヤー情報をまとめたオブジェクトです。
 */
export interface PlayerObject{
    name                 :string,               //ユーザ名
    img_avater_index     :number,               //アバターのイメージインデックス
    img_avater           :HTMLImageElement,     //アバターのイメージ画像。client側でのみ使用する。
    [key: string]        :any;                  //※プロパティをブラケット記法で取得するために必要
}
/**@description プレイ中の全データをまとめたオブジェクトです。
 */
export interface PlayingDataObject{
    prog_status          :number,               //現在の状態
    settings             :SettingsObject,       //clientから取得
    round_status         :RoundStatusObject,
    players              :PlayerObject[]        //PlayerObject //白、黒
}
/**@description サーバにある盤面の駒情報を取得するときに使用します。
 * 縦横全てのデータ
 */
export interface PlayingPiecesDataObject{
    pieces               :number[][]
}
/**@description 設定の、最大値(max)、最小値(min)、Default値(def)、
 * drop-down-listでmax-min内で分割する単位(per)、入力値(val)をまとめたオブジェクトです
 */
export interface SettingsSetObject{
    max                  :SettingsObject,
    min                  :SettingsObject,
    def                  :SettingsObject,
    per                  :SettingsObject,
    val                  :SettingsObject
}
/**
 * @description Server <- -> Clientでやり取りするデータ群
 */
export interface IArgmentSocketEvent {
    request_id          :number,
    playing_data?       :PlayingDataObject,
    player?             :PlayerObject,
    pieces_data?        :PlayingPiecesDataObject,
    start_msec?         :number,
    max_msec?           :number,
    put_piece_data?     :number[],//square_x, square_y, side
    leave_player_index? :number,
    settings?           :SettingsObject
}
/**
 * PlayingDataObjectをクリアします。
 * player.img_avaterはclient側からのみの追加なので
 * 削除もclient側に任せます。
 * @param {PlayingDataObject} playing_data
 */
export function freePlayingData(playing_data: PlayingDataObject):void{
    if(!playing_data)return;
    playing_data.settings = null;
    playing_data.round_status = null;
    let players :PlayerObject[] = playing_data.players,
        score   :number[] = null,
        i       :number = 0,
        len     :number = playing_data.players.length;
    for(i=0; i<len; i++){
        score = players[i].score;
        if(score && score.length > 0){score.splice(0, score.length);}
    }
    playing_data.players = null;
    players = null;
    score = null;
    playing_data = null;
}

export function createRoundStatusObject():RoundStatusObject{
    return {
                now_turn             : 0        //現在のターン数
            }
}
/**
 * SettingsObjectを作成します。
 */
export function createSettingsObject():SettingsObject{
    return {
                recruit_time    : DEF_RECRUIT_TIME,
                thinking_time   : DEF_THINKING_TIME
            };
}
/**
 * SettingsSetObjectを作成します。
 */
export function createSettingsSetObject():SettingsSetObject{
    let res :SettingsSetObject = {
                                    max                  : createSettingsObject(),
                                    min                  : createSettingsObject(),
                                    def                  : createSettingsObject(),
                                    per                  : createSettingsObject(),
                                    val                  : createSettingsObject()

                                }
    res.max.recruit_time = MAX_RECRUIT_TIME;
    res.max.thinking_time = MAX_THINKING_TIME;

    res.min.recruit_time = MIN_RECRUIT_TIME;
    res.min.thinking_time = MIN_THINKING_TIME;

    res.def.recruit_time = DEF_RECRUIT_TIME;
    res.def.thinking_time = DEF_THINKING_TIME;

    res.per.recruit_time = PER_RECRUIT_TIME;
    res.per.thinking_time = PER_THINKING_TIME;

    return res;
}

/**
 * PlayerObjectを作成します。
 */
export function createPlayerObject():PlayerObject{
    return {
                name                 : "",                  //ユーザ名
                img_avater_index     : 0,                   //アバターのイメージインデックス
                img_avater           : null                 //アバターのイメージ画像。client側でのみ使用する。
            };
}
/**
 * PlayingDataObjectを作成します。
 */
export function createPlayingDataObject():PlayingDataObject{
    return {
                prog_status          : ST_RECRUIT,                      //現在の状態
                settings             : null,                            //clientから取得
                round_status         : createRoundStatusObject(),
                players              : []                               //PlayerObject
            };
}
/**
 *
 */
export function createPlayingPiecesDataObject():PlayingPiecesDataObject{
    return {
                pieces              :null
    }
}
/**
 * 指定プロパティから検索し、playersのインデックスを返します。
 * 複数いる場合、最初に合致したインデックスを返します。
 * 存在しない場合、-1を返します。
 */
export function indexOfPlayers(prop_name:string, value:string|number, players:PlayerObject[]):number{
    if(!players){
        return -1;
    }
    let i   :number =0,
        len :number = players.length;
    for(i=0; i<len; i++){
        if(players[i][prop_name] === value){
            return i;
        }
    }
    return -1;
}

//マス目
export class KRReversiSquare {
    public parent   :KRReversiBoard;
    public x        :number;
    public y        :number;
    public piece    :number;                //置いてある駒 無し、黒、白のいずれか
    public puttable :number;                //置くことが出来る駒 無し、黒、白のいずれか
    constructor(x:number, y:number, parent:KRReversiBoard){
        this.x = x;
        this.y = y;
        this.parent = parent;
    }
}
/**
 * リバーシ盤の作成
 */
export class KRReversiBoard {
    public length_x             :number;
    public length_y             :number;
    public squares              :KRReversiSquare[][];      //リバーシの盤(マス目)
    public r8_squares           :KRReversiSquare[][];      //反転(ひっくり返し)用に取得するマス目（８方向）-> r8_squares[8][i] ※アニメ遅延設定でも必要
    public puttable_squares     :KRReversiSquare[][];      //現在黒、白が置けるマス目　[0=黒], [1=白], [2=黒白]
    public length_emp_squares   :number;                   //マス目空き数
    public length_black         :number;
    public length_white         :number;
    constructor(){
        this.length_x = BOARD_LEN_X;
        this.length_y = BOARD_LEN_Y;
        //マス目群作成
        var sq  :KRReversiSquare[][] = new Array(this.length_x);
        var i   :number;
        var ii  :number;
        for(i=0; i < this.length_x; i++){
            sq[i] = new Array(this.length_y);   //こっちは数不動
            for(ii=0; ii < this.length_y; ii++){
                sq[i][ii] = new KRReversiSquare(i, ii, this);
            }
        }
        this.squares = sq;
        //反転用マス目群作成 -> (x,y) x 8 (8方向)
        var r_sq    :KRReversiSquare[][] = new Array(SEARCH_VECTORS.length);
        for(i=0; i<r_sq.length; i++){
            r_sq[i] = new Array();              //こっちは数 頻繁に変化
        }
        this.r8_squares = r_sq;
        //puttableマス目
        var p_sq    :KRReversiSquare[][] = new Array(3);//黒、白、白黒両方=3
        p_sq[0] = new Array();//黒が置ける空きマス
        p_sq[1] = new Array();//白が置ける空きマス
        p_sq[2] = new Array();//黒白が置ける空きマス
        this.puttable_squares = p_sq;
        //マス目の初期化
        this.initSquares();
    }
    /**
     * マス目を初期化します。
    *  |0_|1_|2_|3_|4_|5_|6_|7_|
    * 0|__|__|__|__|__|__|__|__|
    * 1|__|__|__|__|__|__|__|__|
    * 2|__|__|__|_1|_2|__|__|__|
    * 3|__|__|_1|●|〇|_2|__|__|
    * 4|__|__|_2|〇|●|_1|__|__|
    * 5|__|__|__|_2|_1|__|__|__|
    * 6|__|__|__|__|__|__|__|__|
    * 7|__|__|__|__|__|__|__|__|
     */
    public initSquares():void {
        var x   :number = this.length_x,
            y   :number = this.length_y,
            i   :number = 0,
            ii  :number = 0,
            sqs :KRReversiSquare[][] = this.squares;
        for(i=0; i<x; i++){
            for(ii=0; ii<y; ii++){
                sqs[i][ii].piece = PIECE_NONE;
                sqs[i][ii].puttable = PIECE_NONE;
            }
        }
        //駒の初期配置
        sqs[3][3].piece = sqs[4][4].piece = PIECE_WHITE;
        sqs[4][3].piece = sqs[3][4].piece = PIECE_BLACK;
        //置ける場所の設定
        this.setPuttableAndCountPieces();
        //駒が置かれてないマス目
        this.length_emp_squares = this.length_x * this.length_y - 4;
    }
    /**
     * 指定したマス目を取得します。。
     * @param x
     * @param y
     */
    private getSquare(x: number, y: number): KRReversiSquare{
        if(x < 0 || x >= this.length_x || y < 0 || y >= this.length_y){
            return null;
        }
        return this.squares[x][y];
    }
    /**
     * 開始位置(x, y)からvec方向に延びるライン上のマス目を取得し、resに保存します。
     * ※resは取得前に初期化(length=0)されます。
     * ※開始位置のマス目は取得しません。
     */
    private getSquares(x:number, y:number, vec:number[], res:KRReversiSquare[]):void{
        if(res.length > 0){//初期化
            res.splice(0, res.length);
        }
        var i           :number = 0;
        var len         :number = this.length_x;
        var sq          :KRReversiSquare = null;
        if(len < this.length_y){
            len = this.length_y;
        }
        for(i=0; i<len; i++){
            x = x + vec[0];
            y = y + vec[1];
            sq = this.getSquare(x, y);
            if(sq){
                res.push(sq);
            }else{
                break;
            }
        }
    }
    /**
     * 位置(x, y)から8方向に延びるライン上にあるマス目を全て取得します。
     * 取得したマス目は"r8_squares"プロパティに保存されます。
     */
    private getSquares8line(x:number, y:number):void{
        var r_sq    :KRReversiSquare[][] = this.r8_squares;
        var i       :number = 0;
        var len     :number = r_sq.length;
        for(i=0; i<len; i++){
            this.getSquares(x, y, SEARCH_VECTORS[i], r_sq[i]);
        }
    }
    /**
     * 指定したマス目に駒を置けるかチェックします。
     * ※doRevers()を実行することで実行されるsetPuttableAndCountPieces()
     * 後でなければ、正しく機能しません。
     */
    public checkPutPiece(x:number, y:number, piece:number):boolean{
        if(x < 0 || y < 0) return false;
        var sq:KRReversiSquare = this.squares[x][y];
        //何も置かれてない　＆＆　置くことが出来る
        return sq.piece === PIECE_NONE && ((sq.puttable & piece) !== 0);
    }
    /**
     * 盤上の位置(x, y)に指定pieceを置きます。置けた場合trueを返します。
     * これにより"r8_squares"プロパティに反転されるマス目が取得されます。
     * ※まだ反転は適用されていません。doReversを実行することで反転を適用します。
     */
    public putPiece(x:number, y:number, piece:number):boolean{
        //まず指定場所におけるかチェック
        if(!this.checkPutPiece(x, y, piece)){
            return false;
        }
        //置く
        this.squares[x][y].piece = piece;
        this.length_emp_squares--;
        //(x,y)のマス目から8方向へ伸びるマス目を取得
        this.getSquares8line(x, y);
        //反転できるマス目のみ取得
        this.setRevers8line(piece);

        return true;
    }
    /**
     * 事前にr8_squareプロパティに取得しているマス目からリバース(ひっくり返せる)出来るマス目を選別し
     * r8_squaresに再設定します。
     */
    private setRevers8line(piece:number):void{
        var i       :number = 0,
            len     :number = this.r8_squares.length;
        for(i=0; i<len; i++){
            removeNotReversSquares(piece, this.r8_squares[i]);
        }
        /**
         * r8_squares内のマス目で実際ひっくり返すマス目以外を削除します。
         */
        function removeNotReversSquares(piece:number, squares:KRReversiSquare[]):void{
            var i           :number = 0,
                len         :number = squares.length,
                anti_piece  :number = PIECE_BLACK_WHITE & ~piece,//~ = ビット演算子 NOT
                intercepter :number = PIECE_NONE,
                r_count     :number = 0;//ひっくり返す枚数
            for(i=0; i<len; i++){
                //引数pieceが黒なら白、白なら黒だったらr_countを追加
                if(squares[i].piece === anti_piece){
                    r_count++;
                }else
                if(squares[i].piece === piece){
                    intercepter = piece;
                    break;
                }else{
                    break;
                }
            }
            if(intercepter !== PIECE_NONE){//r_count分は残して他削除
                squares.splice(r_count, len - r_count);
            }else{
                squares.splice(0, len);//全消し
            }
        }
    }
    /**
     * r8_squaresに所持しているマス目の駒の反転を適用します。
     * 最後にsetPuttableAndCountPieces()が実行されます。
     */
    public doRevers():void{
        var i       :number = 0,
            len     :number = this.r8_squares.length,
            ii      :number = 0,
            lenlen  :number =0,
            sqs     :KRReversiSquare[] = null;
        for(i=0;i<len;i++){
            sqs = this.r8_squares[i];
            lenlen = sqs.length;
            for(ii=0; ii<lenlen; ii++){
                sqs[ii].piece = PIECE_BLACK_WHITE & ~sqs[ii].piece;
            }
        }
        this.setPuttableAndCountPieces();
    }
    /**
     * 0.まだ駒を置いていないマスに駒を置けるか設定します。(puttable_の値をセット)
     * 1.現在の盤面上の黒と白の数を数えます。
     * 全マスチェック(自分が黒、白、観戦問わず全てチェック)
     * init or doRevers　後に実行。
     */
    private setPuttableAndCountPieces():void{
        var i       :number = 0,
            len     :number = this.length_x,
            ii      :number = 0,
            lenlen  :number = this.length_y,
            sq      :KRReversiSquare = null;
        //置けるマス目保持用配列の初期化
        for(i=0; i<this.puttable_squares.length; i++){
            this.puttable_squares[i].splice(0, this.puttable_squares[i].length);
        }
        this.length_black = 0;
        this.length_white = 0;
        //チェック
        for(i=0;i<len;i++){
            for(ii=0;ii<lenlen;ii++){
                sq = this.squares[i][ii];
                //初期化
                sq.puttable = PIECE_NONE;
                //置かれてないところだけチェック
                if(sq.piece === PIECE_NONE){//置いてないマス
                    this.checkPutable(sq);
                }else{//白Or黒が置いてあるマス
                    switch(sq.piece){
                        case PIECE_BLACK:
                            this.length_black++;
                            break;
                        case PIECE_WHITE:
                            this.length_white++;
                            break;
                    }
                }
            }
        }
    }
    private checkPutable(target:KRReversiSquare):void{
        var i               :number = 0,
            len             :number = SEARCH_VECTORS.length,
            vec             :number[] = null,
            adjoin_sq       :KRReversiSquare = null,    //隣接のマス目
            sq              :KRReversiSquare = null,    //捜査用マス目
            sx              :number = 0,
            sy              :number = 0,
            flg_stop        :boolean = false,
            debug_count     :number = 0;
        for(i=0; i<len; i++){
            vec = SEARCH_VECTORS[i];
            sx = target.x + vec[0];//x
            sy = target.y + vec[1];//y
            adjoin_sq = this.getSquare(sx, sy);
            flg_stop = false;
            if(adjoin_sq && adjoin_sq.piece !== PIECE_NONE){
                while(!flg_stop){
                    sx = sx + vec[0];
                    sy = sy + vec[1];
                    sq = this.getSquare(sx, sy);
                    if(sq && sq.piece !== PIECE_NONE){
                        if(adjoin_sq.piece !== sq.piece){//隣接にある駒と違う種類の駒
                            target.puttable = target.puttable | sq.piece;//bit演算 Black(1),White(2),Black&White(3)
                            flg_stop = true;
                        }
                    }else{//マスが無い、もしくは駒が無い
                        flg_stop = true;
                    }
                    debug_count++;
                    if(debug_count > 1000){
                        console.log("cfunc_reversi->checkPutable():1000over!!");
                        break;
                    }
                }
            }
            debug_count = 0;
        }
        //チェック終了・・・
        if(target.puttable !== PIECE_NONE){
            this.puttable_squares[target.puttable - 1].push(target);//[PIECE_BLACK], [PIECE_WHITE], [PIECE_BLACK_WHITE]
        }
    }
    /**
     * 全てのマス目の駒データを取得します。
     * サーバからクライアントへ盤面のデータを渡す際に使用します。
     */
    public getPiecesData():PlayingPiecesDataObject {
        let res :PlayingPiecesDataObject  = createPlayingPiecesDataObject(),
            i   :number = 0,
            ii  :number = 0,
            len :number = this.length_x,
            lenlen:number = this.length_y;
        res.pieces = new Array(len);
        for(i=0; i < len; i++){
            res.pieces[i] = new Array(lenlen);
            for(ii=0; ii<lenlen; ii++){
                res.pieces[i][ii] = this.squares[i][ii].piece;
            }
        }
        return res;
    }
    /**
     * サーバから送られてきたデータを盤面に設定する際に使用します。
     */
    public setPiecesData(data:PlayingPiecesDataObject):void{
        let p       :number[][] = data.pieces,
            i       :number = 0,
            ii      :number = 0,
            len     :number = this.length_x,
            lenlen  :number = this.length_y;
        for(i=0; i < len; i++){
            for(ii=0; ii<lenlen; ii++){
                this.squares[i][ii].piece = p[i][ii];
                this.squares[i][ii].puttable = PIECE_NONE;
            }
        }
        this.setPuttableAndCountPieces();
    }
    /**
     * ゲームが終了できるかチェックします。
     * 終了条件
     * 0. 置けるところがもうない。
     * 1. 黒、白とも置けるところがもうない。
     * ※これの実行前にsetPuttableAndCountPieces()を実行しておくこと！
     */
    public checkFinish():boolean{
        return this.length_emp_squares === 0 || (this.puttable_squares[0].length === 0 && this.puttable_squares[1].length === 0 && this.puttable_squares[2].length === 0);
    }
    /**
     * 反転数が一番多い8ラインの反転数を取得します。
     * putPiece()を実行するとr8_squaresの内容が変化します。
     * 反転アニメ時間取得等に利用されます。
     */
    public getReversCountMax():number{
        let res :number = 0,
            cmp :number = 0,
            i   :number = 0,
            len :number = this.r8_squares.length;
        for(i=0;i<len;i++){
            cmp = this.r8_squares[i].length;
            if(res < cmp){
                res = cmp;
            }
        }
        return res;
    }
    /**
     * 置ける場所を取得します。
     * 引数は[PIECE_BLACK=1], [PIECE_WHITE=2]のいずれかです。
     */
    public getPuttableSquareCount(side:number):number{
        return this.puttable_squares[side - 1].length + this.puttable_squares[2].length;
    }
    /**
     * 指定したsideの、置ける場所をランダムで、KRReversiSquaresで取得します。
     * サーバ側で時間切れの時に置く場所を取得するために使用します。
     */
    public getRandomPuttableSquare(side:number):KRReversiSquare{
        //まず、片方のみ送る場所を取得。取れなかったら両方置ける場所を取得
        let side_index  :number = side - 1,// black or white
            len         :number = this.puttable_squares[side - 1].length,
            res         :KRReversiSquare = null;
        if(len === 0){
            len = this.puttable_squares[2].length;
            side_index = 2; //black and white
        }
        if(len === 0){
            return null;
        }
        return this.puttable_squares[side_index][Math.floor(Math.random() * len)];
    }

}

