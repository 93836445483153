import {ICustomClientSocket, IDataRoom, IDataCreateRoom, IEmitDataJoinRoom, IEmitDataErrMsg} from "../../common/ctypes";
import {IEmitDataLogin, IEmitDataRollDice, IArgmentChatData} from "../../common/ctypes";
import KRAnimeteDice from "./kr_animete_dice";
import KRDataURLControl from "./kr_dataurl_control";
import KRImageDisplay from "./kr_image_display";
import KRDisplayEffect from "./kr_display_effect";
import KRMgrDaifugouForClient from "./kr_daifugou_client";
import KRMgrReversiForClient from "./kr_reversi_client";

import * as RS from "../../common/cfunc_reversi";//auto debugで使用。通常は使わない

$(() => {
    "use strict";
    //Client側プログラム
    //上記の"$(function(){});"は、jQueryのお約束のようなもの。必ず、書かないと動かない。
    //"$(function(){});"は、"$(document).ready(){};"と同じ意。
    //すなわち、「ページの読込が終わったら～」、命令を実行するという意。
    //また、$はJQueryの略として使われることがある。すなわち　$(function(){〜})=JQuery(function(){〜}),　$(window)=JQuery(window)　である。
    /*
    * TODO:UI dialog positionの挙動がとにかくおかしいので、UI以外を使ってダイアログを表示したい。
    * TODO:✔大富豪のセッティングは全て入力はリストに変更
    * TODO:✔ソフトウェアキーボードが出てくる不具合を解決する -> focusを消せば、多分治る。
    */
    //Javascriptにおいて、$は何の意味も持たない（他のアルファベットと一緒）
    //変数名の頭に＄をつけるのはJQueryからの変数だとわかりやすくするためにつけるのであって、それ以外の意味は無い。
    const DIR_IMG_AVATER                :string = 'img/avater/';
    const IMAGE_AVATER_LENGTH           :number = 52;
    const MIN_DISPLAY_SIZE              :number = 480; //縦横の最小サイズ（これ以下になったら全体を縮小させる）
    const MAX_IMAGE_SIZE                :number = 256; //画像の最大サイズ
    const FADE_TIME                     :number = 150; // msec
    const TYPING_TIMER_LENGTH           :number = 400; // msec
    const DEFAULT_MAX_MESSAGE_LENGTH    :number = 100;     //最大メッセージ数
    const DEFAULT_MESSAGE_DELETE_LENGTH :number = 1;    //最大メッセージ数を超えた際に、削除する数
    const COLORS                        :string[] = [
    '#e21400', '#91580f', '#f8a700', '#f78b00',
    '#58dc00', '#287b00', '#a8f07a', '#4ae8c4',
    '#3b88eb', '#3824aa', '#a700ff', '#d300e7',
    ];
    //各要素の取得(JQuery)
    // Initialize variables
    const $WINDOW                         :JQuery<Window> = $(window);                                    // Windowオブジェクト:ブラウザのウィンドウを制御する機能
    //CSSで定義されたクラスセレクタは、ピリオド（.）とその直後に続くクラス名から成り立ち、
    //クラス名と(X)HTMLのclass属性が一致した場合に適用されます。
    const $CHAT_HEADER                    :JQuery<HTMLElement> = $('.chat.header');                       // chat header
    const $messages                       :JQuery<HTMLElement> = $('#messages');                          // Messages Area
    const $IPTS                           :{[key:string]:JQuery<HTMLElement>} = {
        chat_message: $('.ipt_chat_message'),                    // Input message input box
        user_name:  $('.ipt_username'),                         // Input for usernameInputBox
    };
    //buttons
    const $btns                           :{[key:string]:JQuery<HTMLElement>} = {
        exit_room:  $('.btn.exitRoom'),                         // button exit room
        roll_dice:  $('.btn.rollDice'),                         // button roll dice
        dice_count_up:  $('.btn.diceCounter.up'),               // diceCount up
        dice_count_down:  $('.btn.diceCounter.down'),           // diceCount down
        create_room:    $('.btn.createRoom'),                   // button create room
        table_update:   $('.btn.tableUpdate'),                  // button table update
        open_image_file:$('.btn.openImageFileDialog'),          // button image upload
        daifugou: $('.btn.daifugou'),                           // button daifugou
        reversi: $('.btn.reversi'),
    };

    const $tbl_rooms_body                 :JQuery<HTMLElement> = $('#tbl_rooms_body');              // Rooms tbody
    //pages
    const $loginPage                      :JQuery<HTMLElement> = $('.login.page');                  // The login page
    const $loungePage                     :JQuery<HTMLElement> = $('.lounge.page');                 // The lounge page
    const $chatPage                       :JQuery<HTMLElement> = $('.chat.page');                   // The chatroom page

    let connected                       :boolean = false;
    let typing                          :boolean = false;                                         //現在メッセージを打ち込み中かどうか
    let lastTypingTime                  :number;
    let $currentInput                   :JQuery<HTMLElement> = $IPTS.user_name.focus();           //フォーカスで使用。

    //Socket.ioモジュール. "io()"を実行することでサーバにWebSocketコネクションを生成。
    //引数なしの「io()」や「io.connect()」使用時には、デフォルトで「/」という名前空間を持つWebSocketコネクションが生成される。
    const socket                          :ICustomClientSocket = io() as ICustomClientSocket;

    let key_locked                      :boolean = false;                                        //フラグキーロック
    //init data
    const DATA_ROOM                       :IDataRoom = {                                           //部屋情報。create room, join room で使用する
        name            : "",
        capacity        : 0,
        flg_password    : false,
        password        : "",
        now_count       : 0,
    };

    /**
     * @typedef {{MAX_LENGTH: number, MAX_CAPACITY: number, MIN_CAPACITY: number, DEF_CAPACITY:number, PER_CAPACITY: number}} DATA_CREATE_ROOM
    */
    /**
     * @description 部屋作成情報。 setting.js内で設定されたデータを取得保持
     */
    let DATA_CREATE_ROOM                :IDataCreateRoom = null;
    //dice data
    const animeteDice = KRAnimeteDice({
                                        picture_width: 1200,
                                        picture_height: 400,
                                        cel_width: 200,
                                        cel_height: 200,
                                        roll_animete_start_index: 6,
                                        roll_animete_length:5,
                                        roll_animete_speed: 50,
                                    });
    let dice_length = 1;
    const msg_elm_info = {
        first_index: 0,                             //現在表示されている最初のメッセージ
        last_index: 0,                              //メッセージが追加されるたびに増えていく
        max_length: DEFAULT_MAX_MESSAGE_LENGTH,     //最大表示数。これを超えたら、若い番号から削除していく。
        del_length: DEFAULT_MESSAGE_DELETE_LENGTH,   //最大表示数を超えた際に削除する数。
    };
    const $img_avater = $('#img_avater');
    let img_avater_index = 0;
    let window_scale = 1.0;                         //Cssで縮尺を変更した際、すぐ値が取り出せるように取得
    const element_pages = document.getElementById('pages');

    $img_avater.click((e)=>{
        e.stopImmediatePropagation();
        setDisplayPositionForDialog($dlgAvaterList);
        $dlgAvaterList.dialog('open');
    });
/*
    let my_class = new MyClass(1);
    console.log(my_class.f1());
    console.log(my_class.f3());
    let my_class2 = new MyClass2(2);
    console.log(my_class2.f2(1, 2));
*/
/* node.js Blowser 共有jsファイル Test
    let my_common = new YourModule();
    console.log(my_common.method3("DCBA!"));
*/
//---------------------------------------------------------------------------------------------------------------------------------------------------------
/*
    //画像のロード→カスタムスプライト作成→スプライト作成→フレーム更新　サンプル
    //フレーム更新用Timer
    let _timer = {
        id: null,
        old_msec: 0
    };
    let sp_list = [];
    //canvasの作成とdocumentへの追加
    let divv = document.createElement("div");
    divv.style.position = "fixed";
    divv.style.left = "0px";
    divv.style.top = "0px";
    divv.style.width = "100%";
    divv.style.height = "100%";
    divv.style.backgroundColor = "green";
    let canvas = document.createElement("canvas");
    canvas.style.position = "absolute";
    canvas.style.left = "0px";//※中央ぞろえにするにはtop,left,right,bottomも指定しないと適用されない。
    canvas.style.top = "0px";
    canvas.style.right = "0px";
    canvas.style.bottom = "0px";
    canvas.style.margin = "auto";
    canvas.style.backgroundColor = "red";
    //canvas.setAttribute("width", "512px");//キャンバスのサイズ（横）を変える！
    //canvas.setAttribute("height", "384px");//キャンバスのサイズ（縦）を変える！
    canvas.width = 1024;
    canvas.height = 384;
    //canvas.style.width = "512px";   //こっちはキャンバスの縮尺を変える！
    //canvas.style.height = "240px";  //こっちはキャンバスの縮尺を変える！
    divv.appendChild(canvas);
    document.body.appendChild(divv);
    let mouseEventForCanvas = function( e ) {
        e.preventDefault();//動作を停止
        // マウス位置を取得する(pageX,pageYだと画面左上からの位置になる)
        let mouseX = e.offsetX;//e.pageX ;	//X座標
        let mouseY = e.offsetY;//e.pageY ;	//Y座標
        let len = sp_list.length;
        //初期化
        for(let i=0; i < len; i++){
            sp_list[i].setScale(0.5, 0.5);
        }
        //衝突判定
        for(let i = len - 1; i >= 0; i--){
            let col = sp_list[i].col;
            let index = col.checkBoxListAndCircle(mouseX, mouseY, 0);
            if(index != -1){
                sp_list[i].setScale(0.6, 0.6);
                break;
            }
        }

    };
    canvas.addEventListener("mousemove", mouseEventForCanvas);
    //スプライトマネージャ
    let mgr_sprite = KRSprite2DManager(canvas);
    //カスタムスプライト作成
    function CustomSprite2D(){
        CustomSprite2D.base(this, 'constructor');//これしないとエラー。super()みたいなもの？
    }
    Kr.inherits(CustomSprite2D, KRSprite2D);
    //カスタムスプライトオーバーライド
    CustomSprite2D.prototype.doMove = function(elapsed){
        let a = this._degree + elapsed * 0.01;
        if(a >= 360){
            a = a - 360;
        }
        this._degree = a;
        let sin = Math.sin(a * 10 * Math.PI/180);
        this._scale.x = sin;
        this._scale.y = sin;
    }
    //スプライトマネージャにカスタムスプライトを登録
    mgr_sprite.registSpriteClass(CustomSprite2D);
    //画像ローダー
    let img_loader = KRImageLoader();
    let sources = {};
    for(let i = 0; i < 53; i++){
        sources[i] = "../img/trump/" + i + ".png";
    }
    sources["char_chip"] = "../img/char_chip.png";
    //画像のロード
    img_loader.loadWithObject(sources, function(images) {
        //スプライト作成
        let sp = null;
        let image_pattern = Kr.Image.createImagePattern(images[0], 1, 1);
        for(let i = 0; i < 6; i++){
            let num = i;//Math.floor(Math.random() * 53);
            let deg = 16 * i - 16;
            sp = mgr_sprite.createSprite("KRSprite2D", images[num], image_pattern, 200 + i * 50, 200, 0);
            sp.setRotate(deg);
            sp.col = new KRCollision();
            sp.col.setPosition(sp._position.x, sp._position.y);
            sp.col.setRotate(sp._degree);
            sp.col.setScale(0.5, 0.5);
            sp.col.addHitBox(0, 0, sp._image.naturalWidth, sp._image.naturalHeight);
            sp_list.push(sp);
        }
        image_pattern = Kr.Image.createImagePattern(images["char_chip"], 3, 4);
        sp = mgr_sprite.createSprite("CustomSprite2D", images["char_chip"], image_pattern, 100, 100, 1);
        sp._pattern_index = 1;
        //フレーム更新開始
        _timer.old_msec = Date.now();
        //imageを要素として挿入（スプライトに影響は与えない）
        for(let i=0; i < 3; i++){
            images[i].width = 100;
            images[i].height = 150;
            divv.appendChild(images[i]);
        }

        updateFrame(33);
    });
    //フレーム更新用関数
    function updateFrame(interval){
        _timer.id = setTimeout(function(){
            let now_msec = Date.now();
            let elapsed = now_msec - _timer.old_msec;
            mgr_sprite.move(elapsed);
            _timer.old_msec = now_msec;
            mgr_sprite.draw();
            updateFrame(interval);
        }, interval);
    }
*/
//-----------------------------------------------------------------------------------------------------------------------------------------------------------------

    //first settings---------------------------------------------------------------------------------------------------------------------------------------------------
    //dlgCreateRoomの設定------------------------------------------------------------------------------------------------------
    //html
    const sss =
    '<div class="j-ui-dialog dlgCreateRoom">'+
        '<fieldset>'+
        '<legend >必須</legend>'+
        '<div>名前 <input type="text" class="ipt_cr_name" placeholder="部屋名" maxlength="14" /></div>'+
        '<div>定員 <select class="lst_cr_capacity" style="width: 64px;"></select>名</div>'+
        '</fieldset>'+
        '<fieldset>'+
        '<legend>鍵</legend>'+
        '<div><label><input type="checkbox" class="chk_cr_password" name="flgPass" value="1" />パスワードを設定する</label></div>'+
        '<div><input type="text" class="ipt_cr_password" maxlength="14" disabled/></div>'+
        '</fieldset>'+
    '</div>';
    $('body').append(sss);
    //parts
    const $dlg_parts_cr = {
        ipt_name    : $('.ipt_cr_name'),                // Input room name
        lst_capacity: $('.lst_cr_capacity'),            // Drop down list room capacity
        chk_password: $('.chk_cr_password'),            // Check room password
        ipt_password: $('.ipt_cr_password'),             // Input room password
    }
    $dlg_parts_cr.chk_password.prop("checked", false);
    $dlg_parts_cr.ipt_password.prop("disabled", true);
    //dialog
    const $dlgCreateRoom = $('.j-ui-dialog.dlgCreateRoom');
    let _flg_wait_create_room_result = false;//サーバからMessage貰うまでDlg閉じないので必要
    $dlgCreateRoom.dialog({
        autoOpen: false,
        modal: true,
        width: MIN_DISPLAY_SIZE,
        //height: MIN_DISPLAY_SIZE,
        title: "部屋の作成",
        buttons: {
            // tslint:disable-next-line:object-literal-shorthand
            "作成": ()=> {
                doCreateRoom();//閉じるのはサーバから作成完了Messageを貰ってから
            },
            "中止": ()=> {
                $dlgCreateRoom.dialog('close');
            },
        },
        /*
        open: (event, ui)=>{
        },
        */
        close: (event, ui)=> {
            lockControlls(false);
        },
    });
    //$dlgCreateRoom.dialog("option", "appendTo", "#dialogUIArea" );//どこに所属させるか決定（Defaultはbody）
    //widgetに値を適用するために必要
    $dlgCreateRoom.dialog('open'); $dlgCreateRoom.dialog('close');
    //dlgMessageの設定--------------------------------------------------------------------------------------------------------
    $('body').append('<div class="j-ui-dialog dlgMessage"></div>');
    const $dlgMessage = $('.j-ui-dialog.dlgMessage');
    $dlgMessage.dialog({
        autoOpen: false,
        modal: true,
        width: MIN_DISPLAY_SIZE,
        //height: MIN_DISPLAY_SIZE / 2
    });
    const DEFAULT_DLG_MASSAGE_BUTTONS = {
            "OK": ()=> {$dlgMessage.dialog('close');},
        };
    const DEFAULT_DLG_MASSAGE_FUNC_CLOSE = (event:Event, ui:JQuery)=> {
            if(!$dlgCreateRoom.dialog( 'isOpen' )){
                lockControlls(false);
            }
        };
    //$dlgMessage.dialog("option", "appendTo", "#dialogUIArea" );//どこに所属させるか決定（Defaultはbody）
    //widgetに値を適用するために必要
    $dlgMessage.dialog('open'); $dlgMessage.dialog('close');
    //dlgAvaterListの設定---------------------------------------------------------------------------------------------------
    let $dlgAvaterList          :JQuery<HTMLElement> = null;
    let $tbl_avater_list_body   :JQuery<HTMLElement> = null;
    //setTimeout(setupDlgAvaterList, 100);
    setupDlgAvaterList();
    function setupDlgAvaterList(){
        const COL_LENGTH = 5;
        const HEIGHT = 480;
        const WIDTH = MIN_DISPLAY_SIZE;

        const TXT_OFFER_SITE_TITLE = "提供サイト：";
        const TXT_OFFER_SITE_NAME = "ぴよたそ";
        const TXT_OFFER_SITE_URL = "http://hiyokoyarou.com/";

        //html-JQueryを使わないで作成してみた場合
        //全体のフレーム
        const elm_dlg_div = document.createElement('div');    //Y軸スクロールバー実装用
        elm_dlg_div.id = "dlg_avater_list";
        elm_dlg_div.setAttribute('width', WIDTH + 'px');
        elm_dlg_div.setAttribute('height', HEIGHT + 'px');
        elm_dlg_div.style.overflowY = 'auto';

        //offer site
        const elm_offer_div = document.createElement('div');
        elm_offer_div.appendChild(document.createTextNode(TXT_OFFER_SITE_TITLE));
        const elm_offer_a = document.createElement('a');
        elm_offer_a.href = TXT_OFFER_SITE_URL;
        elm_offer_a.target = "_blank";
        elm_offer_a.appendChild(document.createTextNode(TXT_OFFER_SITE_NAME));
        elm_offer_div.appendChild(elm_offer_a);
        elm_dlg_div.appendChild(elm_offer_div);

        //avater table
        const elm_tbl = document.createElement('table');
        elm_tbl.id = "tbl_avater_list";
        elm_tbl.border = "0";
        elm_tbl.cellSpacing = "1";
        const elm_tbody = document.createElement('tbody');
        elm_tbody.id = "tbl_avater_list_body";
        elm_tbl.appendChild(elm_tbody);
        elm_dlg_div.appendChild(elm_tbl);
        //let txt_tbl = '';
        let i = 0;
        let ii = 0;
        const len_row = Math.floor(IMAGE_AVATER_LENGTH / COL_LENGTH) + 1;
        //let txt_row = '';
        let s_index = 0;
        let img_index = 0;
        let elm_tr = null;
        let elm_td = null;
        let elm_img = null;
        for(i=0; i<len_row; i++){
            s_index = i * COL_LENGTH;
            elm_tr = document.createElement('tr');
            elm_tr.className = 'tbl_avater_list_row';
            for(ii=0; ii<COL_LENGTH; ii++){
                img_index = s_index + ii;
                if(img_index < IMAGE_AVATER_LENGTH){
                    elm_td = document.createElement('td');
                    elm_td.style.padding = "0px";
                    elm_td.style.margin = "0px";
                    elm_img = document.createElement('img');//'Image'ではないので注意。'Image'の場合は new Image()として使う。
                    elm_img.src = DIR_IMG_AVATER + img_index + '.png';
                    elm_img.style.border = "4px double";
                    if(i===0 && ii===0){
                        elm_img.style.backgroundColor = "#33aa33";
                    }
                    elm_td.appendChild(elm_img);
                    elm_tr.appendChild(elm_td);
                }else{
                    break;
                }
            }
            elm_tbody.appendChild(elm_tr);
            if(img_index >= IMAGE_AVATER_LENGTH) {break};
        }
        document.body.appendChild(elm_dlg_div);
        $tbl_avater_list_body = $('#tbl_avater_list_body');
        //function avater選択
        $tbl_avater_list_body.on('click', 'img',(event)=>{
            const target_td   :Node = event.target.parentNode;//親ノード
            const target_row  :Node = target_td.parentNode;//親ノード
            let pos_x = 0;
            let pos_y = 0;
            const tbl         :HTMLTableElement = document.getElementById('tbl_avater_list') as HTMLTableElement;
            let j = 0;let k = 0; let td = null; let row = null; let img:HTMLImageElement = null;
            for(j=0; j<tbl.rows.length; j++){
                row = tbl.rows[j];
                if(target_row === row){ pos_y = j; }
                for(k=0; k<row.cells.length; k++){
                    td = row.cells[k];
                    img = td.childNodes[0] as HTMLImageElement;//子ノード
                    img.style.backgroundColor = "";
                    if(target_td === td){ pos_x = k;}
                }
            }
            img = target_td.childNodes[0] as HTMLImageElement;//子ノード
            img.style.backgroundColor = "#33aa33";
            const src :string = (event.target as HTMLImageElement).src;
            //画像の設定
            $img_avater.attr("src", src);
            img_avater_index = COL_LENGTH * pos_y + pos_x;
        });
        //dialog
        $dlgAvaterList = $('#dlg_avater_list');
        $dlgAvaterList.dialog({
            autoOpen: false,
            modal: true,
            width: WIDTH,
            height: HEIGHT,
            title: "アバターの選択",
            buttons: {
                "OK": ()=>{
                    $dlgAvaterList.dialog('close');
                },
            },
            /*
            open: function(event, ui){
            },
            close: function (event, ui) {
            }
            */
        });
        //$dlgAvaterList.dialog("option", "appendTo", "#dialogUIArea");//どこに所属させるか決定（Defaultはbody）
        //widgetに値を適用するために必要
        $dlgAvaterList.dialog('open'); $dlgAvaterList.dialog('close');
    }
    //----------------------------------------------------------------------------------------------------------------------
    /*  オリジナルボタンからfile選択ダイアログを表示(jqueryは使ってません)
        ※画像ファイル呼び出し用にindex.htmlに以下のボタンを追加する(id,class名はなんでもよい)
        <div class="btn design_btn_name">Button</div>                   ...装飾用ボタン
        <input type="file" id="file_btn_name" style="display:none;">    ...実際Dialogを開くのに必要なボタン（非表示）
    */
    //let designButton = document.getElementsByClassName("btn openImageFileDialog")[0];  //$btnsにまとめた
    const pictureFileButton   :HTMLInputElement = document.getElementById("picture-file-button") as HTMLInputElement;     //実際のファイル選択ダイアログボタン（非表示）
    const dataurlctl = KRDataURLControl();
    const imageDisplay = KRImageDisplay();

    //functions---------------------------------------------------------------------------------------------------------------------------------------------------------
    //debugで使用
    function autoLogin(){
        //名前と画像の自動設定
        $IPTS.user_name.val("Kuro-" + Math.floor(Math.random()*10000));
        img_avater_index = Math.floor(Math.random() * IMAGE_AVATER_LENGTH);
        //ログイン
        setUsername();
    }
    //debugで使用
    function autoJoinRoom(){
        const tbl :HTMLTableElement = document.getElementById('tbl_rooms_body') as HTMLTableElement;
        const row = tbl.rows[0];
        if(row){
            DATA_ROOM.name = (row.cells[0].firstChild as any).data;
            socket.emit('join room', DATA_ROOM);
        }
    }
    /**
     * avaterのイメージを作成して、返します。
     * @param {number} index
     */
    function getImgAvater(index:number){
        const img = new Image();
        img.src = DIR_IMG_AVATER + index + '.png';
        return img;
    }
    /**
     * サイト情報を作成します。
     * 内容はpublic/data/about_sit.txtから読み込みます。
     */
    createAboutThisSite();
    function createAboutThisSite(){
        //吹き出し
        const elm_fukidashi = document.createElement('span');
        elm_fukidashi.className = "msg_fukidashi";
        elm_fukidashi.style.position = 'relative';
        elm_fukidashi.style.left = "64px";
        const elm_edge = document.createElement('span');
        elm_edge.className = "fuki_edge";
        const elm_edge_outline = document.createElement('span');
        elm_edge_outline.className = "fuki_edge_outline";
        const elm_balloon = document.createElement('span');
        elm_balloon.className = "fuki_balloon";
        elm_balloon.style.textAlign = 'left';
        elm_balloon.style.paddingBottom = '24px';
        elm_balloon.style.width = '256px';
        elm_fukidashi.appendChild(elm_balloon);
        elm_fukidashi.appendChild(elm_edge_outline);
        elm_fukidashi.appendChild(elm_edge);
        //event設定
        const e_circle = document.createElement('div');
        e_circle.id = 'event_about_this_site';
        const login_page = document.getElementsByClassName('login page')[0];
        const elm_img_title = login_page.getElementsByClassName('img title')[0];
        elm_img_title.appendChild(e_circle);
        e_circle.addEventListener("mouseover", (e)=>{
            e_circle.appendChild(elm_fukidashi);
        });
        e_circle.addEventListener("mouseout", (e)=>{
            const childNodes = e_circle.childNodes;
            if(childNodes.length > 0){
                const child = childNodes[0];
                child.parentElement.removeChild(child);
            }
        });
        //表示内容の読み込み
        const xml_http = new XMLHttpRequest();
        xml_http.onreadystatechange = checkStatus;
        xml_http.open("GET", "/data/about_this_site.txt", true);
        xml_http.send(null);
        function checkStatus(){
            if (xml_http.readyState === 4 && xml_http.status === 200){
                const txt = xml_http.responseText;
                $(elm_balloon).append(txt);//JQueryだと超楽
            }
        }
    }

    //test--------------------------------------------------
    //let kr_img = new KRSpriteElement();
    const disp_effect = KRDisplayEffect(document.body);
    disp_effect.startTimer(100);

    //大富豪ダイアログ表示
    const mgr_daifugou_c = KRMgrDaifugouForClient(socket, MIN_DISPLAY_SIZE);
    //大富豪開始ボタン
    $btns.daifugou.click(()=>{
        if(!mgr_reversi_c.ui.activated()){
            mgr_daifugou_c.openDialog();
        }
    });
    //リバーシダイアログ表示
    const mgr_reversi_c = KRMgrReversiForClient(socket, MIN_DISPLAY_SIZE);
    //リバーシ開始ボタン
    $btns.reversi.click(()=>{
        if(!mgr_daifugou_c.ui.activated()){
            mgr_reversi_c.openDialog();
        }
    });
    //実行
    setTimeout(()=>{
        mgr_daifugou_c.setup(addChatMessage, addLogMessage, changePageRayout, getImgAvater, setDisplayPositionForDialog, element_pages, ()=>{
            mgr_reversi_c.setup(addChatMessage, addLogMessage, changePageRayout, getImgAvater, setDisplayPositionForDialog, element_pages, ()=>{
                changePageRayout();
                //autoLogin();//debug
             });
         });
    }, 100);

    //画像選択ダイアログ表示(本来のボタン(pictureFileButton)のclickイベントを発火)
    $btns.open_image_file.click(()=>{
        if (key_locked) { return; }
        const evt = document.createEvent("MouseEvents");
        evt.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        pictureFileButton.dispatchEvent( evt );
    });
    //画像選択ダイアログを閉じた後の処理
    pictureFileButton.onchange = ()=>{
        // ファイルが選択されたか
        if(!(pictureFileButton.value)) { return; }
        // FileList オブジェクトを取得する
        const FILE_LIST = pictureFileButton.files;
        if(!FILE_LIST) {return;}
        //socket.ioでサーバにemit
        if(!FILE_LIST[0]){return;}
        //サーバに送信するためimage fileをbase64形式に変換
        dataurlctl.createDataURLWithFile(FILE_LIST[0], (errmsg, dataURL)=>{
                    if(errmsg){
                        showDialogMessage("警告！", errmsg);
                    }else{
                        socket.emit('new image', dataURL);
                    }
                });
    }
    //ブラウザバック無効処理
    window.onhashchange = ()=>{
        window.location.hash="domicha";
    }
    //リサイズウィンドウ
    window.onresize = ()=>{
        changePageRayout();
    }
    //読み込み完了後実行
    window.onload = ()=>{
        changePageRayout();
    }
    /**
     * ダイアログの縮尺と位置を補正します
     * ※JQuery UI Dialogについて(dlg = dialogオブジェクト)
     * ・dlg.dialog( "option", "width" ),dlg.dialog( "option", "height" )は設定してない'auto'を返す。
     * ・widget = dlg.dialog("widget");でダイアログ自体の要素を取得できる。
     * ・widget.outerWidth(), widget.outerHeight()でダイアログの幅と高さを取得できるが、一度'open'しないと適用されないぽい。
     * @param {Object} dlg
     */
    function setDisplayPositionForDialog(dlg:JQuery<HTMLElement>){
        //・dlgの幅と高さは固定
        //・縮尺は変更->親エレメント'pages'のscaleに影響（変数window_scale）
        //・左上を基本として、縮尺１の時は画面中央（センター）に計算で移動させる
        //・縮尺＜１の時、縦と高さ別々で計算して設定する
        /*
        let win_w = window.innerWidth;
        let win_h = window.innerHeight;
        let widget = dlg.dialog("widget");
        let dlg_w = widget.outerWidth(true);
        let dlg_h = widget.outerHeight(true);
        */
        //console.log('dlg: ' + dlg_w + 'px, ' + dlg_h + 'px');
        /*
        var dlg_w = dlg.dialog( "option", "width" );
        if(!dlg_w == 'auto'){dlg_w = dlg.outerWidth(true) + 10;}
	    var dlg_h = dlg.dialog( "option", "height" );
        if(dlg_h == 'auto'){
            //dlg_h = dlg.outerHeight(true) + 135;
            dlg_h = dlg.height();
            let widget = dlg.dialog( "widget" );
            let hhh = widget.height();
            console.log(hhh);
        }
        */
        /*
        let mov_l = 0;
        let mov_t = 0;
        dlg_w = Math.floor(dlg_w * window_scale);
        dlg_h = Math.floor(dlg_h * window_scale);
        if(win_w > dlg_w){
            //センターに移動
            mov_l = Math.floor((win_w - dlg_w) / 2);
        }
        if(win_h > dlg_h){
            //センターに移動
            mov_t = Math.floor((win_h - dlg_h) / 2);
        }
        let txt_my_pos = "left+" + mov_l + " top+" + mov_t;
        //理由は不明だが、複数回実行しないと補正されない。
        for(let i=0; i<20; i++){
            dlg.dialog({position:{
                my: txt_my_pos,
                at: 'left top',
                of: window,
            }});
        }
        */
        //Dialogの値取得テスト
        //testGet();
        /*
        function testGet(){
            //let dlg_w = dlg.dialog( "option", "width" );
            //let dlg_h = dlg.dialog( "option", "height" );
            let out_w = dlg.outerWidth(true);
            let out_h = dlg.outerHeight(true);
            let ddd_w = $("div.ui-dialog.ui-widget.ui-widget-content.ui-corner-all").outerWidth(true);
            let ddd_h = $("div.ui-dialog.ui-widget.ui-widget-content.ui-corner-all").outerHeight(true);
            let sss = $('div#dlg_avater_list.ui-dialog-content.ui-widget-content');
            let sss_w = sss.outerWidth(true);
            let sss_h = sss.outerHeight(true);
            console.log('scale: ' + window_scale + '------------');
            console.log('win: ' + win_w + 'px, ' + win_h + 'px');
            console.log('dlg: ' + dlg_w + 'px, ' + dlg_h + 'px');
            console.log('mov: ' + mov_l + 'px, ' + mov_t + 'px');
            console.log('out: ' + out_w + 'px, ' + out_h + 'px');
            console.log('ddd: ' + ddd_w + 'px, ' + ddd_h + 'px');
            console.log('sss: ' + sss_w + 'px, ' + sss_h + 'px');
        }
        */

/*
        //let ml = Math.floor(width * (1 - window_scale) / 2);
        let ml = Math.floor(dls_w * (1 - window_scale));
        let txt_ml = "left+" + ml + " top+" + ml;
        //console.log('txt_ml = ' + txt_ml);
        for(let i=0; i<20; i++){
            dlg.dialog({position:{
                my: 'left top',
                at: 'left top',
                of: window,
            }});
        }
        console.log("window_scale = " + window_scale);
        console.log("txt_ml = " + txt_ml);
*/
        //縮尺補正
        const window_w = window.innerWidth;
        const window_h = window.innerHeight;
        const widget = dlg.dialog("widget");
        let dlg_w = widget.outerWidth(true);
        if(dlg_w <= 0){dlg_w = 1;}
        let dlg_h = widget.outerHeight(true);
        if(dlg_h <= 0){dlg_h = 1;}
        const scale_w = (window_w / dlg_w > 1) ? 1 : window_w / dlg_w;
        const scale_h = (window_h / dlg_h > 1) ? 1 : window_h / dlg_h;
        const scale = scale_w < scale_h ? scale_w : scale_h;
        const txt_scale = "scale(" + scale + "," + scale + ")";
        widget.css('transform', txt_scale);
        //位置補正
        let mov_l = 0;
        let mov_t = 0;
        dlg_w = Math.floor(dlg_w * scale);
        dlg_h = Math.floor(dlg_h * scale);
        if(window_w > dlg_w){
            //センターに移動
            mov_l = Math.floor((window_w - dlg_w) / 2);
        }
        if(window_h > dlg_h){
            //センターに移動
            mov_t = Math.floor((window_h - dlg_h) / 2);
        }
        const txt_my_pos = "left+" + mov_l + " top+" + mov_t;
        //理由は不明だが、複数回実行しないと補正されない。
        for(let i=0; i<20; i++){
            dlg.dialog({position:{
                my: txt_my_pos,
                at: 'left top',
                of: window,
            }});
        }
        /*
        console.log('scale: ' + window_scale + '------------');
        console.log('win: ' + window_w + 'px, ' + window_h + 'px');
        console.log('wgt: ' + dlg_w + 'px, ' + dlg_h + 'px');
        console.log('mov: ' + mov_l + 'px, ' + mov_t + 'px');
        */

        //dlg.dialog("option", "transform", "scale(0.5)");
/*
        if(scale <= 1){
            dlg.dialog({position:{
                my: 'left center',
                at: 'left center',
                of: window,
            }});
        }else{
            dlg.dialog({position:{
                my: 'center center',
                at: 'center center',
                of: window,
            }});
        }
*/
    }
    /**
     * ①Windowのリサイズ時、②UIの表示・非表示時
     * に呼び出されます。
     */
    function changePageRayout(){
        const window_width = window.innerWidth;
        const window_height = window.innerHeight;
        let scale_w = 1;
        let scale_h = 1;
        let txt_scale = "";
        const per_w = 1;
        const per_h = 1;
        let width_value = 0;
        let height_value = 0;
        //スケール設定
        scale_w = window_width / MIN_DISPLAY_SIZE;
        if(scale_w > 1){scale_w = 1;}
        scale_h = window_height / MIN_DISPLAY_SIZE;
        if(scale_h > 1){scale_h = 1;}
        window_scale = scale_w < scale_h ? scale_w : scale_h;
        if(window_scale <= 0) {window_scale = 1;}
        txt_scale = "scale(" + window_scale + "," + window_scale + ")";
        //幅設定
        width_value = window_width / window_scale;
        height_value = window_height / window_scale;
        //headerの横の長さはwindowに合わせる
        $CHAT_HEADER.width(width_value + "px");

        const act:boolean = (mgr_daifugou_c && mgr_daifugou_c.ui.activated())||(mgr_reversi_c && mgr_reversi_c.ui.activated());
        if(act){
            //寸法計算
            let d_x = 0; let d_y = 0; let d_w = width_value; let d_h = height_value;
            //縦もしくは横のチャットエリアを分割する
            if(window_width < window_height){
                //縦長
                height_value = height_value / 2;
                d_h = height_value;
                d_y = height_value;
            }else{
                //横長
                const h = $CHAT_HEADER.height();
                width_value = width_value / 2;
                d_w = width_value;
                d_h = height_value - h;
                d_x = width_value;
                d_y = h;
            }
            //大富豪UI表示中
            if(mgr_daifugou_c){
                if(mgr_daifugou_c.ui.activated()){
                    mgr_daifugou_c.ui.setPositionAndSize(d_x, d_y, d_w, d_h, window_scale);
                }
            }
            //リバーシUI表示中
            if(mgr_reversi_c){
                if(mgr_reversi_c.ui.activated()){
                    mgr_reversi_c.ui.setPositionAndSize(d_x, d_y, d_w, d_h, window_scale);
                }
            }
        }

        //適用
        element_pages.style.transform = txt_scale;
        //element_pages.style.transformOrigin = "0 0";
        element_pages.style.width = "100%";
        element_pages.style.height = "100%";
        element_pages.style.width = width_value + "px";
        element_pages.style.height = height_value + "px";

        /*
        document.body.style.transform = txt_scale;
        document.body.style.transformOrigin = "0 0";
        document.body.style.width = "100%";
        document.body.style.height = "100%";
        document.body.style.width = width_value + "px";
        document.body.style.height = height_value + "px";
        */
        imageDisplay.resize();

    }

    //サーバ側との共通設定(login時に一度だけ行う設定)
    function setCommonData(dat:any):void{
        DATA_CREATE_ROOM = Object.assign({}, dat.ROOM);
        //収容数の設定
        const min = DATA_CREATE_ROOM.MIN_CAPACITY;
        const max = DATA_CREATE_ROOM.MAX_CAPACITY;
        const def = DATA_CREATE_ROOM.DEF_CAPACITY;
        const per = DATA_CREATE_ROOM.PER_CAPACITY;
        let val = min;
        let txt_val = "";
        let $option = null;
        const $select = $dlg_parts_cr.lst_capacity;
        while(val <= max){
            txt_val = val.toString();
            $option = $('<option>').val(txt_val).text(txt_val);
            if(val === def){
                $option.prop('selected', true);
            }
            $select.append($option);
            val = val + per;
        }
    }

    //JQueryで表示判定で使うプラグイン 例：$loginPage.isVisible()
    ($.fn as any).isVisible = function() {
        return ($ as any).expr.filters.visible(this[0]);
    };

    //部屋の作成
    function doCreateRoom(){
        if(_flg_wait_create_room_result) {return;}
        //各項目チェック&入力
        const MSG_INCORRECT_VALUE_NAME = "部屋名が入力されていません！";
        const MSG_INCORRECT_VALUE_CAPACITY = "定員数の値が不正です！";
        //name
        const roomname = ($dlg_parts_cr.ipt_name.val() as string).trim();
        if(!roomname){
            showDialogMessage("警告！", "<p>" + MSG_INCORRECT_VALUE_NAME + "</p>");
            return;
        }
        DATA_ROOM.name = roomname;
        //capacity
        const capacity = parseInt($dlg_parts_cr.lst_capacity.val() as string, 10);
        if(typeof capacity !== 'number' || capacity < DATA_CREATE_ROOM.MIN_CAPACITY || capacity > DATA_CREATE_ROOM.MAX_CAPACITY){
            showDialogMessage("警告！", "<p>" + MSG_INCORRECT_VALUE_CAPACITY + "</p>");
            return;
        }
        DATA_ROOM.capacity = capacity;
        //flg_password
        DATA_ROOM.flg_password = $dlg_parts_cr.chk_password.prop('checked');
        //password
        DATA_ROOM.password = $dlg_parts_cr.ipt_password.val() as string;
        //結果待ちフラグ
        _flg_wait_create_room_result = true;
        //send to server
        socket.emit('create room', DATA_ROOM);
    }

    //DialogMessageの表示
    function showDialogMessage(title:string, msg:string, buttons?:any, func_close?:any):void {
        //ボタンがなければ"OK"のみ作成
        if(!buttons){
            buttons = DEFAULT_DLG_MASSAGE_BUTTONS;
        }
        if(!func_close){
            func_close = DEFAULT_DLG_MASSAGE_FUNC_CLOSE;
        }
        //title,button設定
        $dlgMessage.dialog({//個別に設定も出来る
            title,
            buttons,
            close: func_close,
        });
        //msg設定
        //$dlgMessage.find('p').remove();            //指定要素内のp要素を取り除く
        $dlgMessage.empty();                         //指定要素の子要素を全て削除
        $dlgMessage.append(msg);
        setDisplayPositionForDialog($dlgMessage);
        $dlgMessage.dialog('open');
    }

    // 部屋内、参加人数表示
    function addParticipantsMessage(data:IEmitDataJoinRoom) {
        let message = '';
        message += data.numUsers + "人、参加中。";
        addLogMessage(message);
    }

    // 部屋内、中の人の名前表示
    // membersName: Array
    function addParticipantsNameMessage(data:IEmitDataJoinRoom){
        let message = '中の人：';
        const membersName = data.membersName;
        const len = membersName.length;
        let i = 0;
        for(i=0; i < len; i++){
            message += membersName[i];
            if(i < len - 1){
                message += ", ";
            }
        }
        addLogMessage(message);
    }

    // 自ユーザネーム設定
    //trim:文字列の先頭と末尾から、空白を除去します。渡された文字列から、正規表現で空白と見做されるものを除去します。
    //そのため、改行コードや全角のブランクであっても、空白として処理されます。
    function setUsername() {
        const temp_username = cleanInput(($IPTS.user_name.val() as string).trim());
        if (temp_username) {
            lockControlls(true);
            const data = {
                username: temp_username,
                img_avater_index,
            };
            socket.emit('login', data);
        }
    }

    // メッセージ発信
    function sendMessage() {
        let message :string = $IPTS.chat_message.val() as string;
        //トリムして文字列が無くなるようなら何もしない。
        if(message.trim() === ''){
            $IPTS.chat_message.val('');//入力したのを削除
            return;
        }
        // Prevent markup from being injected into the message
        message = cleanInput(message);
        // if there is a non-empty message and a socket connection
        if (message && connected) {
            $IPTS.chat_message.val('');//入力したのを削除
            // tell server to execute 'new message' and send along one parameter
            socket.emit('new message', message);
        }
    }
/* 手動位置計算の廃止
    //chatAreaのmessage or log 追加位置取得
    const MARGIN_CHAT_MESSAGE = 4;
    function getInsertTopPositionInChatArea(){
        let elm_messages = document.getElementById('messages');
        let elm_child = elm_messages.lastChild;
        let res = MARGIN_CHAT_MESSAGE;//余白
        if(elm_child){
            let elm_chatArea = document.getElementById('chatArea');
            let style = elm_chatArea.currentStyle || document.defaultView.getComputedStyle(elm_chatArea, '');
            let padding_top =parseInt(style.paddingTop, 10);
            let first_child = elm_child.firstChild;
            let rect = first_child.getBoundingClientRect();
            let scroll_top = elm_messages.scrollTop;
            res = res + rect.bottom / window_scale + scroll_top - padding_top;	// 要素のY座標
        }
        return res;
    }
*/
    const TEXT_MSG_ID = "msg_id_";
    /**
     * ログ、メッセージ要素に管理用（削除用）IDを追加します。
     * @param {Elemet} elm Element
     */
    function addIdInMessageElement(elm:HTMLElement):void{
        elm.id = TEXT_MSG_ID + msg_elm_info.last_index;
        msg_elm_info.last_index++;
    }
    /**
     * ログ、メッセージが追加された際、一定量で削除処理を行います。
     */
    function deleteMessageElements():void{
        const max_len = msg_elm_info.max_length;
        let f_index = msg_elm_info.first_index;
        const l_index = msg_elm_info.last_index;
        const now_len = l_index - f_index;
        if(now_len <= max_len){return;}
        //削除処理
        let i = 0;
        let len = msg_elm_info.del_length;
        let index = 0;
        let txt_id = "";
        if(now_len < len){
            len = now_len;
        }
        for(i = 0; i < len; i++){
            index = f_index + i;
            txt_id = '#' + TEXT_MSG_ID + index;
            $(txt_id).remove();
        }
        f_index = f_index + len;
        msg_elm_info.first_index = f_index;
        /*手動位置計算の廃止
        //再整列（位置の繰り上げ）
        len = l_index - f_index;
        if(len == 0) return;
        txt_id = TEXT_MSG_ID + f_index;
        let elm_li = document.getElementById(txt_id);
        let elm_child = elm_li.firstChild;
        let elm_chatArea = document.getElementById('chatArea');
        let style = elm_chatArea.currentStyle || document.defaultView.getComputedStyle(elm_chatArea, '');
        let padding_top = parseInt(style.paddingTop, 10);
        let rect = elm_child.getBoundingClientRect();
        let dec_value = rect.top + window.pageYOffset - MARGIN_CHAT_MESSAGE - padding_top;	// 要素のY座標
        let new_value = 0;
        for(i=f_index; i < l_index; i++){
            txt_id = TEXT_MSG_ID + i;
            elm_li = document.getElementById(txt_id);
            elm_child = elm_li.firstChild;
            new_value = parseInt(elm_child.style.top, 10) - dec_value;
            elm_child.style.top = new_value + "px";
        }
        */
    }
    /**ログの追加準備
     * @param {string} message
     * @param {Object} options
     */
    function addLogMessage(message:string, options?:any):void {
        /*
        //textの入れ物
        let elm_div = document.createElement('div');
        elm_div.classList.add('log');
        elm_div.appendChild(document.createTextNode(message));
        */
        //要素:li
        const elm_li = document.createElement('li');
        elm_li.classList.add('log');
        elm_li.appendChild(document.createTextNode(message));
        /*
        elm_li.appendChild(elm_div);

        //Yの絶対座標を算出
        let pos_y = getInsertTopPositionInChatArea();
        elm_div.style.top = pos_y + "px";
        */
        //削除用にIDを追加
        addIdInMessageElement(elm_li);
        //htmlに追加
        addMessageElement(elm_li, options);
        /*
        //※中央ぞろえにしたい--addMessageElementでappendした後でないと、$el.width()は正確に取得できない(0になってしまう)
        let rect = elm_div.getBoundingClientRect();
        //let w = parseInt(elm_div.style.width, 10);
        let pos_x = (window.innerWidth - rect.width) / 2;
        pos_x = pos_x / window_scale;
        elm_div.style.left = pos_x + "px";
        */
    }
    /**  message追加準備
        (dataの内容)
        message: string, typing: boolean, username: string, img_avater_index: number    ...タイピング中の場合
        or
        message: string, username: string, img_avater_index: number                     ...文字列の場合
        or
        images: ImageElement[], scale:number, username:string, img_avater_index: number ...画像の場合
        (optionsの内容)
        fade: boolean, prepend: boolean
    */
    function addChatMessage(data:IArgmentChatData, options?:any):void{
        // Don't fade the message in if there is an 'X was typing'
        const $typingMessages = getTypingMessages(data.username);//よくわからんちん
        options = options || {};
        if ($typingMessages.length !== 0) {
            options.fade = false;
            $typingMessages.remove();
        }
        //設置する位置Yを取得する
        //let pos_y = getInsertTopPositionInChatArea();
        //吹き出しと名前の要素作成
        /* <span> 自身は特に何の意味も持たないタグです。<span>～</span> で囲まれた部分にスタイルシートを適用したりするのに用いられます。
         * 同様なタグに <div> があります。<div> はブロック要素(前後に改行がはいる)の汎用的なタグとして、
         * <span> はインライン要素(前後に改行が入らない)の汎用的なタグとして使用されます。
        */
        /*
        //全体の入れ物
        let elm_message_box = document.createElement('span');
        elm_message_box.className = "message_box";
        */
        //吹き出し
        const elm_fukidashi = document.createElement('span');
        elm_fukidashi.className = "msg_fukidashi";
        const elm_edge = document.createElement('span');
        elm_edge.className = "fuki_edge";
        const elm_edge_outline = document.createElement('span');
        elm_edge_outline.className = "fuki_edge_outline";
        const elm_balloon = document.createElement('span');
        elm_balloon.className = "fuki_balloon";
        elm_fukidashi.appendChild(elm_balloon);
        elm_fukidashi.appendChild(elm_edge_outline);
        elm_fukidashi.appendChild(elm_edge);
        //名前
        const elm_username = document.createElement('span');
        elm_username.style.whiteSpace = "nowrap";
        elm_username.className = "msg_username";
        const txt_node = document.createTextNode(data.username);
        elm_username.appendChild(txt_node);
        elm_username.style.color = getUsernameColor(data.username);
        //アバターイメージ
        const elm_avaterImg = document.createElement('img');
        elm_avaterImg.src = DIR_IMG_AVATER + data.img_avater_index + '.png';
        elm_avaterImg.style.position = 'absolute';
        elm_avaterImg.className = 'msg_img_avater';
        //メッセージ？画像？
        if(data.message != null){
            elm_balloon.appendChild(document.createTextNode(data.message));
        }else
        if(data.images != null){
            //0.images要素を設定
            const images = data.images;
            let i = 0;
            const len = images.length;
            let scale = 1;
            let img = null;
            let add_x = 0;
            const c_padding = 16;
            if(data.scale){ scale = data.scale; }
            for(i=0;i<len;i++){
                img = images[i];
                img.width = img.naturalWidth * scale;      //imgなら画像の縮尺も楽々
                img.height = img.naturalHeight * scale;    //imgなら画像の縮尺も楽々
                img.style.position = "absolute";
                img.style.top = c_padding + "px";
                add_x = add_x + c_padding;
                img.style.left = add_x + "px";
                add_x = add_x + img.width;
            }
            //1.balloonの高さと幅を指定
            elm_balloon.style.height = (img.height + c_padding * 2) + "px";
            elm_balloon.style.width = (add_x + c_padding) + "px";
            //2.imgをappend
            for(i=0;i<len;i++){
                elm_balloon.appendChild(images[i]);
            }
        }
        /*
        elm_message_box.appendChild(elm_username);
        elm_message_box.appendChild(elm_avaterImg);
        elm_message_box.appendChild(elm_fukidashi);
        */
        const typingClass = data.typing ? 'typing' : '';  //typingの場合はclass追加

        const elm_li = document.createElement('li');
        elm_li.classList.add('message');//typingで使用してるので消せない + 下記のdata-usernameとセットでないと意味がない
        elm_li.setAttribute('data-username', data.username);
        if(typingClass){
            elm_li.classList.add(typingClass);
        }
        //elm_li.appendChild(elm_message_box);
        elm_li.appendChild(elm_username);
        elm_li.appendChild(elm_avaterImg);
        elm_li.appendChild(elm_fukidashi);
        //Typingは無視
        if(typingClass === ''){
            addIdInMessageElement(elm_li);
        }
        addMessageElement(elm_li, options);//追加

        //※実際に追加した後でないと幅が取得できないからここでX位置調整
        const username_width = elm_username.offsetWidth;
        const img_avater_width = elm_avaterImg.offsetWidth;
        const shift_width = username_width > img_avater_width ? username_width : img_avater_width;
        elm_avaterImg.style.top = "0px";
        elm_avaterImg.style.left = (shift_width - img_avater_width)/2 + "px";

        elm_username.style.left = (shift_width - username_width)/2 + "px";
        elm_username.style.top = "64px";

        elm_fukidashi.style.left = shift_width + "px";
        //全体の大きさを計算してセット
        const rect_b = elm_balloon.getBoundingClientRect();
        const rect_a = elm_avaterImg.getBoundingClientRect();
        const rect_u = elm_username.getBoundingClientRect();
        let total_width = rect_b.right - rect_u.left + window.pageXOffset;
        const f_heigth = rect_b.height;
        const u_height = rect_u.bottom - rect_a.top;
        let total_height = f_heigth > u_height ? f_heigth : u_height;
        total_height = total_height + window.pageYOffset;

        total_width = total_width / window_scale;
        total_height = total_height / window_scale;
        //pos_y = pos_y / window_scale;

        //elm_message_box.style.top = pos_y + "px";
        /*
        elm_message_box.style.width = total_width + "px";
        elm_message_box.style.height = total_height + "px";
        */
        elm_li.style.width = total_width + "px";
        elm_li.style.height = total_height + "px";
    }
    // data.username
    function addChatTyping(data:IArgmentChatData):void {
        data.typing = true;     //追加
        data.message = '...';   //追加
        addChatMessage(data);
    }
    // Removes the visual chat typing message
    function removeChatTyping(username:string):void {
        getTypingMessages(username).fadeOut(function(){
            $(this).remove();
        });
    }
    /**"Message Area"へメッセージの追加処理
     * el - The element to add as a message
     * options.fade - If the element should fade-in (default = true)
     * options.prepend - If the element should prepend
     * all other messages (default = false)
     */
    function addMessageElement(el:HTMLElement, options:any):void {
        const $el = $(el);
        // Setup default options
        if (!options) {
            options = {};
        }
        if (typeof options.fade === 'undefined') {
            options.fade = true;
        }
        if (typeof options.prepend === 'undefined') {
            options.prepend = false;
        }

        //deleteMessageElements();
        const elm_messages = document.getElementById('messages');

        // Apply options
        if (options.fade) {
            $el.hide().fadeIn(FADE_TIME);
        }
        if (options.prepend) {
            $messages.prepend($el);
        } else {
            //$messages.append($el);
            elm_messages.appendChild(el);
        }

        deleteMessageElements();
        setTimeout(()=> {//なんか弄ってたらTimeout使わないと上手く動かなくなった；
            const scroll_h = elm_messages.scrollHeight;
            const normal_h = elm_messages.clientHeight;
            elm_messages.scrollTop = scroll_h - normal_h;
        }, 100);
        //$messages.scrollTop = $messages.scrollHeight;
    }

    // Prevents input from having injected markup
    function cleanInput(input:string):string {
        //text(val):条件に一致する全ての要素にテキストを設定する。html(val)に似ているが、これはあくまでテキストなので、
        //”<“や”>“などはエスケープされてHTMLエンティティとして追加される。
        //text():指定した要素が持つテキストノードを結合したものを返す。
        return $('<div/>').text(input).text();
    }

    // Updates the typing event
    function updateTyping():void {
        if (connected) {
            if (!typing) {
            typing = true;
            socket.emit('typing');
            }
            lastTypingTime = Date.now();
            //指定された遅延の後に、コードの断片または関数を実行します。
            window.setTimeout(()=> {
                const typingTimer = Date.now();
                const timeDiff = typingTimer - lastTypingTime;
                if (timeDiff >= TYPING_TIMER_LENGTH && typing) {
                    socket.emit('stop typing');
                    typing = false;
                }
            }, TYPING_TIMER_LENGTH);
        }
    }

    // Gets the 'X is typing' messages of a user
    //-.filter(fn(index){})-
    //要素集合から、引数で渡したコールバック関数(fn)で合致と判定しなかった要素を全て削除したものを返す。
    //この関数は、全ての要素に対して $.each のように順に実行されます。この時にfalseを返せば、その要素は集合から外される。
    //false以外の値を返せば、その要素は残る。
    function getTypingMessages(username:string):JQuery<HTMLElement> {
        return $('.typing.message').filter(function(i) {
            return $(this).data('username') === username;
        });
    }

    function getUsernameColor(username:string):string {
        // Compute hash code
        let hash = 7;
        for (let i = 0; i < username.length; i++) {
            hash = username.charCodeAt(i) + (hash << 5) - hash;
        }
        // Calculate color
        const index = Math.abs(hash % COLORS.length);
        return COLORS[index];
    }

    //controll lock -> true:lock false:unlock
    function lockControlls(value:boolean):void {
        key_locked = value;
        /*
        if ($currentInput) {
            $currentInput.prop("disabled", value);
        }
        */
        let s = "";
        for(s in $btns){
            if(value){
                $btns[s].attr('disabled', 'disabled');
            }else{
                $btns[s].removeAttr('disabled');
            }
        }
        for(s in $IPTS){
            if(s){
              $IPTS[s].prop("disabled", value);
            }
        }
    }

    //set table of room
    function resetRoomTable(rooms_data:IDataRoom[]):void {
        //現在の表を削除
        $("div.tableArea tbody#tbl_rooms_body tr").remove();//全消し
        //表を追加
        let need_password = ""; //鍵有無
        for (const i in rooms_data) {
            if(i){
                need_password = "無";
                if (rooms_data[i]["flg_password"]) {
                need_password = "有";
                }
                $tbl_rooms_body.append('<tr class="tbl_rooms_row"><td>' + rooms_data[i]["name"] + '</td><td>' + need_password + '</td><td>' + rooms_data[i]["now_count"] + ' / ' + rooms_data[i]["capacity"] + '</td></tr>');
            }
        }
    }

    // Keyboard events-------------------------------------------------------------------------------------------------------------------------------------------
    $WINDOW.keydown((event)=> {
        //キーロック中なら抜ける
        if (key_locked) { return; }
        // Auto-focus the current input when a key is typed
        /*
        if ($currentInput && !(event.ctrlKey || event.metaKey || event.altKey)) {
            $currentInput.focus();
        }*/
        // When the client hits ENTER on their keyboard
        if (event.which === 13) {
            if (socket.username) {
                sendMessage();
                socket.emit('stop typing');
                typing = false;
            } else {
                setUsername();
            }
        }
        //debug start
        /*
        else if(event.which === 96){//テンキー"0"
            if(disp_effect.nowActive()){
                disp_effect.stopTimer();
            }else{
                disp_effect.startTimer(100);
            }
        }
        */
        //debug end
    });

    $IPTS.chat_message.on('input', ()=> {
        updateTyping();
    });

    // Click events-------------------------------------------------------------------------------------------------------------------------------------------
    //Table をクリック
    //※親要素にイベントリスナーを登録しておけば、後から子で追加された要素にもイベントが発生する
    //$(document).on('click','.tbl_rooms_row', function(event){//<-document(全体)でなくても大丈夫
    $tbl_rooms_body.on('click', '.tbl_rooms_row', (event)=> {
        if (key_locked) { return; }
        //部屋名の取得
        const cells = (event.currentTarget as HTMLTableRowElement).cells;
        const roomname = cells[0].innerText;
        const flg_pass = "有" === cells[1].innerText;
        let res = 0;
        const buttons = {
            "はい": ()=> {
                res = 1;
                $dlgMessage.dialog('close');
            },
            "いいえ": ()=> {
                $dlgMessage.dialog('close');
            },
        };
        const func_close = ()=> {
            if (res === 1) {
                lockControlls(true);
                //部屋名と（必要なら）パスワードを入力
                DATA_ROOM.name = roomname;
                if ($(".ipt_join_password")) {
                    DATA_ROOM.password = $(".ipt_join_password").val() as string;
                }
                socket.emit('join room', DATA_ROOM);
            }
        }
        let msg = '<p>' + roomname + 'に入室しますか？</p>';
        //passwordがある場合入力欄生成
        if (flg_pass) {
            msg = msg + '<fieldset>' +
            '<legend>鍵が設定されてます</legend>' +
            'パスワード：<input class="ipt_join_password" type="text" name="NAME" size=15>' +
            '</fieldset>';
        }
        showDialogMessage("確認", msg, buttons, func_close);
    });

    //退室の確認
    $btns.exit_room.click(()=> {
        if (key_locked) { return; }
        let res = 0;
        const buttons = {
            "はい": ()=> {
            res = 1;
            $dlgMessage.dialog('close');
            },
            "いいえ": ()=> {
            $dlgMessage.dialog('close');
            },
        };
        const func_close = (event:Event, ui:JQuery)=> {
            if (res === 1) {
                lockControlls(true);
                socket.emit('leave room');
            }
        }
        showDialogMessage("確認", "<p>退室しますか？</p>", buttons, func_close);
    });

    //roll dice
    $btns.roll_dice.click(()=>{
        if (key_locked) { return; }
        if(animeteDice.nowAnimete()){return;}
        lockControlls(true);
        socket.emit('roll dice', dice_length);
    });
    $btns.dice_count_up.click(()=>{
        if (key_locked) { return; }
        if(animeteDice.nowAnimete()){return;}
        dice_length = dice_length < animeteDice.maxLength() ? dice_length + 1 : dice_length;
        $btns.roll_dice.text("賽 x " + dice_length);
    });
    $btns.dice_count_down.click(()=>{
        if (key_locked) { return; }
        if(animeteDice.nowAnimete()){return;}
        dice_length = dice_length > 1 ? dice_length - 1 : dice_length;
        $btns.roll_dice.text("賽 x " + dice_length);
    });

/*
    // Focus input when clicking anywhere on login page
    $loginPage.click(function () {
        if (key_locked) { return; }
        $currentInput.focus();
    });

    // Focus input when clicking on the message input's border
    $IPTS.chat_message.click(function () {
        if (key_locked) { return; }
        $IPTS.chat_message.focus();
    });
*/
    //Show dialog create room
    $btns.create_room.click(()=> {
        if (key_locked) { return; }
        setDisplayPositionForDialog($dlgCreateRoom);
        $dlgCreateRoom.dialog('open');
        lockControlls(true);
    });

    //room table update
    $btns.table_update.click(()=>{
        if (key_locked) { return; }
        socket.emit('room table data');
        lockControlls(true);
    });

    //Dialog create room
    $dlg_parts_cr.chk_password.click(()=>{
        $dlg_parts_cr.ipt_password.prop('disabled', !$dlg_parts_cr.chk_password.prop('checked'));
    });
    // Socket events-------------------------------------------------------------------------------------------------------------------------------------------
    /**
     * 何かしらのエラーで失敗メッセージが返る
     */
    socket.on('err msg',
    /**@param {{msg:string,title:string, rooms_data:Object}} data title,rooms_dataは無い場合もあります*/
    (data:IEmitDataErrMsg)=> {
        let title = "失敗！";
        if(data.title){
            title = data.title;
        }
        showDialogMessage(title, "<p>" + data.msg + "</p>", null, null);
        //loungePageが表示されていてかつ、データにrooms_dataが添付されていたらTableを更新する
        if(($loungePage as any).isVisible() && data.rooms_data){
            resetRoomTable(data.rooms_data);
        }
    });

    /**
    * 接続数（接続した時、一度だけ送られる。送られないことも？）
    */
    socket.on('totalUsersCount',
    /**@param {number} count */
    (count:number)=>{
        $('.lbl_totalUsersCount').text('接続数：大体' + count + '人');
    });

    /**
    * チャットのメッセージ
    */
    socket.on('new message',
    /**@param {{username:string,img_avater_index:number,message:string}} data */
    (data:IArgmentChatData)=> {
        addChatMessage(data);
    });

    /**
    * 画像の受信
    */
    socket.on('new image',
    /**@param {{username:string,img_avater_index:number,dataURL:any}} data */
    (data:IArgmentChatData)=> {
        dataurlctl.createImageElementWithDataURL(data.dataURL, (img:HTMLImageElement)=>{
            data.scale = imageDisplay.registImage(img, MAX_IMAGE_SIZE, MAX_IMAGE_SIZE);

            data.dataURL = null;
            delete data.dataURL;
            data.images = [img];  //追加
            addChatMessage(data);
        });
    });

    /**
     * loginして、login page から lounge pageへ移行します。
     */
    socket.on('login',
    /**@param {{username:string,rooms_data:any,common_data:any}} data */
    (data:IEmitDataLogin)=> {
        window.location.hash="login-now";           //ブラウザバック無効処理

        socket.username = data.username;            //socketに自分のusernameを登録
        mgr_daifugou_c.setMyUserData(data.username);//自身のユーザデータ入力
        connected = true;
        $currentInput = null;
        $loginPage.off('click');                    //.off():要素のイベントハンドラーを削除。 因みに追加は".on()"
        setCommonData(data.common_data);
        $loginPage.fadeOut('fast');
        resetRoomTable(data.rooms_data);
        $loungePage.fadeIn('fast', ()=>{
            lockControlls(false);
            //autoJoinRoom();//debug
        });
    });

    socket.on('daifugou', (data:any)=> {
        mgr_daifugou_c.socketEvent(data);
    });

    socket.on('reversi', (data:any)=> {
        mgr_reversi_c.socketEvent(data);
    });

    /**
     * 自分自身が部屋に入室し lounge page から chat page へ移動します。
     */
    socket.on('join room',
    /**@param {{numUsers:number,membersName: string[]}} data */
    (data:IEmitDataJoinRoom)=>{
        //ページ移動
        if($dlgCreateRoom.dialog( 'isOpen' )){//create room から来た場合、Room create dialogを閉じる
            $dlgCreateRoom.dialog( 'close' );
        };
        $loungePage.fadeOut('fast');
        $chatPage.fadeIn('fast', ()=>{
            lockControlls(false);
            //auto debug
            /*
            let playingDataObj: RS.PlayingDataObject = RS.createPlayingDataObject();
            let player:RS.PlayerObject = RS.createPlayerObject();
            player.name = "黒の助";
            player.img_avater_index = 0;
            player.img_avater = getImgAvater(player.img_avater_index);
            playingDataObj.players.push(player);
            player = RS.createPlayerObject();
            player.name = "白太の信"
            player.img_avater_index = 1;
            player.img_avater = getImgAvater(player.img_avater_index);
            playingDataObj.players.push(player);

            mgr_reversi_c.ui.setActive(true, playingDataObj);
            changePageRayout();
            */
            //auto debug
        });
        $currentInput = $IPTS.chat_message.focus();
        // Display the welcome message
        const message = "入室しました。";
        addLogMessage(message, {
            prepend: true,
        });
        addParticipantsMessage(data);
        addParticipantsNameMessage(data);
        //画面演出停止
        disp_effect.stopTimer();
    });

    /**
     * 部屋から退出して chat page から lounge page へ移動します
     */
    socket.on('leave room',
    /**@param {{rooms_data:any}} data */
    (data:{rooms_data:IDataRoom[]})=>{
        $currentInput = null;
        $chatPage.fadeOut('fast');
        resetRoomTable(data.rooms_data);
        $loungePage.fadeIn('fast');
        //チャットログの初期化（消去）
        $messages.empty();
        imageDisplay.clear();
        //初期化
        mgr_daifugou_c.clearGameData();
        mgr_reversi_c.clearGameData();
        //ロック解除
        lockControlls(false);
        //画面演出開始
        disp_effect.startTimer(100);
    });

    /**
     * 他のユーザーが入室した際にくるメッセージ
     */
    socket.on('user joined',
    /**@param {{username:string,numUsers:number}} data */
    (data:IEmitDataJoinRoom)=>{
        addLogMessage(data.username + ' が入室しました。');
        addParticipantsMessage(data);
    });

    /**
     * 同室の他ユーザーが退室した際にくるメッセージ
     */
    socket.on('user left',
    /**@param {{username:string,numUsers:number}} data */
    (data:IEmitDataJoinRoom)=>{
        addLogMessage(data.username + ' が退室しました。');
        addParticipantsMessage(data);
        removeChatTyping(data.username);
    });

    /**
     * 自分以外の同部屋のユーザーがタイピングをしてますよ。
     */
    socket.on('typing',
    /**@param {{username:string,img_avater_index:number}} data */
    (data:IArgmentChatData)=>{
        addChatTyping(data);
    });

    /**
     * 自分以外の同部屋のユーザーがタイピングを止めましたよ。
     */
    socket.on('stop typing',
    /**@param {{username:string}} data */
    (data:IArgmentChatData)=>{
        removeChatTyping(data.username);
    });

    /**
     * 部屋一覧の更新ボタンをクリックした時の返信
     */
    socket.on('room table data',
    /**@param {{rooms_data:any}} data */
    (data:{rooms_data:IDataRoom[]})=>{
        resetRoomTable(data.rooms_data);
        lockControlls(false);
    });

    /**
     * サイコロを振りましたよ
     */
    socket.on('roll dice',
    /**@param {{username:string, num_array:number[]}} data */
    (data   :IEmitDataRollDice)=>{
        const options = {
            results: data.num_array,
            callback: ()=>{
                let s = "サイコロ：";
                const num_array = data.num_array;
                const len = num_array.length;
                let val = 0;
                for(let i = 0; i < len; i++){
                    val = val + num_array[i];
                    s = i + 1 !== len ? s + num_array[i] + " + " : s + num_array[i];
                }
                s = s + " = " + val;
                addChatMessage({
                    username: data.username,
                    img_avater_index: data.img_avater_index,
                    message: s,
                });
                //animeが終了したら解除
                lockControlls(animeteDice.nowAnimete());
            },
        };
        lockControlls(true);
        animeteDice.roll(options);
    });

//---------------------------------------------------------------------------------------------------------------------------------------------------

});
