
"use strict";//kekeke
// Implementation ------------------------------------------------------------------------------------------------------------------------------------------------
//const-----------------------------------------------------------------------------------------------------------------------------------------------------------
export const ST_STANDBY            :number = 0;        //待機中...Game開始できますよ。
export const ST_RECRUIT            :number = 1;        //募集中
export const ST_PLAYING            :number = 2;        //プレイ中
export const ST_DISPLAY_RESULT     :number = 3;        //結果表示中
export const ST_EXCHANGE_CARDS     :number = 4;        //カード交換中(大貧民と貧民は強制的に強いカードを提出。富豪大富豪は好きなカードを提出。)
export const ST_GAME_OVER          :number = 5;        //終了中
export const ST_DONE               :number = 6;        //あがり
export const ST_DISCONNECT         :number = 7;        //落ちた
//request_id
export const REQ_ID_RECRUIT        :number = 1;        //参加者募集
export const REQ_ID_JOIN_GAME      :number = 2;        //参加者する Player or watcher
export const REQ_ID_DEAL_CARDS     :number = 3;        //カード（内容）を配る＝ゲーム開始、次ラウンド開始
export const REQ_ID_CARD_LENGTHS   :number = 4;        //player全員のカード枚数を全員に配る
export const REQ_ID_EXCHANGE_CARDS :number = 5;      //カード交換（clientからは大富豪、富豪からのみ。serverからは平民以外に送信）
export const REQ_ID_START_ROUND    :number = 6;        //ラウンド開始
export const REQ_ID_PUTDOWN_CARDS  :number = 7;       //カードを捨てる
export const REQ_ID_PASS_TURN      :number = 8;        //パスする
export const REQ_ID_END_ROUND      :number = 9;        //ラウンド終了
export const REQ_ID_RESULT_CLOSE   :number = 10;       //次ラウンド準備OK
export const REQ_ID_END_GAME       :number = 11;       //ゲーム終了
export const REQ_ID_CANCEL_GAME    :number = 12;       //何かしらの理由によりゲーム続行不可
export const REQ_ID_JOIN_IN_MIDWAY :number = 13;     //途中入室時
export const REQ_ID_LEAVE_IN_MIDWAY :number = 14;    //途中退出
//ranking
export const RANKING_DAI_HINMIN    :number = 0;
export const RANKING_HINMIN        :number = 1;
export const RANKING_HEIMIN        :number = 2;
export const RANKING_FUGOU         :number = 3;
export const RANKING_DAI_FUGOU     :number = 4;
//point
export const POINT_DAI_FUGOU       :number = 2;        //大富豪になった時のポイント
export const POINT_FUGOU           :number = 1;        //富豪になった時のポイント
export const POINT_HEIMIN          :number = 0;
export const POINT_HINMIN          :number = 0;
export const POINT_DAI_HINMIN      :number = 0;
export const RANKING_POINTS        :number[] = [POINT_DAI_HINMIN, POINT_HINMIN, POINT_HEIMIN, POINT_FUGOU, POINT_DAI_FUGOU];
//カード役(Hand Ranking)
export const HRK_GARBAGE           :number = 0;                //役無し
export const HRK_SINGLE            :number = 1;                 //シングル
export const HRK_DOUBLE            :number = 2;                 //ダブル
export const HRK_TRIPLE            :number = 3;                 //トリプル
export const HRK_FOURTH            :number = 4;                 //フォース（革命 consecutive number
export const HRK_CONS_3            :number = 5;                 //連続３
export const HRK_CONS_4            :number = 6;                 //連続４（革命 consecutive number
//テキスト
export const TXT_HRK               :string[] = [];
TXT_HRK[HRK_GARBAGE] = "役なし";
TXT_HRK[HRK_SINGLE] = "シングル";
TXT_HRK[HRK_DOUBLE] = "ダブル";
TXT_HRK[HRK_TRIPLE] = "トリプル";
TXT_HRK[HRK_FOURTH] = "フォース（革命）";
TXT_HRK[HRK_CONS_3] = "階段３";
TXT_HRK[HRK_CONS_4] = "階段４（革命）"
//Mark
export const MK_SPADE              :number = 0;                    //♤
export const MK_HEART              :number = 1;                    //♡
export const MK_CLUB               :number = 2;                    //♧
export const MK_DIAMOND            :number = 3;                    //♢
export const MK_JOKER              :number = 4;                    //ジョーカー
export const TXT_MK                :string[] = [];
TXT_MK[MK_SPADE] = "♠";
TXT_MK[MK_HEART] = "♥";
TXT_MK[MK_CLUB] = "♣";
TXT_MK[MK_DIAMOND] = "♦";
TXT_MK[MK_JOKER] = "JOKER";
//other
export const INDEX_SPADE_3         :number = 2;
export const INDEX_DIAMOND_3       :number = 41;
export const INDEX_JOKER           :number = 52;
export const TRUMP_LENGTH          :number = 53;
export const TRUMP_NUMBER_LENGTH   :number = 13;
export const TRUMP_MAX_VALUE       :number = TRUMP_NUMBER_LENGTH + 2;    //Jokerの値　非リバース時 数字の2(num == 14)が最大だからそれより1多い数
export const TRUMP_MIN_VALUE       :number = 1;                          //Jokerの値　リバース時 数字の3(num == 2)が最小だからそれより1少ない数
//EVENT
export const EV_NONE               :number = -1;   //何でもない、何もしないイベント
export const EV_KAKUMEI            :number = 0;    //革命！
export const EV_ELEVEN_BACK        :number = 1;    //イレブンバック！
export const EV_DISCARD_8          :number = 2;    //８切り！
export const EV_SYMBOL_LOCK        :number = 3;    //しばり！
export const EV_MIYAKO_OCHI        :number = 4;    //都落ち！
export const EV_CLEAN_TABLE        :number = 5;    //カード流し
export const EV_PLAYER_DONE        :number = 6;    //今のプレイヤーが上がった
export const EV_NEXT_TURN          :number = 7;    //次のプレイヤーの番
export const EV_END_ROUND          :number = 8;    //ラウンド終了
export const EV_END_GAME           :number = 9;    //ゲーム終了
export const EV_S3_WIN_JOKER       :number = 10;   //♠３はjokerに勝つ

//Waiting Event (msec)
export const WAIT_EV_KAKUMEI       :number = 2000;
export const WAIT_EV_ELEVEN_BACK   :number = 2000;
export const WAIT_EV_DISCARD_8     :number = 2000;
export const WAIT_EV_SYMBOL_LOCK   :number = 2000;
export const WAIT_EV_MIYAKO_OCHI   :number = 2000;
export const WAIT_EV_CLEAN_TABLE   :number = 2000;
export const WAIT_EV_PLAYER_DONE   :number = 2000;
export const WAIT_EV_NEXT_TURN     :number = 0;
export const WAIT_EV_END_ROUND     :number = 0;
export const WAIT_EV_END_GAME      :number = 0;
export const WAIT_EV_DEAL_CARDS    :number = 2000;
export const WAIT_EV_EXCHANGE_CARDS :number = 2000;
//Exchange
export const EXCHANGE_TOP_LENGTH   :number = 2;
export const EXCHANGE_SECOND_LENGTH :number = 1;
//settings sec
export const MAX_RECRUIT_TIME      :number = 60;
export const MIN_RECRUIT_TIME      :number = 15;
export const DEF_RECRUIT_TIME      :number = 30;//debug
export const PER_RECRUIT_TIME      :number = 15;

export const MAX_PLAYERS_COUNT     :number = 8;
export const MIN_PLAYERS_COUNT     :number = 4;
export const DEF_PLAYERS_COUNT     :number = 4;//debug
export const PER_PLAYERS_COUNT     :number = 1;

export const MAX_ROUND_COUNT       :number = 12;
export const MIN_ROUND_COUNT       :number = 1;
export const DEF_ROUND_COUNT       :number = 6;//debug
export const PER_ROUND_COUNT       :number = 1;

export const MAX_THINKING_TIME     :number = 60;
export const MIN_THINKING_TIME     :number = 10;
export const DEF_THINKING_TIME     :number = 30;//debug
export const PER_THINKING_TIME     :number = 5;

//Settings 2
export const ROUND_INTERVAL        :number = 20;//debug
export const GAME_OVER_INTERVAL    :number = 20; //秒
//Class-----------------------------------------------------------------------------------------------------------------------------------------------------
/**@description カードの内容を分析した情報を保持するオブジェクトです。
 * @typedef {{index:number,num:number,mark:number,selected:boolean}} CardObject
 */
export interface CardObject {
    index        :number,         //0~52のユニークな値
    num          :number,         //トランプの数字
    mark         :number,         //トランプのマーク
    selected     :boolean
    [key: string]: any;         //※プロパティをブラケット記法で取得するために必要
}
/**@description カードの内容を分析した情報を保持するオブジェクトです。
 * @typedef {{rank:number,exist_joker:boolean,cards:CardObject[]}} PutDownCardsContentObject
 */
export interface PutDownCardsContentObject{
    rank         :number,           //役
    exist_joker  :boolean,          //jokerある？
    cards        :CardObject[]      //CardObject
}
/** @description オプションルールをまとめたオブジェクトです。
 * @typedef {{symbol_lock:number,discard_8:number,must_always_win:number,eleven_back:number,s3_win_joker:number}} OptionRulesObject
 */
export interface OptionRulesObject{
    symbol_lock     :number,    //0~2
    discard_8       :number,    //0~1
    must_always_win :number,    //0~1
    eleven_back     :number,    //0~1
    s3_win_joker    :number     //0~1
}
/**@description 基本設定をまとめたオブジェクトです。
 * @typedef {{recruit_time:number,players_count:number,round_count:number,thinking_time:number}} SettingsObject
 */
export interface SettingsObject{
    recruit_time    :number,
    players_count   :number,
    round_count     :number,
    thinking_time   :number
}
/**@description プレイ中の情報をまとめたオブジェクトです。
 * @typedef {{now_round:number,now_turn:number,now_order_name:string,latest_putter_name:string,putdown_history:PutDownCardsContentObject[],rankers:string[],now_kakumei:boolean,now_symbol_lock:number,now_eleven_back:number}} RoundStatusObject
 */
//プレイ中の情報まとめ・clientと共用
export interface RoundStatusObject{
    now_round            :number,       //現在のラウンド
    now_turn             :number,       //現在のターン数
    now_order_name       :string,       //現在の番
    latest_putter_name   :string,       //最近のパスせず、カード出した人
    putdown_history      :PutDownCardsContentObject[],      //出したカードの履歴
    rankers              :string[],     //上がったPlayerObjectの"名前"を順番に保持。Play中に大富豪、富豪が落ちた時に繰り上げ順位するために必要

    now_kakumei          :boolean,      //現在革命中
    now_symbol_lock      :number,       //現在「しばり」発生中
    now_eleven_back      :number        //現在「イレブンバック」発生中
}
/**@description プレイヤー情報をまとめたオブジェクトです。
 * @typedef {{name:string,img_avater_index:number,img_avater:Image,ranking:number,status:number,score:number[],cards:CardObject[],card_length:number}} PlayerObject
 */
export interface PlayerObject{
    name                 :string,               //ユーザ名
    img_avater_index     :number,               //アバターのイメージインデックス
    img_avater           :HTMLImageElement,     //アバターのイメージ画像。client側でのみ使用する。
    ranking              :number,               //ランク（大富豪、富豪、平民、貧民、大貧民）
    status               :number,               //playing, done, disconnect
    score                :number[],             //各ラウンドのスコア保存用
    cards                :CardObject[],         //所持カード（自分の手札のみ持つ）
    card_length          :number,               //カード枚数
    [key: string]: any;                         //※プロパティをブラケット記法で取得するために必要
}
/**@description プレイ中の全データをまとめたオブジェクトです。
 * @typedef {{prog_status:number,settings:SettingsObject,option_rules:OptionRulesObject,round_status:RoundStatusObject,players:PlayerObject[]}} PlayingDataObject
 */
export interface PlayingDataObject{
    prog_status          :number,               //現在の状態
    settings             :SettingsObject,       //clientから取得
    option_rules         :OptionRulesObject,    //clientから取得
    round_status         :RoundStatusObject,
    players              :PlayerObject[]        //PlayerObject
}
/**@description 設定の、最大値、最小値、デフォルト値、入力値をまとめたオブジェクトです
 * @typedef {{max:SettingsObject,min:SettingsObject,def:SettingsObject,per:SettingsObject,val:SettingsObject}} SettingsSetObject
 */
export interface SettingsSetObject{
    max                  :SettingsObject,
    min                  :SettingsObject,
    def                  :SettingsObject,
    per                  :SettingsObject,
    val                  :SettingsObject
}
/**@description カード交換する際、一時的に内容を保持しておくオブジェクトです。Servar専用。
 * @typedef {{player:PlayerObject, card_indexs:number[]}} ExchangerObject
 */
export interface ExchangerObject{
    player       :PlayerObject,
    card_indexs  :number[]
}
/**
 * @description Server <- -> Clientでやり取りするデータ群
 */
export interface IArgmentSocketEvent{
    request_id          :number,
    playing_data?       :PlayingDataObject,
    player              :PlayerObject,
    start_msec?         :number,
    card_indexs?        :number[],
    lengths?            :number[],
    now_order_name?     :string,
    send_card_indexs?   :number[],
    receive_card_indexs?:number[],
    putdown_indexs?     :number[],
    leave_player_index? :number
}

//functions-------------------------------------------------------------------------------------------------------------------------------------------------------
export function createRoundStatusObject():RoundStatusObject{
    return {
                now_round            : 0,        //現在のラウンド
                now_turn             : 0,        //現在のターン数
                now_order_name       : "",       //現在の番
                latest_putter_name   : "",       //最近のパスせず、カード出した人
                putdown_history      : [],       //出したカードの履歴
                rankers              : [],       //上がったPlayerObjectの"名前"を順番に保持。Play中に大富豪、富豪が落ちた時に繰り上げ順位するために必要
                now_kakumei          : false,    //現在革命中
                now_symbol_lock      : 0,        //現在「しばり」発生中
                now_eleven_back      : 0         //現在「イレブンバック」発生中
            }
}
/**
 * SettingsObjectを作成します。
 */
export function createSettingsObject():SettingsObject{
    return {
                recruit_time    : DEF_RECRUIT_TIME,
                players_count   : DEF_PLAYERS_COUNT,
                round_count     : DEF_ROUND_COUNT,
                thinking_time   : DEF_THINKING_TIME
            };
}
/**
 * SettingsSetObjectを作成します。
 */
export function createSettingsSetObject():SettingsSetObject{
    let res :SettingsSetObject = {
                                    max                  : createSettingsObject(),
                                    min                  : createSettingsObject(),
                                    def                  : createSettingsObject(),
                                    per                  : createSettingsObject(),
                                    val                  : createSettingsObject()

                                }
    res.max.recruit_time = MAX_RECRUIT_TIME;
    res.max.players_count = MAX_PLAYERS_COUNT;
    res.max.round_count = MAX_ROUND_COUNT;
    res.max.thinking_time = MAX_THINKING_TIME;

    res.min.recruit_time = MIN_RECRUIT_TIME;
    res.min.players_count = MIN_PLAYERS_COUNT;
    res.min.round_count = MIN_ROUND_COUNT;
    res.min.thinking_time = MIN_THINKING_TIME;

    res.per.recruit_time = PER_RECRUIT_TIME;
    res.per.players_count = PER_PLAYERS_COUNT;
    res.per.round_count = PER_ROUND_COUNT;
    res.per.thinking_time = PER_THINKING_TIME;

    return res;
}
/**
 * OptionRulesObjectを作成します。
 */
export function createOptionRulesObject():OptionRulesObject{
    return {
                symbol_lock     : 0,
                discard_8       : 0,
                must_always_win : 0,
                eleven_back     : 0,
                s3_win_joker    : 0
            };
}
/**
 * PlayerObjectを作成します。
 */
export function createPlayerObject():PlayerObject{
    return {
                name                 : "",                  //ユーザ名
                img_avater_index     : 0,                   //アバターのイメージインデックス
                img_avater           : null,                //アバターのイメージ画像。client側でのみ使用する。
                ranking              : RANKING_HEIMIN,      //ランク（大富豪、富豪、平民、貧民、大貧民）
                status               : ST_STANDBY,          //playing, done, disconnect
                score                : [],                  //各ラウンドのスコア保存用
                cards                : null,                //所持カード（自分の手札のみ持つ）
                card_length          : 0                    //カード枚数
            };
}
/**
 * PlayingDataObjectを作成します。
 */
export function createPlayingDataObject():PlayingDataObject{
    return {
                prog_status          : ST_STANDBY,                      //現在の状態
                settings             : null,                            //clientから取得
                option_rules         : null,                            //clientから取得
                round_status         : createRoundStatusObject(),
                players              : []                               //PlayerObject
            };
}
/**
 * ExchangerObjectの配列を作成します。
 * ※RANKING_～の定数を使用するため、一つ余分に作成されます。
 * @return {ExchangerObject[]}
 */
export function createExchangerArray():ExchangerObject[]{
    let res :ExchangerObject[] = [],
        i   :number = 0,
        len :number = 5;        //大富豪、富豪、平民、貧民、大貧民 = 5
    for(i=0; i<len; i++){
        res.push({
                    player       : null,
                    card_indexs  : []
                 });
    }

    return res;
}
/**
 * ExchangerObjectの配列をクリアにします
 * @param {ExchangerObject[]} exchangers
 */
export function initExchangerArray(exchangers:ExchangerObject[]):void{
    if(!exchangers) return;
    let i   :number = 0,
        len :number = exchangers.length;
    for(i=0; i<len; i++){
        exchangers[i].player = null;
        exchangers[i].card_indexs.splice(0, exchangers[i].card_indexs.length);
    }
}
/**
 * PlayingDataObjectをクリアします。
 * player.img_avaterはclient側からのみの追加なので
 * 削除もclient側に任せます。
 * @param {PlayingDataObject} playing_data
 */
export function freePlayingData(playing_data: PlayingDataObject):void{
    if(!playing_data)return;
    playing_data.settings = null;
    playing_data.option_rules = null;
    playing_data.round_status = null;
    let players :PlayerObject[] = playing_data.players,
        cards   :CardObject[] = null,
        score   :number[] = null,
        i       :number = 0,
        len     :number = playing_data.players.length;
    for(i=0; i<len; i++){
        cards = players[i].cards;
        if(cards && cards.length > 0){cards.splice(0, cards.length);}
        score = players[i].score;
        if(score && score.length > 0){score.splice(0, score.length);}
    }
    playing_data.players = null;
    players = null;
    cards = null;
    score = null;
    playing_data = null;
}
/**
 * カード名を取得します
 * @param {number} card_id
 * @return {string}
 */
export function getCardName(card_id: number):string{
    let num :number = card_id % TRUMP_NUMBER_LENGTH,
        mk  :number = Math.floor(card_id / TRUMP_NUMBER_LENGTH),
        res :string = "";
    if(card_id === INDEX_JOKER){
        res = TXT_MK[MK_JOKER];
    }else{
        res = TXT_MK[mk] + (num + 1);
    }

    return res;
}
/**
 * カードのマークを取得します
 * @param {number} mark
 * @return {string}
 */
export function getCardMark(mark:number):string{
    return TXT_MK[mark];
}
/**
 * 役名を取得します
 * @param {number} rank
 * @return {string}
 */
export function getHandRankName(rank:number):string{
    return TXT_HRK[rank];
}
/**
 * カード（53枚）を作成します。
 * @return {CardObject[]}
 */
export function createCardAll():CardObject[]{
    let i           :number =0,
        card_case   :CardObject[] = new Array(TRUMP_LENGTH);
    for(i=0; i < TRUMP_LENGTH; i++){
        card_case[i] = createCard(i);
    }
    return card_case;
}
/**
 * indexで指定されたindexを持つCardObjectをcard_caseから取り出し返します。
 * @param {CardObject[]} card_case
 * @param {number} index
 * @return {CardObject}
 */
export function pickupFromCardCase(card_case:CardObject[], index:number):CardObject{
    let res :CardObject = card_case[index];
    card_case[index] = null;
    return res;
}
/**
 * card_indexsで指定されたindexを持つCardObjectをcard_caseから取り出し
 * cardsへ移動します。card_caseのlengthは変更されず、Nullに置換されます。
 * 戻り値は取得数になります。
 * @param {CardObject[]} card_case
 * @param {CardObject[]} cards
 * @param {number[]} card_indexs
 * @return {number}
 */
export function pickupFromCardCaseMultiple(card_case:CardObject[], cards:CardObject[], card_indexs:number[]):number{
    let i       :number =0,
        len     :number = card_indexs.length,
        index   :number = 0,
        res     :number = 0,
        c       :CardObject = null;
    for(i=0; i<len; i++){
        index = card_indexs[i];
        c = card_case[index];
        if(c){
            cards.push(c);
            res++;
        }
        card_case[index] = null;
    }

    return res;
}
/**
 * card_indexsで指定されたindexを持つCardObjectを取得します。
 * 現在の順番である、playing_data.players.cards != nullならそこから、
 * nullならcard_caseから取得を試みます。(clientからの呼び出しで、他プレイヤーのカードを操作する場合)
 * ※取得元のカード（の参照）は消去されます。
 * 取得したカードはcardsに保持されます。取得に成功した場合、trueを返します。
 * @param {PlayingDataObject} playing_data
 * @param {CardObject[]} card_case
 * @param {CardObject[]} cards
 * @param {number[]} card_indexs
 * @return {boolean}
 */
export function pickupCardsFromNowOrderPlayer(playing_data:PlayingDataObject, card_case:CardObject[], cards:CardObject[], card_indexs:number[]):boolean{
    let players     :PlayerObject[] = playing_data.players,
        username    :string = playing_data.round_status.now_order_name,
        index       :number = indexOfPlayers("name", username, players),
        res         :number = 0;
    if(index === -1 || ! players[index].cards){
        res = pickupFromCardCaseMultiple(card_case, cards, card_indexs);
    }else{
        res = moveCardsAtoB(players[index].cards, cards, card_indexs);
    }
    //card_lengthも減らす
    players[index].card_length = players[index].card_length - res;

    return res === card_indexs.length;
}
/**
 * 一枚のCardObjectをcard_caseに戻します。
 * jokerの場合、numとmarkは初期化されます。
 * @param {CardObject} card
 * @param {CardObject[]} card_case
 */
export function backToCardCase(card:CardObject, card_case:CardObject[]):void{
    if(card.index === INDEX_JOKER){
        setCardOfJoker(card);
    }
    card_case[card.index] = card;
}
/**
 * CardObjectをcard_caseに戻します。
 * CardObjectはidの位置に格納されます。
 * 配列cardsのlengthは0になります。
 * jokerのnumとmarkは初期化されます。
 * @param {CardObject[]} cards
 * @param {CardObject[]} card_case
 */
export function backToCardCaseMultiple(cards:CardObject[], card_case:CardObject[]):void{
    if(!cards) return;
    let i   :number =0,
        len :number = cards.length,
        c   :CardObject = null;
    for(i=0; i<len; i++){
        c = cards[i];
        if(c.index === INDEX_JOKER){
            setCardOfJoker(c);
        }
        card_case[c.index] = c;
    }
    cards.splice(0, cards.length);
}
/**
 * playerが持っているCardObject、Table上のCardObject
 * をcard_caseに戻します。この処理で全てのCardObjectが
 * card_caseに収納されます。成功した場合Trueを返します。
 * ※"縛り"等の状態も解除されるので、初期化以外では使用しないでください。
 * @param {PlayingDataObject} playing_data
 * @param {CardObject[]} card_case
 */
export function backToCardCaseAll(playing_data:PlayingDataObject, card_case:CardObject[]):boolean{
    let players :PlayerObject[] = null,
        i       :number = 0,
        len     :number = 0;
    if(playing_data){
        players = playing_data.players;
        len = players.length;
        //Table上のカード
        cleanTable(playing_data.round_status, card_case);
        //プレイヤーのカード
        for(i=0; i<len; i++){
            backToCardCaseMultiple(players[i].cards, card_case);
            players[i].card_length = 0;
        }
    }
    //check
    len = card_case.length;
    for(i=0; i<len;i++){
        if(card_case[i] == null){
            return false;
        }
    }
    return true;
}
/**
 * cards内のCardObjectを元の場所に戻します。
 * 現在の順番であるplaying_data.players.cardsが存在すればそこに、
 * なければcard_caseに戻します。cardsのlengthは0になります。
 * @param {CardObject[]} pickup_cards
 * @param {PlayingDataObject} playing_data
 * @param {CardObject[]} card_case
 */
export function undoCardsToNowOrderPlayer(pickup_cards:CardObject[], playing_data:PlayingDataObject, card_case:CardObject[]):void{
    let len         :number = pickup_cards.length,
        players     :PlayerObject[] = playing_data.players,
        username    :string = playing_data.round_status.now_order_name,
        index       :number = indexOfPlayers("name", username, players),
        j_index     :number = indexOfCards("index", INDEX_JOKER, pickup_cards);
    //Jokerがあれば、初期化しておく(ケースに戻す際に初期化されるけど、playerに返す時は初期化されないため)
    if(j_index !== -1){
        let c = pickup_cards[j_index];
        setCardOfJoker(c);
    }
    //player.cardsが存在するか？
    if(index === -1 || !players[index].cards){
        //ケースに戻す
        backToCardCaseMultiple(pickup_cards, card_case);
    }else{
        //playerに戻す
        moveCardsAtoB(pickup_cards, players[index].cards);
        //Numberでソート
        players[index].cards.sort(compareCardsByNumber);
    }
    //card_lengthも戻しておく
    players[index].card_length = players[index].card_length + len;
}
/**
 * card_indexsに含まれている数値(index)を持つCardObjectを
 * 配列aから配列bへ移動します。
 * 戻り値はbに移動した数を返します。
 * ※移動によりaとbのlengthが変更されます。
 * ※card_indexsを指定しない場合、aは全てbに移動します。
 * @param {CardObject[]} a
 * @param {CardObject[]} b
 * @param {number[]} card_indexs
 * @return {number}
 */
export function moveCardsAtoB(a:CardObject[], b:CardObject[], card_indexs:number[] = null):number{
    let i = 0,
        ii = 0,
        len = 0,
        lenlen = 0,
        val = 0,
        count = 0;

    if(card_indexs){
        len = card_indexs.length;
        for(i=0; i<len; i++){
            val = card_indexs[i];
            lenlen = a.length;
            for(ii=0; ii<lenlen; ii++){
                if(val === a[ii].index){
                    b.push(a[ii]);
                    a.splice(ii, 1);
                    count++;
                    break;
                }
            }
        }
    }else{
        count = a.length;
        for(i=0; i<count; i++){
            b.push(a[i]);
        }
        a.splice(0, count);
    }

    return count;
}
/**
 * CardObjectのindexをtargetに抽出します。
 * targetにあらかじめデータが入っている場合、そのデータは削除されます。
 * @param {CardObject[]} cards
 * @param {number[]} target
 */
export function extractCardIndex(cards:CardObject[], target:number[]):void{
    target.splice(0, target.length);
    let i = 0,
        len = cards.length;
    for(i=0; i<len; i++){
        target.push(cards[i].index);
    }
}
/**
 * 次のラウンドを開始する前に実行する必要がある関数です。
 * ※カードを配る(dealCards)前に必ず行う必要があります。
 * ※複数回実行しても、結果は変わりません。
 * @param {PlayingDataObject} playing_data
 * @param {CardObject[]} card_case
 */
export function initDataBeforeNextRound(playing_data:PlayingDataObject, card_case:CardObject[]):void{
    let r_s     :RoundStatusObject = playing_data.round_status,
        players :PlayerObject[] = playing_data.players,
        player  :PlayerObject = null,
        i       :number = 0,
        len     :number = players.length;
    //テーブル上を綺麗にする。
    cleanTable(r_s, card_case);
    //playerの設定(初期化)
    for(i=0;i<len;i++){
        player = players[i];
        player.status = ST_STANDBY;//ST_PLAYING;
        player.card_length = 0;
        if(player.cards && player.cards.length > 0){
            backToCardCaseMultiple(player.cards, card_case);
        }
    }
    //round_statusの設定
    //r_s.now_eleven_back = 0;          //cleanTableでやってる
    r_s.now_kakumei = false;            //革命リセット
    //r_s.now_order_name = "";          //dealする時勝手に設定されるから触らない
    //r_s.now_symbol_lock = 0;          //cleanTableでやってる
    r_s.now_turn = 1;                   //now_turnを１にする。
    r_s.latest_putter_name = "";
    //r_s.putdown_history.splice(0, r_s.putdown_history.length) //cleanTableでやってる
    initRankers(playing_data);
}
/**
 * 次のラウンドを設定します。
 * ※毎ラウンド1回のみ実行する必要があります。
 * playing_dataから交換可能かチェックします。
 * 可能な場合はプレイングデータとプレイヤーのステータスを変更します。
 * この関数は新たにカードが配られた後に実行されます。
 * またexchangersがある場合、内容をセットします(serverからのみ)
 * @param {PlayingDataObject} playing_data
 * @param {ExchangerObject[]} exchangers serverのみ
 */
export function setNextRound(playing_data:PlayingDataObject, exchangers?:ExchangerObject[]):void{
    let r_s     :RoundStatusObject = playing_data.round_status,
        players :PlayerObject[] = playing_data.players,
        player  :PlayerObject = null,
        i       :number = 0,
        len     :number = players.length,
        top_p   :PlayerObject = null,
        second_p:PlayerObject = null,
        booby_p :PlayerObject = null,
        last_p  :PlayerObject = null;;
    r_s.now_round = r_s.now_round + 1;  //now_roundを１進める。
    //とりあえずST_STANDBYに設定
    playing_data.prog_status = ST_STANDBY;
    //ランキングからプレイヤーチョイス
    for(i=0;i<len;i++){
        player = players[i];
        switch(player.ranking){
            case RANKING_DAI_FUGOU:
                top_p = player;
                break;
            case RANKING_FUGOU:
                second_p = player;
                break;
            case RANKING_HINMIN:
                booby_p = player;
                break;
            case RANKING_DAI_HINMIN:
                last_p = player;
                break;
        }
    }
    //大富豪と大貧民、富豪と貧民はセットで考える。
    //どちらか一方が不在の場合は交換は成立しない。
    let t_l :boolean = false,
        s_b :boolean = false;
    if(top_p && last_p) {//大富豪と大貧民
        t_l = true;
        playing_data.prog_status = ST_EXCHANGE_CARDS;
        top_p.status = ST_EXCHANGE_CARDS;
    }
    if(second_p && booby_p) {//富豪と貧民
        s_b = true;
        playing_data.prog_status = ST_EXCHANGE_CARDS;
        second_p.status = ST_EXCHANGE_CARDS;
    }
    //exchangersが無ければここで脱出
    if(!exchangers)return;
    //exchangersがある場合
    initExchangerArray(exchangers);
    exchangers[RANKING_DAI_FUGOU].player = top_p;
    exchangers[RANKING_FUGOU].player = second_p;
    exchangers[RANKING_HINMIN].player = booby_p;
    exchangers[RANKING_DAI_HINMIN].player = last_p;
    if(t_l){//交換が成立する場合 大貧民のカードから強いカードを2枚チョイス
        autoSelectExchangeCards(last_p, exchangers[RANKING_DAI_HINMIN].card_indexs);
    }else{
        exchangers[RANKING_DAI_FUGOU].player = null;
        exchangers[RANKING_DAI_HINMIN].player = null;
    }
    if(s_b){//交換が成立する場合 貧民のカードから強いカードを1枚チョイス
        autoSelectExchangeCards(booby_p, exchangers[RANKING_HINMIN].card_indexs);
    }else{
        exchangers[RANKING_FUGOU].player = null;
        exchangers[RANKING_HINMIN].player = null;
    }
}
/**
 * ラウンドスタート時に呼び出されます。
 * 状態の変更を行います。
 * @param {PlayingDataObject} playing_data
 */
export function setStartRound(playing_data:PlayingDataObject):void{
    let r_s     :RoundStatusObject = playing_data.round_status,
        players :PlayerObject[] = playing_data.players,
        i       :number = 0,
        len     :number = players.length;
    //状態変更
    playing_data.prog_status = ST_PLAYING;
    //playerの設定(初期化)
    for(i=0;i<len;i++){
        players[i].status = ST_PLAYING;
    }
}

/**
 * カードの交換を行います。
 * 交換が成功した場合、a_player.status, b_player.statusはST_STANDBYに変更されます。
 * a_cards==null or b_cards==nullの場合、card_caseから取得を試みます  (clientの場合はあり得る)
 * a_cards==null and b_cards==nullの場合、何もしません                (あり得ないけど)
 * @param {PlayerObject} a_player
 * @param {PlayerObject} b_player
 * @param {number[]} a_indexs
 * @param {number[]} b_indexs
 * @param {CardObject[]} card_case
 * @return {boolean}
 */
export function exchangeCards(a_player:PlayerObject, b_player:PlayerObject, a_indexs:number[], b_indexs:number[], card_case:CardObject[]):boolean{
    let a_cards     :CardObject[] = null,
        b_cards     :CardObject[] = null;
    if(a_player){
        a_cards = a_player.cards;
    }
    if(b_player){
        b_cards = b_player.cards;
    }
    if(!a_cards && !b_cards) return false;//両方いなければ何もしない。

    let temp_cards  :CardObject[] = [];
    //a -> b
    if(a_cards){//カードの取得
        moveCardsAtoB(a_cards, temp_cards, a_indexs);
    }else{
        pickupFromCardCaseMultiple(card_case, temp_cards, a_indexs);
    }
    if(b_cards){//bに渡す
        moveCardsAtoB(temp_cards, b_cards);
        sortCards(b_cards);
    }else{//カードケースに戻す
        backToCardCaseMultiple(temp_cards, card_case);
    }

    //b -> a
    if(b_cards){//カードの取得
        moveCardsAtoB(b_cards, temp_cards, b_indexs);
    }else{
        pickupFromCardCaseMultiple(card_case, temp_cards, b_indexs);
    }
    if(a_cards){//aに渡す
        moveCardsAtoB(temp_cards, a_cards);
        sortCards(a_cards);
    }else{//カードケースに戻す
        backToCardCaseMultiple(temp_cards, card_case);
    }

    if(a_player){
        a_player.status = ST_STANDBY;
    }
    if(b_player){
        b_player.status = ST_STANDBY;
    }

    return true;
}
/**
 * 各ランクにおける交換カードのidを自動でセレクトします。
 * cardsから指定されたカードのインデックスをcard_indexsに抽出します。
 * ※大富豪と富豪はカードの選択権がありますが、時間切れで呼び出されることがあります。
 * 大貧民 -> 強いカードｘ２
 * 貧民   -> 強いカードｘ１
 * 大富豪 -> 弱いカードｘ２
 * 富豪   -> 弱いカードｘ１
 * @param {PlayerObject} player
 * @param {number[]} card_indexs
 */
export function autoSelectExchangeCards(player:PlayerObject, card_indexs:number[]):void{
    let ranking         :number = player.ranking,
        cards           :CardObject[] = player.cards,
        select_length   :number = 1,
        i               :number = 0,
        card            :CardObject = null;
    card_indexs.splice(0, card_indexs.length);
    switch(ranking){
        case RANKING_DAI_HINMIN:
            card_indexs.push(cards[cards.length - 2].index);
        case RANKING_HINMIN:
            card_indexs.push(cards[cards.length - 1].index);
        break;
        case RANKING_DAI_FUGOU:
            card_indexs.push(cards[1].index);
        case RANKING_FUGOU:
            card_indexs.push(cards[0].index);
        break;
        default:
    }
}
/**
 * カードを場に出します。
 * この処理の前に、カードの"正当性チェック"と"切り札可能チェック"は完了しているとします。
 * 以下の状態により、戻り値が変化します。
 * Scenario 0：次のプレイヤーに順番が移ります。                                                   -> EV_NEXT_TURN
 * Scenario 1：出したプレイヤーは”あがり”、次の人に順番が移ります。                               -> EV_NEXT_TURN
 * Scenario 2：出したプレイヤーは”あがり”、ビリが決まります。ラウンド終了します。                 -> EV_END_ROUND
 * Scenario 3：出したプレイヤーは”あがり”、ビリが決まります。ラウンド終了でゲームも終了します。   -> EV_END_GAME
 * @param {PutDownCardsContentObject} card_content
 * @param {PlayingDataObject} playing_data
 * @param {Array.<CardObject>} card_case
 * @param {Array.<number>} event_list client、serverともに処理は呼び出し元でやった方がよい？
 * @return {number}
 */
export function putDownCards(card_content:PutDownCardsContentObject, playing_data:PlayingDataObject, card_case:CardObject[], event_list:number[]):number{
    let r_s             :RoundStatusObject = playing_data.round_status,
        o_r             :OptionRulesObject = playing_data.option_rules,
        players         :PlayerObject[] = playing_data.players,
        now_order_index :number = indexOfPlayers("name", r_s.now_order_name, players),
        p               :PlayerObject = players[now_order_index];
    //カード出し履歴を保存
    r_s.putdown_history.push(card_content);
    //最近カード出した人に登録
    r_s.latest_putter_name = p.name;
    //カード効果チェック(戻り値は　効果でカードが流れた時はtrueを返す)
    let flg_clean_table :boolean = doEffectByPutDownCards(playing_data, card_case, event_list);
    //あがり？
    if(p.card_length === 0){
        //前回の大富豪を取得
        let old_winner_index:number = indexOfPlayers("ranking", RANKING_DAI_FUGOU, players);//※setRankersでRankingがセットされてしまうので先に取っておく
        setRankers(p, playing_data);
        event_list.push(EV_PLAYER_DONE);
        //都落ちチェック
        if(o_r.must_always_win > 0){
            //前回の大富豪がまだ上がってないなら、最下位に設定
            if(old_winner_index !== -1){
                let old_p:PlayerObject = players[old_winner_index];
                if(old_p.status === ST_PLAYING && p.name !== old_p.name){
                    setRankersAsMiyakoOchi(old_p, playing_data);
                    event_list.push(EV_MIYAKO_OCHI);
                }
            }
        }
    }
    //ビリは決まった？
    let res         :number = 0,
        done_len    :number = getDoneLength(playing_data);
    if(players.length - done_len === 1){
        let last_one_index  :number = indexOfPlayers("status", ST_PLAYING, players);
        setRankers(players[last_one_index], playing_data);
        //次のラウンドの準備 or ゲーム終了
        if(r_s.now_round === playing_data.settings.round_count){
            event_list.push(EV_END_GAME);
            res = EV_END_GAME;
        }else{
            setDisplayResult(playing_data);
            event_list.push(EV_END_ROUND);
            res = EV_END_ROUND;
        }
    }else{
        //8切りでそのプレイヤーがまだ上がらない時は、またそのプレイヤーから始まる。
        //♠3流しでそのプレイヤーがまだ上がらないときは、そのプレイヤーから始まる。
        if(!(flg_clean_table && p.status === ST_PLAYING)){
            setNextPlayer(playing_data);
        }
        setNextTurn(r_s);
        event_list.push(EV_NEXT_TURN);
        res = EV_NEXT_TURN;
    }

    return res;
}

/**
 * Gameの途中で退室したプレイヤーの処理を行います。
 * playing_data.playersからの削除と
 * Server側Client側共通の処理
 * 1.ST_PLAYING中で
 * 2-1.このプレイヤーが抜けたことによってRoundもしくはGameが終了する、
 * 2-2.このプレイヤーが現在の手番の場合、次のプレイヤーに手番を回す処理
 * のみ行います。
 * ※プレイヤーが抜けても最低人数をクリアしていることを前提として処理します。
 * ※putDownCards()の後半コードと酷似してますが、違います。
 * @param {PlayingDataObject} playing_data
 * @param {number} leave_player_index
 * @param {CardObject[]} card_case
 * @param {Array.<number>} event_list
 * @return {number}
 */
export function leavePlayerInMidway(playing_data:PlayingDataObject, leave_player_index:number, card_case:CardObject[], event_list:number[]):number{
    let r_s         :RoundStatusObject = playing_data.round_status,
        o_r         :OptionRulesObject = playing_data.option_rules,
        players     :PlayerObject[] = playing_data.players,
        leave_player:PlayerObject = players[leave_player_index],
        res         :number = EV_NONE;
    //プレイヤーを抜く処理
    function removeLeavePlayer(now_playing: boolean){
        //rankersの変更
        removeRankersForLeavePlayer(leave_player, playing_data, now_playing);
        //playersから削除
        players.splice(leave_player_index, 1);
        if(leave_player.cards && leave_player.cards.length > 0){
            backToCardCaseMultiple(leave_player.cards, card_case);
        }
    }
    //条件：playing_data.prog_status != ST_PLAYING
    if(playing_data.prog_status !== ST_PLAYING) {
        //カード交換中で落ちたのが開始プレイヤーなら順番変更
        if(playing_data.prog_status === ST_EXCHANGE_CARDS && leave_player.name === r_s.now_order_name){
            //ここに来たってことは大貧民のプレイヤーが落ちたってことになる。
            //つまりカードが配られた後に落ちた場合は、貧民プレイヤーが開始プレイヤーになるのではなく、
            //落ちた大貧民プレイヤーの次のプレイヤーが開始プレイヤーになる。
            let next_order_index = (leave_player_index + 1) % players.length;
            r_s.now_order_name = players[next_order_index].name;
        }
        removeLeavePlayer(false);
        return res;
    }
    //条件：playing_data.prog_status == ST_PLAYING
    /*
     * player.statusがST_PLAYINGかST_DONEで場合分けする
     * ST_DONEの場合
     * ・rankersの変更が必要。
     * ・プレイヤーが落ちてもゲームの終了はあり得ない。
     * ・now_order_nameになってることはあり得ない。
     * ST_PLAYINGの場合
     * ・rankersにはまだ登録されていない。
     * ・プレイヤーが落ちることでゲームが終了するかもしれない。
     * ・now_order_nameかもしれない。
    */
    //条件：leave_player.name == raound_status.latest_putter_name
    if(leave_player.name === r_s.latest_putter_name){
        //抜けるプレイヤーが最近カードを出したプレイヤーなら無限ループ防止の為に
        //latest_putter_nameを次のプレイヤーにする。
        r_s.latest_putter_name = getNextPlayerName(r_s.latest_putter_name, players);
    }
    //条件：leave_player.status == ST_DONE
    if(leave_player.status === ST_DONE){
        removeLeavePlayer(true);
        return res;
    }
    //条件：leave_player.status == ST_PLAYING
    //抜けることによってラウンドもしくはゲームが終了するか？
    let done_len :number = getDoneLength(playing_data);
    if(players.length - 1 - done_len === 1){
        //最初に抜く
        removeLeavePlayer(true);
        let last_one_index: number = indexOfPlayers("status", ST_PLAYING, players);
        setRankers(players[last_one_index], playing_data);
        //次のラウンドの準備 or ゲーム終了
        if(r_s.now_round === playing_data.settings.round_count){
            event_list.push(EV_END_GAME);
            res = EV_END_GAME;
        }else{
            setDisplayResult(playing_data);
            event_list.push(EV_END_ROUND);
            res = EV_END_ROUND;
        }
    }else{
        //順番を変えてから抜く
        if(leave_player.name === r_s.now_order_name){
            setNextPlayer(playing_data);
            setNextTurn(r_s);
            event_list.push(EV_NEXT_TURN);
            res = EV_NEXT_TURN;
        }
        removeLeavePlayer(true);
    }
    return res;
}

/**
 * カードを出すことによる効果を処理します。
 * ここに来る前に最新のCardContentObjectは、playing_data.rount_status.putdown_historyに追加済であるとします。
 * 戻り値はBooleanで、８切り等でカードが流れた場合、trueを返します。
 * @param {PlayingDataObject} playing_data
 * @param {Array.<CardObject>} card_case
 * @param {Array.<number>} event_list
 * @return {boolean}
 */
export function doEffectByPutDownCards(playing_data:PlayingDataObject, card_case:CardObject[], event_list:number[]):boolean{
    let res             :boolean = false,
        o_r             :OptionRulesObject = playing_data.option_rules,
        r_s             :RoundStatusObject = playing_data.round_status,
        putdown_history :PutDownCardsContentObject[] = r_s.putdown_history,
        card_content    :PutDownCardsContentObject = r_s.putdown_history[r_s.putdown_history.length - 1],
        rank            :number = card_content.rank,
        card_num        :number = card_content.cards[0].num;
    //0番目のカードがジョーカーなら避ける
    if(card_content.cards[0].index === INDEX_JOKER && card_content.cards.length > 1){
        card_num = card_content.cards[1].num;
    }
    //革命？
    if(rank === HRK_FOURTH || rank === HRK_CONS_4){
        r_s.now_kakumei = !r_s.now_kakumei;
        event_list.push(EV_KAKUMEI);
    }
    //イレブンバック？
    if(o_r.eleven_back > 0){
        if(rank === HRK_SINGLE || rank === HRK_DOUBLE || rank === HRK_TRIPLE){
            if(card_num === 10){
                r_s.now_eleven_back = 1;
                event_list.push(EV_ELEVEN_BACK);
            }
        }
    }
    //八切り？
    if(o_r.discard_8 > 0){
        if(rank === HRK_SINGLE || rank === HRK_DOUBLE || rank === HRK_TRIPLE || rank === HRK_FOURTH){
            if(card_num === 7){
                event_list.push(EV_DISCARD_8);
                cleanTable(r_s, card_case);
                event_list.push(EV_CLEAN_TABLE);
                res = true;
            }
        }
    }
    //以下は過去カードが無ければ実行しない
    if(r_s.putdown_history.length <= 1) return res;
    //前回のカードを取得
    let old_content: PutDownCardsContentObject = r_s.putdown_history[r_s.putdown_history.length - 2];
    //しばり？
    if(o_r.symbol_lock > 0){
        if(checkSameSymbol(card_content, old_content)){
            r_s.now_symbol_lock++;
            if(r_s.now_symbol_lock === o_r.symbol_lock){
                event_list.push(EV_SYMBOL_LOCK);
            }
        }else{
            r_s.now_symbol_lock = 0;    //違ったときは0にリセット
        }
    }
    //役シングルでs3がjokerに勝ったよ -> 場を流す
    if(o_r.s3_win_joker > 0 && rank === HRK_SINGLE){
        if(old_content.cards[0].index === INDEX_JOKER && card_content.cards[0].index === INDEX_SPADE_3){
            event_list.push(EV_S3_WIN_JOKER);
            cleanTable(r_s, card_case);
            event_list.push(EV_CLEAN_TABLE);
            res = true;
        }
    }

    return res;
}
/**
 * Passで次の人に渡します。パス出来ない場合はfalseを返します。
 * @param {PlayingDataObject} playing_data
 * @param {CardObject[]} card_case cleanTableで必要
 * @param {Array.<number>} event_list
 * @return {boolean}
 */
export function passTurn(playing_data:PlayingDataObject, card_case:CardObject[], event_list:number[]):boolean{
    let r_s     :RoundStatusObject = playing_data.round_status,
        players :PlayerObject[] = playing_data.players;
    //場に一度もカードが出されてなければパス出来ない<-これは無くても良いかな？
    /*if(r_s.putdown_history.length == 0){
        return false;
    }*/
    /* パスした次のプレイヤー(p)をStatus(ST_DONE)にかかわらず選択し調べる。
       SCENARIO 0. p.status == ST_PLAYING && p.name == r_s.latest_putter_name -> 最後にカードを出した"p"まで一周した。カードを流して、選択"p"の順番へ
       SCENARIO 1. p.status == ST_PLAYING && p.name != r_s.latest_putter_name -> カードを流さず、選択"p"の順番へ
       SCENARIO 2. p.status == ST_DONE && p.name == r_s.latest_putter_name -> 上がった"p"まで一周した。カードを流して、次のプレイヤー(ST_PLAYING)が見つかるまで移動
       SCENARIO 3. p.status == ST_DONE && p.name != r_s.latest_putter_name -> 次のプレイヤーへ移動して、新たにこの工程をチェック
    */
    let i           :number = 0,
        len         :number = players.length,
        now_p_index :number = indexOfPlayers("name", r_s.now_order_name, players),//now_order_name==パスした人
        index       :number = 0,
        p           :PlayerObject = null;
    for(i=0; i<len; i++){
        index = (now_p_index + 1 + i) % len;
        p = players[index];
        if(p.status === ST_PLAYING){
            if(p.name === r_s.latest_putter_name){//最近だしたプレイヤーにまた順番が回ってきた
                cleanTable(r_s, card_case);
                event_list.push(EV_CLEAN_TABLE);
            }
            r_s.now_order_name = p.name;//setNextPlayer()の処理と同じ
            setNextTurn(r_s);
            event_list.push(EV_NEXT_TURN);
            return true;
        }else
        if(p.status === ST_DONE){//上がった"p"まで一周した。カードを流して、次のプレイヤー(ST_PLAYING)をセット
            if(p.name === r_s.latest_putter_name){
                cleanTable(r_s, card_case);
                event_list.push(EV_CLEAN_TABLE);
                setNextPlayer(playing_data);
                setNextTurn(r_s);
                event_list.push(EV_NEXT_TURN);
                return true;
            }else{
                continue;
            }
        }
    }

    return false;
}
/**
 * 指定プロパティから検索し、playersのインデックスを返します。
 * 複数いる場合、最初に合致したインデックスを返します。
 * 存在しない場合、-1を返します。
 * @param {string} prop_name                プロパティ名
 * @param {string|number} value             プロパティの値
 * @param {PlayerObject[]} players    プレイヤーオブジェクト配列
 * @return {number}
 */
export function indexOfPlayers(prop_name:string, value:string|number, players:PlayerObject[]):number{
    if(!players){
        return -1;
    }
    let i   :number =0,
        len :number = players.length;
    for(i=0; i<len; i++){
        if(players[i][prop_name] === value){
            return i;
        }
    }
    return -1;
}
/**
 * 指定プロパティから検索し、cardsのインデックスを返します。
 * 複数いる場合、最初に合致したインデックスを返します。
 * 存在しない場合、-1を返します。
 * @param {string} prop_name
 * @param {string|number} value
 * @param {CardObject[]} cards
 * @return {number}
 */
export function indexOfCards(prop_name:string, value:string|number, cards:CardObject[]):number{
    if(!cards){
        return -1;
    }
    let i   :number = 0,
        len :number = cards.length;
    for(i=0; i<len; i++){
        if(cards[i][prop_name] === value){
            return i;
        }
    }
    return -1;
}
/**
 * カードの内容（役）を分析します。
 * 分析結果はPutDownCardsContentObjectに格納され、返されます。
 * @param {CardObject[]} cards 分析対象のカード
 * @param {PlayingDataObject} playing_data
 * @return {PutDownCardsContentObject}
 */
export function analyzeRankOfCards(cards:CardObject[], playing_data:PlayingDataObject):PutDownCardsContentObject{
    //分析
    let i   :number = 0,
        len :number = cards.length,
        num :number = 0,
        mk  :number = 0,
        jc  :CardObject = null;
    //カードコンテンツの作成
    let res :PutDownCardsContentObject = {  rank         : 0,     //役
                                            exist_joker  : false,   //jokerある？
                                            cards        : []     //CardObject
                                         };
    res.cards = cards;
    //ジョーカーの有無をチェックしあれば抜いておく
    let index:number = indexOfCards("index", INDEX_JOKER, cards);
    if(index !== -1){
        jc = cards[index];
        cards.splice(index, 1);
        res.exist_joker = true;
    }
    //cardsを昇順に並び替え
    cards.sort(compareCardsByNumber);
    //革命中でなく、且つイレブンバック or 革命中、且つイレブンバックでない
    let r_s         :RoundStatusObject = playing_data.round_status;
    let flg_reverse :boolean = (!r_s.now_kakumei && (r_s.now_eleven_back > 0))||(r_s.now_kakumei && !(r_s.now_eleven_back > 0));
    //条件により分岐
    switch(len){
        case 1:
            //jokerがあるなら、数字に変換しておく
            if(res.exist_joker){
                if(flg_reverse){
                    num = TRUMP_MIN_VALUE;
                }else{
                    num = TRUMP_MAX_VALUE;
                }
                jc.num = num;
                res.cards.push(jc);
            }
            res.rank = HRK_SINGLE;
            break;
        case 2:
            if(checkSameNumber(res, jc)){
                res.rank = HRK_DOUBLE;
            }else{
                res.rank = HRK_GARBAGE;
            }
            break;
        case 3:
            if(checkSameNumber(res, jc)){
                res.rank = HRK_TRIPLE;
            }else
            if(checkConsecutiveNumber(res, jc, flg_reverse)){
                res.rank = HRK_CONS_3;
            }else{
                res.rank = HRK_GARBAGE;
            }
            break;
        case 4:
            if(checkSameNumber(res, jc)){
                res.rank = HRK_FOURTH;
            }else
            if(checkConsecutiveNumber(res, jc, flg_reverse)){
                res.rank = HRK_CONS_4;
            }else{
                res.rank = HRK_GARBAGE;
            }
            break;
        default:
            res.rank = HRK_GARBAGE;
    }
    //抜いたJokerを戻す（上記までの処理でHRK_GARBAGEでなければ、戻っている筈）
    if(res.exist_joker){
        index = indexOfCards("index", INDEX_JOKER, res.cards);
        if(index === -1){
            res.cards.push(jc);
        }
    }
    //最後にもいっちょソート
    res.cards.sort(compareCardsByNumber);

    return res;
}
/**
 * カードを切れるかチェックします。
 * 可能ならtrueを返します。
 * ※出し札の正当性チェックは終了済の状態で呼び出してください
 * @param {PutDownCardsContentObject} card_content
 * @param {PlayingDataObject} playing_data
 * @return {boolean}
 */
export function checkEnablePutDownCards(card_content:PutDownCardsContentObject, playing_data:PlayingDataObject):boolean{
    let o_r             :OptionRulesObject = playing_data.option_rules,
        r_s             :RoundStatusObject = playing_data.round_status,
        putdown_history :PutDownCardsContentObject[] = r_s.putdown_history,
        flg_reverse     :boolean = (!r_s.now_kakumei && (r_s.now_eleven_back > 0))||(r_s.now_kakumei && !(r_s.now_eleven_back > 0));
    //putdown_historyが無ければ、なんでも出せる
    if(putdown_history.length === 0){
        return true;
    }
    let old_content     :PutDownCardsContentObject = putdown_history[putdown_history.length - 1];
    //前の切り札と役は同じか？
    if(card_content.rank !== old_content.rank){
        return false;
    }
    //s3_win_joker == 1で、役がSingleで前札がJokerの場合、♠3なら問答無用で勝てる
    //s3_win_joker == 1で、役がSingleで前札が♠3の場合、Jokerなら問答無用で出せない
    if(o_r.s3_win_joker > 0 && old_content.rank === HRK_SINGLE){
        if(old_content.cards[0].index === INDEX_JOKER && card_content.cards[0].index === INDEX_SPADE_3){
            return true;
        }else
        if(old_content.cards[0].index === INDEX_SPADE_3 && card_content.cards[0].index === INDEX_JOKER){
            return false;
        }
    }
    //大小チェック(※JokerもanalyzeRankOfCards()で該当する数字numに変換済)
    //通常時、前札の最大Indexと現札の最小Indexの数値を調べる
    //反転時、前札の最小Indexと現札の最大Indexの数値を調べる
    if(flg_reverse){
        if(old_content.cards[0].num <= card_content.cards[card_content.cards.length - 1].num){
            return false;
        }
    }else{
        if(old_content.cards[old_content.cards.length - 1].num >= card_content.cards[0].num){
            return false;
        }
    }
    //シンボルロック中(now_symbol_lock)ならマークのチェックも忘れずに
    if(o_r.symbol_lock > 0 && o_r.symbol_lock <= r_s.now_symbol_lock){
        return checkEnablePutDownCardsBySymbolLock(card_content.cards, putdown_history);
    }
    //ここまでくれば、OK
    return true;
}
/**
 * symbol_lock中に対象のカードが切れるかどうかのチェックを行います。
 * ※now_cardsはSymboleLockを抜きにすれば、切ることが可能状態であることが確定しているとします。
 * ※ここではおけるかどうかのチェックだけです。Jokerの変更は一切しません。
 * @param {CardObject[]} now_cards
 * @param {PutDownCardsContentObject[]} putdown_history
 * @return {boolean}
 */
export function checkEnablePutDownCardsBySymbolLock(now_cards:CardObject[], putdown_history:PutDownCardsContentObject[]):boolean{
    //※シンボルロック中ということは、場に最低でも２つのPutDownCardContentがあるということ。
    //比較するold_cardsを含むold_contentを取得※old_cardsはJokerが含まれているのを避けて取得する
    let old_content :PutDownCardsContentObject = putdown_history[putdown_history.length - 1];
    if(old_content.exist_joker){
        old_content = putdown_history[putdown_history.length - 2];
    }
    let old_cards   :CardObject[] = old_content.cards;

    let i           :number = 0,
        ii          :number = 0,
        len         :number = now_cards.length,
        lenlen      :number = old_cards.length,
        c           :CardObject = null,
        match_count :number = 0;
    if(old_content.rank === HRK_SINGLE || old_content.rank === HRK_DOUBLE || old_content.rank === HRK_TRIPLE){
        for(i=0; i<len; i++){
            c = now_cards[i];
            for(ii=0; ii<lenlen; ii++){
                if(c.index === INDEX_JOKER || c.mark === old_cards[ii].mark){
                    match_count++;
                    break;
                }
            }
        }
        return len === match_count;
    }else
    if(old_content.rank === HRK_CONS_3 || old_content.rank === HRK_CONS_4){//3~4枚
        c = now_cards[0];
        if(c.index === INDEX_JOKER){
            c = now_cards[1];
        }
        return c.mark === old_cards[0].mark;
    }else{
        //HRK_FOURTH <- 出せるならtrue
        return true;
    }
}
/**
 * 前回出したカードと同じマーク（シンボルロック）かチェックします。
 * ※now_contentは、切ることが可能状態であるとして処理します。
 * シンボルロックになる場合は"true"を、ならない場合は"false"を返します。
 * @param {PutDownCardsContentObject} now_content
 * @param {PutDownCardsContentObject} old_content
 * @return {boolean}
 */
export function checkSameSymbol(now_content:PutDownCardsContentObject, old_content:PutDownCardsContentObject):boolean{
    let i           :number = 0,
        ii          :number = 0,
        len         :number = old_content.cards.length,
        jc          :CardObject = null,
        jc_mark_as  :number = -1,
        match_count :number = 0,
        flg_match   :boolean = false,
        a_cards     :CardObject[] = null, //jokerを含まないカード
        b_cards     :CardObject[] = null, //jokerを含む（かもしれない）カード
        a_c         :CardObject = null,
        b_c         :CardObject = null;
    if(old_content.rank === HRK_SINGLE || old_content.rank === HRK_DOUBLE || old_content.rank === HRK_TRIPLE || old_content.rank === HRK_FOURTH){
        a_cards = now_content.cards;
        b_cards = old_content.cards;
        if(old_content.exist_joker){
            jc = b_cards[indexOfCards("index", INDEX_JOKER, b_cards)];
            if(jc.mark !== MK_JOKER){//Jokerのmarkは既に決まっているかもしれない
                jc = null;
            }
        }else
        if(now_content.exist_joker){
            a_cards = old_content.cards;
            b_cards = now_content.cards;
            //今から出すカードだからJokerのmarkは未確定
            jc = b_cards[indexOfCards("index", INDEX_JOKER, b_cards)];
        }
        //
        for(i=0;i<len;i++){
            a_c = a_cards[i];
            flg_match = false;
            for(ii=0;ii<len;ii++){
                b_c = b_cards[ii];
                if(a_c.mark === b_c.mark){
                    match_count++;
                    flg_match = true;
                    break;
                }
            }
            if(flg_match===false && jc && jc_mark_as === -1){
                jc_mark_as = a_c.mark;
                match_count++;
            }
        }
        if(len === match_count){
            if(jc && jc_mark_as !== -1){//マッチした場合のみmark未確定jcがあればmark変更
                jc.mark = jc_mark_as;//jc.markを確定させる。
                b_cards.sort(compareCardsByNumber);
            }
            return true;
        }else{
            return false;
        }

    }else//階段
    if(old_content.rank === HRK_CONS_3 || old_content.rank === HRK_CONS_4){//3~4枚
        a_cards = now_content.cards;
        b_cards = old_content.cards;
        a_c = a_cards[0];
        if(a_c.index === INDEX_JOKER){
            a_c = a_cards[1];
        }
        b_c = b_cards[0];
        if(b_c.index === INDEX_JOKER){
           b_c = b_cards[1];
        }
        return a_c.mark === b_c.mark;
    }

    return false;
}
/**
 * @param {CardObject[]} cards
 */
export function sortCards(cards:CardObject[]):void{
    cards.sort(compareCardsByNumber);
}
//private functions------------------------------------------------------------------------------------------------------------------------------------------
/**
 * Jokerのnumとmkをセットします。
 * @param {CardObject} card
 */
function setCardOfJoker(card:CardObject):void{
    if(card.index === INDEX_JOKER){
        card.num = TRUMP_MAX_VALUE;//15
        card.mk = Math.floor(card.index / TRUMP_NUMBER_LENGTH);//4
    }
}
/**
 * CardObjectを作成します。
 * num:0,1 は 13,14 に変換されます。
 *
 * @param {number} index
 * @return {CardObject}
 */
function createCard(index:number):CardObject{
    //let res :CardObject = new CardObject();
    let res :CardObject = {
            index        :0,         //0~52のユニークな値
            num          :0,         //トランプの数字
            mark         :0,         //トランプのマーク
            selected     :false
        }
    res.index = index;
    if(res.index === INDEX_JOKER){
        setCardOfJoker(res);
    }else{
        res.num = index % TRUMP_NUMBER_LENGTH;
        if(res.num <= 1){//0 or 1
            res.num = res.num + TRUMP_NUMBER_LENGTH;
        }
        res.mark = Math.floor(index / TRUMP_NUMBER_LENGTH);
    }

    return res;
}
/**
 * 結果表示の状態にします
 * @param {PlayingDataObject} playing_data
 */
function setDisplayResult(playing_data:PlayingDataObject):void{
    playing_data.prog_status = ST_DISPLAY_RESULT;
    //player全員の状態をST_DISPLAY_RESULT状態にする。
    let players :PlayerObject[] = playing_data.players,
        i       :number = 0,
        len     :number = players.length;
    for(i=0; i<len; i++){
        players[i].status = ST_DISPLAY_RESULT;
    }
}
/**
 * 次のプレイヤーに順番を移します。※ST_DONEのplayerは飛ばします。
 * 行うのはnow_order_nameの更新のみです。成功した場合、trueを返します。
 * @param {PlayingDataObject} playing_data
 * @return {boolean}
 */
function setNextPlayer(playing_data: PlayingDataObject):boolean{
    let r_s = playing_data.round_status,
        players = playing_data.players,
        next_p_name = getNextPlayerName(r_s.now_order_name, players);
    if(next_p_name !== ""){
        r_s.now_order_name = next_p_name;
        return true;
    }
    return false;
}
/**
 * 引数"now_player_name"の次の番のプレイヤーの名前を返します。
 * @param {string} now_player_name
 * @param {PlayerObject[]} players
 * @return {string}
 */
function getNextPlayerName(now_player_name:string, players:PlayerObject[]):string{
    let now_p_index :number = indexOfPlayers("name", now_player_name, players),
        i           :number = 0,
        index       :number = 0,
        len         :number = players.length;
    for(i=0; i<len; i++){
        index = (i + 1 + now_p_index) % len;
        if(players[index].status === ST_PLAYING){
            return players[index].name;
        }
    }
    return "";
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * ※※※ランキングの追加と変更は、prog_status == ST_PLAYINGの時のみ整合性をとります。※※※
 */
/**
 * プレイヤーをランキングを取得します。
 * @param {number} index
 * @param {number} rankers_length
 */
function getRanking(index:number, rankers_length:number):number{
    let res = RANKING_HEIMIN;
    if(index === 0){                    //大富豪
        res = RANKING_DAI_FUGOU;
    }else
    if(index === 1){                    //富豪
        res = RANKING_FUGOU
    }else
    if(rankers_length - index === 2){   //貧民（最後から2番目
        res = RANKING_HINMIN;
    }else
    if(rankers_length - index === 1){   //大貧民（最後から1番目
        res = RANKING_DAI_HINMIN;
    }
    return res;
}
/**
 * 上がったプレイヤー名をround_status.rankersに追加(もしくは修正)します。
 * ※player.ranking, player.status, player.scoreも設定されます。
 * @param {PlayerObject} player
 * @param {PlayingDataObject} playing_data
 */
function setRankers(player:PlayerObject, playing_data:PlayingDataObject):void{
    let now_round       :number = playing_data.round_status.now_round,
        rankers         :string[] = playing_data.round_status.rankers,
        rankers_length  :number = rankers.length,
        index           :number = rankers.indexOf(player.name);
    //index == -1 -> 新規登録
    if(index === -1){
        index = rankers.indexOf('');
    }
    //ranking
    let ranking         :number = getRanking(index, rankers_length),
        score           :number = RANKING_POINTS[ranking];
    player.ranking = ranking;
    //score
    if(player.score.length < now_round){
        player.score.push(score);               //新規登録
    }else{
        player.score[now_round - 1] = score;    //修正
    }

    player.status = ST_DONE;    //statusの変更
    rankers[index] = player.name;
}
/**
 * "都落ち"したプレイヤーをround_status.rankersに追加します。
 * ※rankingの設定も行われます。
 * ※player.statusもST_DONEに変更されます。
 * ※scoreも設定されます。
 * @param {PlayerObject} player
 * @param {PlayingDataObject} playing_data
 */
function setRankersAsMiyakoOchi(player:PlayerObject, playing_data:PlayingDataObject):void{
    let rankers :string[] = playing_data.round_status.rankers,
        index   :number = rankers.length - 1,
        ranking :number = RANKING_DAI_HINMIN,
        score   :number = RANKING_POINTS[ranking];
    player.ranking = ranking;
    player.score.push(score);
    player.status = ST_DONE;            //statusの変更
    rankers[index] = player.name;
}
/**
 * round_status.rankersから取り除きます。
 * flg_change_ranking がtrueの場合、rankingの再評価を行います。
 * ※Playerがプレイの途中で落ちた際に呼び出されます。
 * ※rankingの再評価が行われるのはST_PLAYING中のみとします。
 * ※順位の繰上りが起こる場合は、各プレイヤーのrankingを変更します。
 * ※都落ちしたプレイヤーが落ちた場合は、都落ちは解除されます。
 * ※初期化の場合はinitRankers()を呼び出してください。
 * @param {PlayerObject} player
 * @param {PlayingDataObject} playing_data
 * @param {boolean} flg_change_ranking
 */
function removeRankersForLeavePlayer(player:PlayerObject, playing_data:PlayingDataObject, flg_change_ranking:boolean):void{
    //まだ上がってなければ、rankersの空文字部分を探し出して、削るだけでOK。
    //あがったプレイヤーだった場合は下のランカーのランキングが変わる場合がある
    //富豪→大富豪、平民→富豪
    //都落ちしたプレイヤーが落ちた場合は、他のプレイヤーが大貧民になる可能性がでてくる。
    let rankers         :string[] = playing_data.round_status.rankers,
        rankers_length  :number = rankers.length,
        index           :number = rankers.indexOf(player.name);
    //index == -1 -> 未登録
    if(index === -1){
        index = rankers.indexOf('');
    }
    rankers.splice(index, 1);
    //以下の実行はprog_status がST_PLAYING中のみ、交換中や結果が出た後のrankingの再評価は行わない。
    if(!flg_change_ranking) return;
    //各ランカーのランキング再評価
    let players :PlayerObject[] = playing_data.players,
        i       :number = 0,
        len     :number = rankers.length,
        p_name  :string = "",
        p_index :number = 0,
        p       :PlayerObject = null;
    for(i=0; i < len; i++){
        p_name = rankers[i];
        if(p_name === '') continue;
        p_index = indexOfPlayers('name', p_name, players);
        p = players[p_index];
        setRankers(p, playing_data);
    }
}
/**
 * 上がっているプレイヤーの人数を返します。
 * @param {PlayingDataObject} playing_data
 */
function getDoneLength(playing_data:PlayingDataObject):number{
    if(!playing_data) return 0;
    let rankers :string[] = playing_data.round_status.rankers,
        res     :number = 0,
        i       :number = 0,
        len     :number = rankers.length;
    for(i=0;i<len;i++){
        if(rankers[i] !== ''){
            res++;
        }
    }
    return res;
}
/**
 * rankersを初期化します。
 * 参加プレイヤーの分だけrankersに空の文字列を追加します。
 * @param {PlayingDataObject} playing_data
 */
function initRankers(playing_data: PlayingDataObject){
    if(!playing_data) return;
    let players :PlayerObject[] = playing_data.players,
        rankers :string[] = playing_data.round_status.rankers;
    if(rankers.length > 0){
        rankers.splice(0, rankers.length);
    }
    let i       :number = 0,
        len     :number = players.length;
    for(i=0;i<len;i++){
        rankers.push('');
    }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * 場（カード）を流します。特殊状態も解除されます。
 * 流されたCardObjectはcard_caseに保持されます。
 * @param {RoundStatusObject} round_status
 * @param {CardObject[]} card_case
 */
function cleanTable(round_status:RoundStatusObject, card_case:CardObject[]):void{
    //カード経歴をクリア
    let putdown_history :PutDownCardsContentObject[] = round_status.putdown_history,
        i               :number = 0,
        len             :number = putdown_history.length,
        card_content    :PutDownCardsContentObject = null;
    //CardObjectの確保
    for(i=0; i<len; i++){
        card_content = putdown_history[i];
        backToCardCaseMultiple(card_content.cards, card_case);
        card_content.cards = null;//gc対策
    }
    putdown_history.splice(0, len);
    //現在のルール状態もリセット
    round_status.now_symbol_lock = 0;   //現在「しばり」発生中
    round_status.now_eleven_back = 0;   //現在「イレブンバック」発生中
}
/**
 * 次のターンを設定します。
 * @param {RoundStatusObject} round_status
 */
function setNextTurn(round_status:RoundStatusObject):void{
    //turn更新
    round_status.now_turn++;
}
/**
 * 同数字のカードかチェックします。
 * ※引数cardsは昇順に並んでいるとして計算します。
 * ※jcが存在していれば、card_content.cardsに戻されます。
 * @param {PutDownCardsContentObject} card_content
 * @param {CardObject} jc Card of Joker
 * @return {boolean}
 */
function checkSameNumber(card_content:PutDownCardsContentObject, jc:CardObject):boolean{
    //※jokerがあっても含まれていない状態です
    let cards   :CardObject[] = card_content.cards,
        i       :number = 0,
        len     :number = cards.length,
        num     :number = cards[0].num;
    for(i=1; i<len; i++){
        if(num !== cards[i].num){//数字が違えばダメ
            return false;
        }
    }
    //数字がすべて等しければ、jokerがあるなら同数字に変換して保持させる
    if(card_content.exist_joker){
        jc.num = num;
        jc.mark = MK_JOKER; //まだjokerのマークは未確定(しばり次第で変更される)
        cards.push(jc);
    }

    return true;
}
/**
 * 階段になっているかチェックします。
 * ※引数cardsは昇順に並んでいるとして計算します。
 * ※jcが存在していれば、card_content.cardsに戻されます。
 * @param {PutDownCardsContentObject} card_content
 * @param {CardObject} jc Card of Joker
 * @param {boolean} flg_reverse カード反転中か？
 * @return {boolean}
 */
function checkConsecutiveNumber(card_content:PutDownCardsContentObject, jc:CardObject, flg_reverse:boolean):boolean{
    //※jokerがあっても含まれていない状態です
    //同マークチェック
    let cards   :CardObject[] = card_content.cards,
        i       :number = 0,
        len     :number = cards.length,
        mark    :number = cards[0].mark;
    for(i=1; i<len; i++){
        if(mark !== cards[i].mark){
            return false;
        }
    }
    //Jokerがあり、Jokerが何に使われているかチェックし、設定する。
    if(card_content.exist_joker){
        let joker_as:number = -1;
        jc.mark = mark;
        //中間補完か？
        for(i = 0; i < len - 1; i++){
            if(cards[i].num + 1 !== cards[i + 1].num){
                joker_as = cards[i].num + 1;
                jc.num = joker_as;
                cards.splice(i+1, 0, jc);
                break;
            }
        }
        //joker_as == -1なら、まだjokerは確定していない -> 並び端として使ったということ
        if(joker_as === -1){
            len = cards.length;
            if(flg_reverse){//革命orイレブンバック中
                if(cards[0].num === 2){
                    joker_as = cards[len - 1].num + 1;
                    jc.num = joker_as;
                    cards.push(jc);
                }else{
                    joker_as = cards[0].num - 1;
                    jc.num = joker_as;
                    cards.splice(0, 0, jc);
                }
            }else{//通常時
                if(cards[len - 1].num === 14){
                    joker_as = cards[0].num - 1;
                    jc.num = joker_as;
                    cards.splice(0, 0, jc);
                }else{
                    joker_as = cards[len - 1].num + 1;
                    jc.num = joker_as;
                    cards.push(jc);
                }
            }
        }
    }
    //やっとチェックできる
    len = cards.length;
    for(i = 0; i < len - 1; i++){
        if(cards[i].num + 1 !== cards[i + 1].num){
            return false;
        }
    }

    return true;
}

/**
 * sort用関数 数字でソート。
 * ※同数字の場合はmarkでソートします。
 * @param {CardObject} a
 * @param {CardObject} b
 * @return {number}
 */
function compareCardsByNumber(a:CardObject, b:CardObject):number {
    let res = a.num - b.num;
    if(res === 0){
        return a.mark - b.mark;
    }
    return res;
}
/**
 * sort用関数 マークでソート。
 * @param {CardObject} a
 * @param {CardObject} b
 * @return {number}
 */
function compareCardsByMark(a:CardObject, b:CardObject):number {
    return a.mark - b.mark;
}
/**
 * sort用関数 idでソート。
 * @param {CardObject} a
 * @param {CardObject} b
 * @return {number}
 */
function compareCardsByIndex(a:CardObject, b:CardObject):number {
    return a.index - b.index;
}
