/**
 * {x:number, y:number}
 */
export interface KRPoint{
    x:number;
    y:number;
}
/**
 * {x:number, y:number, radius:number}
 */
export interface KRCircle{
    x:number;
    y:number;
    radius:number;
}
/**
 * {cx:number, cy:number, width:number, height:number}
 */
export interface KRBox{
    cx:number;
    cy:number;
    width:number;
    height:number;
}
/**
 * {ax:number, ay:number, bx:number, by:number}
 */
export interface KRSegment{
    ax:number;
    ay:number;
    bx:number;
    by:number;
}

/**
 * childCtorにparentCtorを継承させます。
 * Usage:
 * <pre>
 * function ParentClass(a, b) { }
 * ParentClass.prototype.foo = function(a) { };
 *
 * function ChildClass(a, b, c) {
 *   ChildClass.base(this, 'constructor', a, b);
 * }
 * goog.inherits(ChildClass, ParentClass);
 *
 * var child = new ChildClass('a', 'b', 'see');
 * child.foo(); // This works.
 * </pre>
 * @param {!Function} childCtor Child class.
 * @param {!Function} parentCtor Parent class.
 */
export function inherits(childCtor:any, parentCtor:any):any{
    /** @constructor */
    function tempCtor() {}
    tempCtor.prototype = parentCtor.prototype;
    childCtor.superClass_ = parentCtor.prototype;
    childCtor.prototype = new tempCtor();
    /** @override */
    childCtor.prototype.constructor = childCtor;
    /**
     * Calls superclass constructor/method.
     *
     * This function is only available if you use goog.inherits to
     * express inheritance relationships between classes.
     *
     * NOTE: This is a replacement for goog.base and for superClass_
     * property defined in childCtor.
     *
     * @param {!Object} me Should always be "this".
     * @param {string} methodName The method name to call. Calling
     *     superclass constructor can be done with the special string
     *     'constructor'.
     * @param {...*} var_args The arguments to pass to superclass
     *     method/constructor.
     * @return {*} The return value of the superclass method/constructor.
     */
    childCtor.base = function(me:any, methodName:any, var_args:any) {
        // Copying using loop to avoid deop due to passing arguments object to
        // function. This is faster in many JS engines as of late 2014.
        var args = new Array(arguments.length - 2);
        for (var i = 2; i < arguments.length; i++) {
            args[i - 2] = arguments[i];
        }
        return parentCtor.prototype[methodName].apply(me, args);
    };
};

export namespace Element {
    export function getKRPoint(x:number, y:number):KRPoint{
        let res:KRPoint = {
            x,
            y
        };
        return res;
    }
    export function getKRCircle(x:number, y:number, radius:number):KRCircle{
        let res: KRCircle = {
            x,
            y,
            radius
        };
        return res;
    }
    export function getKRBox(cx:number, cy:number, width:number, height:number):KRBox{
        let res: KRBox = {
            cx,
            cy,
            width,
            height
        }
        return res;
    }
    export function getKRSegment(ax:number, ay:number, bx:number, by:number):KRSegment{
        let res: KRSegment = {
            ax,
            ay,
            bx,
            by
        }
        return res;
    }
};

export namespace Geometry{
    /**
     * ラジアン->度
     */
    export function radToDeg(rad: number):number{
        return rad * 180 / Math.PI;
    }
    /**
     * 度->ラジアン
     */
    export function degToRad(deg: number):number{
        return deg * Math.PI / 180;
    }
    /**
     * 3点(a-c-b)のなす角度をラジアンで返す。(+-はa,bの位置により変わる)
     * 値はc-aから時計回り(+)もしくは反時計回り(-)の角度となる。
     * [c-aを起点としてc-b]と[c-bを起点としてc-a]では符号が逆になります。
     */
    export function getRadianFrom3Point(c:KRPoint, a:KRPoint, b:KRPoint):number{
        //c-aベクトル
        let cax     :number = a.x - c.x;
        let cay     :number = a.y - c.y;
        let caRad   :number = Math.atan2(cay, cax);
        //c-bベクトル
        let cbx     :number = b.x - c.x;
        let cby     :number = b.y - c.y;
        let cbRad   :number = Math.atan2(cby, cbx);
        //答え
        let ans     :number = cbRad - caRad;
        let pi      :number	= Math.PI;
        //degree = rad * 180 / Math.PI
        //rad = degree * Math.PI / 180;
        //-π~πの間に収める（これ結構重要！)
        while (ans < -pi) {
            ans = ans + 2 * pi;
        }
        while (ans > pi) {
            ans = ans - 2 * pi;
        }

        return ans;
    }
    /**
     * X軸＋方向(1,0)を0度として、2点間のなす角度をラジアンで返します。
     */
    export function getRadianFrom2Point(s:KRPoint, e:KRPoint):number {
        return Math.atan2(e.y - s.y, e.x - s.x);
    }
    /**
     * 2点間の長さを返します。
     */
    export function getLength2D(p0:KRPoint, p1:KRPoint):number{
        let xx:number = p0.x - p1.x;
        let yy:number = p0.y - p1.y;
        return Math.sqrt(xx * xx + yy * yy);
    }
    /**
     * 2点間の長さを返します その２
     */
    export function getLength2DFrom4Number(ax:number, ay:number, bx:number, by:number):number{
        let xx:number = bx - ax;
        let yy:number = by - ay;

        return Math.sqrt(xx * xx + yy * yy);
    }
    /**
     * targetをscale, radian, moveから座標変換します。
     */
    export function transformGrid2D(target:KRPoint, scale:KRPoint, radian:number, move:KRPoint):void{
        let sin :number = Math.sin(radian);
        let cos :number = Math.cos(radian);
        let x0  :number = target.x;
        let y0  :number = target.y;
        let x1  :number = 0;
        let y1  :number = 0;
        //拡大縮小
        x0 = x0 * scale.x;
        y0 = y0 * scale.y;
        //回転
        x1 = x0 * cos - y0 * sin;
        y1 = x0 * sin + y0 * cos;
        //移動
        target.x = x1 + move.x;
        target.y = y1 + move.y;
    }
    /**
     * KRSegmentから正規化ベクトルを算出します。
     */
    export function nomalizeVector(s:KRSegment, ans:KRPoint):void {
        let xx:number = s.bx - s.ax;
        let yy:number = s.by - s.ay;
        if (xx !== 0 || yy !== 0) {
            let ll:number = Math.sqrt(xx * xx + yy * yy);
            ans.x = xx / ll;
            ans.y = yy / ll;
        }else {
            ans.x = 0;
            ans.y = 0;
        }
    }
    /**
     * 正規化されたベクトルから法線ベクトル(時計回り）を算出します。
     */
    export function nomalizeUnitVector(normalize_vector:KRPoint, ans:KRPoint):void {
        ans.x = -normalize_vector.y;
        ans.y = normalize_vector.x;
    }

};

export namespace Css{
    /**
     * CSSのTransformの入力を支援する関数です。
     * 複数の操作をしたい場合は以下のように設定します。
     * value= "translate(100px,200px) rotate(10deg) scale(2,3)"
     * この場合、移動→回転→拡縮の順で実行されます。
     */
    export function styleDeclarationSetTransform(style:CSSStyleDeclaration, value:string):boolean{
        /*
        let list:string[] = [
            "transform",
            "webkitTransform",
            "MozTransform",
            "msTransform",
            "OTransform"
        ];
        let i       :number;
        let num     :number = list.length;
        let propname:string;
        for(i=0;i < num; i++){
            propname = list[i];
            if(style.getPropertyValue(propname) !== ""){
                style.setProperty(propname, value);
                return true;
            }
        }
        return false;
        */
        let list = [
            "transform",
            "webkitTransform",
            "MozTransform",
            "msTransform",
            "OTransform"
        ];
        let i   :number;
        let num :number = list.length;
        for(i=0;i < num;i++){
            //Object/Enumのkeyに変数でアクセスしたら怒られる
            if( (style as any)[list[i]] !== undefined){
                (style as any)[list[i]] = value;
                return true;
            }
        }
        return false;
    }
};

export namespace Collision {
    //汎用ポイント
    let p0:KRPoint = {x:0, y:0};
    let p1:KRPoint = {x:0, y:0};
    let p2:KRPoint = {x:0, y:0};
    /**
     * 線分と円のあたり判定(内積だけ使った方法
     */
    export function crossToSegmentAndCircle(s:KRSegment, c:KRCircle):boolean{
        if (c.radius <= 0) return false;
        //p0 = s.a -> s.b のベクトル
        p0.x = s.bx - s.ax; 	p0.y = s.by - s.ay;
        //p1 = s.a -> c のベクトル
        p1.x = c.x - s.ax;	p1.y = c.y - s.ay;
        let vc		:number = p0.x * p1.x + p0.y * p1.y;	//内積
        let crPow2	:number = c.radius * c.radius;			//円の二乗
        let p0Pow2	:number = 0;
        let p1Pow2	:number = 0;
        if (vc < 0) {//内積がマイナス＝鈍角の場合
            return (p1.x * p1.x + p1.y * p1.y <= crPow2);
        }else {
            p0Pow2 = p0.x * p0.x + p0.y * p0.y;
            if (vc >= p0Pow2) {
                //p2 = s.b -> c のベクトル
                p2.x = s.bx - c.x; p2.y = s.by - c.y;
                return (p2.x * p2.x + p2.y * p2.y <= crPow2);
            }else {
                p1Pow2 = p1.x * p1.x + p1.y * p1.y;
                return ( p1Pow2 - vc * vc / p0Pow2 <= crPow2);
            }
        }
    }
    /**
     * 線分と円のあたり判定(外積と内積を使った方法
     */
    export function crossToSegmentAndCircle2(s:KRSegment, c:KRCircle):boolean{
        if (c.radius <= 0) return false;
        //p0 = s.a -> s.b のベクトル
        p0.x = s.bx - s.ax; 	p0.y = s.by - s.ay;
        //p1 = s.a -> c のベクトル
        p1.x = c.x - s.ax;	p1.y = c.y - s.ay;
        let ss      :number = p0.x * p0.x + p0.y * p0.y;	//sの長さの二乗
        let	co      :number = p0.x * p1.y - p1.x * p0.y;	//外積
        co = co * co;								    //外積の二乗
        let d		:number = co / ss;				//線分と円中心との距離の二乗
        let rr		:number = c.radius * c.radius;	//ｃの半径の二乗
        let dot01	:number = 0;
        let dot02	:number = 0;
        let sa		:number = 0;
        let sb		:number = 0;
        if (d <= rr) {//衝突の可能性あり！
            //p0とp1の内積
            dot01 = p0.x * p1.x + p0.y * p1.y;
            //p2 = s.b -> c のベクトル
            p2.x = c.x - s.bx;	p2.y = c.y - s.by;
            //p0とp2の内積
            dot02 = p0.x * p2.x + p0.y * p2.y;
            if (dot01 * dot02 <= 0) {
                return true;
            }else {
                sa = p1.x * p1.x + p1.y * p1.y;
                sb = p2.x * p2.x + p2.y * p2.y;
                return (sa <= rr || sb <= rr);
            }
        }
        return false;
    }
    /**
     * 線分と円のあたり判定(位置補正付き)
     * 線分は反時計回りを基本として（ポリゴンの表と一緒）外側（点a->点bに対して右側）に押し出すようにcorrect_xyは補正され設定される
     */
    export function crossToSegmentAndCircleWithCorrect(s:KRSegment, c:KRCircle, correct_xy:KRPoint):boolean{
        // 線の法線
        Geometry.nomalizeVector(s, p0);
        Geometry.nomalizeUnitVector(p0, p1);
        let nx  :number = p1.x;
        let ny  :number = p1.y;
        let sx  :number = -nx * c.radius;			//玉から面と垂直な線を出して面と交差するか調べる
        let sy  :number = -ny * c.radius;
        let l   :number = (nx * sx + ny * sy);		//線分までの倍率tを求める
        if (l === 0) return false;	//線分が0なら駄目
        let d   :number = -(s.ax * nx + s.ay * ny);
        let t   :number = -(nx * c.x + ny * c.y + d) / l;
        // 線と交差している（この時点では無限線）
        if(t >= -1 && t <= 1){
            // 交点
            let cx   :number  = c.x + t * sx;
            let cy   :number  = c.y + t * sy;
            let acx  :number  = cx - s.ax;
            let acy  :number  = cy - s.ay;
            let bcx  :number  = cx - s.bx;
            let bcy  :number  = cy - s.by;
            // 交点が線分の間にあるか調べる
            if((acx * bcx) + (acy * bcy) <= 0){
                //補正された値を入力
                if (correct_xy) {
                    correct_xy.x = cx + c.radius * nx;//変更
                    correct_xy.y = cy + c.radius * ny;//変更
                }
                return true;
            }
            //線分の端と交差する場合
            let rr  :number  = c.radius;
            let ll  :number  = 0;
            let xy  :number  = 0;
            rr = rr * rr;
            sx = c.x - s.ax;
            sy = c.y - s.ay;
            xy = sx * sx + sy * sy;
            if (xy <= rr) {
                if (correct_xy) {
                    ll = Math.sqrt(xy);
                    if(ll > 0)ll = 1 / ll;
                    sx = sx * ll;
                    sy = sy * ll;
                    // 位置補正
                    correct_xy.x = s.ax + sx * c.radius;
                    correct_xy.y = s.ay + sy * c.radius;
                }
                return true;
            }
            sx = c.x - s.bx;
            sy = c.y - s.by;
            xy = sx * sx + sy * sy;
            if (xy <= rr) {
                if (correct_xy) {
                    ll = Math.sqrt(xy);
                    if(ll > 0)ll = 1 / ll;
                    sx = sx * ll;
                    sy = sy * ll;
                    // 位置補正
                    correct_xy.x = s.bx + sx * c.radius;
                    correct_xy.y = s.by + sy * c.radius;
                }
                return true;
            }
        }
        return false;
    }
    /**
     * 線分と線分の接触判定。trueの場合、ansに交点座標が入力されます。
     */
    export function crossToSegAndSegWithAnswer(s0:KRSegment, s1:KRSegment, ans:KRPoint):boolean{
        let dBunbo  :number = (s0.bx - s0.ax) * (s1.by - s1.ay) - (s0.by - s0.ay) * (s1.bx - s1.ax);
        if (dBunbo === 0) {
            return false;
        }

        let vx  :number = s1.ax - s0.ax;
        let vy  :number = s1.ay - s0.ay;
        let dR  :number = ((s1.by - s1.ay) * vx - (s1.bx - s1.ax) * vy) / dBunbo;
        if (dR < 0 || dR > 1) return false;

        let dS  :number = ((s0.by - s0.ay) * vx - (s0.bx - s0.ax) * vy) / dBunbo;
        if (dS < 0 || dS > 1) return false;

        ans.x = s0.ax + dR * (s0.bx - s0.ax);
        ans.y = s0.ay + dR * (s0.by - s0.ay);

        return true;
    }
    /**方形と円の接触判定
     * boxの拡大、回転等は考慮してません。
     * @param {Kr.Element.Box} box
     * @param {Kr.Element.Circle} circle
     * @return {boolean}
     */
    export function checkBoxAndCircle(box:KRBox, circle:KRCircle):boolean{
        //ボックスの座標（左上座標）が原点にくるように移動
        let cx:number = circle.x - box.cx;
        let cy:number = circle.y - box.cy;

        return cx > -circle.radius && cx < box.width + circle.radius && cy > -circle.radius && cy < box.height + circle.radius;
    }

};

export namespace Text{
    /**
     * 文字列"text"を縦書きで描画します。
     * "\n"で改行を行います。
     */
    export function fillToVertical(context:CanvasRenderingContext2D, text:string, x:number, y:number):void {
        let textList    :string[] = text.split('\n');
        let lineHeight  :number = context.measureText("あ").width;
        textList.forEach(function(elm, i) {
            Array.prototype.forEach.call(elm, function(ch:string, j:number) {
                context.fillText(ch, x-lineHeight*i, y+lineHeight*j);
            });
        });
    }
    /**
     * Randomな文字列を返します。
     * @param {number} len
     * @param {boolean} addUpper    大文字を含ませる
     * @param {boolean} addLower    小文字を含ませる
     * @param {boolean} addNumber   数字を含ませる
     * @param {boolean} uniqueWords 重複禁止
     * @return {string}
     */
    export function getRandomWords(len:number, addUpper:boolean = true, addLower:boolean = true, addNumber:boolean = true, uniqueWords:boolean = false):string{
        // 生成する文字列に含める文字セット
        let c_up    :string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
            c_low   :string = "abcdefghijklmnopqrstuvwxyz",
            c_num   :string = "0123456789";
        let cs      :string = "",
            c       :string = "",
            cs_len  :number = 0,
            res     :string = "";
        if(addUpper){cs = c_up;}
        if(addLower){cs = cs + c_low;}
        if(addNumber){cs = cs + c_num;}
        for(let i=0; i<len; i++){
            if(cs.length === 0) break;
            c = cs[Math.floor(Math.random() * cs.length)];
            if(uniqueWords){
                let regExp = new RegExp(c, "g");//置換支持を出す為のオブジェクト※複数を置換する時は"g"を、
                                                //                              ※大文字/小文字の区別をしない場合には"i"を指定します。
                cs = cs.replace(regExp, "") ;//空文字に置換
            }
            res = res + c;
        }
        return res;
    }
};

export namespace Util {
    /**
     * targetの関数（クラス名）を文字列で返します。
     */
    export function getFunctionName(target: any):string{
        //target.toString() -> "function 関数名(){}" -> match(/\w+/g):英数字のみを分割して抜き出す
        //target.toString()の中身が"function 関数名(){}"でないと機能しない
        return target.toString().match(/\w+/g)[1];
    }
}

