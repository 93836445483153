import * as cfunc from "../../common/cfunc_reversi";
import {IArgmentChatData} from "../../common/ctypes";
import {ICustomClientSocket} from "../../common/ctypes";
import KRReversiUI from "./kr_reversi_ui";


export default function KRMgrReversiForClient(socket:ICustomClientSocket, dialog_default_width:number){
    "use strict";
    //CONST----------------------------------------------------------------------------------------
    const ST_RECRUIT          = cfunc.ST_RECRUIT;            //募集中
    const ST_PLAYING          = cfunc.ST_PLAYING;            //プレイ中
    const ST_GAME_OVER        = cfunc.ST_GAME_OVER;          //ゲームオーバー中
    //request_id
    const REQ_ID_RECRUIT      = cfunc.REQ_ID_RECRUIT;        //参加者募集
    const REQ_ID_JOIN_GAME    = cfunc.REQ_ID_JOIN_GAME;      //参加者する Player or watcher
    const REQ_ID_PUT_PIECE    = cfunc.REQ_ID_PUT_PIECE;      //ピースを置く
    const REQ_ID_PASS_TURN    = cfunc.REQ_ID_PASS_TURN;      //パスする
    const REQ_ID_CANCEL_GAME  = cfunc.REQ_ID_CANCEL_GAME;    //何かしらの理由によりゲーム続行不可
    const REQ_ID_JOIN_IN_MIDWAY = cfunc.REQ_ID_JOIN_IN_MIDWAY;//途中入室したユーザーに送る用
//---------------------------------------------------------------------------------------------------------------------------------
    /**@description チャットで表示する関数                         ※mainから貰う */
    let _func_add_chat_message  :(value:IArgmentChatData)=>void = null;//関数型は引数と戻り値を宣言する。
    /**@description ログメッセージを表示する関数                   ※mainから貰う */
    let _func_add_log_message   :(message:string, options?:any)=>void = null;//関数型は引数と戻り値を宣言する。
    /**@description UIの表示・非表示の変化時に実行する関数。       ※mainから貰う */
    let _func_change_rayout     :()=>void = null;//関数型は引数と戻り値を宣言する。
    /**@description mainが管理してるアバター画像を取得する。       ※mainから貰う */
    let _func_get_img_avater    :(index:number)=>HTMLImageElement = null;//関数型は引数と戻り値を宣言する。
    /**@description windowのscaleが１未満だと、表示位置が左にずれ込むのでそれを補正する関数。※mainから貰う */
    let _func_set_pos_dlg       :(dlg:any)=>void = null;        //関数型は引数と戻り値を宣言する。
    /**@description 自分のソケット 作成時に引数で貰います*/
    let _my_socket = socket;
    /**@description ダイアログの基本幅 作成時に引数で貰います*/
    let _dialog_default_width = dialog_default_width;
    /**@description 現在プレイヤーとして参加してます */
    let _joined_game = false;
    /**@description タイマーをまとめたもの */
    let _my_timer = {id: 0,
                     max_msec: 0,
                     start_msec: 0
                    };
    /**@description 設定用・基本設定
     * @type {SettingsSetObject}
     */
    let _settings_set :cfunc.SettingsSetObject = null;
    /**@description REQ_ID_RECRUITでサーバ側MgrReversiから貰うデータ保存場所
     * @type {PlayingDataObject} _playing_data
     */
    let _playing_data :cfunc.PlayingDataObject = null;
    /**@description pass, putdowncardsした時のイベント内容
     * @type {Array}
     */
    let _event_list:number[]  = [];
    /**@description ユーザーインターフェース
     */
    let _ui = KRReversiUI();
    //Dialogs
    let $dlgReversiSettings    :JQuery = null;
    let $dlgReversiRecruit     :JQuery = null;
    //Dialog Settings
    let $dlg_parts_settings: {
        lst_recruit_time        : JQuery,
        lst_thinking_time       : JQuery
    } = {
        lst_recruit_time        : null,
        lst_thinking_time       : null
    };
    //Dialog Recruit
    let $dlg_parts_recruit : {
        default_rules   : JQuery,
        time            : JQuery
    } = {
        default_rules   : null,
        time            : null
    };
    const DLG_RESULT_WIDTH_BASE = 172;
    const DLG_RESULT_WIDTH_PER_ROUND_COUNT = 46;
    const DLG_RESULT_MIN_WIDTH = 360;
    //User
    let _my_side        :number = cfunc.PIECE_NONE;      //自分が観戦ならNONE、プレイヤーなら白or黒
    let _board          : cfunc.KRReversiBoard = null;
    //FUNCTIONS-----------------------------------------------------------------------------
    /**
     * ev_indexで指定したイベントのメッセージを取得します。
     * @param {number} ev_index
     * @return {string}
     */
    function getEventMessage(ev_index:number):string{
        switch(ev_index){
            default: return "";
        }
    }
    /**
     * パスもしくはカード出しをしたことで発生したイベントの内容を
     * メッセージとしてチャットに表示します。
     * ※現在使ってません
     * @param {string} username パスもしくはカードを出したプレイヤーです。※pass(),putDownCards()でnow_order_nameは変更されてしまうため
     * @param {number} img_avater_index
     */
    function addEventMessageToChat(username:string, img_avater_index:number):void{
        let i = 0,
            len = _event_list.length,
            chat_data   :IArgmentChatData = {
                username,
                img_avater_index,
                message: ""
            };
        for(i=0; i<len; i++){
            chat_data.message = getEventMessage(_event_list[i]);
            if(chat_data.message !== ""){
                _func_add_chat_message(chat_data);
            }
        }
    }
    /**
     * パスもしくはカード出しをしたことで発生したイベントの内容を
     * ログとしてチャットに表示します。
     */
    function addEventLogToChat():void{
        let i = 0,
            len = _event_list.length,
            message = "";
        for(i=0; i<len; i++){
            message = getEventMessage(_event_list[i]);
            if(message !== ""){
                _func_add_log_message(message);
            }
        }
    }
    /**
     * UIから実行され、盤面をクリックし、置くピース位置をサーバに送信します。
     * ※置ける場合のみ、実行されます。
     * @param put_piece_data {number[]} 0:square_x, 1:square_y, 2:side
     * @return {boolean}
     */
    function doMyPutAction(put_piece_data:number[]):void {
        _ui.controllLock(true);
        _my_socket.emit('reversi', {
            request_id: REQ_ID_PUT_PIECE,
            put_piece_data
        });
    }
    /**
     * サーバ側MgrReversiからの通信を処理します
     * @param {Object} data {request_id:number, +α}
     * @api public
     */
    function socketEvent(data:cfunc.IArgmentSocketEvent){
        //console.log("From server... request_id =" + data.request_id);
        switch(data.request_id){
            case REQ_ID_RECRUIT:        //黒が募集を添付Dataの内容でかけます。    (mgr)
                reqOpenDialogRecruit(data);
                break;
            case REQ_ID_JOIN_GAME:      //白がゲームに参加します。                (User)
                reqJoinGame(data);
                break;
            case REQ_ID_PUT_PIECE:      //ピースを指定場所へ置きます。            (mgr)
                reqPutPiece(data);
                break;
            case REQ_ID_PASS_TURN:      //パスします                          (Player)
                reqPassTurn(data);
                break;
            case REQ_ID_CANCEL_GAME:    //何らかの理由でゲームを中断します。  (mgr)
                reqCancelGame(data);
                break;
            case REQ_ID_JOIN_IN_MIDWAY: //途中入室した時送られてくる（このクライアントのみ）
                reqJoinInMidway(data);
                break;
        }
    }
    /**
     * 募集用ダイアログを開きます。
     * @param {{request_id:number,playing_data:PlayingDataObject,start_msec:number}} data
     */
    function reqOpenDialogRecruit(data:cfunc.IArgmentSocketEvent){
        closeDialogAll();
        clearTimeout(_my_timer.id);//妥当ではない ID を渡しても、何の効果もありません（また、例外はスローされません）。
        //playing_dataの設定
        setPlayingDataAndUI(data.playing_data);
        //ダイアログの設定
        let players = _playing_data.players,
            recruiter = players[0],
            s = _playing_data.settings,
            txt = "";
        //登録設定
        registAsPlayer(recruiter);
        //表示内容変更
        txt = '<legend >基本</legend>'+
                '<div>・思考時間 ' + s.thinking_time + '秒</div>';
        $dlg_parts_recruit.default_rules.empty();
        $dlg_parts_recruit.default_rules.append(txt);
        //募集タイマーセット
        setTimer(data.start_msec, s.recruit_time * 1000, updateRecruitTime);
        //募集者以外を開く
        if(recruiter.name !== _my_socket.username){
            openDialogRecruit();
        }else{
            setMySide(cfunc.PIECE_BLACK);
        }
    }
    /**
     * playing_dataを設定します。
     * @param {PlayingDataObject} playing_data
     */
    function setPlayingDataAndUI(playing_data: cfunc.PlayingDataObject){
        _playing_data = playing_data;
        _ui.setMyUserName(_my_socket.username);
        _ui.setActive(true, _playing_data);
        _ui.controllLock(true);//部屋内全員ロック
        //レイアウト変更
        if(_func_change_rayout){
            _func_change_rayout();
        }
    }
/* プロパティの追加方法
    interface ICustomSocket extends SocketIOClient.Socket{
        username: string;
    }
    var ggg:ICustomSocket = io() as ICustomSocket;
*/
    /**
     * Player(白)を登録 -> ゲーム開始します。
     * player.img_avater_indexからplayer.img_avaterに画像を読み込みます。
     * @param {{request_id:number, player:PlayerObject}} data
     */
    function reqJoinGame(data:cfunc.IArgmentSocketEvent){
        let players = _playing_data.players,
            player = data.player;
        //送られてきたプレイヤー（白）は自分か？
        if(player.name === _my_socket.username){
            setMySide(cfunc.PIECE_WHITE);
        }
        //登録設定
        registAsPlayer(player);
        //登録
        players.push(player);
        //開始準備
        _playing_data.prog_status = ST_PLAYING;
        _func_add_log_message('対戦スタート！');
        //Dialogを閉じる
        closeDialogAll();
        //タイマーをセットする
        setTimer(data.start_msec, _playing_data.settings.thinking_time * 1000, updateThinkingTime);//シンキングタイム
        //自分が黒ならコントロールロックを解除する
        if(_my_side === cfunc.PIECE_BLACK){
            _ui.controllLock(false);
        }
    }
    /**
     * プレイヤーを追加します。
     * reqruite(黒)時、joinGame(白)時に呼び出されます。
     */
    function registAsPlayer(player:cfunc.PlayerObject):void{
        //img_avaterの設定
        player.img_avater = _func_get_img_avater(player.img_avater_index);
        //自分？
        if(player.name === _my_socket.username){
            _joined_game = true;
            _ui.setMyPlayer(player);
        }
    }
    /**
     * サーバから送られてきたデータから
     * 駒を指定場所に配置します。
     * @param {{request_id:number, start_msec:number, max_msec:number, put_piece_data:number[]}} data
     */
    function reqPutPiece(data:cfunc.IArgmentSocketEvent){
        clearTimeout(_my_timer.id); //妥当ではない ID を渡しても、何の効果もありません（また、例外はスローされません）。
        _ui.controllLock(true);     //サーバから直接来るかもしれないので必要
        let d:number[] = data.put_piece_data,
            x:number = d[0],
            y:number = d[1],
            side:number = d[2];//x,y,side
        //置く（チェック無し　必ず置けるデータが飛んでくる）
        _board.putPiece(x, y, side)
        _board.doRevers();
        _ui.putPiece(x, y, side, doReversAnimationFinished);//UIの方にも反映
        //思考時間設定Or終了時間設定
        setTimer(data.start_msec, data.max_msec - 1000, updateThinkingTime);//終了 or Thinkingのどれかだけどとりあえずセット
    }
    /**
     * UIのアニメが実行完了した後に実行する関数です。
     * "ひっくり返しアニメ"、"パスアニメ"が対象です。
     * reqPutPiece(),reqPassTurn()で一々渡します。
     */
    function doReversAnimationFinished():void{
        //以下doAnimFinished()で実行
        if(_board.checkFinish()){  //終了
            _func_add_log_message(getWinnerMsg());
            clearGameData();
        }else{                  //続行
            //ターンを進める
            _playing_data.round_status.now_turn++;
            //自分がプレイヤーで且つ自分の番ならロック解除(PIECE_BLACK==1前提で計算)
            //もしくはどこにも置けないならパスする。
            let now_side:number = _playing_data.round_status.now_turn % 2 === 0 ? cfunc.PIECE_BLACK:cfunc.PIECE_WHITE;
            //置けるところがあるかチェック
            if(_board.getPuttableSquareCount(now_side) > 0){
                //自分はプレイヤーで自分の番？
                if(_my_side === now_side){
                    _ui.controllLock(false);
                }
            }else{
                //置けるところが無いので、パスを送信する
                //自分はプレイヤーで自分の番？
                if(_my_side === now_side){
                    let data = {
                        request_id: REQ_ID_PASS_TURN
                    };
                    _my_socket.emit('reversi', data);
                }
            }
        }
    }
    /**
     * 勝敗文章取得
     */
    function getWinnerMsg():string{
        let b   :number = _board.length_black,
            w   :number = _board.length_white,
            res :string = "黒" + b + " : " + "白" + w + " で";
        if(b === w){
            res = res + "引き分け！";
        }else if (b > w){
            res = res + _playing_data.players[0].name + "の勝ち！";
        }else{
            res = res + _playing_data.players[1].name + "の勝ち！";
        }
        return res;
    }
    /**
     *
     * @param {{request_id:number, start_msec:number, max_msec:number}} data
     */
    function reqPassTurn(data:cfunc.IArgmentSocketEvent){
        //次のタイマーをセットする
        setTimer(data.start_msec, data.max_msec - 1000, updateThinkingTime);//終了 or Thinkingのどれかだけどとりあえずセット
        //UIの方にパス表示をセット
        _ui.displayPassTurn("パス", cfunc.PASS_INTERVAL, doDisplayPassFinished);
    }
    /**
     * UIでパスを表示した後に実行するメソッドです。
     */
    function doDisplayPassFinished():void{
        //ターンを進める
        _playing_data.round_status.now_turn++;
        let now_order:number = _playing_data.round_status.now_turn % 2 === 0 ? cfunc.PIECE_BLACK:cfunc.PIECE_WHITE;
        //プレイヤーは自分？
        if(_my_side === now_order){
            _ui.controllLock(false);
        }
    }
    /**
     * ゲームを中断します
     * @param {{request_id:number}} data
     */
    function reqCancelGame(data:cfunc.IArgmentSocketEvent){
        //ダイアログが開いていたら閉じます
        closeDialogAll();
        clearGameData();
    }
    /**
     * 入室しようとした部屋でゲームが始まっていた際に送られてきます。
     * つまり部屋内全員で実行するメソッドでなく、新しく入ってきたこのクライアントのみで実行されます。
     * 基本的に進行はServerからの送信待ちなので、何もしなくて良い。
     * @param {{request_id:number,playing_data:PlayingDataObject,start_msec:number}} data
     */
    function reqJoinInMidway(data:cfunc.IArgmentSocketEvent){
        //各登録済プレイヤーのアバター画像を設定
        let playing_data = data.playing_data,
            prog_status = playing_data.prog_status,
            settings = playing_data.settings,
            players = playing_data.players,
            i = 0,
            len = players.length;
        for(i=0; i<len; i++){
            players[i].img_avater = _func_get_img_avater(players[i].img_avater_index);
        }
        //まだ募集中ならdlgRecruitを表示、そうでないならplaying_dataだけ設定
        if(prog_status === ST_RECRUIT){
            reqOpenDialogRecruit(data);//※内部でsetPlayingDataAndUI()を実行している。
        }else{
            setPlayingDataAndUI(playing_data);
            //ST_PLAYINGならタイマーセット
            if(prog_status === ST_PLAYING){
                setTimer(data.start_msec, settings.thinking_time * 1000, updateThinkingTime);
                //Boardセット
                _board.setPiecesData(data.pieces_data);
                _ui.synchroPieces();
            }
        }
    }
    /**
     * 現在プレイ中の"プレイヤー"が退室した際に送られてきます。
     * ※プレイヤーが退室することで最低人数を割る場合はこのメソッドは実行されません。->CancelGameが呼び出される
     * ※つまりこのメソッドは絶対呼び出されないということです。
     * @param {{request_id:number,leave_player_index:number,start_msec:number}} data
     */
    /*
    function reqLeaveInMidway(data:cfunc.IArgmentSocketEvent){
        if(!_playing_data) return;
        _event_list.splice(0, _event_list.length);
        let now_prog_status = _playing_data.prog_status;//現在の進行状況を確保（leavePlayerInMidway()内部で値が変わってしまうかもしれないから）
        if(now_prog_status == ST_PLAYING){
            addEventLogToChat();//イベント処理
        }
    }
    */
    /**
     * _playing_dataを破棄します。
     * UIも消します。
     */
    function clearGameData(){
        clearTimeout(_my_timer.id);//妥当ではない ID を渡しても、何の効果もありません（また、例外はスローされません）。
        //if(!_playing_data) return;
        //手動でPlayerObjectに追加したimg_avaterの消去
        let players :cfunc.PlayerObject[] = null,
            i       :number = 0,
            len     :number = 0,
            player  :cfunc.PlayerObject= null;
        if(_playing_data){
            players = _playing_data.players;
        }
        if(players){
            len = players.length;
            for(i=0;i<len;i++){
                player = players[i];
                player.img_avater = null;
                delete player.img_avater;
            }
        }
        //free
        cfunc.freePlayingData(_playing_data);
        _playing_data = null;
        _joined_game = false;
        _ui.setActive(false, null);
        if(_func_change_rayout){
            _func_change_rayout();
        }
        setMySide(cfunc.PIECE_NONE);
        //盤面の初期化
        _board.initSquares();
        _ui.synchroPieces();
    }
    /**
     * 全ての関連のダイアログを閉じます
     */
    function closeDialogAll(){
        if($dlgReversiRecruit && $dlgReversiRecruit.dialog('isOpen')){
            $dlgReversiRecruit.dialog('close');
        }
        if($dlgReversiSettings && $dlgReversiSettings.dialog('isOpen')){
            $dlgReversiSettings.dialog('close');
        }
    }

    /**
     * 初期設定。一度だけ呼び出します。
     * @param {Function} func_add_chat_message チャットする時に呼び出したい関数。
     * @param {Function} func_add_log_message ログを表示する時に呼び出したい関数。
     * @param {Function} func_change_rayout UI表示・非表示時に呼び出したい関数。
     * @param {Function} func_get_img_avater playerのavaterイメージを取得するとき呼び出す関数。
     * @param {Function} func_set_pos_dlg windowの縮尺が１未満だとダイアログの表示位置が左にずれ込むので補正した値を設定する関数。
     * @param {Object} parent_element UIの表示領域(canvas)を追加するための要素(Element)
     * @param {Function} func_finished 設定が完了した際に呼び出したい関数。
     */
    function setup( func_add_chat_message:(value:IArgmentChatData)=>void,
                    func_add_log_message:(message:string, options?:any)=>void,
                    func_change_rayout:()=>void,
                    func_get_img_avater:(index:number)=>HTMLImageElement,
                    func_set_pos_dlg:(dlg:JQuery)=>void,
                    parent_element:HTMLElement,
                    func_finished:()=>void){
        _func_add_chat_message = func_add_chat_message;
        _func_add_log_message = func_add_log_message;
        _func_change_rayout = func_change_rayout;
        _func_get_img_avater = func_get_img_avater;
        _func_set_pos_dlg = func_set_pos_dlg;
        let parent_element_name = '#' + parent_element.id;
        createSettingDialog(parent_element_name);
        createRecruitDialog(parent_element_name);
        setInitData();
        _board = new cfunc.KRReversiBoard();
        _ui.setup(parent_element, _board, doMyPutAction, func_finished);
    }
    /**
     *
     */
    function setMySide(my_side:number):void{
        _my_side = my_side;
        _ui.setMySide(my_side);
    }
    /**
     * 基本設定をcfunc_reversiから読み込みます。
     */
    function setInitData():void{
        _settings_set = cfunc.createSettingsSetObject();
        //ダイアログのパーツ「ドロップダウンリスト」を作成
        setset($dlg_parts_settings.lst_recruit_time,
                _settings_set.max.recruit_time,
                _settings_set.min.recruit_time,
                _settings_set.def.recruit_time,
                _settings_set.per.recruit_time);
        setset($dlg_parts_settings.lst_thinking_time,
                _settings_set.max.thinking_time,
                _settings_set.min.thinking_time,
                _settings_set.def.thinking_time,
                _settings_set.per.thinking_time);
        function setset($select:JQuery, max:number, min:number, def:number, per:number){
            let val = min,
                txt_val = "",
                $option = null;
            if(per <= 0){ per = 10; }
            //このwhileは大丈夫
            while(val <= max){
                txt_val = val.toString();
                $option = $('<option>').val(txt_val).text(txt_val);
                if(val === def){
                    $option.prop('selected', true);
                }
                $select.append($option);
                val = val + per;
            }
        }

    }

    /**
     * 考え中のカウントダウン文字更新
     * ※引数msecは時間経過でどんどん減っていきます。
    */
    function updateThinkingTime(msec:number):void{
        _ui.setlimitTime(msec, _playing_data.settings.thinking_time * 1000);
    }
    /**
     * 募集時のカウントダウン文字更新
     * ※引数msecは時間経過でどんどん減っていきます。
    */
    function updateRecruitTime(msec:number):void{
        $dlg_parts_recruit.time.text((msec / 1000).toFixed(0));
        _ui.setlimitTime(msec, msec);
    }
    /**
     * タイマー実行。updateCountDownTime()を直接呼び出すのでなく、こちらから実行する。
     * @param {number} start_msec
     * @param {number} max_msec
     * @param {Function} update_func
     */
    function setTimer(start_msec:number, max_msec:number, update_func:(msec:number)=>void):void{
        clearTimeout(_my_timer.id);//妥当ではない ID を渡しても、何の効果もありません（また、例外はスローされません）。
        //最大ミリ秒をセット
        _my_timer.start_msec = start_msec;
        _my_timer.max_msec = max_msec;
        //Loop関数
        updateCountDownTime(100, update_func);
    }
    /**
     * 汎用のカウントダウンタイマーです。
     * この関数からのイベント進行はありません。進行はすべてサーバから指示が来ます。
     * ※この関数を直接呼び出さないでください。代わりにsetTimer()を実行してください。
     * @param {number} interval         更新間隔をmsecで指定します。
     * @param {Function} update_func    更新時に実行したい関数です引数として現在のカウントを渡します。
     */
    function updateCountDownTime(interval:number, update_func:(msec:number)=>void):void{
        _my_timer.id = window.setTimeout(function(){//NodeJS.Timerが返るNodeJS.setTimeoutもあるので注意
            let t = _my_timer.max_msec - (Date.now() - _my_timer.start_msec);//時間経過でどんどん減っていく
            if(t < 0){
                t = 0;
            }
            if(update_func){
                update_func(t);
            }
            if(t !== 0){
                updateCountDownTime(interval, update_func);
            }
        } , interval);
    }

    /**
     * 設定用のダイアログを作成します。一度だけ呼び出されます。
     * @param {string} parent_name
     */
    function createSettingDialog(parent_name:string){
        //ダイアログ作成
        let txt =
        '<div class="j-ui-dialog dlgReversiSettings">'+
            '<fieldset>'+
            '<legend >基本</legend>'+
            '<div>募集時間 <select class="lst_recruit_time" style="width: 64px;"></select>秒</div>'+
            '<div>思考時間 <select class="lst_thinking_time" style="width: 64px;"></select>秒</div>'+
            '</fieldset>'+
        '</div>';
        $('body').append(txt);

        $dlgReversiSettings = $('.j-ui-dialog.dlgReversiSettings');

        $dlgReversiSettings.dialog({
            autoOpen: false,
            modal: true,
            width: _dialog_default_width,
            title: "リバーシ　ルール設定",
            buttons: {
                "開始"(){
                    _settings_set.val.recruit_time = parseInt($dlg_parts_settings.lst_recruit_time.val().toString(), 10);
                    _settings_set.val.thinking_time = parseInt($dlg_parts_settings.lst_thinking_time.val().toString(), 10);
                    let v = 0;
                    let data = {
                        request_id: REQ_ID_RECRUIT,
                        settings: _settings_set.val
                    };
                    _my_socket.emit('reversi', data);
                    $dlgReversiSettings.dialog('close');
                },
                "中止"(){
                    $dlgReversiSettings.dialog('close');
                }
            }
        });
        $dlg_parts_settings.lst_recruit_time = $('.j-ui-dialog.dlgReversiSettings .lst_recruit_time');
        $dlg_parts_settings.lst_thinking_time = $('.j-ui-dialog.dlgReversiSettings .lst_thinking_time');

        //widgetに値を適用するために必要
        $dlgReversiSettings.dialog('open');
        $dlgReversiSettings.dialog('close');
    }

    /**
     * 参戦用のダイアログを作成します。一度だけ呼び出されます。
     * @param {string} parent_name
     */
    function createRecruitDialog(parent_name:string){
        //ダイアログ作成
        let txt =
        '<div class="j-ui-dialog dlgReversiRecruit">'+
            '<fieldset class="default_rules">'+
            '</fieldset>'+
            '<p class="time" style="float:right">00</p><p style="float:right">開始まで・・・</p>'+
        '</div>';
        $('body').append(txt);

        $dlgReversiRecruit = $('.j-ui-dialog.dlgReversiRecruit');

        $dlgReversiRecruit.dialog({
            autoOpen: false,
            modal: true,
            width: _dialog_default_width,
            title: "対戦者募集！",
            buttons: {
                "対戦":()=> {
                    _my_socket.emit('reversi', {request_id: REQ_ID_JOIN_GAME});
                    $dlgReversiRecruit.dialog('close');
                },
                "観戦":()=> {
                    $dlgReversiRecruit.dialog('close');
                }
            }
        });
        $dlg_parts_recruit.default_rules =  $('.j-ui-dialog.dlgReversiRecruit .default_rules');
        $dlg_parts_recruit.time = $('.j-ui-dialog.dlgReversiRecruit .time');

        //$dlgReversiRecruit.dialog("option", "appendTo", "#dialogUIArea");//どこに所属させるか決定（Defaultはbody）
        //widgetに値を適用するために必要
        $dlgReversiRecruit.dialog('open');
        $dlgReversiRecruit.dialog('close');
    }

    /**
     * 設定用ダイアログを開きます。
     */
    function openDialogSettings(){
        _func_set_pos_dlg($dlgReversiSettings);
        $dlgReversiSettings.dialog('open');
    }

    /**
     * 募集用ダイアログを開きます。
     */
    function openDialogRecruit(){
        _func_set_pos_dlg($dlgReversiRecruit);
        $dlgReversiRecruit.dialog('open');
    }

    /**
     * 現在の状況により異なるアクションを行います。
     * playing_data == null：設定用ダイアログを開きます。
     * playing_data.prog_status == ST_RECRUIT： (参加済 -> 何もしません。)
     *                                          (不参加 -> RecruitDialogを開きます。)
     * playing_data.prog_status != ST_RECRUIT：何もしません
     */
    function openDialog(){
        if(_playing_data == null){
            openDialogSettings();
        }else{
            if(_playing_data.prog_status === ST_RECRUIT){
                if(_joined_game === false){
                    openDialogRecruit();
                }
            }
        }

    }

    return{
        socketEvent,
        setup,
        openDialog,
        ui: _ui,
        clearGameData,
    };

}
