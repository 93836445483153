//画面いっぱいにイメージ表示
export default function KRImageDisplay(){
    "use strict";
    let flg_displaying = false;  //現在表示中？
    let imgDisplayArea = document.createElement('div');
    let imgContent = new Image();
    let padding = 32;
    let css_imgDisplayArea =    "position:absolute;" +
                                "top:0px;" +
                                "left:0px;" +
                                "width:100%;" +
                                "height:100%;" +
                                "background-color: rgba(0,0,0,0.5);" +
                                "display: none;" +
                                "cursor: pointer;" +
                                "opacity: 0;" +
                                "z-index:9999;";
    let css_imgContent =        "position:absolute;" +
                                "z-index:10000;";
    let list    :any[] = [];

    let fade_interval = 15;
    let fade_value = 0.1;



    //初期設定-------------------------------------------------------------------------------------------------
    imgDisplayArea.id = "imgDisplayArea";   //<-これはいらないかな？
    document.body.appendChild(imgDisplayArea);

    imgDisplayArea.style.cssText = css_imgDisplayArea;
    imgContent.style.cssText = css_imgContent;

    //functions-------------------------------------------------------------------------------------------------
    /**
     * 画像要素にクリックの際全体表示させるイベントを追加します。
     * max_w, max_hが与えられている場合、縦横比を変えずに
     * 画像をリサイズします。戻り値に縮尺率(0~1)を返します。
     * @param {Image} img_element
     * @param {Number} max_w
     * @param {Number} max_h
     * @return {Number}
     */
    function registImage(img_element:HTMLImageElement, max_w:number, max_h:number):number{
        //0.clickイベントを追加
        img_element.addEventListener('click', onClickAndDisplay, false);
        //1.縮尺設定
        let org_w = img_element.naturalWidth,
            org_h = img_element.naturalHeight,
            w = org_w,
            h = org_h,
            scale = 1;
        // 画像のリサイズ
        if(max_w && max_w < w && org_w !== 0){
            w = max_w;
            scale = max_w / org_w;
            h = org_h * scale;
        }
        if(max_h && max_h < h && org_h !== 0){//max_h < hがポイント！（上記で”h”の値が変わっている（かもしれない）状態で”max_h<h”なら更に変更が必要ということになる）
            scale = max_h / org_h;
            w = org_w * scale;
            h = max_h;
        }
        img_element.width = w;  //自動で拡大縮小してくれる
        img_element.height = h; //自動で拡大縮小してくれる
        //2. カーソルはポインタに変更
        img_element.style.cursor = "pointer";
        //3.登録
        list.push(img_element);

        return scale;
    }

    //初期化
    function clear(){
        //登録イメージの初期化
        let i = 0,
            len = list.length,
            img = null;
        for(i=0; i < len; i++){
            img = list[i];
            img.removeEventListener('click', onClickAndDisplay, false);
            list[i] = null;
        }
        list = [];

    }

    //各img要素をclickした時実行
    function onClickAndDisplay(e: MouseEvent){
        if(flg_displaying){ return; }
        e.stopImmediatePropagation();
        flg_displaying = true;
        imgDisplayArea.style.display = "block";//速攻でblockにして他clickイベントが起きないようにする
        imgContent.src = (e.target as HTMLImageElement).src;
        imgContent.onload = function(){
            imgDisplayArea.appendChild(imgContent);
            resize();
            //表示
            fadeIn();
        }
    }

    /**
     * 画面のサイズが変わった時に実行します。
     */
    function resize(){
        //表示中で無ければ何もしない
        if(!flg_displaying){ return; }
        //画像サイズと位置を調整。
        let inner_w = window.innerWidth,
            inner_h = window.innerHeight,
            org_w = imgContent.naturalWidth,
            org_h = imgContent.naturalHeight,
            res_w = org_w,
            res_h = org_h;
        //画面全体
        imgDisplayArea.style.width = inner_w + "px";
        imgDisplayArea.style.height = inner_h + "px";
        //表示するイメージ
        if(inner_w < res_w + padding * 2){
            res_w = inner_w - padding * 2;
            res_h = org_h * res_w / org_w;
        }
        if(inner_h < res_h + padding * 2){
            res_h = inner_h - padding * 2;
            res_w = org_w * res_h / org_h;
        }
        imgContent.width = res_w;//自動で拡大縮小してくれる
        imgContent.height = res_h;//自動で拡大縮小してくれる
        imgContent.style.left = (inner_w - res_w) / 2 + "px";
        imgContent.style.top = (inner_h - res_h) / 2 + "px";
    }

    function fadeIn(){
        //処理を書く
        let opt = parseFloat(imgDisplayArea.style.opacity);
        opt = opt + fade_value;
        if(opt > 1){ opt = 1; }
        imgDisplayArea.style.opacity = opt.toString();
        //タイマーセット
        let timerId = setTimeout(fadeIn, fade_interval);
        //条件をクリアしてるならタイマーストップ
        if(opt === 1){
            clearTimeout(timerId);
            //事後処理
            imgDisplayArea.addEventListener('click', onClickAndClose, false);
        }
    }

    //画像を表示中にクリックした時実行
    function onClickAndClose(e:MouseEvent){
        imgDisplayArea.removeEventListener('click', onClickAndClose, false);
        fadeOut();
    }

    function fadeOut(){
        //処理を書く
        let opt = parseFloat(imgDisplayArea.style.opacity);
        opt = opt - fade_value;
        if(opt < 0){ opt = 0; }
        imgDisplayArea.style.opacity = opt.toString();
        //タイマーセット
        let timerId = setTimeout(fadeOut, fade_interval);
        //条件をクリアしてるならタイマーストップ
        if(opt === 0){
            clearTimeout(timerId);
            //事後処理
            imgDisplayArea.removeChild(imgContent);
            imgDisplayArea.style.display = "none";
            flg_displaying = false;
        }
    }



    return{
        registImage,
        clear,
        resize
    }

}
