import * as cfunc from "../../common/cfunc_reversi";

import KRImageLoader from "./kr_image_loader";
import { KRCollision } from "./kr_collision";
import { KRSprite2DManager } from "./kr_sprite2d";
import { KRSprite2D } from "./kr_sprite2d";
import { KRImagePattern } from "./kr_sprite2d";

/* 記述されてる関数は全てRRreversiUI内 */
export default function KRReversiUI(){
    "use strict";
    //CONST Public/////////////////////////////////////////////////////////////////////////////
    const ST_RECRUIT          = cfunc.ST_RECRUIT;           //募集中
    const ST_PLAYING          = cfunc.ST_PLAYING;           //プレイ中
    const ST_GAME_OVER        = cfunc.ST_GAME_OVER;         //ゲームオーバー中
    //CONST Private/////////////////////////////////////////////////////////////////////////////
    const BTN_COLOR = 'rgb(128, 160, 128)';
    const BTN_RIGHT_COLOR = 'rgb(160, 192, 160)';
    const BTN_DARK_COLOR = 'rgb(96, 128, 96)';
    const BTN_TEXT_COLOR = 'rgb(255, 128, 128)';

    const BOARD_BK_COLOR = 'rgb(0, 255, 0)';
    const BOARD_LINE_COLOR = 'rgb(0, 0, 0)';

    const BTN_POS_Y = 360;
    const BTN_WIDTH = 112;
    const BTN_HEIGHT = 40;
    const BTN_DEPTH = 4;
    const TEXT_BTN_PASS = 'パス';

    const ST_PLAYERS_COL_LENGTH = 4;

    const ST_P_PADDING = 2;
    const ST_P_MIN_HEIGHT = 80;     //最小ST高さ
    const ST_P_FACE_MAX_SIZE = 80;  //最大顔画像サイズ

    const BIG_BAR_HEIGHT = 12;
    const SMALL_BAR_HEIGHT = 4;
    //画面の大きさで決まる変数//////////////////////////////////////////////////////////
    let _st_p_height = 64;
    ///////////////////////////////////////////////////////////////////////////////////
    let _first_setup = false;
    let _activated = false;
    /** @type {Function} */
    let _func_do_my_put_action  :(putdown_indexs:number[])=>void = null;
    /** @type {KRSprite2DManager} _mgr_sprite */
    let _mgr_sprite                 :KRSprite2DManager = null;
    /** @type {PlayingDataObject} _read_only_playing_data 呼び出し専用。この中でデータをいじっては絶対ダメ*/
    let _read_only_playing_data     :cfunc.PlayingDataObject = null;
    //フレーム更新用Timer
    let _frame_timer = {
        id: 0,
        old_msec: 0
    };
    /** @type {ButtonSprite2D} _sp_button */
    let _sp_button                  :ButtonSprite2D   = null;      //button描画
    /** @type {StPlayerSprite2D} _sp_st_players */
    let _sp_st_players              :StPlayerSprite2D = null;
    /** @type {BoardSprite2D} */
    let _sp_board                   :BoardSprite2D = null;
    /** @type {TextSprite2D} */
    let _sp_text                    :TextSprite2D = null;

    let _parent_elm                 :HTMLElement = null;
    //_canvasの作成とdocumentへの追加
    let _screen                     :HTMLDivElement = null;//縮尺が絡むとこれが必要になる
    let _canvas                     :HTMLCanvasElement = null;
    let _down_start = {
        now_down: false,
        time: 0,
        x: 0,
        y: 0
    };
    //自身のユーザー情報
    let _my_username                :string = "";
    let _my_side                    :number = cfunc.PIECE_NONE;      //自分が観戦ならNONE、プレイヤーなら白or黒
    let _my_player                  :cfunc.PlayerObject = null;
    let _flg_rot_180                :boolean = false;
    //制限時間
    let _limit = {
        now_msec: 1,
        max_msec: 1,
        rate: 1
    };
    //操作ロック
    let _ctrl_lock = false;
    /**
     * debug用
     */
    let _txt_status = ['STANDBY','RECRUIT','PLAYING','DISPLAY_RESULT','EXCHANGE_CARDS','GAME_OVER','DONE','DISCONNECT'];
    let _touch_x = 0;
    let _touch_y = 0;
    let _touch_name = "";
    /**
     * スマホかPCかで、イベントを選別
     */
    let _support_touch = 'ontouchend' in document;
    let EVENTNAME_TOUCHSTART = _support_touch ? 'touchstart' : 'mousedown';
    let EVENTNAME_TOUCHMOVE = _support_touch ? 'touchmove' : 'mousemove';
    let EVENTNAME_TOUCHEND = _support_touch ? 'touchend' : 'mouseup';
    /**
     * Touch Eventだとevent.offsetX,offsetYが存在しないので、_canvasのスケールと位置（スケールを考慮しない）を設定時に取得しておく必要がある。
     * この変数が無くても、event内プロパティを漁ればわかりそうだが、設定時に取得しとけば手間もかからないだろう・・・という考え。
     * つまりTouch Event以外では使ってない。
     */
    let _screen_values = {
        now_left: 0,
        now_top: 0,
        now_scale: 1    //マウスの位置取得のためだけに使用
    }
    /**
     * @param {Event} e
     */
    let userEvent = function(e:any) {
        e.preventDefault();//動作を停止
        if(_ctrl_lock) return;//コントロールがロックされていれば何もしない。
        // マウス位置を取得する(pageX,pageYだと画面左上からの位置になる)
        let now_x = 0,
            now_y = 0,
            i = 0,
            len = 0,
            hit_index = -1,
            col = null;
        if(e.changedTouches) {  //TouchイベントにはOffsetが無いのでこうするしかない。
            now_x = e.changedTouches[0].pageX / _screen_values.now_scale - _screen_values.now_left;
            now_y = e.changedTouches[0].pageY / _screen_values.now_scale - _screen_values.now_top;
            now_x = Math.floor(now_x);
            now_y = Math.floor(now_y);
        } else {                //Mouse
            now_x = e.offsetX;
            now_y = e.offsetY;
        }
        //初期化
        if(_sp_button){
            _sp_button.pushed = false;
        }
        //e.type
        switch(e.type){
            case EVENTNAME_TOUCHSTART:
                doDown();
                break;
            case EVENTNAME_TOUCHMOVE:
                doMove();
                break;
            case EVENTNAME_TOUCHEND:
                doUp();
                break;
            default:
        }
        function doDown(){
            _down_start.now_down = true;
            _down_start.x = now_x;
            _down_start.y = now_y;
            checkCollision();
        }
        function doMove(){
            checkCollision();
        }
        function doUp(){
            checkCollision();
            _down_start.now_down = false;
            if(_sp_button && _sp_button.pushed){
                //Buttonを押した時することをここに記述
                _sp_button.pushed = false;
                doPushedButton();
                return;
            }
            if(_sp_board){
                if(_sp_board.checkPutPiece(_sp_board.ms_sq_x, _sp_board.ms_sq_y, _my_side)){
                    let ary:number[] = [_sp_board.ms_sq_x, _sp_board.ms_sq_y, _my_side];
                    _func_do_my_put_action(ary);
                }
            }
        }
        function checkCollision(){
            if(_sp_button && _down_start.now_down && _sp_button.visibled && _sp_button.col.checkRoughBoxAndCircle(now_x, now_y, 0)){
                _sp_button.pushed = true;
                return;
            }
            if(_sp_board && _sp_board.visibled){
                _sp_board.checkCollision(now_x, now_y);
            }
        }
    };
    /**
     * ボタンが押されたときにする処理
     */
    function doPushedButton(){

    }
//ButtonSprite///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    class ButtonSprite2D extends KRSprite2D{
        public col      :KRCollision = new KRCollision();
        public width    :number = 10;
        public height   :number = 10;
        public text     :string = TEXT_BTN_PASS;
        public visibled :boolean = false;
        public pushed   :boolean = false;

        public setSize(w:number, h:number):void{
            this.width = w;
            this.height = h;
            this.col.setRoughBox(w, h);
        }
        public setPosition(x:number, y:number):void{
            super.setPosition(x, y);
            this.col.setPosition(x, y);
        }
        public doDraw(context:CanvasRenderingContext2D):void{
            if(!this.visibled)return;
            context.setTransform(1, 0, 0, 1, 0, 0);
            let d_s = 2,
                d_pos = 0,
                w = this.width,
                h = this.height,
                dx = this._position.x - w / 2,
                dy = this._position.y - h / 2;
            let i=0,
                len = 0;
            //ボタン文字決定
            /*
            if(_read_only_playing_data.prog_status == ST_PLAYING){

            }else{

            }
            */
            //ボタン描画
            if(this.pushed){
                d_pos = d_s;
            }
            context.font = "italic bold 22px 'ＭＳ Ｐゴシック'";
            context.textAlign = "center";
            context.textBaseline = "middle";
            context.fillStyle = BTN_COLOR;
            context.fillRect(dx, dy, w, h);
            context.fillStyle = BTN_RIGHT_COLOR;
            context.fillRect(dx + d_pos, dy + d_pos, w - d_pos, BTN_DEPTH);
            context.fillRect(dx + d_pos, dy + d_pos, BTN_DEPTH, h - d_pos);
            context.fillStyle = BTN_DARK_COLOR;
            context.fillRect(dx + d_pos, dy + h - BTN_DEPTH + d_pos, w - d_pos, BTN_DEPTH - d_pos);
            context.fillRect(dx + w - BTN_DEPTH + d_pos, dy + d_pos, BTN_DEPTH - d_pos, h - d_pos);
            if(this.pushed){
                context.fillStyle = "black";
                context.fillRect(dx, dy, w, BTN_DEPTH - d_pos);
                context.fillRect(dx, dy, BTN_DEPTH - d_pos, h);
            }
            context.fillStyle = BTN_TEXT_COLOR;
            context.fillText(this.text, this._position.x + d_pos, this._position.y + d_pos, w - BTN_DEPTH * 2);
        }
    }

//BoardSprite///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    class BoardSprite2D extends KRSprite2D {
        public width                :number = 128;
        public height               :number = 128;
        public margin               :number = 16;                   //外枠
        public line_width           :number = 2;                    //線の太さ
        public ms_sq_x              :number = -1;                   //マウスが乗っているマス目X
        public ms_sq_y              :number = -1;                   //マウスが乗っているマス目Y
        public visibled             :boolean = false;
        public visibled_puttable    :boolean = false;

        private _board              :cfunc.KRReversiBoard = null;
        private _sq_width           :number = 16;                           //setSizeで設定する
        private _sq_height          :number = 16;                           //setSizeで設定する
        private _pieces             :cfunc.KRReversiSquare[][] = null;      //_boardに対応した駒の状態を保存 putPiece()で駒を追加する。

        private _func_do_anim_finished :()=>void = null;//アニメが完了したら行う関数(clientからputPiece()される毎に貰います)

        private ANIM_REVERS_PIECE   :number = cfunc.ANIM_REVERS_PIECE;         //msec
        private ANIM_REVERS_SPEED   :number = 1000 / this.ANIM_REVERS_PIECE;   //1secでAnimationする量

        //※constructor()をするとmgr_sprite.registSpriteClass()が出来なくなるのでやらない
        public setSize(w:number, h:number):void{
            this.width = w;
            this.height = h;
            this._sq_width = (w - 2 * this.margin) / this._board.length_x;
            this._sq_height = (h - 2 * this.margin) / this._board.length_y;
        }
        public setPosition(x:number, y:number):void{
            super.setPosition(x, y);
        }
        /**BoardSprite2D
         * ボードをセットします。
         * このメソッドでboardのマス目に対応するように_piecesをセットするので、
         * 既にマス目が確定しているboardを渡すようにしてください。
         */
        public setBoard(board:cfunc.KRReversiBoard):void{
            this._board = board;
            //_pieces作成
            var sqs  :cfunc.KRReversiSquare[][] = new Array(this._board.length_x);
            var i   :number;
            var ii  :number;
            for(i=0; i < this._board.length_x; i++){
                sqs[i] = new Array(this._board.length_y);   //こっちは数不動
                for(ii=0; ii < this._board.length_y; ii++){
                    sqs[i][ii] = new cfunc.KRReversiSquare(i, ii, null);
                }
            }
            this._pieces = sqs;
            this.synchroPieces();
        }
        /**BoardSprite2D
         * 現在の指定駒の枚数を取得します。
         */
        public getPiecesCount(piece_side:number):number{
            return piece_side === cfunc.PIECE_BLACK ? this._board.length_black : this._board.length_white;
        }
        /**BoardSprite2D
         * _picecsをboard.squaresに同期させます。
         * board.initSquares()の後に実行してください。
         */
        public synchroPieces():void{
            var i   :number;
            var ii  :number;
            for(i=0; i < this._board.length_x; i++){
                for(ii=0; ii < this._board.length_y; ii++){
                    this._pieces[i][ii].piece = this._board.squares[i][ii].piece;
                    this._pieces[i][ii].puttable = 0;//waitで使用
                }
            }
        }
        /**BoardSprite2D
         * ひっくり返しアニメーション時の遅延を設定します。
         * ※waitはputtableで代用し、設定します。
         */
        private setAnimWait():void{
            let sqs :cfunc.KRReversiSquare[] = null,
                i       :number = 0,
                ii      :number = 0,
                len     :number = this._board.r8_squares.length,
                lenlen  :number = 0,
                x       :number = 0,
                y       :number = 0;
            for(i=0; i<len; i++){
                sqs = this._board.r8_squares[i];
                lenlen = sqs.length;
                for(ii=0; ii<lenlen; ii++){
                    x = sqs[ii].x;
                    y = sqs[ii].y;
                    this._pieces[x][y].puttable = this.ANIM_REVERS_PIECE * ii;//wait
                }
            }
        }
        /**BoardSprite2D
         * 対象のマス目上の駒の色を取得します。
         * @param sq 対象のマス目上の駒
         */
        private getPieceColor(piece_side:number):string{
            let res:string = "rgba(255, 0, 0, 1)";
            //Black(1)~White(2)
            let div_val:number = (cfunc.PIECE_WHITE - cfunc.PIECE_BLACK) / 2;

            if(piece_side >= cfunc.PIECE_BLACK && piece_side < cfunc.PIECE_BLACK + div_val){
                res = "rgba(0, 0, 0, 1)";// 1 <= p < 1.5
            }else
            if(piece_side >= cfunc.PIECE_BLACK + div_val && piece_side <= cfunc.PIECE_WHITE){
                res = "rgba(255, 255, 255, 1)"; // 1.5 <= p <= 2
            }

            return res;
        }
        /**BoardSprite2D
         * 対象のマス目上の駒の色を取得します。
         * my_sideがBlackならBlackのWhiteならWhiteの色を取得します。
         * 観戦者がこのメソッドを実行しても正しい色を取得できません。
         * @param sq 対象のマス目上の駒
         */
        private getPuttableColor(puttable:number):string{
            //置いてないとこにおけるかどうか（半透明）
            if((puttable & cfunc.PIECE_BLACK & _my_side) > 0){
                return "rgba(0, 0, 0, 0.5)";
            }else
            if((puttable & cfunc.PIECE_WHITE & _my_side) > 0){
                return "rgba(255, 255, 255, 0.5)";
            }
            return "rgba(255, 0, 0, 0.5)";
        }
        public doMove(elapsed:number):void{
            let sq  : cfunc.KRReversiSquare,
                p   : cfunc.KRReversiSquare,
                i   : number = 0,
                ii  : number = 0,
                elp : number = 0,
                now_anim: boolean = false;
            //全マス
            for(i=0; i < this._board.length_x; i++){
                for(ii=0; ii < this._board.length_y; ii++){
                    sq = this._board.squares[i][ii];
                    p = this._pieces[i][ii];
                    elp = elapsed;
                    //Wait
                    if(p.puttable > 0){//待機
                        if(elp > p.puttable){
                            elp = elp - p.puttable;
                            p.puttable = 0;
                        }else{
                            p.puttable = p.puttable - elp;
                            elp = 0;
                        }
                        now_anim = true;//現在アニメ中
                    }
                    //Turn
                    if(p.piece !== sq.piece && elp > 0){//ターン中
                        if(sq.piece > p.piece){
                            p.piece = p.piece + this.ANIM_REVERS_SPEED * elp / 1000;
                            if(sq.piece <= p.piece){
                                p.piece = sq.piece;
                            }
                        }else{
                            p.piece = p.piece - this.ANIM_REVERS_SPEED * elp / 1000;
                            if(sq.piece >= p.piece){
                                p.piece = sq.piece;
                            }
                        }
                        now_anim = true;//現在アニメ中
                    }
                }
            }
            //アニメが実行されない（終了）且つ、finished関数ある？
            if(this._func_do_anim_finished && !now_anim){
                this._func_do_anim_finished();
                this._func_do_anim_finished = null;
            }

        }
        public doDraw(context:CanvasRenderingContext2D):void{
            if(!this.visibled || !this._board)return;
            context.setTransform(1, 0, 0, 1, 0, 0);
            let bd      :cfunc.KRReversiBoard = this._board,
                x_len   :number = bd.length_x,
                y_len   :number = bd.length_y,
                w       :number = this.width,
                h       :number = this.height,
                sq_w    :number = this._sq_width,                //一マスの幅
                sq_h    :number = this._sq_height,               //一マスの高さ
                cx      :number = this._position.x,
                cy      :number = this._position.y,
                dx      :number = cx - w / 2,
                dy      :number = cy - h / 2,
                i       :number = 0,
                ii      :number = 0;

            if(_flg_rot_180){
                //htmlの中心回転の発想は他と違うので注意！
                context.translate(cx, cy);          //回転の中心に持っていく発想
                context.rotate(180 * Math.PI / 180); //回転
                context.translate(-cx, -cy);        //回転の中心を元に戻す
            }
            //全体描画
            context.fillStyle = BOARD_BK_COLOR;
            context.fillRect(dx, dy, w, h);
            //線描画
            w = w - this.margin * 2;
            h = h - this.margin * 2;
            dx = dx + this.margin;
            dy = dy + this.margin;
            context.lineWidth = this.line_width;
            context.beginPath();
            //縦線
            for(i=0; i<x_len+1; i++){
                context.moveTo(dx + sq_w * i, dy);
                context.lineTo(dx + sq_w * i, dy + h);
            }
            //横線
            for(i=0; i<y_len+1; i++){
                context.moveTo(dx,     dy + sq_h * i);
                context.lineTo(dx + w, dy + sq_h * i);
            }
            context.stroke();
            //駒描画
            let sqs     :cfunc.KRReversiSquare[][] = bd.squares,
                r       :number = sq_w / 2 * 0.9;
            //マウス位置描画
            if(this.ms_sq_x !== -1 && _my_side !== cfunc.PIECE_NONE && !_ctrl_lock){
                dx = this._position.x - this.width / 2 + this.margin + this.ms_sq_x * this._sq_width + this._sq_width / 2;
                dy = this._position.y - this.height / 2 + this.margin + this.ms_sq_y * this._sq_height + this._sq_height / 2;
                context.beginPath();
                context.fillStyle = this.getPuttableColor(_my_side);
                context.arc(dx, dy, r, 0, Math.PI*2, true);
                context.fill();
                context.stroke();
            }
            this.drawPieces(context, r);
            //puttable描画
            if(this.visibled_puttable){
                for(i=0; i < x_len; i++){
                    for(ii=0; ii < y_len; ii++){
                        dx = this._position.x - this.width / 2 + this.margin + i * this._sq_width + this._sq_width / 2;
                        dy = this._position.y - this.height / 2 + this.margin + ii * this._sq_height + this._sq_height / 2;
                        if(sqs[i][ii].piece === cfunc.PIECE_NONE && sqs[i][ii].puttable !== cfunc.PIECE_NONE &&
                            ((sqs[i][ii].puttable & _my_side) !== 0)){
                                context.beginPath();
                                context.fillStyle = this.getPuttableColor(sqs[i][ii].puttable);
                                context.arc(dx, dy, r, 0, Math.PI*2, true);
                                context.fill();
                                context.stroke();
                        }
                    }
                }
            }
        }
        /**BoardSprite2D
         * 現在のpieceと最終pieceから横方向のscaleを取得します。
         * Scale 1    <- 0   -> 1
         * Piece 1(B) <- 0.5 -> 2(W)
         */
        private getPieceScale(now_p:number, goal_p:number):number{
            let div_val :number = (cfunc.PIECE_WHITE - cfunc.PIECE_BLACK) / 2;
            //0.PIECE_BLACK分引く
            now_p = now_p - cfunc.PIECE_BLACK;
            goal_p = goal_p - cfunc.PIECE_BLACK;
            //1.div_valより低いか高いかで分岐
            if(now_p < div_val){//黒
                return (div_val - now_p) / div_val;
            }else
            if(now_p >=div_val){//白
                return (now_p - div_val) / div_val;
            }
            return 2;
        }
        /**BoardSprite2D
         * 配置中の駒を描画します。
         */
        private drawPieces(context:CanvasRenderingContext2D, r_def:number):void{
            let sqs     :cfunc.KRReversiSquare[][] = this._board.squares,
                ps      :cfunc.KRReversiSquare[][] = this._pieces,
                i       :number = 0,
                ii      :number = 0,
                dx      :number = 0,
                dy      :number = 0,
                x_len   :number = this._board.length_x,
                y_len   :number = this._board.length_y,
                scale   :number = 1;
            for(i=0; i < x_len; i++){
                for(ii=0; ii < y_len; ii++){
                    if(sqs[i][ii].piece !== cfunc.PIECE_NONE){
                        dx = this._position.x - this.width / 2 + this.margin + i * this._sq_width + this._sq_width / 2;
                        dy = this._position.y - this.height / 2 + this.margin + ii * this._sq_height + this._sq_height / 2;
                        if(ps[i][ii].piece !== sqs[i][ii].piece){
                            scale = this.getPieceScale(ps[i][ii].piece, sqs[i][ii].piece);
                        }else{
                            scale = 1;
                        }
                        context.beginPath();
                        context.fillStyle = this.getPieceColor(ps[i][ii].piece);
                        context.ellipse(dx, dy, r_def * scale, r_def, 0, 0, Math.PI*2, true);
                        context.fill();
                        context.stroke();
                    }
                }
            }
        }
        /**BoardSprite2D
         * マウスの位置にある盤面のマス目をindexで返します。
         * マス目上に無い場合は-1を返します。
         * @param mouse_x
         * @param mouse_y
         */
        public checkCollision(mouse_x:number, mouse_y:number):number{
            let left = this._position.x - this.width / 2 + this.margin,
                top = this._position.y - this.height / 2 + this.margin,
                len_x = this._board.length_x,
                len_y = this._board.length_y,
                sq_w = this._sq_width,
                sq_h = this._sq_height;
            //マス目左上を(0, 0)へもってく
            mouse_x = mouse_x - left;
            mouse_y = mouse_y - top;

            if(mouse_x < 0 || mouse_y < 0){
                this.ms_sq_x = -1;
                this.ms_sq_y = -1;
                return -1;
            }else{
                let m_len_x = Math.floor(mouse_x / sq_w),
                    m_len_y = Math.floor(mouse_y / sq_h);
                if(m_len_x >= len_x || m_len_y >= len_y){
                    this.ms_sq_x = -1;
                    this.ms_sq_y = -1;
                    return -1;
                }else{
                    if(_flg_rot_180){
                        m_len_x = len_x - 1 - m_len_x;
                        m_len_y = len_y - 1 - m_len_y;
                    }
                    this.ms_sq_x = m_len_x;
                    this.ms_sq_y = m_len_y;
                    return m_len_y * len_x + m_len_x;
                }
            }
        }
        /**BoardSprite2D
         * 指定位置における場合、trueを返します。
         * userEventで使用。サーバに送信する前のチェック用です。
         */
        public checkPutPiece(square_x:number, square_y:number, piece_side:number):boolean{
            return this._board.checkPutPiece(square_x, square_y, piece_side);
        }
        /**BoardSprite2D
         * ms_sq_x, ms_sq_yの位置に自分サイドの駒を配置します。
         */
        public putPiece(square_x:number, square_y:number, piece_side:number, func_do_anim_finished:()=>void):void{
            this._pieces[square_x][square_y].piece = piece_side;
            this.setAnimWait();
            this._func_do_anim_finished = func_do_anim_finished;
        }
        /**BoardSprite2D
         * 現在アニメ中の場合trueを返します。
         */
        public getNowAnimation():boolean{
            return this._func_do_anim_finished != null;
        }
    }
//STPlayerSprite///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    class StPlayerSprite2D extends KRSprite2D{
        public doDraw(context:CanvasRenderingContext2D):void{
            //プレイングデータが無ければ何もしない。
            if(!_read_only_playing_data)return;
            context.setTransform(1, 0, 0, 1, 0, 0);
            context.textAlign = "left";
            let i           :number = 0,
                players     :cfunc.PlayerObject[] = _read_only_playing_data.players,
                player      :cfunc.PlayerObject = null,
                len         :number = players.length,//1 or 2
                r_s         :cfunc.RoundStatusObject = _read_only_playing_data.round_status,
                board_w     :number = _sp_board.width,
                board_x     :number = (_canvas.width - board_w) / 2,
                //Face
                face_s      :number = _st_p_height - 2 * ST_P_PADDING < ST_P_FACE_MAX_SIZE ? _st_p_height - 2 * ST_P_PADDING : ST_P_FACE_MAX_SIZE,
                face_x      :number = board_x + ST_P_PADDING,
                face_y      :number = (_st_p_height - face_s) / 2,
                //name
                name_w      :number = _canvas.width - (face_s + ST_P_PADDING * 2),
                name_x      :number = face_x + face_s + ST_P_PADDING,
                name_y      :number = face_y,
                //mark
                mark_color  :string = "",
                mark_r      :number = 16,
                mark_cx     :number = face_x + face_s + ST_P_PADDING + mark_r,
                mark_cy     :number = face_y + face_s - mark_r,
                //count(駒の枚数)
                count       :number = 0,
                count_w     :number = _canvas.width - (face_s + ST_P_PADDING * 2),
                count_x     :number = mark_cx + mark_r + ST_P_PADDING,
                count_y     :number = mark_cy + mark_r,//bottomに合わせてbaselineをbottomにする
                //time
                time_w      :number = 32,
                time_x      :number = _canvas.width - (time_w + ST_P_PADDING * 2),
                time_y      :number = _st_p_height - 24,
                //round
                now_turn    :number = r_s.now_turn,
                now_order   :number = now_turn % 2,
                //temp
                add_y       :number = 0;
            //Bar color
            let rate = _limit.rate,
                r = -4/3 * rate + 4/3,
                g = 4 * rate - 1,
                b = 2 * rate - 1,
                a = 1;
            if(r > 1){r = 1;} else if(r < 0){r = 0;}; r = Math.floor(r * 255);
            if(g > 1){g = 1;} else if(g < 0){g = 0;}; g = Math.floor(g * 255);
            if(b > 1){b = 1;} else if(b < 0){b = 0;}; b = Math.floor(b * 255);
            let txt_bar_color = 'rgba(' + r + ',' + g + ',' + b + ',' + a + ')';
            //①プレイヤーステータス描画(0=black, 1=white)
            for(i=0;i<len;i++){
                player = players[i];
                //上か下かに描画
                if((!_flg_rot_180 && i === 0)||(_flg_rot_180 && i === 1)){
                    add_y = _canvas.height - _st_p_height;
                }else{
                    add_y = 0;
                }
                //顔描画
                context.fillStyle = 'rgb(128, 255, 255)';
                context.fillRect(face_x, face_y + add_y, face_s, face_s);
                context.drawImage(player.img_avater, face_x, face_y + add_y, face_s, face_s);
                //名前描画
                context.font = "bold 30px 'ＭＳ Ｐゴシック'";
                context.textBaseline = "top";
                context.fillText(player.name, name_x, name_y + add_y, name_w);
                //駒マーク描画
                context.beginPath();
                context.fillStyle = i === 0 ? "rgb(0, 0, 0)": "rgb(255, 255, 255)";
                context.strokeStyle = 'rgba(0, 0, 0, 1.0)';
                context.lineWidth = 1;
                context.arc(mark_cx, mark_cy + add_y, mark_r, 0, Math.PI*2, true);
                context.fill();
                context.stroke();
                //枚数描画
                context.font = "bold 30px 'ＭＳ Ｐゴシック'";
                context.textBaseline = "bottom";
                count = i === 0 ? _sp_board.getPiecesCount(cfunc.PIECE_BLACK) : _sp_board.getPiecesCount(cfunc.PIECE_WHITE);
                context.fillText("x" + count, count_x, count_y + add_y, count_w);
                //残り時間
                if(_read_only_playing_data.prog_status === ST_PLAYING && i === now_order && !_sp_board.getNowAnimation()){
                    let time    :string = Math.floor(_limit.now_msec / 1000).toString(),
                        time_x  :number = _canvas.width - 32,
                        time_y  :number = name_y + add_y;
                    context.textBaseline = "top";
                    context.fillStyle = txt_bar_color;
                    context.fillText(time, time_x, time_y, count_w);
                }
            }
            //③開始前"秒読み"描画
            if(_read_only_playing_data.prog_status === ST_RECRUIT){
                let time    :string = Math.floor(_limit.now_msec / 1000).toString(),
                    time_x  :number = _canvas.width / 2,
                    time_y  :number = _canvas.height / 2,
                    size    :number = 32;
                context.fillStyle = 'rgba(255, 0, 128, 1.0)';
                context.beginPath();
                context.arc(time_x, time_y, size * 4 / 5, 0, 2 * Math.PI, true);
                context.fill();
                context.stroke();
                context.font = "bold 40px 'ＭＳ Ｐゴシック'";
                context.textAlign = "center";
                context.textBaseline = "middle";
                context.fillStyle = 'rgba(255, 255, 255, 1.0)';
                context.fillText(time, time_x, time_y, size);
            }
            //④debug clientのPlayingDataの状態表示
            //drawPlayingDataProgStatusForDebug();
            //functions...
            function drawPlayingDataProgStatusForDebug(){
                let text_x = 2,
                    text_y = 98,
                    text_w = 160;
                context.font = "bold 24px 'ＭＳ Ｐゴシック'";
                context.textAlign = "left";
                context.textBaseline = "middle";
                context.fillStyle = 'rgba(255, 0, 0, 1.0)';
            }

        }
    }

//TextSprite///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class TextSprite2D extends KRSprite2D{
    private _text: string = "";
    private _msec: number = 0;
    private _func_do_display_finished: ()=>void = null;

    public setText(text:string, msec:number, func_do_display_finished:()=>void):void{
        this._text = text;
        this._msec = msec;
        this._func_do_display_finished = func_do_display_finished;
    }

    public getNowDisplay():boolean{
        return this._func_do_display_finished != null;
    }

    public doMove(elapsed: number):void{
        if(this._msec === 0) return;
        this._msec = this._msec - elapsed;
        if(this._msec <= 0){
            if(this._func_do_display_finished != null){
                this._func_do_display_finished();
                this._func_do_display_finished = null;
            }
            this._msec = 0;
        }
    }

    public doDraw(context:CanvasRenderingContext2D):void{
        if(this._msec === 0) return;
        let text_x = _canvas.width / 2,
            text_y = _canvas.height / 2,
            text_w = 80,
            add_y = _sp_board.height / 4,
            side = _read_only_playing_data.round_status.now_turn % 2;
        if(!((!_flg_rot_180 && side === 0)||(_flg_rot_180 && side === 1))){
            add_y = -add_y;
        }
        context.font = "bold 40px 'ＭＳ Ｐゴシック'";
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillStyle = 'rgba(255, 0, 0, 1.0)';
        context.fillText(this._text, text_x, text_y + add_y, text_w);
    }

}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //フレーム更新用関数
    function updateFrame(interval:number):void{
        _frame_timer.id = window.setTimeout(function(){
            let now_msec = Date.now();
            let elapsed = now_msec - _frame_timer.old_msec;
            _mgr_sprite.move(elapsed);//msec
            _frame_timer.old_msec = now_msec;
            _mgr_sprite.draw();
            updateFrame(interval);
        }, interval);
    }
    /**
     * 初期設定を行います。必ず一度だけ行われます。
     * @param {Object} parent_element _screenを追加するための要素(Element)
     * @param {Function} func_do_my_put_action パスOr札を出すボタンを押した時に実行する関数。
     * @param {Function} func_finished 設定が完了した際に呼び出したい関数。
     */
    function setup(parent_element:HTMLElement, board:cfunc.KRReversiBoard, func_do_my_put_action:(putdown_indexs:number[])=>void, func_finished:()=>void){
        if(_first_setup) return;
        _first_setup = true;
        _func_do_my_put_action = func_do_my_put_action;
        //親エレメント
        _parent_elm = parent_element;
        //スクリーン
        _screen = document.createElement("div");
        _screen.style.position = "fixed";
        _screen.style.backgroundColor = "black";
        //キャンバス
        _canvas = document.createElement("canvas");
        _canvas.style.position = "absolute";
        _canvas.style.left = "0px";//※中央ぞろえにするにはtop,left,right,bottomも指定しないと適用されない。
        _canvas.style.top = "0px";
        _canvas.style.right = "0px";
        _canvas.style.bottom = "0px";
        _canvas.style.margin = "auto";
        _canvas.style.backgroundColor = "gray";
        //_canvas.setAttribute("width", "512px");//キャンバスのサイズ（横）を変える！
        //_canvas.setAttribute("height", "384px");//キャンバスのサイズ（縦）を変える！
        //_canvas.style.width = "512px";   //こっちはキャンバスの縮尺を変える！
        //_canvas.style.height = "240px";  //こっちはキャンバスの縮尺を変える！
        _screen.appendChild(_canvas);

        //スプライトマネージャ
        _mgr_sprite = new KRSprite2DManager(_canvas);
        //スプライトマネージャにカスタムスプライトを登録
        _mgr_sprite.registSpriteClass(BoardSprite2D, "BoardSprite2D");
        _mgr_sprite.registSpriteClass(ButtonSprite2D, "ButtonSprite2D");
        _mgr_sprite.registSpriteClass(StPlayerSprite2D, "StPlayerSprite2D");
        _mgr_sprite.registSpriteClass(TextSprite2D, "TextSprite2D");
        //ボード作成
        _sp_board = _mgr_sprite.createSprite("BoardSprite2D", null, null, 0, 0, 1) as BoardSprite2D;
        _sp_board.setBoard(board);
        _sp_board.visibled = true;

        //debug
        /*
        //ボタン作成
        _sp_button = _mgr_sprite.createSprite(ButtonSprite2D, null, null, 0, 0, 1) as ButtonSprite2D;
        _sp_button.setSize(BTN_WIDTH, BTN_HEIGHT);
        */

        //プレイヤーST作成
        _sp_st_players = _mgr_sprite.createSprite("StPlayerSprite2D", null, null, 0, 0, 2);
        //テキスト作成
        _sp_text = _mgr_sprite.createSprite("TextSprite2D", null, null, 0, 0, 2) as TextSprite2D;
        //setup終了後の処理
        if(func_finished){
            func_finished();
        }

    }
    /**
     * キャンバスの位置とサイズを設定します。値は
     * 全体（ブラウザ）のレイアウトとサイズに依存します。
     * @param {number} px_left
     * @param {number} px_top
     * @param {number} px_width
     * @param {number} px_height
     * @param {number} scale touchEventで必要です。
     */
    function setPositionAndSize(px_left:number, px_top:number, px_width:number, px_height:number, scale:number):void{
        _screen_values.now_left = px_left;
        _screen_values.now_top = px_top;
        _screen_values.now_scale = scale;
        if(_screen){
            _screen.style.left = px_left + "px";
            _screen.style.top =  px_top + "px";
            _screen.style.width = px_width + "px";      //縮尺が変わる
            _screen.style.height = px_height + "px";    //縮尺が変わる
        }
        if(_canvas){
            _canvas.width = px_width;                   //実際のサイズが変わる
            _canvas.height = px_height;                 //実際のサイズが変わる
        }
        //ステータス部、ボード部のサイズ設定
        //短い方を取得
        let b_size:number = px_width < px_height ? px_width : px_height;
        //ステータス部の高さ設定
        let min_h   :number = ST_P_MIN_HEIGHT + ST_P_PADDING * 2;//最小サイズ
        _st_p_height = (px_height - b_size) / 2;
        if(_st_p_height < min_h){
            _st_p_height = min_h;
            b_size = px_height - _st_p_height * 2;
        }
        //ボード設定
        if(_sp_board){
            _sp_board.setSize(b_size, b_size);
            _sp_board.setPosition(px_width / 2, px_height / 2); //centerに合わせる
        }
        //ボタン配置位置
        if(_sp_button){
            _sp_button.setPosition(px_width / 2, px_height * 5 / 6);
        }
    }
    /**
     * 大富豪のUIの表示・非表示を設定します。
     * @param {boolean} value
     * @param {PlayingDataObject} playing_data read only
     */
    function setActive(value:boolean, playing_data:cfunc.PlayingDataObject){
        if(_activated === value) return;
        _activated = value;
        if(_activated){
            _read_only_playing_data = playing_data;
            //表示
            _screen.addEventListener(EVENTNAME_TOUCHSTART, userEvent);
            _screen.addEventListener(EVENTNAME_TOUCHMOVE, userEvent);
            _screen.addEventListener(EVENTNAME_TOUCHEND, userEvent);
            _parent_elm.appendChild(_screen);
            _frame_timer.old_msec = Date.now();
            updateFrame(33);
        }else{
            _read_only_playing_data = null;
            _my_player = null;
            //非表示
            _screen.removeEventListener(EVENTNAME_TOUCHSTART, userEvent);
            _screen.removeEventListener(EVENTNAME_TOUCHMOVE, userEvent);
            _screen.removeEventListener(EVENTNAME_TOUCHEND, userEvent);
            _parent_elm.removeChild(_screen);   //node.parentNode.removeChild(node);//node = Element
            clearTimeout(_frame_timer.id);
            setButtonVisibled(false);
        }
    }
//CardSprites controll///////////////////////////////////////////////////////////////////////////
    /**
     * @param {boolean} value
     */
    function setButtonVisibled(value:boolean):void{
        if(_sp_button){
            _sp_button.visibled = value;
        }
    }
    /**
     * 残り時間を設定します。現在の状態により、表示方法等が異なります。
     * @param {number} now_msec 残り時間
     * @param {number} max_msec 最大値
     */
    function setlimitTime(now_msec:number, max_msec:number):void{
        _limit.now_msec = now_msec;
        _limit.max_msec = max_msec;
        _limit.rate = now_msec / max_msec;
    }
    /**
     * PlayingDataObjectにplayerが追加される際、プレイヤーのImageを追加するために呼び出される関数です。
     * @param {Image} image
     */
    function setPlayerImage(image:HTMLImageElement){
        //clientの方で設定するのでいらない
    }
    /**自分のクライアントのユーザー設定
     * @param {string} my_username
     */
    function setMyUserName(my_username:string):void{
        _my_username = my_username;
    }
    /**
     * 自ユーザーがゲームに参加したら設定します。
     * Activeをfalseにすると、自動でnullに置き換えられます。
     * @param {PlayerObject} player
     */
    function setMyPlayer(player:cfunc.PlayerObject):void{
        _my_player = player;
    }
    /**
     * 現在の表示状態（大貧民実行中）を取得します。
     * 表示中の場合、trueを返します。
     */
    function getActivated():boolean{
        return _activated;
    }
    /**
     * コントロールをロックします。
     * trueでロック。falseで解除します。
     * @param {boolean} value
     */
    function controllLock(value:boolean):void{
        _ctrl_lock = value;
    }
    /**
     * 自分がプレイヤーになった時の黒、白設定を行います。
     * 黒以外は、盤の描画が180°、プレイヤー描画位置が反転します。
     * ※途中入室のユーザは黒と同じ方向となる
     */
    function setMySide(my_side:number):void{
        _my_side = my_side;
        if(_my_side !== cfunc.PIECE_BLACK){
            _flg_rot_180 = true;
        }else{
            _flg_rot_180 = false;
        }
    }
    /**
     * ピースを置きます。その後ひっくり返しアニメが実行されます。
     * 置けるかどうかのチェック等は行いません
     */
    function putPiece(square_x:number, square_y:number, piece_side:number, func_do_anim_finished:()=>void):void{
        _sp_board.putPiece(square_x, square_y, piece_side, func_do_anim_finished);
    }
    /**
     * パスを表示します。
     */
    function displayPassTurn(text:string, msec:number, func_do_display_finished:()=>void):void{
        _sp_text.setText(text, msec, func_do_display_finished);
    }
    /**
     * sp_boardのboardとpiecesをシンクロさせます。
     * kr_reversi_clientからClearGameDataから呼び出されます。
     */
    function synchroPieces():void{
        _sp_board.synchroPieces();
    }
////////////////////////////////////////////////////////////////////////////////////////////////
    return {
        setup,
        setActive,
        activated: getActivated,
        setButtonVisibled,
        setPositionAndSize,
        setlimitTime,
        setPlayerImage,//プレイングデータにプレイヤーが追加されたら、プレイヤーのイメージの追加を行わなきゃなのでその関数。
        setMyUserName,
        setMyPlayer,
        controllLock,
        setMySide,
        putPiece,
        displayPassTurn,
        synchroPieces
    }

}
