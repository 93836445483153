import * as cfunc from "../../common/cfunc_daifugou";
import {IArgmentChatData} from "../../common/ctypes";
import {ICustomClientSocket} from "../../common/ctypes";
import KRDaifugouUI from "./kr_daifugou_ui";
import {ILimitTime} from "./mgr_trump_game_ui";


export default function KRMgrDaifugouForClient(socket:ICustomClientSocket, dialog_default_width:number){
    "use strict";
    //CONST----------------------------------------------------------------------------------------
    //label
    const LBL_SYMBOL_LOCK = 'しばり';
    const LBL_DISCARD_8 = '８切り';
    const LBL_MUST_ALWAYS_WIN = '都落ち';
    const LBL_ELEVEN_BACK = 'イレブンバック';
    const LBL_S3_WIN_JOKER = 'スペードの３はジョーカーに勝つ';

    const ST_STANDBY          = cfunc.ST_STANDBY;            //待機中...Game開始できますよ。
    const ST_RECRUIT          = cfunc.ST_RECRUIT;            //募集中
    const ST_PLAYING          = cfunc.ST_PLAYING;            //プレイ中
    const ST_DISPLAY_RESULT   = cfunc.ST_DISPLAY_RESULT;     //結果表示中
    const ST_EXCHANGE_CARDS   = cfunc.ST_EXCHANGE_CARDS;     //カード交換中
    const ST_GAME_OVER        = cfunc.ST_GAME_OVER;          //ゲームオーバー中
    const ST_DONE             = cfunc.ST_DONE;               //あがり
    const ST_DISCONNECT       = cfunc.ST_DISCONNECT;         //落ちた
    //request_id
    const REQ_ID_RECRUIT      = cfunc.REQ_ID_RECRUIT;        //参加者募集
    const REQ_ID_JOIN_GAME    = cfunc.REQ_ID_JOIN_GAME;      //参加者する Player or watcher
    const REQ_ID_DEAL_CARDS   = cfunc.REQ_ID_DEAL_CARDS;     //カード（内容）を配る＝ゲーム開始、次ラウンド開始
    const REQ_ID_CARD_LENGTHS = cfunc.REQ_ID_CARD_LENGTHS;   //player全員のカード枚数を全員に配る
    const REQ_ID_EXCHANGE_CARDS = cfunc.REQ_ID_EXCHANGE_CARDS;   //カード交換（clientからは大富豪、富豪からのみ。serverからは平民以外に送信）
    const REQ_ID_START_ROUND  = cfunc.REQ_ID_START_ROUND;    //ラウンドスタート
    const REQ_ID_PUTDOWN_CARDS = cfunc.REQ_ID_PUTDOWN_CARDS;  //カードを捨てる
    const REQ_ID_PASS_TURN    = cfunc.REQ_ID_PASS_TURN;      //パスする
    const REQ_ID_END_ROUND    = cfunc.REQ_ID_END_ROUND;      //ラウンド終了
    const REQ_ID_RESULT_CLOSE = cfunc.REQ_ID_RESULT_CLOSE;   //次ラウンド準備OK
    const REQ_ID_END_GAME     = cfunc.REQ_ID_END_GAME;       //ゲーム終了
    const REQ_ID_CANCEL_GAME  = cfunc.REQ_ID_CANCEL_GAME;    //何かしらの理由によりゲーム続行不可
    const REQ_ID_JOIN_IN_MIDWAY = cfunc.REQ_ID_JOIN_IN_MIDWAY;//途中入室したユーザーに送る用
    const REQ_ID_LEAVE_IN_MIDWAY = cfunc.REQ_ID_LEAVE_IN_MIDWAY;//途中退室したことを全ユーザーに送る用
    //ranking
    const RANKING_DAI_HINMIN  = cfunc.RANKING_DAI_HINMIN;
    const RANKING_HINMIN      = cfunc.RANKING_HINMIN;
    const RANKING_HEIMIN      = cfunc.RANKING_HEIMIN;
    const RANKING_FUGOU       = cfunc.RANKING_FUGOU;
    const RANKING_DAI_FUGOU   = cfunc.RANKING_DAI_FUGOU;
    //Mark
    const MK_SPADE            = cfunc.MK_SPADE;              //♤
    const MK_HEART            = cfunc.MK_HEART;              //♡
    const MK_CLUB             = cfunc.MK_CLUB;               //♧
    const MK_DIAMOND          = cfunc.MK_DIAMOND;            //♢
    const MK_JOKER            = cfunc.MK_JOKER;              //ジョーカー
    //役
    const HRK_GARBAGE         = cfunc.HRK_GARBAGE;           //役なし
    const HRK_SINGLE          = cfunc.HRK_SINGLE;            //シングル
    const HRK_DOUBLE          = cfunc.HRK_DOUBLE;            //ダブル
    const HRK_TRIPLE          = cfunc.HRK_TRIPLE;            //トリプル
    const HRK_FOURTH          = cfunc.HRK_FOURTH;            //フォース（革命 consecutive number
    const HRK_CONS_3          = cfunc.HRK_CONS_3;            //連続３
    const HRK_CONS_4          = cfunc.HRK_CONS_4;            //連続４（革命 consecutive number

    //EVENT
    const EV_NONE             = cfunc.EV_NONE;           //何でもない、何もしない
    const EV_KAKUMEI          = cfunc.EV_KAKUMEI;        //革命！
    const EV_ELEVEN_BACK      = cfunc.EV_ELEVEN_BACK;    //イレブンバック！
    const EV_DISCARD_8        = cfunc.EV_DISCARD_8;      //８切り！
    const EV_SYMBOL_LOCK      = cfunc.EV_SYMBOL_LOCK;    //しばり！
    const EV_MIYAKO_OCHI      = cfunc.EV_MIYAKO_OCHI;    //都落ち！
    const EV_CLEAN_TABLE      = cfunc.EV_CLEAN_TABLE;    //カード流し
    const EV_PLAYER_DONE      = cfunc.EV_PLAYER_DONE;    //今のプレイヤーが上がった
    const EV_NEXT_TURN        = cfunc.EV_NEXT_TURN;      //次のプレイヤーの番
    const EV_END_ROUND        = cfunc.EV_END_ROUND;      //ラウンド終了
    const EV_END_GAME         = cfunc.EV_END_GAME;       //ゲーム終了
    const EV_S3_WIN_JOKER     = cfunc.EV_S3_WIN_JOKER;   //♠3はジョーカーに勝つ
    //exchange
    const EXCHANGE_TOP_LENGTH = cfunc.EXCHANGE_TOP_LENGTH;
    const EXCHANGE_SECOND_LENGTH = cfunc.EXCHANGE_SECOND_LENGTH;
    //Other
    const GAME_OVER_INTERVAL  = cfunc.GAME_OVER_INTERVAL;
    const ROUND_INTERVAL      = cfunc.ROUND_INTERVAL;

//--------------------------------------------------------------------------------------------------------------------------------
    //TYPES----------------------------------------------------------------------------------------
    /**
     * @typedef {{index:number,num:number,mark:number,selected:boolean}} CardObject
     * @typedef {{rank:number,exist_joker:boolean,cards:CardObject[]}} PutDownCardsContentObject
     * @typedef {{symbol_lock:number,discard_8:number,must_always_win:number,eleven_back:number,s3_win_joker:number}} OptionRulesObject
     * @typedef {{recruit_time:number,players_count:number,round_count:number,thinking_time:number}} SettingsObject
     * @typedef {{now_round:number,now_turn:number,now_order_name:string,latest_putter_name:string,putdown_history:PutDownCardsContentObject[],rankers:string[],now_kakumei:boolean,now_symbol_lock:number,now_eleven_back:number}} RoundStatusObject
     * @typedef {{name:string,img_avater_index:number,img_avater:Image,ranking:number,status:number,score:number[],cards:CardObject[],card_length:number}} PlayerObject
     * @typedef {{prog_status:number,settings:SettingsObject,option_rules:OptionRulesObject,round_status:RoundStatusObject,players:PlayerObject[]}} PlayingDataObject
     * @typedef {{max:SettingsObject,min:SettingsObject,def:SettingsObject,per:SettingsObject,val:SettingsObject}} SettingsSetObject
     */
//---------------------------------------------------------------------------------------------------------------------------------
    /**@description 出したカードをチャットで表示する関数 mainから貰う */
    let _func_add_chat_message  :(value:IArgmentChatData)=>void = null;//関数型は引数と戻り値を宣言する。
    /**@description カードの効果やメッセージを表示する関数 mainから貰う */
    let _func_add_log_message   :(message:string, options?:any)=>void = null;//関数型は引数と戻り値を宣言する。
    /**@description UIの表示・非表示の変化時に実行する関数。mainから貰う */
    let _func_change_rayout     :()=>void = null;//関数型は引数と戻り値を宣言する。
    /**@description mainが管理してるアバター画像を取得する。mainから貰う */
    let _func_get_img_avater    :(index:number)=>HTMLImageElement = null;//関数型は引数と戻り値を宣言する。
    /**@description windowのscaleが１未満だと、表示位置が左にずれ込むのでそれを補正する関数。mainから貰う */
    let _func_set_pos_dlg       :(dlg:any)=>void = null;//関数型は引数と戻り値を宣言する。
    /**@description 自分のソケット 作成時に引数で貰います*/
    let _my_socket = socket;
    /**@description ダイアログの基本幅 作成時に引数で貰います*/
    let _dialog_default_width = dialog_default_width;
    /**@description 現在プレイヤーとして参加してます */
    let _joined_game = false;
    /**@description タイマーをまとめたもの */
    let _my_timer = {id: 0,
                     max_msec: 0,
                     start_msec: 0
                    };
    /**@description 設定用・基本設定
     * @type {SettingsSetObject} _settings_set
     */
    let _settings_set :cfunc.SettingsSetObject = null;
    /**@description 設定用・オプションルール設定
     * @type {OptionRulesObject} _option_rules
     */
    let _option_rules :cfunc.OptionRulesObject = null;
    /**@description REQ_ID_RECRUITでサーバ側MgrDaifugouから貰うデータ保存場所
     * @type {PlayingDataObject} _playing_data
     */
    let _playing_data :cfunc.PlayingDataObject = null;
    /**@description カード（53枚）を収納しておく変数
     * @type {CardObject[]} _card_case
     */
    let _card_case :cfunc.CardObject[] = cfunc.createCardAll();
    /**@description pass, putdowncardsした時のイベント内容
     * @type {Array} _event_list
     */
    let _event_list:number[]  = [];
    /**@description ユーザーインターフェース
     * @type {KRDaifugouUI} _ui
     */
    let _ui = KRDaifugouUI();
    //Dialogs
    let $dlgDaifugouSettings    :JQuery = null;
    let $dlgDaifugouRecruit     :JQuery = null;
    let $dlgDaifugouResult      :JQuery = null;
    //$dlgDaifugouRecruit.dialog('isOpen');
    //Dialog Settings
    let $dlg_parts_settings: {
        lst_recruit_time        : JQuery,
        lst_players_count       : JQuery,
        lst_round_count         : JQuery,
        lst_thinking_time       : JQuery,
        chk_symbol_lock         : JQuery,
        lst_symbol_lock_count   : JQuery,
        chk_discard_8           : JQuery,
        chk_must_always_win     : JQuery,
        chk_eleven_back         : JQuery,
        chk_s3_win_joker        : JQuery
    } = {
        lst_recruit_time        : null,
        lst_players_count       : null,
        lst_round_count         : null,
        lst_thinking_time       : null,
        chk_symbol_lock         : null,
        lst_symbol_lock_count   : null,
        chk_discard_8           : null,
        chk_must_always_win     : null,
        chk_eleven_back         : null,
        chk_s3_win_joker        : null
    };
    //Dialog Recruit
    let $dlg_parts_recruit : {
        default_rules   : JQuery,
        option_rules    : JQuery,
        players_count   : JQuery,
        time            : JQuery
    } = {
        default_rules   : null,
        option_rules    : null,
        players_count   : null,
        time            : null
    };
    //Dialog Result
    let $dlg_parts_result : {
        tbl_head        : JQuery,
        tbl_body        : JQuery,
        time            : JQuery
    } =  {
        tbl_head        : null,
        tbl_body        : null,
        time            : null
    };
    const DLG_RESULT_WIDTH_BASE = 172;
    const DLG_RESULT_WIDTH_PER_ROUND_COUNT = 46;
    const DLG_RESULT_MIN_WIDTH = 360;
    //User
    let _my_username = "";

    //FUNCTIONS-----------------------------------------------------------------------------
    /**
     * ev_indexで指定したイベントのメッセージを取得します。
     * @param {number} ev_index
     * @return {string}
     */
    function getEventMessage(ev_index:number):string{
        switch(ev_index){
            case(EV_KAKUMEI):          return "革命！";
            case(EV_ELEVEN_BACK):      return "イレブンバック！";
            case(EV_DISCARD_8):        return "８切り！";
            case(EV_SYMBOL_LOCK):      return "しばり！";
            case(EV_MIYAKO_OCHI):
                let players = _playing_data.players,
                    p_index = cfunc.indexOfPlayers('ranking', RANKING_DAI_HINMIN, players),
                    p_name = "";
                if(p_index !== -1){
                    p_name = players[p_index].name;
                }
                return p_name + "は都落ち！";
            case(EV_CLEAN_TABLE):      return "カード流し";
            case(EV_PLAYER_DONE):      return "あがり！";
            case(EV_NEXT_TURN):        return "";
            case(EV_END_ROUND):        return "ラウンド終了！";
            case(EV_END_GAME):         return "ゲーム終了！";
            case(EV_S3_WIN_JOKER):     return "♠3はジョーカーに勝つ！";
            default: return "";
        }
    }
    /**
     * パスもしくはカード出しをしたことで発生したイベントの内容を
     * メッセージとしてチャットに表示します。
     * ※現在使ってません
     * @param {string} username パスもしくはカードを出したプレイヤーです。※pass(),putDownCards()でnow_order_nameは変更されてしまうため
     * @param {number} img_avater_index
     */
    function addEventMessageToChat(username:string, img_avater_index:number):void{
        let i = 0,
            len = _event_list.length,
            chat_data   :IArgmentChatData = {
                username,
                img_avater_index,
                message: ""
            };
        for(i=0; i<len; i++){
            chat_data.message = getEventMessage(_event_list[i]);
            if(chat_data.message !== ""){
                _func_add_chat_message(chat_data);
            }
        }
    }
    /**
     * パスもしくはカード出しをしたことで発生したイベントの内容を
     * ログとしてチャットに表示します。
     */
    function addEventLogToChat():void{
        let i = 0,
            len = _event_list.length,
            message = "";
        for(i=0; i<len; i++){
            message = getEventMessage(_event_list[i]);
            if(message !== ""){
                _func_add_log_message(message);
            }
        }
    }
    /**
     * UIの"パス"もしくは"札を出す"もしくは"交換"ボタンを押した際に実行する関数。
     * kr_daifugou_uiから呼び出して使う関数。-> _ui.setup()の引数とする。
     * 行動が成功（パスOr札を出せるOr交換）した場合、Trueを返す。
     * @param {number[]} putdown_indexs
     * @return {boolean}
     */
    function doMyAction(putdown_indexs:number[]):boolean{
        switch(_playing_data.prog_status){
            case ST_PLAYING:
                return actionPutDown(putdown_indexs);
            case ST_EXCHANGE_CARDS:
                return actionExchange(putdown_indexs);
        }
    }
    /**
     * カード（パスOr札を出せる）をサーバに送信する関数。
     * 行動が成功（パスOr札を出せる）した場合、Trueを返す。
     * @param {number[]} putdown_indexs 送信するカード数の配列。0の場合はパスと同義。
     * @return {boolean}
     */
    function actionPutDown(putdown_indexs:number[]):boolean{
        //パスの場合・・・
        if(putdown_indexs.length === 0){
            _my_socket.emit('daifugou', {
                request_id: REQ_ID_PASS_TURN
            });
            return true;
        }
        //札を出す場合・・・
        //カードの取得チェック
        let pickup_cards:cfunc.CardObject[] = [];
        if(!cfunc.pickupCardsFromNowOrderPlayer(_playing_data, _card_case, pickup_cards, putdown_indexs)){
            cfunc.undoCardsToNowOrderPlayer(pickup_cards, _playing_data, _card_case);
            return false;
        }
        //カードの整合性（役）チェック
        let card_content = cfunc.analyzeRankOfCards(pickup_cards, _playing_data);
        if(card_content.rank === HRK_GARBAGE){
            cfunc.undoCardsToNowOrderPlayer(pickup_cards, _playing_data, _card_case);
            card_content.cards = null;
            return false;
        }
        //カードの提出可能チェック
        if(!cfunc.checkEnablePutDownCards(card_content, _playing_data)){
            cfunc.undoCardsToNowOrderPlayer(pickup_cards, _playing_data, _card_case);
            card_content.cards = null;
            return false;
        }
        //出せるよ
        cfunc.undoCardsToNowOrderPlayer(pickup_cards, _playing_data, _card_case);//上記はチェックだけなので元に戻しておく
        card_content.cards = null;
        _my_socket.emit('daifugou', {
            request_id: REQ_ID_PUTDOWN_CARDS,
            putdown_indexs
        });
        return true;
     }
     /**
     * カード（交換）をサーバに送信する関数。
     * 行動が成功（交換）した場合、Trueを返す。
     * @param {number[]} putdown_indexs 送信するカード数の配列。
     * @return {boolean}
     */
    function actionExchange(putdown_indexs:number[]){
        let players = _playing_data.players,
            p_index = cfunc.indexOfPlayers('name', _my_username, players);
        //プレイヤーか？
        if(p_index === -1){
            return false;
        }
        //大富豪Or富豪か？
        let player = players[p_index];
        if(player.ranking !== RANKING_DAI_FUGOU && player.ranking !== RANKING_FUGOU){
            return false;
        }
        //正しい枚数か？
        if(player.ranking === RANKING_DAI_FUGOU && putdown_indexs.length !== EXCHANGE_TOP_LENGTH){
            return false;
        }else
        if(player.ranking === RANKING_FUGOU && putdown_indexs.length !== EXCHANGE_SECOND_LENGTH){
            return false;
        }
        //出せるよ
        _my_socket.emit('daifugou', {
            request_id: REQ_ID_EXCHANGE_CARDS,
            putdown_indexs
        });

        return true;
    }

    /**
     * サーバ側MgrDaifugouからの通信を処理します
     * @param {Object} data {request_id:number, +α}
     * @api public
     */
    function socketEvent(data:cfunc.IArgmentSocketEvent){
        //console.log("From server... request_id =" + data.request_id);
        switch(data.request_id){
            case REQ_ID_RECRUIT:        //募集を添付Dataの内容でかけます。    (mgr)
                reqOpenDialogRecruit(data);
                break;
            case REQ_ID_JOIN_GAME:      //ゲームに参加します。                (User)
                reqJoinGame(data);
                break;
            case REQ_ID_DEAL_CARDS:     //カード（内容）を配る＝毎ラウンド開始時(mgr)
                reqDealMyCards(data);
                break;
            case REQ_ID_CARD_LENGTHS:   //player全員のカード枚数を配る＝毎ラウンド開始時(mgr)
                reqSetCardLengths(data);
                break;
            case REQ_ID_EXCHANGE_CARDS: //カードの交換をします。
                reqExchangeCards(data);
                break;
            case REQ_ID_START_ROUND:    //ラウンドを開始します。              (mgr)
                reqStartRound(data);
                break;
            case REQ_ID_PUTDOWN_CARDS:  //添付Dataのカードを処理します。      (Player)
                reqPutDownCards(data);
                break;
            case REQ_ID_PASS_TURN:      //パスします                          (Player)
                reqPassTurn(data);
                break;
            case REQ_ID_END_ROUND:      //ラウンドを終了します。  -> REQ_ID_PUTDOWN_CARDSで代用できる。
                break;
            case REQ_ID_RESULT_CLOSE:   //結果表を閉じます。      -> これはいらない
                break;
            case REQ_ID_END_GAME:       //ゲームを終了します。    -> 終了かどうかはplaying_dataみればわかる。
                break;
            case REQ_ID_CANCEL_GAME:    //何らかの理由でゲームを中断します。  (mgr)
                reqCancelGame(data);
                break;
            case REQ_ID_JOIN_IN_MIDWAY: //途中入室した時送られてくる
                reqJoinInMidway(data);
                break;
            case REQ_ID_LEAVE_IN_MIDWAY: //Playerが抜けた時に送られてくる
                reqLeaveInMidway(data);
                break;
        }
    }
    /**
     * 募集用ダイアログを開きます。
     * @param {{request_id:number,playing_data:PlayingDataObject,start_msec:number}} data
     */
    function reqOpenDialogRecruit(data:cfunc.IArgmentSocketEvent){
        closeDialogAll();
        clearTimeout(_my_timer.id);//妥当ではない ID を渡しても、何の効果もありません（また、例外はスローされません）。
        //playing_dataの設定
        setPlayingDataAndUI(data.playing_data);
        //ダイアログの設定
        let players = _playing_data.players,
            s = _playing_data.settings,
            o = _playing_data.option_rules,
            txt = "";
        //表示内容変更
        txt = '<legend >基本</legend>'+
                '<div>・プレイ人数 ' + '<span  class="players_count">' + players.length + '</span>' + '/' + s.players_count + '名</div>'+
                '<div>・ラウンド数 ' + s.round_count + '回</div>'+
                '<div>・思考時間 ' + s.thinking_time + '秒</div>';

        $dlg_parts_recruit.default_rules.empty();
        $dlg_parts_recruit.default_rules.append(txt);

        txt = '<legend >追加ルール</legend>';
        if(o.symbol_lock > 0){
            txt = txt + '<div>・' + LBL_SYMBOL_LOCK + ' ' + (o.symbol_lock + 1) +'連続</div>';
        }
        if(o.discard_8 > 0){
            txt = txt + '<div>・' + LBL_DISCARD_8 + '</div>';
        }
        if(o.must_always_win > 0){
            txt = txt + '<div>・' + LBL_MUST_ALWAYS_WIN + '</div>';
        }
        if(o.eleven_back > 0){
            txt = txt + '<div>・' + LBL_ELEVEN_BACK + '</div>';
        }
        if(o.s3_win_joker > 0){
            txt = txt + '<div>・' + LBL_S3_WIN_JOKER + '</div>';
        }
        $dlg_parts_recruit.option_rules.empty();
        $dlg_parts_recruit.option_rules.append(txt);

        $dlg_parts_recruit.players_count= $('.j-ui-dialog.dlgDaifugouRecruit .players_count');

        //タイマーセット
        setTimer(data.start_msec, s.recruit_time * 1000, updateRecruitTime);

        openDialogRecruit();
    }
    /**
     * playing_dataを設定します。
     * @param {PlayingDataObject} playing_data
     */
    function setPlayingDataAndUI(playing_data: cfunc.PlayingDataObject){
        _playing_data = playing_data;
        _ui.setActive(true, _playing_data);
        //レイアウト変更
        if(_func_change_rayout){
            _func_change_rayout();
        }
    }
/* プロパティの追加方法
    interface ICustomSocket extends SocketIOClient.Socket{
        username: string;
    }
    var ggg:ICustomSocket = io() as ICustomSocket;
*/
    /**
     * Playerを登録します。
     * player.img_avater_indexからplayer.img_avaterに画像を読み込みます。
     * @param {{request_id:number, player:PlayerObject}} data
     */
    function reqJoinGame(data:cfunc.IArgmentSocketEvent){
        let players = _playing_data.players,
            player = data.player;
        //img_avaterの設定
        player.img_avater = _func_get_img_avater(player.img_avater_index);
        //登録
        players.push(player);
        //dialog更新
        $dlg_parts_recruit.players_count.text(players.length);
        //自分？
        if(player.name === _my_socket.username){
            _joined_game = true;
            _ui.setMyPlayer(player);
        }
    }
    /**
     * 全員のカード枚数をセットし、開始順番を設定します。毎ラウンドきます。
     * @param {{request_id:number, lengths: [number], start_msec: [number], now_order_name:string}} data
     */
    function reqSetCardLengths(data:cfunc.IArgmentSocketEvent){
        clearTimeout(_my_timer.id);//妥当ではない ID を渡しても、何の効果もありません（また、例外はスローされません）。
        closeDialogAll();
        cfunc.initDataBeforeNextRound(_playing_data, _card_case);//これやったらカード情報がクリアされる
        cfunc.setNextRound(_playing_data);
        let players = _playing_data.players,
            i = 0,
            len = players.length;
        if(len !== data.lengths.length){
            console.log("Error: reqSetCardLengths():Players_Length -> client = " + len + ",server = " + data.lengths.length);
        }
        for(i = 0; i < len; i++){
            players[i].card_length = data.lengths[i];
        }
        //誰から始めるかセットする
        _playing_data.round_status.now_order_name = data.now_order_name;
        //setNextRoundを実行すればplaying_data.prog_statusがTS_STANDBY Or ST_EXCHANGE_CARDSになるので
        //それで判断する。
        //タイマーをセットする(とりあえず、exchange時間も同じでｂ)
        setTimer(data.start_msec, _playing_data.settings.thinking_time * 1000, updateThinkingTime);//シンキングタイム

        let btn_visible_value = false;
        if(_playing_data.prog_status === ST_STANDBY){//交換なしにすぐ開始
            cfunc.setStartRound(_playing_data);
            _func_add_log_message('ラウンド' + _playing_data.round_status.now_round + ' スタート！');
            btn_visible_value = _playing_data.round_status.now_order_name === _my_username;
        }else{//交換
            //プレイヤーで且つ、大富豪、富豪で、大貧民、貧民が存在するのが条件
            let p_index = cfunc.indexOfPlayers('name', _my_username, players);
            if(p_index !== -1){
                let my_ranking = players[p_index].ranking;
                let i_am_winner = my_ranking === RANKING_DAI_FUGOU || my_ranking === RANKING_FUGOU;
                let loser_ranking = my_ranking === RANKING_DAI_FUGOU ? RANKING_DAI_HINMIN : RANKING_HINMIN;
                let exist_loser = -1 !== cfunc.indexOfPlayers('ranking', loser_ranking, players);
                //自分が勝者、且つ交換対象は存在する（落ちてない）
                btn_visible_value = i_am_winner && exist_loser;
            }
        }
        //対象が自分ならUIのボタン表示
        _ui.setButtonVisibled(btn_visible_value);
        //カードが配られたらuiのロック解除
        _ui.controllLock(false);
    }
    /**
     * カードの交換を行います。自プレイヤーが平民以外の場合に
     * 実行されます。
     * @param {{request_id: number, send_card_indexs:number[], receive_card_indexs:number[]}} data
     */
    function reqExchangeCards(data:cfunc.IArgmentSocketEvent){
        //send_cards == 自分が出すカード
        //receive_cards == 自分が受け取るカード
        let p_index = cfunc.indexOfPlayers('name', _my_username, _playing_data.players);
        if(p_index !== -1){
            cfunc.exchangeCards(_playing_data.players[p_index], null, data.send_card_indexs, data.receive_card_indexs, _card_case);
            //uiにも適用
            let new_card_indexs:number[] = [];
            cfunc.extractCardIndex(_playing_data.players[p_index].cards, new_card_indexs);
            _ui.setMyHand(new_card_indexs);
        }else{
            console.log('Error!!:reqExchangeCards()->不正な呼び出し');
        }
    }
    /**
     * ラウンドの開始処理を行います。
     * ※このメソッドは"カード交換"の後に呼び出されます。
     * ※"カード交換"が発生しない（カードを配り終えた後、交換なしにゲーム開始）場合
     * このメソッドが呼び出されなくても、ラウンドは開始されます。
     * @param {{request_id:number, start_msec:number}} data
     */
    function reqStartRound(data:cfunc.IArgmentSocketEvent){
        //開始準備
        cfunc.setStartRound(_playing_data);
        _func_add_log_message('ラウンド' + _playing_data.round_status.now_round + ' スタート！');
        //タイマーをセットする
        setTimer(data.start_msec, _playing_data.settings.thinking_time * 1000, updateThinkingTime);//シンキングタイム
        //対象が自分ならUIのボタン表示
        _ui.setButtonVisibled(_playing_data.round_status.now_order_name === _my_username);
    }
    /**
     * 自分の分のカードを取得しセットします。毎ラウンドきます。
     * @param {{request_id:number, card_indexs:number[]}} data
     */
    function reqDealMyCards(data:cfunc.IArgmentSocketEvent){
        let players = _playing_data.players,
            index = cfunc.indexOfPlayers('name', _my_socket.username, players),
            card_indexs = data.card_indexs;
        if(index === -1){
            console.log("Error: reqDealMyCards(): 参加してないのにカードが配られた！！おかしい！");
        }
        //カードケースからカードオブジェクトを取得
        let player = players[index];
        if(!player.cards){//初回時は無い
            player.cards = [];
        }
        cfunc.pickupFromCardCaseMultiple(_card_case, player.cards, card_indexs);
        player.card_length = card_indexs.length;
        //exchange で　自分が　貧民　Or　大貧民

        //UIにも反映させる
        _ui.setMyHand(card_indexs);
    }
    /**
     * 指定されたカードを出します。
     * チャットに表示します。自分自身なら、UIの手札も操作します。
     * @param {{request_id:number, putdown_indexs:number[], start_msec:number}} data
     */
    function reqPutDownCards(data:cfunc.IArgmentSocketEvent){
        const CARD_SCALE = 0.25;
        clearTimeout(_my_timer.id);//妥当ではない ID を渡しても、何の効果もありません（また、例外はスローされません）。
        let index = cfunc.indexOfPlayers('name', _playing_data.round_status.now_order_name, _playing_data.players);
        let player = _playing_data.players[index];
        let putdown_indexs = data.putdown_indexs,
            chat_data:IArgmentChatData = {
                username        : player.name,
                img_avater_index: player.img_avater_index,
                images          : [],
                scale           : CARD_SCALE
            },
            i = 0,
            len = putdown_indexs.length;
        for(i=0;i<len;i++){
            chat_data.images.push(_ui.getCardImage(putdown_indexs[i]));
        };
        //カードをチャットへ表示
        _func_add_chat_message(chat_data);
        //自分の手札ならUIも反映させる
        if(player.name === _my_username){
            _ui.putDownMyHand(putdown_indexs);
        }
        //playing_dataの処理(ここまでくれば、全てエラーなしの筈)
        let cards           :cfunc.CardObject[] = [];
        cfunc.pickupCardsFromNowOrderPlayer(_playing_data, _card_case, cards, putdown_indexs);
        let card_content    :cfunc.PutDownCardsContentObject = cfunc.analyzeRankOfCards(cards, _playing_data);
        _event_list.splice(0, _event_list.length);
        let res             :number = cfunc.putDownCards(card_content, _playing_data, _card_case, _event_list);
        addEventLogToChat();//イベント処理
        //出すことによってどうなったか？
        doNextPhase(res, data.start_msec);
    }
    /**
     * resultの値によって処理を行います。
     * reqPutDownCards()もしくは reqLeaveInMidway()から呼び出されます。
     * @param {number} result
     * @param {number} start_msec
     */
    function doNextPhase(result:number, start_msec:number){
        switch(result){
            case EV_NEXT_TURN:
                setTimer(start_msec, _playing_data.settings.thinking_time * 1000, updateThinkingTime);//シンキングタイム
                //対象が自分の番になるならUIのボタン表示
                _ui.setButtonVisibled(_playing_data.round_status.now_order_name === _my_username);
                break;
            case EV_END_ROUND:
                setTimer(start_msec, ROUND_INTERVAL * 1000, updateDisplayResultTime);//結果表示タイム
                _ui.controllLock(true);
                if(_joined_game){
                    resetResultTable();
                    setTimeout(openDialogResult, 1000);//1秒後に表示
                }
                break;
            case EV_END_GAME:
                _ui.controllLock(true);
                resetResultTable();
                setTimeout(openDialogResult, 1000);//1秒後に表示
                rankingAsLogMessage();
                clearGameData();
                break;
        }
    }
    /**
     * ゲームの順位をメッセージログとして追加します。
     * ※playing_dataが削除される前に行ってください。
     */
    function rankingAsLogMessage(){
        if(!_playing_data || _playing_data.players.length === 0) return;
        let rankers = [],
            i = 0,
            ii = 0,
            len = _playing_data.players.length,
            lenlen = 0,
            player = null;
        //total_scoreを算出して、rankersにpush
        for(i = 0; i<len; i++){
            player = _playing_data.players[i];
            lenlen = player.score.length;
            player.total_score = 0; //propertyの追加
            for(ii=0; ii<lenlen; ii++){
                player.total_score = player.total_score + player.score[ii];
            }
            rankers.push(player);
        }
        //total_scoreの高い順からソート
        rankers.sort(compareRankerForRankingAsLogMessage);
        //一位から三位までログに追加
        let ranking_num = 1,
            now_score = rankers[0].total_score,
            message = "";
        for(i=0; i<len; i++){
            player = rankers[i];
            if(now_score > player.total_score){
                ranking_num++;//2,3
                now_score = player.total_score;
                if(ranking_num > 3) break;  //3位まで
                //順位が1ランク下がった時 i > 2 ならbreak
                if(i > 2) break;
            }
            message = ranking_num + "位 " + player.name;
            _func_add_log_message(message);
        }
        //後処理
        for(i=0; i<len; i++){
            player = rankers[i];
            rankers[i] = null;
            delete player.total_score;
        }
        rankers.splice(0, len);
        rankers = null;
    }
    /**
     * ランカーソート用関数です。
     * PlayerObjectには"total_score"というプロパティが追加されていることが前提です。
     * total_scoreの高い順に並び替えをします。
     * @param {PlayerObject} a
     * @param {PlayerObject} b
     */
    function compareRankerForRankingAsLogMessage(a:cfunc.PlayerObject, b:cfunc.PlayerObject){
        let res = b.total_score - a.total_score;
        return res;
    }
    /**
     *
     * @param {{request_id:number, start_msec:number}} data
     */
    function reqPassTurn(data:cfunc.IArgmentSocketEvent){
        clearTimeout(_my_timer.id);//妥当ではない ID を渡しても、何の効果もありません（また、例外はスローされません）。
        let index = cfunc.indexOfPlayers('name', _playing_data.round_status.now_order_name, _playing_data.players);
        let player = _playing_data.players[index];
        let chat_data:IArgmentChatData = {
            message: "パス！",
            username: player.name,
            img_avater_index: player.img_avater_index
        };
        //チャットへ表示
        _func_add_chat_message(chat_data);
        //パスを処理する
        _event_list.splice(0, _event_list.length);
        cfunc.passTurn(_playing_data, _card_case, _event_list);
        addEventLogToChat();//イベント処理
        //カードが流れたかチェックする(カードが流れる演出時間分を考慮するため)
        let effect_time_clean_cards = 0;
        if(_playing_data.round_status.putdown_history.length === 0){
            effect_time_clean_cards = 2;//秒（仮
        }
        //次のタイマーをセットする(1秒の余裕を持たす)
        setTimer(data.start_msec, _playing_data.settings.thinking_time * 1000, updateThinkingTime);//シンキングタイム
        //対象が自分の番になるならUIのボタン表示
        _ui.setButtonVisibled(_playing_data.round_status.now_order_name === _my_username);
    }
    /**
     * ゲームを中断します
     * @param {{request_id:number}} data
     */
    function reqCancelGame(data:cfunc.IArgmentSocketEvent){
        //ダイアログが開いていたら閉じます
        closeDialogAll();
        clearGameData();
    }
    /**
     * 途中入室した際に送られてきます。
     * playing_data.prog_statusから判断します。
     * 基本的に進行はServerからの送信待ちなので、何もしなくて良い。
     * @param {{request_id:number,playing_data:PlayingDataObject,start_msec:number}} data
     */
    function reqJoinInMidway(data:cfunc.IArgmentSocketEvent){
        //各登録済プレイヤーのアバター画像を設定
        let playing_data = data.playing_data,
            prog_status = playing_data.prog_status,
            settings = playing_data.settings,
            players = playing_data.players,
            i = 0,
            len = players.length;
        for(i=0; i<len; i++){
            players[i].img_avater = _func_get_img_avater(players[i].img_avater_index);
        }
        //まだ募集中ならdlgRecruitを表示、そうでないならplaying_dataだけ設定
        if(prog_status === ST_RECRUIT){
            reqOpenDialogRecruit(data);//※内部でsetPlayingDataAndUI()を実行している。
             //dialog更新
            $dlg_parts_recruit.players_count.text(players.length);
        }else{
            setPlayingDataAndUI(playing_data);
            //ST_PLAYINGならタイマーセット
            if(prog_status === ST_PLAYING){
                setTimer(data.start_msec, settings.thinking_time * 1000, updateThinkingTime);
            }
        }
    }
    /**
     * 現在プレイ中のプレイヤーが退室した際に送られてきます。
     * ※プレイヤーが退室することで最低人数を割る場合はこのメソッドは実行されません。
     * ※進行状況によりこのメソッドの後、サーバから別の命令が送信されることがあります。
     * @param {{request_id:number,leave_player_index:number,start_msec:number}} data
     */
    function reqLeaveInMidway(data:cfunc.IArgmentSocketEvent){
        if(!_playing_data) return;
        _event_list.splice(0, _event_list.length);
        let now_prog_status = _playing_data.prog_status,//現在の進行状況を確保（leavePlayerInMidway()内部で値が変わってしまうかもしれないから）
            res = cfunc.leavePlayerInMidway(_playing_data, data.leave_player_index, _card_case, _event_list);
        if(now_prog_status === ST_PLAYING){
            addEventLogToChat();//イベント処理
            doNextPhase(res, data.start_msec);
        }
    }
    /**
     * _playing_dataを破棄します。
     * UIも消します。
     */
    function clearGameData(){
        clearTimeout(_my_timer.id);//妥当ではない ID を渡しても、何の効果もありません（また、例外はスローされません）。
        if(!_playing_data) return;
        let res = cfunc.backToCardCaseAll(_playing_data, _card_case);//card_caseに全てのカードを戻す
        if(!res){
            console.log("Fatal Error!!:backToCardCaseAll() -> false");
        }
        //手動でPlayerObjectに追加したimg_avaterの消去
        let players = _playing_data.players,
            i = 0,
            len = players.length,
            player = null;
        for(i=0;i<len;i++){
            player = players[i];
            player.img_avater = null;
            delete player.img_avater;
        }
        //free
        cfunc.freePlayingData(_playing_data);
        _playing_data = null;
        _joined_game = false;
        _ui.setActive(false, null);
        if(_func_change_rayout){
            _func_change_rayout();
        }
    }
    /**
     * 全ての大富豪関連のダイアログを閉じます
     */
    function closeDialogAll(){
        if($dlgDaifugouRecruit && $dlgDaifugouRecruit.dialog('isOpen')){
            $dlgDaifugouRecruit.dialog('close');
        }
        if($dlgDaifugouSettings && $dlgDaifugouSettings.dialog('isOpen')){
            $dlgDaifugouSettings.dialog('close');
        }
        if($dlgDaifugouResult && $dlgDaifugouResult.dialog('isOpen')){
            $dlgDaifugouResult.dialog('close');
        }
    }

    /**
     * 初期設定。一度だけ呼び出します。
     * @param {Function} func_add_chat_message チャットする時に呼び出したい関数。
     * @param {Function} func_add_log_message ログを表示する時に呼び出したい関数。
     * @param {Function} func_change_rayout UI表示・非表示時に呼び出したい関数。
     * @param {Function} func_get_img_avater playerのavaterイメージを取得するとき呼び出す関数。
     * @param {Function} func_set_pos_dlg windowの縮尺が１未満だとダイアログの表示位置が左にずれ込むので補正した値を設定する関数。
     * @param {Object} parent_element UIの表示領域(canvas)を追加するための要素(Element)
     * @param {Function} func_finished 設定が完了した際に呼び出したい関数。
     */
    function setup( func_add_chat_message:(value:IArgmentChatData)=>void,
                    func_add_log_message:(message:string, options?:any)=>void,
                    func_change_rayout:()=>void,
                    func_get_img_avater:(index:number)=>HTMLImageElement,
                    func_set_pos_dlg:(dlg:JQuery)=>void,
                    parent_element:HTMLElement,
                    func_finished:()=>void){
        _func_add_chat_message = func_add_chat_message;
        _func_add_log_message = func_add_log_message;
        _func_change_rayout = func_change_rayout;
        _func_get_img_avater = func_get_img_avater;
        _func_set_pos_dlg = func_set_pos_dlg;
        let parent_element_name = '#' + parent_element.id;
        createSettingDialog(parent_element_name);
        createRecruitDialog(parent_element_name);
        createResultDialog(parent_element_name);
        setInitData();
        _ui.setup(parent_element, doMyAction, func_finished);
    }
    /**
     * 基本設定をcfunc_daifugouから読み込みます。
     */
    function setInitData():void{
        _settings_set = cfunc.createSettingsSetObject();
        _option_rules = cfunc.createOptionRulesObject();

        $dlg_parts_settings.lst_symbol_lock_count.prop('disabled', true);

        setset($dlg_parts_settings.lst_recruit_time,
                _settings_set.max.recruit_time,
                _settings_set.min.recruit_time,
                _settings_set.def.recruit_time,
                _settings_set.per.recruit_time);
        setset($dlg_parts_settings.lst_players_count,
                _settings_set.max.players_count,
                _settings_set.min.players_count,
                _settings_set.def.players_count,
                _settings_set.per.players_count);
        setset($dlg_parts_settings.lst_round_count,
                _settings_set.max.round_count,
                _settings_set.min.round_count,
                _settings_set.def.round_count,
                _settings_set.per.round_count);
        setset($dlg_parts_settings.lst_thinking_time,
                _settings_set.max.thinking_time,
                _settings_set.min.thinking_time,
                _settings_set.def.thinking_time,
                _settings_set.per.thinking_time);
        function setset($select:JQuery, max:number, min:number, def:number, per:number){
            let val = min,
                txt_val = "",
                $option = null;
            while(val <= max){
                txt_val = val.toString();
                $option = $('<option>').val(txt_val).text(txt_val);
                if(val === def){
                    $option.prop('selected', true);
                }
                $select.append($option);
                val = val + per;
            }
        }

    }

    //結果表示時ののカウントダウン文字更新
    function updateDisplayResultTime(msec:number):void{
        $dlg_parts_result.time.text((msec / 1000).toFixed(0));
    }
    //考え中のカウントダウン文字更新
    function updateThinkingTime(msec:number):void{
        let max_msec = _playing_data.settings.thinking_time * 1000;
        _ui.setlimitTime(msec, max_msec);
    }
    //募集時のカウントダウン文字更新
    function updateRecruitTime(msec:number):void{
        $dlg_parts_recruit.time.text((msec / 1000).toFixed(0));
        _ui.setlimitTime(msec, msec);
    }
    /**
     * タイマー実行。updateCountDownTime()を直接呼び出すのでなく、こちらから実行する。
     * @param {number} start_msec
     * @param {number} max_msec
     * @param {Function} update_func
     */
    function setTimer(start_msec:number, max_msec:number, update_func:(msec:number)=>void):void{
        clearTimeout(_my_timer.id);//妥当ではない ID を渡しても、何の効果もありません（また、例外はスローされません）。
        //最大ミリ秒をセット
        _my_timer.start_msec = start_msec;
        _my_timer.max_msec = max_msec;
        //Loop関数
        updateCountDownTime(100, update_func);
    }
    /**
     * 汎用のカウントダウンタイマーです。
     * この関数からのイベント進行はありません。進行はすべてサーバから指示が来ます。
     * ※この関数を直接呼び出さないでください。代わりにsetTimer()を実行してください。
     * @param {number} interval         更新間隔をmsecで指定します。
     * @param {Function} update_func    更新時に実行したい関数です引数として現在のカウントを渡します。
     */
    function updateCountDownTime(interval:number, update_func:(msec:number)=>void):void{
        _my_timer.id = window.setTimeout(function(){//NodeJS.Timerが返るNodeJS.setTimeoutもあるので注意
            let t = _my_timer.max_msec - (Date.now() - _my_timer.start_msec);
            if(t < 0){
                t = 0;
            }
            if(update_func){
                update_func(t);
            }
            if(t !== 0){
                updateCountDownTime(interval, update_func);
            }
        } , interval);
    }

    /**
     * 設定用のダイアログを作成します。一度だけ呼び出されます。
     * @param {string} parent_name
     */
    function createSettingDialog(parent_name:string){
        //ダイアログ作成
        let txt =
        '<div class="j-ui-dialog dlgDaifugouSettings">'+
            '<fieldset>'+
            '<legend >基本</legend>'+
            /*
            '<div>募集時間 <input type="number" class="ipt_recruit_time" step="1" style="width: 64px;"/>秒</div>'+
            '<div>プレイ人数 <input type="number" class="ipt_players_count" step="1" style="width: 64px;"/>名</div>'+
            '<div>ラウンド数 <input type="number" class="ipt_round_count" step="1" style="width: 64px;"/>回</div>'+
            '<div>思考時間 <input type="number" class="ipt_thinking_time" step="1" style="width: 64px;"/>秒</div>'+
            */
            '<div>募集時間 <select class="lst_recruit_time" style="width: 64px;"></select>秒</div>'+
            '<div>プレイ人数 <select class="lst_players_count" style="width: 64px;"></select>名</div>'+
            '<div>ラウンド数 <select class="lst_round_count" style="width: 64px;"></select>回</div>'+
            '<div>思考時間 <select class="lst_thinking_time" style="width: 64px;"></select>秒</div>'+
            '</fieldset>'+
            '<fieldset>'+
            '<legend>追加ルール</legend>'+
            '<div><label><input type="checkbox" class="chk_symbol_lock" value="1" />' + LBL_SYMBOL_LOCK + ' -----------------> </label>'+
            //'<div style="float: right;"><input type="number" class="ipt_symbol_lock_count" step="1" value = "2" style="width: 32px;"/>連続</div></div>'+
            '<div style="float: right;"><select id="lst_symbol_lock_count" style="width: 80px;"><option value="1">2連続</option><option value="2">3連続</option></select></div></div>'+

            '<div><label><input type="checkbox" class="chk_discard_8" value="1" />' + LBL_DISCARD_8 + '</label></div>'+
            '<div><label><input type="checkbox" class="chk_must_always_win" value="1" />' + LBL_MUST_ALWAYS_WIN + '</label></div>'+
            '<div><label><input type="checkbox" class="chk_eleven_back" value="1" />' + LBL_ELEVEN_BACK + '</label></div>'+
            '<div><label><input type="checkbox" class="chk_s3_win_joker" value="1" />' + LBL_S3_WIN_JOKER + '</label></div>'+
            '</fieldset>'+
        '</div>';
        $('body').append(txt);

        $dlgDaifugouSettings = $('.j-ui-dialog.dlgDaifugouSettings');

        $dlgDaifugouSettings.dialog({
            autoOpen: false,
            modal: true,
            width: _dialog_default_width,
            title: "大富豪のルール設定",
            buttons: {
                "開始": ()=>{
                    /*
                    _settings_set.val.recruit_time = parseInt($dlg_parts_settings.ipt_recruit_time.val());
                    _settings_set.val.players_count = parseInt($dlg_parts_settings.ipt_players_count.val());
                    _settings_set.val.round_count = parseInt($dlg_parts_settings.ipt_round_count.val());
                    _settings_set.val.thinking_time = parseInt($dlg_parts_settings.ipt_thinking_time.val());
                    */
                    _settings_set.val.recruit_time = parseInt($dlg_parts_settings.lst_recruit_time.val().toString(), 10);
                    _settings_set.val.players_count = parseInt($dlg_parts_settings.lst_players_count.val().toString(), 10);
                    _settings_set.val.round_count = parseInt($dlg_parts_settings.lst_round_count.val().toString(), 10);
                    _settings_set.val.thinking_time = parseInt($dlg_parts_settings.lst_thinking_time.val().toString(), 10);
                    let v = 0;
                    if($dlg_parts_settings.chk_symbol_lock.prop('checked')){
                        //v = parseInt($dlg_parts_settings.ipt_symbol_lock_count.val()) - 1;//2~3 -> 1~2
                        v = parseInt($dlg_parts_settings.lst_symbol_lock_count.val().toString(), 10);
                    }
                    _option_rules.symbol_lock = v;
                    v = 0; v = +$dlg_parts_settings.chk_discard_8.prop('checked');
                    _option_rules.discard_8       = v;          //0~1
                    v = 0; v = +$dlg_parts_settings.chk_must_always_win.prop('checked');
                    _option_rules.must_always_win = v;          //0~1
                    v = 0; v = +$dlg_parts_settings.chk_eleven_back.prop('checked');
                    _option_rules.eleven_back     = v;          //0~1
                    v = 0; v = +$dlg_parts_settings.chk_s3_win_joker.prop('checked');
                    _option_rules.s3_win_joker    = v;          //0~1
                    let data = {
                        request_id: REQ_ID_RECRUIT,
                        settings: _settings_set.val,
                        option_rules: _option_rules
                    };
                    _my_socket.emit('daifugou', data);
                    $dlgDaifugouSettings.dialog('close');
                },
                "中止": ()=>{
                    $dlgDaifugouSettings.dialog('close');
                }
            }
        });
        /*
        $dlg_parts_settings.ipt_recruit_time = $('.j-ui-dialog.dlgDaifugouSettings .ipt_recruit_time');
        $dlg_parts_settings.ipt_players_count = $('.j-ui-dialog.dlgDaifugouSettings .ipt_players_count');
        $dlg_parts_settings.ipt_round_count = $('.j-ui-dialog.dlgDaifugouSettings .ipt_round_count');
        $dlg_parts_settings.ipt_thinking_time = $('.j-ui-dialog.dlgDaifugouSettings .ipt_thinking_time');
        */
        $dlg_parts_settings.lst_recruit_time = $('.j-ui-dialog.dlgDaifugouSettings .lst_recruit_time');
        $dlg_parts_settings.lst_players_count = $('.j-ui-dialog.dlgDaifugouSettings .lst_players_count');
        $dlg_parts_settings.lst_round_count = $('.j-ui-dialog.dlgDaifugouSettings .lst_round_count');
        $dlg_parts_settings.lst_thinking_time = $('.j-ui-dialog.dlgDaifugouSettings .lst_thinking_time');

        $dlg_parts_settings.chk_symbol_lock = $('.j-ui-dialog.dlgDaifugouSettings .chk_symbol_lock');
        //$dlg_parts_settings.ipt_symbol_lock_count = $('.j-ui-dialog.dlgDaifugouSettings .ipt_symbol_lock_count');
        $dlg_parts_settings.lst_symbol_lock_count = $('.j-ui-dialog.dlgDaifugouSettings #lst_symbol_lock_count');
        $dlg_parts_settings.chk_discard_8 = $('.j-ui-dialog.dlgDaifugouSettings .chk_discard_8');
        $dlg_parts_settings.chk_must_always_win = $('.j-ui-dialog.dlgDaifugouSettings .chk_must_always_win');
        $dlg_parts_settings.chk_eleven_back = $('.j-ui-dialog.dlgDaifugouSettings .chk_eleven_back');
        $dlg_parts_settings.chk_s3_win_joker = $('.j-ui-dialog.dlgDaifugouSettings .chk_s3_win_joker');

        $dlg_parts_settings.chk_symbol_lock.on('click', function(){//'on()'は追加式
            //$dlg_parts_settings.ipt_symbol_lock_count.prop('disabled', !$dlg_parts_settings.ipt_symbol_lock_count.prop('disabled'));
            $dlg_parts_settings.lst_symbol_lock_count.prop('disabled', !$dlg_parts_settings.lst_symbol_lock_count.prop('disabled'));
        });

        //$dlgDaifugouSettings.dialog("option", "appendTo", "#dialogUIArea");//どこに所属させるか決定（Defaultはbody）
        //widgetに値を適用するために必要
        $dlgDaifugouSettings.dialog('open'); $dlgDaifugouSettings.dialog('close');
    }

    /**
     * 参戦用のダイアログを作成します。一度だけ呼び出されます。
     * @param {string} parent_name
     */
    function createRecruitDialog(parent_name:string){
        //ダイアログ作成
        let txt =
        '<div class="j-ui-dialog dlgDaifugouRecruit">'+
            '<fieldset class="default_rules">'+
            '</fieldset>'+
            '<fieldset class="option_rules">'+
            '</fieldset>'+
            '<p class="time" style="float:right">00</p><p style="float:right">開始まで・・・</p>'+
        '</div>';
        $('body').append(txt);

        $dlgDaifugouRecruit = $('.j-ui-dialog.dlgDaifugouRecruit');

        $dlgDaifugouRecruit.dialog({
            autoOpen: false,
            modal: true,
            width: _dialog_default_width,
            title: "大富豪参加者募集！",
            buttons: {
                "参加": ()=> {
                    _my_socket.emit('daifugou', {request_id: REQ_ID_JOIN_GAME});
                    $dlgDaifugouRecruit.dialog('close');
                },
                "観戦": ()=> {
                    $dlgDaifugouRecruit.dialog('close');
                }
            }
        });
        $dlg_parts_recruit.default_rules =  $('.j-ui-dialog.dlgDaifugouRecruit .default_rules');
        $dlg_parts_recruit.option_rules = $('.j-ui-dialog.dlgDaifugouRecruit .option_rules');
        $dlg_parts_recruit.time = $('.j-ui-dialog.dlgDaifugouRecruit .time');

        //$dlgDaifugouRecruit.dialog("option", "appendTo", "#dialogUIArea");//どこに所属させるか決定（Defaultはbody）
        //widgetに値を適用するために必要
        $dlgDaifugouRecruit.dialog('open'); $dlgDaifugouRecruit.dialog('close');
    }

    /**
     * 設定用ダイアログを開きます。
     */
    function openDialogSettings(){
        _func_set_pos_dlg($dlgDaifugouSettings);
        $dlgDaifugouSettings.dialog('open');
    }

    /**
     * 募集用ダイアログを開きます。
     */
    function openDialogRecruit(){
        _func_set_pos_dlg($dlgDaifugouRecruit);
        $dlgDaifugouRecruit.dialog('open');
    }

    /**
     * 結果表示用ダイアログを開きます。
     */
    function openDialogResult(){
        _func_set_pos_dlg($dlgDaifugouResult);
        $dlg_parts_result.time.text("");
        $dlgDaifugouResult.dialog('open');
    }

    /**
     * 現在の状況により異なるアクションを行います。
     * playing_data == null：設定用ダイアログを開きます。
     * playing_data.prog_status == ST_RECRUIT： (参加済 -> 何もしません。)
     *                                          (不参加 -> RecruitDialogを開きます。)
     * playing_data.prog_status != ST_RECRUIT：ResultDialogを開きます。
     */
    function openDialog(){
        if(_playing_data == null){
            openDialogSettings();
        }else{
            if(_playing_data.prog_status === ST_RECRUIT){
                if(_joined_game === false){
                    openDialogRecruit();
                }
            }else{
                resetResultTable();
                openDialogResult();
            }
        }

    }

    /**
     * 結果表示用のダイアログを作成します
     * @param {string} parent_name
     */
    function createResultDialog(parent_name:string){
        //ダイアログ作成
        let txt =
        '<div class="j-ui-dialog dlgDaifugouResult">'+
        '<table  border="0" cellspacing="1">' +
            '<thead>' +
            '</thead>' +
            '<tbody>' +
            '</tbody>'+
        '</table>'+
        '<p class="time" style="float:right">00</p>'+
        '</div>';
        $('body').append(txt);

        $dlgDaifugouResult = $('.j-ui-dialog.dlgDaifugouResult');
        $dlg_parts_result.tbl_head = $('div.j-ui-dialog.dlgDaifugouResult thead');
        $dlg_parts_result.tbl_body = $('div.j-ui-dialog.dlgDaifugouResult tbody');
        $dlg_parts_result.time = $('div.j-ui-dialog.dlgDaifugouResult .time');

        $dlgDaifugouResult.dialog({
            autoOpen: false,
            modal: true,
            resizable: true,
            width: _dialog_default_width,
            title: "結果表",
            buttons: {
                "OK": ()=>{
                    //playerで無ければ、送信する必要はない。
                    //Gameが終了していれば(_playing_data==nullなら)送信する必要はない。
                    //結果表示中で無ければ送信する必要はない
                    if(_joined_game && _playing_data && _playing_data.prog_status === ST_DISPLAY_RESULT){
                        _my_socket.emit('daifugou', {request_id: REQ_ID_RESULT_CLOSE});
                    }
                    $dlgDaifugouResult.dialog('close');
                }
            }
        });

        //$dlgDaifugouResult.dialog("option", "appendTo", "#dialogUIArea");//どこに所属させるか決定（Defaultはbody）
        //widgetに値を適用するために必要
        $dlgDaifugouResult.dialog('open'); $dlgDaifugouResult.dialog('close');
    }

    /**
     * 結果を入力します
     * @api private
     */
    function resetResultTable(){
        let players = _playing_data.players,
            settings = _playing_data.settings,
            i = 0,
            len = settings.round_count,
            ii = 0,
            lenlen = 0,
            lenlenlen = 0,
            player = null,
            txt = '',
            total = 0;
        //削除
        $('div.j-ui-dialog.dlgDaifugouResult thead tr').remove();
        $('div.j-ui-dialog.dlgDaifugouResult tbody tr').remove();
        //表題
        txt = '<tr><th>Player</th>';
        for(i=0; i<len; i++){
            txt = txt + '<th>' + (i + 1) + '回戦</th>';
        }
        txt = txt + '<th>計</th></tr>';
        $dlg_parts_result.tbl_head.append(txt);
        //プレイヤー評価
        len = players.length;
        lenlen = settings.round_count;
        lenlenlen = players[0].score.length;
        for(i=0; i<len; i++){
            player = players[i];
            total = 0;
            txt = '<tr><td>' + player.name + '</td>';
            for(ii=0; ii < lenlen; ii++){
                if(ii < lenlenlen){
                    total = total + player.score[ii];
                    txt = txt + '<td>' + player.score[ii] + '</td>';
                }else{
                    txt = txt + '<td>-</td>';
                }
            }
            txt = txt + '<td>' + total + '</td></tr>';
            $dlg_parts_result.tbl_body.append(txt);//行挿入
        }
        //幅計算
        let win_w = window.innerWidth - 16,
            dlg_w = DLG_RESULT_WIDTH_BASE + settings.round_count * DLG_RESULT_WIDTH_PER_ROUND_COUNT;
        if(dlg_w > win_w){
            dlg_w = win_w;
        }
        if(dlg_w < DLG_RESULT_MIN_WIDTH){
            dlg_w = DLG_RESULT_MIN_WIDTH;
        }
        $dlgDaifugouResult.dialog({width:dlg_w});

    }
    /**自分のクライアントのユーザー設定
     * @param {string} my_username
     */
    function setMyUserData(my_username:string){
        _my_username = my_username;
        _ui.setMyUserData(my_username);
    }

    return{
        socketEvent,
        setup,
        openDialog,
        setMyUserData,
        ui: _ui,
        clearGameData,
    };

}
