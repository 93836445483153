//サーバ⇔クライアント間での画像データのやり取り
export default function KRDataURLControl(){
    "use strict";
    const ERR_BIGGER_FILE_SIZE = '<p>画像が大きすぎます！</p><p>送信できるファイルサイズは1Mb（メガバイト）以下です。</p>';
    const ERR_NO_MATCH_TYPE = '<p>ファイル形式が違います！</p><p>選択できるファイルは"jpeg", "png", "gif"形式のみです。</p>';

    /*  jpegファイル内のExif.orientationを取得する。
        ※png, gifの場合はExif自体が無いので、undefindを返す。
        ※jpegでもExifが無い時もあるので、その時はundefindを返す.
        ※引数  imgDataURL: base64にエンコードしたDataURL形式
    */
    function getOrientationWithDataURL(imgDataURL:string){
        let byteString  :string = atob(imgDataURL.split(',')[1]);    //内容取得。詳しくは下記のDataURLの"中身(format)"を参照
        let orientaion  :number = byteStringToOrientation(byteString);
        return orientaion;

        function byteStringToOrientation(img:string){
            let head = 0;
            let orientation;
            while (1){
                if (img.charCodeAt(head) === 255 && img.charCodeAt(head + 1) === 218) {break;}
                if (img.charCodeAt(head) === 255 && img.charCodeAt(head + 1) === 216) {
                    head += 2;
                }
                else {
                    let length = img.charCodeAt(head + 2) * 256 + img.charCodeAt(head + 3);
                    let endPoint = head + length + 2;
                    if (img.charCodeAt(head) === 255 && img.charCodeAt(head + 1) === 225) {
                        let segment = img.slice(head, endPoint);
                        let bigEndian = segment.charCodeAt(10) === 77;
                        let count;
                        if (bigEndian) {
                            count = segment.charCodeAt(18) * 256 + segment.charCodeAt(19);
                        } else {
                            count = segment.charCodeAt(18) + segment.charCodeAt(19) * 256;
                        }
                        for (let i=0; i < count; i++){
                            let field = segment.slice(20 + 12 * i, 32 + 12 * i);
                            if ((bigEndian && field.charCodeAt(1) === 18) || (!bigEndian && field.charCodeAt(0) === 18)) {
                                orientation = bigEndian ? field.charCodeAt(9) : field.charCodeAt(8);
                            }
                        }
                        break;
                    }
                    head = endPoint;
                }
                if (head > img.length){break;}
            }
            return orientation;
        }
    }

    /*  Orientationの数値から画像を補正するための変換行列設定
        ※ここに来る前にキャンバスのサイズは設定しておくこと！
        dataURL: dataURL, img_type: String('image/jpg'以外は無い),
        orientation: number, callback: func(変換されたdataURL)
    */
    function getTransformDataURLByExifOrientation(dataURL:string, img_type:any, orientation:any, callback:any){
        //image作成
        let img = new Image(),//document.createElement('img')でも可
            canvas = document.createElement( 'canvas' ),
            context = canvas.getContext('2d');
        img.src = dataURL;
        //onload
        img.onload = function() {
            let w       :number = img.width,
                h       :number = img.height,
                wdiv2   :number = Math.floor(w / 2),
                hdiv2   :number = Math.floor(h / 2);
            //キャンバスサイズ設定
            if(orientation >= 5 && orientation <= 8){ //90 or 270度回転は高さと幅を交換
                canvas.width = h;
                canvas.height = w;
            }else{
                canvas.width = w;
                canvas.height = h;
            }
            //orentationの値による画像の補正方法
            switch(orientation){
                case 1://補正しない
                    break;
                case 2://上下反転
                    context.translate(0, h);
                    context.scale(1, -1);
                    break;
                case 3://180度回転
                    context.translate(w, h);
                    context.scale(-1, -1);
                    break;
                case 4://左右反転
                    context.translate(w, 0);
                    context.scale(-1, 1);
                    break;
                case 5://上下反転+時計周りに270度回転
                    context.translate(wdiv2, hdiv2);
                    context.rotate((270 * Math.PI) / 180);
                    context.translate(-wdiv2, -hdiv2);
                    context.translate(0, h);
                    context.scale(1, -1);
                    break;
                case 6://時計周りに90度回転
                    context.translate(wdiv2, hdiv2);
                    context.rotate((90 * Math.PI) / 180);
                    context.translate(-wdiv2, -hdiv2);
                    break;
                case 7://上下反転+時計周りに90度回転
                    context.translate(wdiv2, hdiv2);
                    context.rotate((90 * Math.PI) / 180);
                    context.translate(-wdiv2, -hdiv2);
                    context.translate(0, h);
                    context.scale(1, -1);
                    break;
                case 8://時計周りに270度回転
                    context.translate(wdiv2, hdiv2);
                    context.rotate((270 * Math.PI) / 180);
                    context.translate(-wdiv2, -hdiv2);
                    break;
            }
            //描画
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
            callback(canvas.toDataURL(img_type));
        }

    }

    //送信側:引数fileからDataURL(base64)を作成してcallbackで返す。
    //jpegでexifが含まれている場合、orientationの値によってDataURLを回転させる。
    //callbackの引数は(err_msg, dataURL)となる
    function createDataURLWithFile(file:Blob, callback:(err_msg:string, data_url:string)=>void){
        if (!file.type.match(/image.*/)) {  //画像形式のチェック
            callback(ERR_NO_MATCH_TYPE, null);
        }else
        if(file.size > 1000000){            //サイズチェック(base64にエンコードされると大体1.33倍になる)
            callback(ERR_BIGGER_FILE_SIZE, null);
        }else{
            let reader = new FileReader();
            //DataURL形式でで画像を読み込む
            reader.onload = function(event: Event) {
                let dataURL :string = (event.target as any).result;
                //0.file形式がjpgならorientationを取得
                if(file.type === 'image/jpeg'){
                    let orientation = getOrientationWithDataURL(dataURL);
                     //1.orientationが存在且つ1以外ならcancas作って変換したDataURLを作成
                    if(orientation && orientation !== 1){
                        getTransformDataURLByExifOrientation(dataURL, file.type, orientation, function(trans_dataURL:any){
                            callback(null, trans_dataURL);
                        });
                    }else{
                        callback(null, dataURL);
                    }
                }else{
                    callback(null, dataURL);
                }
            };
            reader.readAsDataURL(file);//base64にエンコードされたDataURLができる
        }
    }

    //受信側:dataURLから<canvas>要素を作成し返す。
    //こっちは受け取ったデータを何の処理もせずそのまま描画して返す
    function createCanvasElementWithDataURL(dataURL:string, callback:any){

        createImageElementWithDataURL(dataURL, function(img:any){
            let canvas = document.createElement( 'canvas' ),
                context = canvas.getContext( '2d' );
            if(img){
                //ロードし終わってからでないとキャンバスに描画されない
                let w = img.width,
                    h = img.height;
                canvas.width = w;
                canvas.height = h;

                context.drawImage(img, 0, 0, w, h);

                callback(canvas);
            }else{
                callback(null);
            }

        });
        /*
        if ( dataURL ) {
            let canvas = document.createElement( 'canvas' );
            let context = canvas.getContext( '2d' );
            let img = new Image();//document.createElement('img')でも可
            img.src = dataURL;
            img.onload = function() {
                // キャンバスに画像を描画
                // ロードし終わってからでないとキャンバスに描画されない
                let w = img.width,
                    h = img.height;
                canvas.width = w;
                canvas.height = h;

                context.drawImage(img, 0, 0, w, h);

                callback(canvas);
            }
        }else{
            callback(null);
        }*/
    }

    //dataURLからimage要素作成
    function createImageElementWithDataURL(dataURL:string, callback:any){
        if ( dataURL ) {
            const IMG = new Image();//document.createElement('img')でも可
            IMG.src = dataURL;
            IMG.onload = () => {
                callback(IMG);
            }
        }else{
            callback(null);
        }
    }


    return{
        createCanvasElementWithDataURL,
        createDataURLWithFile,
        createImageElementWithDataURL,
    }
/* ◆<canvas>要素について
    0.   HTML の <canvas> 要素は、スクリプティング (基本的には JavaScript) によりグラフィックを描画することができます。
        例えば、グラフの描画、写真の合成、さらにはアニメーションの作成までが可能です。
        <canvas> のブロック内で、代替コンテンツを提供することが可能 (また、提供すべき) です。
        その内容物は、canvas をサポートしない古いブラウザおよび JavaScript が無効であるブラウザで描画されます。
    1.   img要素の内容をCanvasに読み込んだり、Canvasの画像をimg要素に書き出す機能があります。
        Canvasからの書き出しは「pngなど画像ファイルデータ形式の文字列」を介して行うため、Canvasの内容を文字列として取得する
        （サーバーやWebブラウザのローカルストレージに保存する）ことも可能です。
        img -> canvas   :drawImage()
        canvas -> img   :toDataURL() Data URL形式
    2.  座標（行列）変換は逆から実行されることに注意しないといけない。例えばcontext(以下ctx)を
        ctx.translate(a, b);
        ctx.rotate(c);
        ctx.translate(e, f);
        の順で実行すると、(e, f)に移動 -> (c)回転 -> (a, b)に移動
        と、実行したところに描画されることになる。
    3.  行列の初期化 -> context.setTransform(1,0,0,1,0,0);

    ☆スタイルの設定方法いろいろ
    elt.style.cssText = "margin: 1em; padding: 1em; border: 1em;";
    elt.setAttribute("style", "color: blue; margin: 1em;");
    elt.style.color = "blue";

    ◆Data URL形式(data部分-->base64エンコード)について
    0.  中身(format)-> "data:[<mediatype>][;base64],<data>" -> "data:image/jpeg;base64,asldkfaslkdfasldk...(文字列)"
    1.  エンコードによるサイズの変化 -> バイナリ(元画像)のサイズを 3 で割り、小数を切り上げます。さらに 4 を掛けた結果が、Base64 の総サイズとなります。
*/
}
