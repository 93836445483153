import * as Kr from "./kr_library";

//public interface
export interface IDiceDefaultRollOptions{
    results     : number[];
    callback    :()=>void;
    [key:string]:any;
}

//クロージャに書き換え
export default function KRAnimeteDice(init_data:any){
    "use strict";
//private interface---------------------------------------------------------
    interface IDice extends JQuery<HTMLElement>{
        kr:{
            res_cel_index   :number,
            now_time        :number,
            old_time        :number,
            total_time      :number,
            active          :boolean,
            animete_options :{
                duration    :number,
                complete    :any
            }
        }
    }
//init----------------------------------------------------------------------

    //const Dice移動範囲
    let AREA_W      :number = 500;
    let AREA_H      :number = 500;
    //create data_pic
    let pic_w       :number = init_data.picture_width;
    let pic_h       :number = init_data.picture_height;

    //画像情報
    var data_pic    :{[key:string]:number} = {};
    data_pic.cel_w = init_data.cel_width;
    data_pic.cel_h = init_data.cel_height;
    data_pic.roll_animete_start_index = init_data.roll_animete_start_index;
    data_pic.roll_animete_length = init_data.roll_animete_length;
    data_pic.roll_animete_speed = init_data.roll_animete_speed;
    data_pic.len_w = Math.floor(pic_w / data_pic.cel_w);
    //data_pic.len_h = Math.floor(pic_h / data_pic.cel_h);//<-これは使ってない
    data_pic.bk_size_w = 100 * pic_w / data_pic.cel_w;
    data_pic.bk_size_h = 100 * pic_h / data_pic.cel_h;
    data_pic.scale_w = 0.5;
    data_pic.scale_h = 0.5;


    //create other properties
    var MAX_ROLL                :number = 10;                           //サイコロ個数最大値
    var interval_id             :number = 0;                      //timer
    var now_Animete             :boolean = false;                     //実行中？
    var now_comp_count          :number = 0;                      //各サイコロの終了個数

    var default_roll_options    :IDiceDefaultRollOptions = {
        results: [1],                            //各サイコロの結果
        callback:()=>{}                    //全ての処理が終わった後実行するcallback関数
    };
    var roll_options            :IDiceDefaultRollOptions = null;
    var stocker                 :IDiceDefaultRollOptions[] = [];                            //スタッカー


    //create dices
    //サイコロを入れる親要素が指定されてれば、それにする
    var dices                   :IDice[] = [];
    let $parent_elm             :JQuery<HTMLElement> = init_data.parent_element != null ? init_data.parent_element : $('body');
    //append
    let i                       :number = 0;
    let count                   :number = 10;
    let s0                      :string = "<div class='dice d";
    let s1                      :string = "' />";
    let s                       :string = "";
    for(i = 0; i < count; i++){
        s = s + s0 + i + s1;    //<div class='dice d0'/><div class='dice d1'/>...<div class='dice d9'/>
    }
    $parent_elm.append(s);
    let $dice                   :IDice = null;
    for(i = 0; i < count; i++){
        //selector
        $dice = $(".dice.d" + i) as IDice;
        //set properties
        $dice.kr = {
            res_cel_index   : 0,        //出目
            now_time        : 0,        //現在時間
            old_time        : 0,        //過去時間
            total_time      : 0,        //総アニメ時間
            active          : false,    //回転中
            animete_options : {         //roll animeteのoptions
                duration: 100,
                complete: cbCompleteAnimete.bind($dice)
            }
        };                //汚染防止用
        //set size
        $dice.css({width: data_pic.cel_w * data_pic.scale_w + "px", height: data_pic.cel_h * data_pic.scale_h + "px", backgroundSize: data_pic.bk_size_w + "% " + data_pic.bk_size_h + "%"});
        dices[i] = $dice;
    }

    //create points
    let def_points = [[-60,0],[-200,-30],[-300,0],[-360,-20],[-430,0],[-480, 0]];
    let end_points = [[70,60],[150,40],[250,40],[330,50],[40,160],[100,130],[200,110],[280,100],[360,120],[90,210],
                      [160,160],[230,180],[300,170],[60,290],[140,250],[220,260],[290,250],[60,360],[130,320],[200,320]];
    let start_points = [[410,410],[440,400],[400,430],[430,420],[460,420],
                        [400,460],[440,450],[420,470],[450,470],[470,450]];
    let e_len = end_points.length;
    let s_len = start_points.length;
    let scale = {x:0, y:0};
    let radian = 0;
    let move = {x:0, y:0};
    let s_pos = {x:0, y:0};
    let e_pos = {x:0, y:0};
    let index = 0;
    let l = 0;
    let def_l = Math.abs(def_points[def_points.length - 1][0]);//基本の長さ
    var trace_points        :number[][][] = [];
    //screenの中心へ来るように移動補正分(これはroll毎に補正計算を行うに変更：画面サイズが変わってるかもしれないから)
    /*
    let xx = (window.innerWidth - AREA_W) / 2;
    let yy = (window.innerHeight - AREA_H) / 2;
    */
    //軌跡作成
    for(i=0; i < e_len; i++){
        //start位置設定
        index = Math.floor(Math.random() * s_len);
        s_pos.x = start_points[index][0];
        s_pos.y = start_points[index][1];
        //end位置設定
        e_pos.x = end_points[i][0];
        e_pos.y = end_points[i][1];
        //scale設定
        l = Kr.Geometry.getLength2D(s_pos, e_pos);
        scale.x = scale.y = l / def_l;
        //radianの設定
        radian = Kr.Geometry.getRadianFrom2Point(s_pos, e_pos) - Math.PI;
        //moveの設定
        /*
        move.x = s_pos.x + xx;
        move.y = s_pos.y + yy;
        */
        move.x = s_pos.x;
        move.y = s_pos.y;
        //軌跡を作成
        trace_points[i] = createPoints(def_points, scale, radian, move);
    }


//functions------------------------------------------------------------------------------------
    //public 現在Anime実行中です
    function nowAnimete(){
        return now_Animete || stocker.length > 0;
    }

    //public 最大個数
    function maxLength(){
        return MAX_ROLL;
    }

    //コロコロ開始 呼び出し元:AnimeteDice
    function roll(options:IDiceDefaultRollOptions){
        //Anime中ならスタッカーへ
        if(now_Animete){
            if(stocker.length < 20){
            stocker.push(options);
            }
            return;
        }
        roll_options = $.extend({}, default_roll_options, options);

        //roll前に毎回画面サイズ計算
        let start_dice_x = (window.innerWidth - AREA_W) / 2;
        let start_dice_y = (window.innerHeight - AREA_H) / 2;

        let $dice = null;
        let scale = {x:0, y:0};
        let angle = 0;
        let move = {x:0, y:0};
        let len = roll_options.results.length < MAX_ROLL ? roll_options.results.length : MAX_ROLL;
        let temp_trace_points = [];
        let index = 0;
        let points = null;
        let time = 0;
        let i = 0;
        for(i = 0; i < len; i++){
            $dice = dices[i];
            //init
            $dice.css({display: "block", opacity: 1});
            $dice.kr.res_cel_index = roll_options.results[i] - 1;   //出目
            $dice.kr.now_time = Date.now();                         //現在時間
            $dice.kr.old_time =  $dice.kr.now_time;                 //過去時間
            $dice.kr.total_time = 0;                                //総アニメ時間
            $dice.kr.active = true;                                 //回転中
            //set cel
            setCelIndex($dice, data_pic.roll_animete_start_index);
            //set animete
            index = Math.floor(Math.random() * trace_points.length);
            points = trace_points[index];
            trace_points.splice(index, 1);
            temp_trace_points.push(points);
            time = 100 + 20 * (1 - Math.random() * 2);
            $dice.kr.animete_options.duration = time;
            $dice.css({left: points[0][0] + start_dice_x, top: points[0][1] + start_dice_y});
            $dice.animate({left: points[1][0] + start_dice_x, top: points[1][1] + start_dice_y}, time * 2)
                 .animate({left: points[2][0] + start_dice_x, top: points[2][1] + start_dice_y}, time * 2)
                 .animate({left: points[3][0] + start_dice_x, top: points[3][1] + start_dice_y}, time)
                 .animate({left: points[4][0] + start_dice_x, top: points[4][1] + start_dice_y}, time)
                 .animate({left: points[5][0] + start_dice_x, top: points[5][1] + start_dice_y}, $dice.kr.animete_options);
            //現在Anime中ですよ
            now_Animete = true;
        }
        //元に戻す
        len = temp_trace_points.length;
        for(i = 0; i < len; i++){
            trace_points.push(temp_trace_points[i]);
        }
        temp_trace_points.splice(0);

        //コロコロセル更新
        //interval_id = setInterval(func_interval, 16);//直接update()を渡しちゃだめ(bindしないとthisはAnimeteDiceでなくなる)
        interval_id = window.setInterval(update, 16);
    }

    //コロコロセル更新 呼び出し元:interval_id
    function update(){
        let i = 0;
        let len = roll_options.results.length;
        let $dice = null;
        let now_time = Date.now();
        let kr = null;
        let index = 0;
        let offset = null;
        let zIndex = 0;
        for(i=0; i < len; i++){
            $dice = dices[i];
            kr = $dice.kr;
            if(!kr.active)continue;

            kr.now_time = now_time;
            kr.total_time = (kr.total_time + kr.now_time - kr.old_time) % 10000;
            kr.old_time = kr.now_time;
            index = Math.floor(kr.total_time / data_pic.roll_animete_speed) % data_pic.roll_animete_length + data_pic.roll_animete_start_index;
            //set cel
            setCelIndex($dice, index);
            //z-indexの設定
            offset = $dice.offset();
            zIndex = Math.floor(offset.top + offset.left / 4);//× $dice.prop("top"); <-この方法だと"256px"と文字列が返ってしまう
            $dice.css({"z-index": zIndex});
        }

    }


    //軌跡計算 呼び出し元:AnimeteDice
    function createPoints(def_points:number[][], scale:{x:number,y:number}, radian:number, move:{x:number,y:number}):number[][]{
        let points = [[0,0],[0,0],[0,0],[0,0],[0,0],[0, 0]];
        let p = {x:0, y:0};
        let len = def_points.length;
        //let rad = Kr.Geometry.degToRad(angle);
        let i = 0;
        for(i=0; i < len; i++){
            p.x = def_points[i][0];
            p.y = def_points[i][1];
            Kr.Geometry.transformGrid2D(p, scale, radian, move);
            points[i][0] = p.x;
            points[i][1] = p.y;
        }

        return points;
    }

    //画像をセルインデックスにセット 呼び出し元:任意
    //target:$dice, index:cel_index
    function setCelIndex(target:IDice, index:number){
        let ptn_x = index % data_pic.len_w;
        let ptn_y = Math.floor(index / data_pic.len_w);
        let pos_w = - ptn_x * data_pic.cel_w * data_pic.scale_w;
        let pos_h = - ptn_y * data_pic.cel_h * data_pic.scale_h;
        let pos_wh = String(pos_w) + "px " + String(pos_h) + "px";

        target.css({'background-position':pos_wh});
    }

    //callback functions

    //アニメ完了->消去アニメ 呼び出し元:$dice
    function cbCompleteAnimete(){
        let kr = this.kr;
        //回転ストップ
        kr.active = false;
        //出目をセット
        setCelIndex(this, kr.res_cel_index);
        //消去アニメセット
        this.animate({opacity:1},1000)
            .animate({opacity:0},100, cbCompleteDisappear.bind(this));
    }

    //消去アニメ完了 呼び出し元:$dice
    function cbCompleteDisappear(){
        let ro = roll_options;
        now_comp_count++;
        let finished = now_comp_count === ro.results.length;
        let callback = ro.callback;
        this.css({display: "none"});
        //finish all?
        if(finished){
            now_Animete = false;
            now_comp_count = 0;
            clearInterval(interval_id);
            interval_id = null;
            callback();
            if(stocker.length > 0){
                roll(stocker.shift());
            }
        }
    }

    return {
        nowAnimete,
        maxLength,
        roll
    };


}
